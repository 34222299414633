import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import {Img} from "../Img";
import Title from "../Title";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import reactHtmlParser from "react-html-parser";
import {ImgP} from "../ImgP";
import ReactHtmlParser from "react-html-parser";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Benefits = ({data}) => {
    gsap.registerPlugin(ScrollTrigger, CSSPlugin);

    useEffect(() => {
        if(window.innerWidth>767){
            gsap.utils.toArray(".image_right").forEach((item, i) => {
                let getImg = item.querySelector('img')
                let parallaxSpeed = item.getAttribute('data-speed');
                gsap.to(getImg, {
                    yPercent: parallaxSpeed ? parallaxSpeed : 30,
                    ease: "none",
                    scrollTrigger: {
                        trigger: item,
                        // markers: true,
                        scrub: true
                    }
                });
            });
        }


    }, [data])

    return (
        <StyledTextWithImage background={"#F2F0F0"} className={`about_image_with_text pb-200`}>

            <Container className={'version_one'}>
                <Row className={'about_image_with_text_wrapper'}>
                    <Col md={5} className={'pr-0'}>
                        {
                            data?.title &&
                            <Title text={data?.title} fontSize={'60'} lineHeight={'72'} color={'#897059'}/>

                        }
                        <div className="version-one__text">
                            {
                                data?.description_plain &&
                                <p className={'split-up'}>
                                    {data?.description_plain}
                                </p>
                            }
                            {
                                data?.description_list &&
                                <div className="version-one__text__list">
                                    {ReactHtmlParser(data?.description_list)}
                                </div>
                            }


                        </div>
                    </Col>
                    <Col md={{span:4,offset:3}}>
                        <div className="image_wrapper_whole">
                            {
                                data?.image_upper &&
                                <div className="image_left ">
                                    <Img src={data?.image_upper}/>
                                </div>
                            }
                            {
                                data?.image_lower &&
                                <div className="image_right">
                                    <ImgP src={data?.image_lower}/>
                                </div>
                            }


                        </div>
                    </Col>
                </Row>

            </Container>
        </StyledTextWithImage>

    );


};


const StyledTextWithImage = styled.div`
  background-color: ${ColorBg};
  position: relative;

  .parallax_one {
    position: relative;
    z-index: 1;
  }

  .parallax_two {
    position: relative;
    z-index: 0;

  }


  .version_one {
    .small_title {
      margin-bottom: 0 !important;
      @media (max-width: 767px) {
        margin-bottom: 60px !important;

      }
    }
    

    .parallax_two {

      @media (max-width: 767px) {
        margin-top: 120px;
      }
    }

    .image_wrapper_whole {
      position: relative;

      .image_left {
        padding-top: calc(520 / 400 * 100%);
        position: relative;
        z-index: 1;
        bottom: 0;
        width: calc(100% + ${props => props.offset + "px" || '0'});
          // height:calc(100% + ${props => props.offset + "px" || '0'} ) ;
        right: -15px;
        @media (max-width: 767px) {
          //bottom: -50px;
          right: 0px;
          top: -50px;

        }
      }

      .image_right {
        padding-top: calc(320 / 400 * 100%);
        position: absolute;
        left: -120px;
        bottom: -80px;
        right: 0;
        width: calc(100% + ${props => props.offset + "px" || '0'});
        z-index: 0;
        width: 100%;
        @media (max-width: 767px) {
          left: -15px;
          bottom: 50px;
          width: calc(100% - 80px);
        }
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

  }

  .about_image_with_text_wrapper {
    p {
      margin-top: 60px;
      color: ${MidNightBlue};
      &:not(:nth-last-child(1)) {
        margin-bottom: 60px;
      }

    }
    @media (max-width: 767px) {
      .col-md-5{
        margin-top: 180px;
      }
      flex-direction: column-reverse;
      
      p{
        margin-top: 0;
        &:not(:nth-last-child(1)) {
          margin-bottom: 40px;
        }
      }
      h2{
        margin-top: 40px;
      }
    }
  }

  .version-one__text{
    margin-top: 60px;
    p{
      font-size: 16px;
      line-height: 24px;
      color: ${MidNightBlue};
    }
    &__list{
      ul{
        li{
          font-size: 16px;
          line-height: 24px;
          color: ${MidNightBlue};
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid #B2A89F;
          &:last-child{
            border-bottom: unset;
          }
        }
      }
    }
    @media(max-width: 767px){
      margin-top: 0px;
    }
  }
  


  @media (max-width: 767px) {
    .pr-0 {
      padding-right: 15px !important;
    }

    .pl-0 {
      padding-left: 15px !important;
    }
  }

`;


export default Benefits;
