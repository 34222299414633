import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import { useState } from 'react';
import {Img} from "../Img";
import TruncateText from "./TruncateText";
import Title from "../Title";
import {SwiperSlide} from "swiper/react";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    const message = data?.leader;

    const [text, setText] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.');
    const [showFullText, setShowFullText] = useState(false);
    const wordsToShow = 25;

    //let shortText = text.split(' ').slice(0, wordsToShow).join(' ');
    //let displayText = showFullText ? text : shortText + '...';
    return (
        <StyledComponent className='pb-200' id={'sectionToExclude'}>
            <Container className='leaders'>
                <Row>
                    <Col md={5}>
                        <Title text={data?.title} fontSize={'60'} lineHeight={'72'} color={'#897059'}/>
                    </Col>
                </Row>
                {
                    message && message.length>0 ?
                        <Row>
                            {
                                message && message.length>0 &&
                                message.map((element,index)=>{
                                    return(
                                        <Col md={4} className={'leaders__msg'} key={index}>
                                            <div className='leaders__msg__img '>
                                                <div className="reveal">
                                                    <Img src={element?.image}/>
                                                </div>
                                            </div>
                                            {
                                                element?.name &&
                                                <p className='leaders__msg__name split-up'>
                                                    {element?.name}
                                                </p>
                                            }
                                            {
                                                element?.designation &&
                                                <p className='leaders__msg__designation split-up'>

                                                    {element?.designation}
                                                </p>
                                            }


                                            <TruncateText  longText={element?.message}/>
                                        </Col>
                                    )

                                })
                            }
                        </Row> : ''
                }
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  margin-top: -2px;
  .leaders{
    &__left{
      padding-right: 40px;
    }
    &__msg{
      margin-top: 60px;
      &__img{
        position: relative;
        padding-top: calc(460 / 370 * 100%);
      }
      &__name{
        font-size: 20px;
        line-height: 22px;
        font-weight: 500;
        border-top: 1px solid #B2A89F;
        margin-top: 40px;
        padding-top: 20px;
        color: #897059;
      }
      &__designation{
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #B2A89F;
        margin-bottom: 40px;
      }
      &__message{
        margin-bottom: 30px;
        color: ${MidNightBlue};
      }
      svg{
        cursor: pointer;
      }
    }
  }
  @media(max-width: 767px){
    .leaders__msg{
      margin-bottom: 40px;
      margin-top: 0;
    }
    .leaders__msg:last-child {
      margin-bottom: 0;
    }
    .TruncateText{
      overflow: inherit!important;
    }
  }

`;

export default MyComponent;
