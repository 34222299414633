import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Table} from "react-bootstrap";
import {
    InstagramShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton, InstapaperIcon,
} from "react-share";
import Sticky from 'react-stickynode';
import reactHtmlParser from "react-html-parser";
import {ColorBg, hover, hoverNd, MidNightBlue, text} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import {Link} from "react-router-dom";
import Button from "../Button";
// import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {gsap} from "gsap";
import ModalVideo from "react-modal-video";
import ReactHtmlParser from "react-html-parser";
import SimpleBar from "simplebar-react";
import Modal from "react-bootstrap/Modal";


const MyComponent = ({data}) => {

    // const router = useRouter();

    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])

    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    //sticky
    // gsap.registerPlugin(ScrollToPlugin);

    useEffect(() => {

        gsap.to(".detail-page__text-content__detail", {
            scrollTrigger: {
                trigger: ".share-area",
                start: "top top",
                endTrigger: ".detail-page__text-content__detail",
                end: "bottom-=150px top",
                pin: true,
                pinSpacing: false
            }
        });
    }, []);

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handleOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };

    return (
        <StyledComponent className={'detail-page pb-200'}>
            {/*<div className={'detail-page__img'}>*/}
            {/*    <Img src={'/images/dynamic/mdetail.jpg'}/>*/}
            {/*</div>*/}
            <Container className={'detail-page__text-content'}>
                <Row>
                    <Col className={'banner-title'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="1" viewBox="0 0 31 1">
                            <line id="Line_3888" data-name="Line 3888" x2="30" transform="translate(0.5 0.5)" fill="none" stroke="#897059" stroke-linecap="round" stroke-width="1"/>
                        </svg>
                        <h6>News Details</h6>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3} className={'detail-page__text-content__share'}>
                        <div className={'share-area'}>
                            <p>Share:</p>
                            <ul className={'share-area__icons'}>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>

                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#fff"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>


                            </ul>
                        </div>
                        <div className={'mobile-share'}>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#fff"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                            </ul>
                        </div>

                    </Col>
                    <Col sm={9} className={'detail-page__text-content__detail'}>
                        <h5 className={'news-title split-up'}>
                            {data?.post_title}
                        </h5>
                        <div className={'detail-page__text-content__detail__date '}>
                            <p className={'split-up'}>{data?.media_cat}</p>
                            <p>/</p>
                            <p className={'split-up'}>{data?.date_month}</p>
                            {/*<p>{data?.date_month}</p>*/}
                        </div>
                        <div className={'full-detail'}>
                            {
                                data?.banner_image &&
                                <div className="banner-image ">
                                    <Img parallax src={data?.banner_image}/>
                                </div>
                            }

                            {ReactHtmlParser(data?.short_desc)}
                            {ReactHtmlParser(data?.description)}
                            {
                                data?.video &&
                                <div className="video-box" onClick={() => handleOpen(true, data?.video)} >
                                    <div className="hover-btn hover" onClick={() => handleOpen(true, data?.video)}>
                                        <svg id="Group_20006" data-name="Group 20006" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(60 60) rotate(180)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                                <circle cx="30" cy="30" r="30" stroke="none"/>
                                                <circle cx="30" cy="30" r="29.5" fill="none"/>
                                            </g>
                                            <path id="Polygon_1" data-name="Polygon 1" d="M10,0,20,13.333H0Z" transform="translate(38.334 19) rotate(90)" fill="#fffdfb"/>
                                        </svg>

                                    </div>
                                    <Img src={data?.video_image}/>
                                </div>
                            }

                            {ReactHtmlParser(data?.lower_description)}
                            {
                                data?.lower_image &&
                                <div className="media-details-image reveal">
                                    <Img src={data?.lower_image}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                </div>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoId}
                                                onClose={handleCloseModal}/>
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 80px;
  background: ${ColorBg};
  
  .banner-title{
    display: flex;
    gap:12px;
    margin-bottom: 60px;
    align-items: center;
    h6{
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
      color: #897059;
    }
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
  }
  .share-area{
    p{
      color: #B2A89F !important;
    }
    &__icons{
      display: flex;
      gap: 10px;
    }
    .hover{
      background-color: #897059 !important;
    }
  }
  .full-detail {
    // border-bottom: 1px solid ${text};
    //padding-bottom: 60px;
    h5{
      margin-top: 40px;
    }
  }

  .detail-page__img {
    position: relative;
    padding-top: calc(612 / 1366 * 100%);
    @media (max-width: 767px) {
      padding-top: calc(560 / 375 * 100%);
    }
  }


  .detail-page__heading {
    h1 {
      font-size: 32px;
      line-height: 36px;
      color: #FFFDFB;
      font-weight: 600;
    }

    &__tags {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        color: #FFFDFB;
        font-size: 16px;
        line-height: 24px;
      }

      ul {
        display: flex;

        li {

          font-size: 16px;
          line-height: 24px;
          color: #FFFDFB;


          &:not(:nth-last-child(1)) {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 0;
            border-right: 1px solid #CED7ED;
          }
        }
      }
    }

  }

  .detail-page__banner {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .hover:after {
    border-radius: 50%;
  }

  .detail-page__text-content {
    padding-top: 40px;

    &__share {
      .mobile-share {
        display: none;
      }
      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          width: 100%;
          //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
          .hover{
            background-color: #897059 !important;
          }
        }
      }
      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
          p{
            color: #897059 !important;
          }

        }
        .share-area{
          display: none;
        }
        .sticky-outer-wrapper {
          display: none;
        }
      }


      p {
        color: #FFFDFB;
        margin-bottom: 20px;
        font-weight: 500 !important;
      }

      ul {
        li {
          min-height: 30px;
          height: 30px;
          width: 30px;
          min-width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-bottom: 20px;
          position: relative;
          color: #FFFDFB;

          button {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
          }

          svg {
            position: relative;
            z-index: 2;
          }
        }
      }

      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between !important;
      }
    }

    &__detail {


      .custom-list {
        padding: 0;

        li {

          position: relative;
          padding-left: 20px;
          color: #FFFDFB;
          //padding-top: 20px;
          &:not(:last-child) {
            padding-bottom: 0px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: -5px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            box-sizing: content-box;
            background-color: #AC835E;
          }
        }

      }

      img {
        width: 100%;
      }

      span {
        p {
          color: #FFFDFB;
          font-weight: 500;
          margin-top: 20px;
        }
      }

      &__date {
        display: flex;
        gap:10px;


        p {
          font-weight: 500 !important;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px !important;
          color: #B2A89F !important;
        }

        @media (max-width: 767px) {
          p {
            &:not(last-child) {
              margin-bottom: 10px !important;
            }
          }
        }
      }

      h5 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 40px;
        color: ${hoverNd};

        @media (max-width: 767px) {
          font-size: 24px;
          margin-top: 40px;
        }
      }

      h2 {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 20px;
        color: #FFFDFB;
      }

      p {
        font-weight: 400;
        color: ${MidNightBlue};
      }

      .quotes {
        padding-left: 40px;
        position: relative;
        margin-bottom: 40px;
        color: #897059;
        font-size: 28px;
        line-height: 40px;
        font-weight: 500;
        font-style: oblique;

        &:after {
          content: '';
          position: absolute;
          width: 5px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: #897059;
          border-radius: 5px;
        }

        h6 {
          margin-top: 30px;
          font-weight: 500;
          color: #B2A89F;
        }
      }

      table {
        min-width: 100%;
        margin-bottom: 30px;


        th {
          color: #897059;
          border-bottom: 1px solid #AC835E;
          padding: 20px 0;
          font-weight: 500 !important;
        }

        td {
          padding: 20px 0;
          border-bottom: 1px solid #B2A89F;
          color: ${MidNightBlue};

          &:nth-of-type(1) {
            padding-right: 30px;
          }
        }
      }

      .custom-list {
        padding: 0;

        li {

          position: relative;
          padding-left: 20px;
          padding-top: 20px;
          color: ${MidNightBlue};

          &:not(:last-child) {
            //padding-bottom: 20px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: 13px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            box-sizing: content-box;
            background-color: #B2A89F;
          }
        }

      }

      .video-box {
        position: relative;
        cursor: pointer;
        margin-top: 30px;
        width: 100%;
        margin-bottom: 60px;
        padding-top: calc(440 / 870 * 100%);
        .modal-video{
          position: absolute;
          .modal-video-inner{
            //width: 100% !important;
            //padding: 0 !important;
          }
        }
        
        .hover-btn {
          height: 60px;
          width: 60px;
          background-color: transparent;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;

          &.hover:after {
            border-radius: 50%;
          }

          svg {
            position: relative;
            z-index: 2;
            #Ellipse_10{
              transition: stroke-width .3s ease;
            }
          }

          path {
            transition: all .3s ease;
          }
          

        }

        &:hover {

          .hover-btn {
            &:after {
              height: 100%;
              width: 100%;
            }

            path {
              fill: #FFF;
            }
            #Ellipse_10{
              stroke-width: 0;
            }
          }
        }
      }


    }

  }


  @media (max-width: 767px) {

    margin-bottom: 80px;
    .detail-page__heading__tags {
      flex-wrap: wrap;

      ul {
        min-width: 100%;
        margin-bottom: 20px;
      }

      p {
        min-width: 100%;
      }
    }

    .detail-page__text-content__share {
      margin-bottom: 60px;
      min-width: 100%;

      ul {
        display: flex;

        li {
          margin-right: 10px;
        }
      }
    }

    .detail-page__text-content__detail {
      min-width: 100%;
    }
  }
  //.sticky-inner-wrapper{
  //  top:120px !important;
  //}
  @media(max-width: 767px){
    .title h2{
      text-align: start !important;
    }
    .news-title{
      margin-top: 0 !important;
    }
  }
  
  .banner-image{
    position: relative;
    padding-top: calc(410 / 870 * 100%);
  }
  .media-details-image{
    position: relative;
    padding-top: calc(480 / 870 * 100%);
  }
`;

export default MyComponent;
