import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import MediaList from "../../components/media/MediaList";
import BrandKit from "../../components/media/BrandKit";
import InnerBanner from "../../components/InnerBanner";
import {fetchAbout} from "../../api/redux/about";
import {fetchMedias} from "../../api/redux/media";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {

    const dispath = useDispatch()
    const history=useHistory();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA
        dispath(fetchMedias([api_url]))
    }, [history.location.pathname])

    let getPost = useSelector(state => state.media)

    //Refactor data

    let banner=getPost?.posts?.[0]?.banner;
    let mediaList=getPost?.posts?.[0]?.list;
    let filter=getPost?.posts?.[0]?.filter?.category;
    let pressKit=getPost?.posts?.[0]?.press_kit;
    let meta=getPost?.posts?.[0]?.meta;



    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>
            <StyledComponent>
                <InnerBannerV2 page_name={banner?.page}
                               img={banner?.image}
                               mimg={banner?.mimage} title={banner?.title}
                />
                {/*{*/}
                {/*    banner?.image && banner?.title &&*/}
                {/*    <InnerBanner img={banner?.image} title={banner?.title} page_name={banner?.page}/>*/}
                {/*}*/}
                {
                    mediaList?.length>0  &&
                    <MediaList data={mediaList} category={filter}/>
                }
                {
                    pressKit?.title && pressKit?.description && pressKit?.image &&
                    <BrandKit data={pressKit}/>
                }
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  background: #181D24;

`;

export default MyComponent;
