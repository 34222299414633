import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150'}>
            <Container>
                <Row className={'investor-overview'}>
                    <Col md={5}>
                        {
                            data?.image &&
                            <div className="investor-overview__img reveal">
                                <Img src={data?.image}/>
                            </div>
                        }

                    </Col>
                    <Col md={{span:6, offset:1}}>
                        <div className="investor-overview__text">
                            <Title text={data?.title} color={'#897059'}/>
                            {
                                data?.description &&
                                <p className={'split-up'}>{ReactHtmlParser(data?.description)}</p>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: ${ColorBg};
  .investor-overview{
    &__img{
      padding-top: calc(680 / 500 * 100%);
      position: relative;
      img{
        top: -28px;
      }
    }
    &__text{
      p{
        font-size: 16px;
        line-height: 24px;
        color: ${MidNightBlue};
        font-weight: 500;
        margin-top: 60px;
      }
      @media(max-width: 767px){
        margin-top: 40px;
        p{
          margin-top: 40px;
        }
      }
    }
  }
`;

export default MyComponent;
