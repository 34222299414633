import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {BsX} from "react-icons/bs";

const PopupV1 = ({
                   show,
                   handleClose,
                   no_img,
                   item,
                   title,
                   description,
                   data,
                   subtitle,
                   img
               }) => {

    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one"
            >
                <SimpleBar className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>
                       <Container>
                           <Row className={'for-close'}>
                               <div onClick={handleClose} className="modal-close hover">
                                   <BsX/>
                               </div>
                           </Row>
                       </Container>
                       <Container>
                           <Row>


                               <div className="modal-data d-flex">
                                   <Col sm={4} className="modal-data__img">
                                       <img
                                           src={'https://beta.corporate.mjlbl.com/admin/uploads/page/board-of-director-overview/1678766840wz504.jpg'}
                                           alt=""/>
                                   </Col>

                                   <Col sm={{span: 7, offset: 1}} className='modal-data__content'>
                                       <h4>{'Dr. Md Khairuzzaman Mozumder'}</h4>


                                       <div className="scroll-div">
                                           {winWidth ?
                                               <SimpleBar autoHide={true} style={{maxHeight: 'calc(100vh - 200px)'}}>
                                                   <ul>
                                                       <li>Chairman, Board of Directors, MJL Bangladesh PLC.</li>
                                                       <li>Secretary of the Energy and Mineral Resources Division, Government of
                                                           the People's Republic of Bangladesh
                                                       </li>
                                                   </ul>
                                                   <p>Dr. Md Khairuzzaman Mozumder, Secretary, Energy, and Mineral Resources
                                                       Division, is an accomplished professional with a 30-year career in various
                                                       fields combining finance, macroeconomics, international economics, customs,
                                                       trade facilitation issues, international development, WTO issues, and
                                                       international trade. Before joining the Energy and Mineral Resources
                                                       Division under the Ministry of Power, Energy, and Mineral Resources, he
                                                       served as Additional Secretary (Macroeconomic) in the Finance Division under
                                                       the Ministry of Finance. He joined Bangladesh Civil Service (Administration)
                                                       in the 11th Batch in April 1993 and later served in various positions of BCS
                                                       (Customs &amp; Excise) in the National Board of Revenue under the Ministry
                                                       of Finance.</p><p>Dr. Mozumder has a Ph.D. in government (political economy)
                                                   from the University of Essex in the United Kingdom. He has also obtained an MA
                                                   in political economy from McGill University in Canada. He obtained an MSS in
                                                   international relations from the University of Dhaka in First Class securing the
                                                   Second Position, and prior to that he obtained a BSS (Honors) in international
                                                   relations from the same university securing the First Position. He also received
                                                   advanced certificates in the French Language. During his long professional
                                                   career, he participated in numerous training programs at home and abroad
                                                   covering fiscal affairs, finance, trade, and development issues.</p><p>Dr.
                                                   Mozumder served in various capacities in government and international
                                                   development, holding offices in the Finance Division, Ministry of Commerce,
                                                   National Board of Revenue, Deputy Commissioner’s Office of Bandarban, USAID
                                                   Programme (as COP of IBI project), Business Promotion Council, and Bangladesh
                                                   Foreign Trade Institute. He has also served as an adjunct faculty at the
                                                   Bangladesh Institute of Governance and Management (BIGM) teaching three courses
                                                   namely WTO &amp; International Trade, Macroeconomics, and International
                                                   Economics.
                                               </p><p>His work at Finance Division involved the formulation of macroeconomic
                                                   policy, planning and analysis of fiscal policy, preparation of medium-term
                                                   macroeconomic policy statement, and close coordination with the World Bank and
                                                   International Monetary Fund and other development partners in issues related to
                                                   budgetary support and macroeconomic and fiscal framework. He facilitated the
                                                   successful conclusion of many loan programs for budgetary support, including the
                                                   recently negotiated US$ 4.5 billion loan program from the International Monetary
                                                   Fund.</p><p>His work at the Ministry of Commerce involved conducting bilateral,
                                                   regional, and multilateral trade negotiations, handling trade facilitation
                                                   issues, and dealing with various WTO issues. Dr. Mozumder also worked as the
                                                   Coordinator of the Business Promotion Council, where he facilitated the
                                                   implementation of the government policy of diversifying the export product base
                                                   in Bangladesh to reduce concentration on readymade garment products. This
                                                   involved private sector development through addressing supply-side constraints
                                                   of the manufacturing sector, especially small and medium enterprises, in
                                                   leather, agricultural products, ICT, fisheries, light engineering, medicinal
                                                   plants, and herbal products.</p><p>
                                                   During his nearly 5-year tenure on the USAID-funded programme of Bangladesh
                                                   Trade Facilitation Activity (BTFA), he was involved in the implementation of
                                                   several trade facilitation measures under the WTO TFA, namely, publication of
                                                   information through the internet, enquiry points, advance ruling, risk
                                                   management, post clearance audit, authorized operators, and expedited clearance
                                                   of courier cargoes. Dr. Mozumder was able to successfully steer the
                                                   international development program activities of BTFA while cultivating a strong
                                                   and varied network. Dr. Mozumder has also worked briefly as a national
                                                   consultant in international organizations such as UNCTAD and OECD, and as a
                                                   Program Evaluation Committee Member for the CEPR research programme under the
                                                   Private Enterprise Development in Low-Income Countries in the United Kingdom.
                                               </p><p>
                                                   Dr. Mozumder has several international publications to his credit. He has an
                                                   insatiable appetite for reading and likes to listen to music.
                                               </p><p>
                                                   Dr. Mozumder is happily married and is the proud father of two daughters.</p>
                                               </SimpleBar>
                                               :
                                               <>
                                                   <ul>
                                                       <li>Chairman, Board of Directors, MJL Bangladesh PLC.</li>
                                                       <li>Secretary of the Energy and Mineral Resources Division, Government of
                                                           the People's Republic of Bangladesh
                                                       </li>
                                                   </ul>
                                                   <p>Dr. Md Khairuzzaman Mozumder, Secretary, Energy, and Mineral Resources
                                                       Division, is an accomplished professional with a 30-year career in various
                                                       fields combining finance, macroeconomics, international economics, customs,
                                                       trade facilitation issues, international development, WTO issues, and
                                                       international trade. Before joining the Energy and Mineral Resources
                                                       Division under the Ministry of Power, Energy, and Mineral Resources, he
                                                       served as Additional Secretary (Macroeconomic) in the Finance Division under
                                                       the Ministry of Finance. He joined Bangladesh Civil Service (Administration)
                                                       in the 11th Batch in April 1993 and later served in various positions of BCS
                                                       (Customs &amp; Excise) in the National Board of Revenue under the Ministry
                                                       of Finance.</p><p>Dr. Mozumder has a Ph.D. in government (political economy)
                                                   from the University of Essex in the United Kingdom. He has also obtained an MA
                                                   in political economy from McGill University in Canada. He obtained an MSS in
                                                   international relations from the University of Dhaka in First Class securing the
                                                   Second Position, and prior to that he obtained a BSS (Honors) in international
                                                   relations from the same university securing the First Position. He also received
                                                   advanced certificates in the French Language. During his long professional
                                                   career, he participated in numerous training programs at home and abroad
                                                   covering fiscal affairs, finance, trade, and development issues.</p><p>Dr.
                                                   Mozumder served in various capacities in government and international
                                                   development, holding offices in the Finance Division, Ministry of Commerce,
                                                   National Board of Revenue, Deputy Commissioner’s Office of Bandarban, USAID
                                                   Programme (as COP of IBI project), Business Promotion Council, and Bangladesh
                                                   Foreign Trade Institute. He has also served as an adjunct faculty at the
                                                   Bangladesh Institute of Governance and Management (BIGM) teaching three courses
                                                   namely WTO &amp; International Trade, Macroeconomics, and International
                                                   Economics.
                                               </p><p>His work at Finance Division involved the formulation of macroeconomic
                                                   policy, planning and analysis of fiscal policy, preparation of medium-term
                                                   macroeconomic policy statement, and close coordination with the World Bank and
                                                   International Monetary Fund and other development partners in issues related to
                                                   budgetary support and macroeconomic and fiscal framework. He facilitated the
                                                   successful conclusion of many loan programs for budgetary support, including the
                                                   recently negotiated US$ 4.5 billion loan program from the International Monetary
                                                   Fund.</p><p>His work at the Ministry of Commerce involved conducting bilateral,
                                                   regional, and multilateral trade negotiations, handling trade facilitation
                                                   issues, and dealing with various WTO issues. Dr. Mozumder also worked as the
                                                   Coordinator of the Business Promotion Council, where he facilitated the
                                                   implementation of the government policy of diversifying the export product base
                                                   in Bangladesh to reduce concentration on readymade garment products. This
                                                   involved private sector development through addressing supply-side constraints
                                                   of the manufacturing sector, especially small and medium enterprises, in
                                                   leather, agricultural products, ICT, fisheries, light engineering, medicinal
                                                   plants, and herbal products.</p><p>
                                                   During his nearly 5-year tenure on the USAID-funded programme of Bangladesh
                                                   Trade Facilitation Activity (BTFA), he was involved in the implementation of
                                                   several trade facilitation measures under the WTO TFA, namely, publication of
                                                   information through the internet, enquiry points, advance ruling, risk
                                                   management, post clearance audit, authorized operators, and expedited clearance
                                                   of courier cargoes. Dr. Mozumder was able to successfully steer the
                                                   international development program activities of BTFA while cultivating a strong
                                                   and varied network. Dr. Mozumder has also worked briefly as a national
                                                   consultant in international organizations such as UNCTAD and OECD, and as a
                                                   Program Evaluation Committee Member for the CEPR research programme under the
                                                   Private Enterprise Development in Low-Income Countries in the United Kingdom.
                                               </p><p>
                                                   Dr. Mozumder has several international publications to his credit. He has an
                                                   insatiable appetite for reading and likes to listen to music.
                                               </p><p>
                                                   Dr. Mozumder is happily married and is the proud father of two daughters.</p>
                                               </>
                                           }

                                       </div>


                                   </Col>


                               </div>
                           </Row>
                       </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }
  h4{
    letter-spacing: 1.5px;
  }

`;


export default PopupV1;
