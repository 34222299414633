import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import {Img} from "./Img";
import Title from "./Title";

const MyComponent = ({vidTitle,vidThumb,vidUrl}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };
    return (
        <StyledComponent className={'video pt-200'} >
            <Container>
                <Row className={'video__title'}>
                    <Col md={7} className={''}>
                        <Title
                            text={vidTitle ? vidTitle : ''}
                            fontSize="60"
                            lineHeight="72"
                            color="#FFFDFB"
                        />
                    </Col>
                </Row>
                <Row className={'video__video'}>

                    <Col md={{span:10,offset:1}} >
                        <div className="video__video__image parallax" onClick={() => handelOpen(true, vidUrl ? vidUrl : '')}  >
                            <Img parallax src={vidThumb ? vidThumb : ''} />
                        </div>
                        <div className="video__video__button" onClick={() => handelOpen(true, vidUrl ? vidUrl : '')}>
                            <div className="svg-container">
                                <svg id="Group_20003" data-name="Group 20003" xmlns="http://www.w3.org/2000/svg"
                                     width="140" height="140" viewBox="0 0 140 140">
                                    <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(140 140) rotate(180)"
                                       fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                        <circle id="circle-border" cx="70" cy="70" r="70" stroke="#FFFDFB" stroke-width="1"/>
                                        <circle id="hover-color" cx="70" cy="70" r="69.5" stroke="none" fill=""/>
                                    </g>
                                    <path id="Polygon_1" data-name="Polygon 1" d="M15,0,30,20H0Z"
                                          transform="translate(83 55) rotate(90)" fill="#fffdfb"/>
                                </svg>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
            <ModalVideo channel='youtube' isOpen={open}
                        videoId={videoId}
                        onClose={() => handelOpen(false, videoId)}/>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  background-color: #181D24;
  padding-bottom: 275px;
  @media(max-width: 767px){
    padding-bottom: 120px;
  }
  .video {
    &__title {
      position: relative;
      z-index: 5;
      //h2 {
      //  font-size: 60px;
      //  line-height: 72px;
      //  color: white;
      //}
    }

    &__video {
      position: relative;
      margin-top: -83px;
      @media(min-width: 1499px){
        margin-top:-75px;
      }
      .modal-video{
        position: absolute;
        .modal-video-inner{
          //width: 100% !important;
          //padding: 0 !important;
        }
      }
      &__image{
        cursor: pointer;
        position: relative;
        padding-top: calc(840 / 1455 * 100%);
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__button {
        position: absolute;
        top: 100.6%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
  .svg-container {
    display: inline-block;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    svg{
      width: 100%;
      height: 100%;
    }
  }

  #hover-color {
    fill: transparent;
    transition: fill 0.8s;
  }
  #circle-border{
    transition: all 1s;
  }
  

  .svg-container:hover {
    
    #hover-color {
      fill: #736640;
      animation: fillAnimation 0.8s forwards;
    }
    #circle-border{
      stroke-width: 0;
    }
  }
  

  .svg-container:not(:hover) #hover-color {
    fill: transparent;
    animation: reverseFillAnimation 0.8s backwards;
  }

  @keyframes fillAnimation {
    0% {
      r: 0px;
    }
    100% {
      r: 70px;
    }
  }
  @keyframes reverseFillAnimation {
    0% {
      r: 69.5px;
    }
    100% {
      r: 0px;
    }
  }
  
  @media(max-width: 767px){
    .video{
      &__title{
        .title{
          margin-bottom: 0 !important;
          h2{
            font-size: 36px;
            line-height: 48px;
          }
        }
        
      }
      &__video{
        .col-md-10{
          padding-left: 0;
          padding-right: 0;
        }
        margin-top: 40px;
        &__button{
          top:50%;
          left:50%;
          .svg-container{
            width: 60px;
            height: 60px;
            svg{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  
`;

export default MyComponent;
