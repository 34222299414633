import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Overview from "../../components/Overview";
import Milestones from "../../components/sustainability/Milestones";
import Umbrella from "../../components/sustainability/Umbrella";
import Children from "../../components/sustainability/Children";
import Future from "../../components/sustainability/Future";
import GiveUsVisit from "../../components/GiveUsVisit";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import {fetchSustainability} from "../../api/redux/sustainability";
import CsrMediaSLider from "../../components/sustainability/CsrMediaSLider";
import Rental from "../rental";
import RentOverview from "../../components/rental/RentOverview";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SUSTAINABILITY
        dispath(fetchSustainability([api_url]))
    }, [])



    let getPost = useSelector(state => state.sustainability)
    const csrBanner= getPost?.posts?.page_data?.[0]?.banner;
    const csrOverview= getPost?.posts?.page_data?.[0]?.overview;
    const csrMilestone= getPost?.posts?.page_data?.[0]?.milestone;
    const csrUmbrella= getPost?.posts?.page_data?.[0]?.umbrella;
    const csrChildren= getPost?.posts?.page_data?.[0]?.children;
    const csrFuture= getPost?.posts?.page_data?.[0]?.future;
    const csrSlider = getPost?.posts?.page_data?.[0]?.media;
    const giveVisit = getPost?.posts?.page_data?.[0]?.visit;
    const meta = getPost?.posts?.page_data?.[0]?.meta;

    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>

            <StyledComponent>
                <InnerBannerV2 page_name={csrBanner?.page}
                               img={csrBanner?.image}
                               mimg={csrBanner?.mimage} title={csrBanner?.title}
                />
                {/*<InnerBanner img={csrBanner?.image} title={csrBanner?.title} page_name={csrBanner?.page}/>*/}
                <Overview headerText={csrOverview?.title} leftText={csrOverview?.description_left}
                          rightText={csrOverview?.description_right}/>
                <Milestones data={csrMilestone}/>
                <Umbrella data={csrUmbrella}/>
                <Children data={csrChildren}/>
                <Future data={csrFuture} />
                {
                    csrSlider?.media_list?.length>0 &&
                    <CsrMediaSLider data={csrSlider}/>
                }

                <GiveUsVisit data={giveVisit}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
