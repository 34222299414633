import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import RentLists from "../../components/rental/RentLists";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey, ApiParam} from "../../api/network/apiParams";
import {fetchProjects} from "../../api/redux/for-rent";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {
    const dispath = useDispatch()
    const history=useHistory();
    const locations = useLocation();
    let getPost = useSelector(state => state.rent)
    let meta=getPost?.posts?.[0]?.meta;


    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.RENTS
        dispath(fetchProjects([api_url]))
    }, [history?.location?.pathname])


    //get the query
    const project_status = new URLSearchParams(locations?.search).get("status");

    // step -01

    const [selectType, setSelectType] = useState('')
    const [selectCity, setSelectCity] = useState('')
    const [selectArea, setSelectArea] = useState('')
    const [selectRange, setSelectRange] = useState('')



    const optionsList=getPost?.posts?.[0]?.filter;

    // api call
    useEffect(() => {
        let param = {
            [ApiParam.rent_type]: selectType,
            [ApiParam.rent_city]: selectCity,
            [ApiParam.rent_area]: selectArea,
            [ApiParam.rent_range]: selectRange
        }
    }, [selectCity,selectType,selectArea,selectRange,history?.location?.pathname])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>
            <StyledComponent>
                <InnerBannerV2 page_name={getPost?.posts?.[0]?.banner?.page}
                               img={getPost?.posts?.[0]?.banner?.image}
                               mimg={getPost?.posts?.[0]?.banner?.mimage}
                               title={getPost?.posts?.[0]?.banner?.title}
                />
                {/*{*/}
                {/*    getPost?.posts?.[0]?.banner?.image && getPost?.posts?.[0]?.banner?.title &&*/}
                {/*    <InnerBanner img={getPost?.posts?.[0]?.banner?.image} title={getPost?.posts?.[0]?.banner?.title} page_name={getPost?.posts?.[0]?.banner?.page}/>*/}
                {/*}*/}
                <RentLists data={getPost?.posts?.[0]?.list} options={optionsList}/>

            </StyledComponent>
        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
`
export default MyComponent;