import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {ColorBg, hoverNd, MidNightBlue} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-150 pb-200'}>
            <Container>
                <Row>
                    <Col md={9}>
                        <Title
                            fontSize={28}
                            lineHeight={40}
                            mFontSize={24}
                            mLineHeight={30}
                            margin={'0 0 40px 0'}
                            text={data?.title}/>

                        {ReactHtmlParser(data?.desc)}
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    background: ${ColorBg};
  h5{
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    color: ${hoverNd};
    margin-bottom: 40px;
  }
  p{
    font-weight: 500;
    margin-bottom: 40px;
  }
  ul{
    margin-bottom: 40px;
  }
  .custom-list {
    padding: 0;

    li {

      position: relative;
      padding-left: 20px;
      padding-top: 20px;
      color: ${MidNightBlue};

      &:not(:last-child) {
        //padding-bottom: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        margin-top: 13px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        box-sizing: content-box;
        background-color: #B2A89F;
      }
    }

  }

`
export default MyComponent;