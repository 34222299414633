import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {CSSPlugin,gsap} from "gsap";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';

// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/projects';
import ProjectDetail from './pages/projects/single';
import RentalDetail from './pages/rental/single';
import Rental from './pages/rental/index';
import Launch from './pages/new-launching/index';
import {SplitUp} from "./components/animations/TextAnimation";
import Contacts from "./pages/contacts";
import Sustainability from "./pages/sustainability";
import InvestorRelations from "./pages/investor-relations";
import Career from "./pages/career";
import MediaCenter from "./pages/media-center";
import MediaCenterDetail from "./pages/media-center/single";
import JointVenture from "./pages/joint-venture";
import Menu from "./components/Menu";
import MobileMenu from "./components/MobileMenu";
import Footer from "./components/Footer";
import SideWhatsapp from "./components/SideWhatsapp";
import {ToastContainer} from "react-toastify";
import Search from "./components/Search";
import {ImageReveal} from "./animation/imageReveal";
import {Parallax, ParallaxImg} from "./animation/parallax";
import PageTransition from "./animation/PageTransition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Campaign from "./pages/Campaign";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "./api/network/apiEndPoints";
import {fetchFooter} from "./api/redux/footer";

function App() {
    const location = useLocation();

    gsap.registerPlugin(ScrollSmoother, CSSPlugin,);

    const el = useRef();
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(Number(localStorage.getItem('containeroffset')))
    }, [])


    useEffect(() => {
        if (location.pathname !== '/') {
            document.body.classList.add('in-inner-page')
        } else {
            document.body.classList.remove('in-inner-page')
        }
    }, [location.pathname])


    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()

    // page transition
    PageTransition()

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 0.8,
            effects: true,
        });
        return () => {
            smoother.kill();
        };
    }, []);


    //api call  for footer

    const dispath = useDispatch()
    useEffect(() => {
        let api_url = apiEndPoints.FOOTER
        dispath(fetchFooter([api_url]))
    }, [])

    let getPost = useSelector(state => state.footer)


    return (
        <>
            <GlobalStyle/>
            <ToastContainer
                position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>

            {isMobile ? <MobileMenu getPost={getPost}/> : <Menu getPost={getPost} />}
            <SideWhatsapp offset={offset}/>

            {/*page transition */}
            <div className="page-loader">
                <svg id="Sarnmar_-_White" data-name="Sarnmar - White" xmlns="http://www.w3.org/2000/svg" width="173.233" height="50" viewBox="0 0 173.233 50">
                    <path id="Path_7002" data-name="Path 7002" d="M51.714,42.23a5.014,5.014,0,0,0-.994-.066H48.733a5.014,5.014,0,0,0-.994.066c-.2-.6-.4-1.259-.729-2.12q-.5-1.192-.994-2.584c-.4-.927-.729-1.789-1.126-2.716s-.729-1.789-1.06-2.65c-.795,0-1.656-.066-2.451-.066H36.544c-.729,0-1.524,0-2.319.066C33.431,34.148,32.7,36,32.04,37.792s-1.126,3.246-1.457,4.438a9.784,9.784,0,0,0-1.391-.066,8.892,8.892,0,0,0-1.391.066c2.054-4.438,4.041-8.877,6.028-13.249,1.921-4.372,3.908-8.877,5.9-13.381h.729q.894,2.186,1.987,4.77l2.186,5.167c.729,1.722,1.457,3.511,2.186,5.233s1.457,3.312,2.054,4.836c.662,1.457,1.259,2.782,1.722,3.975A22.654,22.654,0,0,0,51.714,42.23ZM34.888,30.372c.662,0,1.325,0,2.054.066.729,0,1.391.066,2.054.066h2.12c.729,0,1.391,0,2.12-.066L39,20.767Z" transform="translate(-9.384 -5.28)" fill="#fff"/>
                    <path id="Path_7003" data-name="Path 7003" d="M186.214,42.23a5.014,5.014,0,0,0-.994-.066h-1.987a5.014,5.014,0,0,0-.994.066c-.2-.6-.4-1.259-.729-2.12q-.5-1.192-.994-2.584c-.4-.927-.729-1.789-1.126-2.716s-.729-1.789-1.06-2.65c-.795,0-1.656-.066-2.451-.066h-4.836c-.729,0-1.524,0-2.319.066-.795,1.987-1.524,3.842-2.186,5.631s-1.126,3.246-1.457,4.438a9.784,9.784,0,0,0-1.391-.066,8.892,8.892,0,0,0-1.391.066c2.054-4.438,4.041-8.877,6.028-13.249,1.921-4.372,3.908-8.877,5.9-13.381h.729q.894,2.186,1.987,4.77l2.186,5.167c.729,1.722,1.457,3.511,2.186,5.233s1.457,3.312,2.054,4.836c.662,1.457,1.259,2.782,1.722,3.975A22.651,22.651,0,0,0,186.214,42.23ZM169.388,30.372c.662,0,1.325,0,2.054.066.729,0,1.391.066,2.054.066h2.12c.729,0,1.391,0,2.12-.066l-4.24-9.672Z" transform="translate(-54.786 -5.28)" fill="#fff"/>
                    <path id="Path_7004" data-name="Path 7004" d="M1.987,38.653a4.552,4.552,0,0,0,1.259,1.259,6.077,6.077,0,0,0,1.722.927,5.829,5.829,0,0,0,2.186.331,4.9,4.9,0,0,0,2.252-.464,4.481,4.481,0,0,0,1.722-1.192,4.2,4.2,0,0,0,1.06-1.722,5.738,5.738,0,0,0,.4-2.12,4.024,4.024,0,0,0-.927-2.782,7.52,7.52,0,0,0-2.252-1.722,28.33,28.33,0,0,0-2.849-1.259A12.805,12.805,0,0,1,3.71,28.451a8.835,8.835,0,0,1-2.252-2.186,6.406,6.406,0,0,1-.795-3.511,6.949,6.949,0,0,1,.6-2.915,6.226,6.226,0,0,1,1.656-2.252A7.082,7.082,0,0,1,5.5,16.13a11.059,11.059,0,0,1,3.445-.53,9.45,9.45,0,0,1,3.18.464,7.368,7.368,0,0,1,2.451,1.325c-.2.464-.4.861-.6,1.391a14.943,14.943,0,0,0-.464,1.855H13.05c-.132-.331-.331-.729-.53-1.192a6.724,6.724,0,0,0-.861-1.192,6.711,6.711,0,0,0-1.325-.927,4.043,4.043,0,0,0-1.855-.331,6.245,6.245,0,0,0-1.987.331,3.587,3.587,0,0,0-1.457.994,3.95,3.95,0,0,0-1.06,1.457,4.515,4.515,0,0,0-.331,1.921,4,4,0,0,0,.927,2.849,7.52,7.52,0,0,0,2.252,1.722,28.329,28.329,0,0,0,2.849,1.259,16.155,16.155,0,0,1,2.849,1.325A7.4,7.4,0,0,1,14.772,30.9a5.679,5.679,0,0,1,.927,3.378,9.181,9.181,0,0,1-.6,3.312,7.638,7.638,0,0,1-1.855,2.716A7.708,7.708,0,0,1,10.334,42.1a11.818,11.818,0,0,1-3.908.6,10.392,10.392,0,0,1-3.842-.6A10.236,10.236,0,0,1,0,40.64a10.109,10.109,0,0,0,.464-1.59c.066-.464.2-1.06.265-1.789h.464A7.454,7.454,0,0,0,1.987,38.653Z" transform="translate(0 -5.28)" fill="#fff"/>
                    <path id="Path_7005" data-name="Path 7005" d="M121.538,26.067c1.789,3.378,3.643,6.889,5.631,10.467.265-.464.6-1.192,1.126-2.054.464-.927,1.06-1.921,1.656-3.113.662-1.192,1.325-2.451,2.054-3.776s1.457-2.716,2.186-4.107,1.457-2.782,2.252-4.107c.729-1.325,1.391-2.584,2.054-3.776h.464c.265,2.451.53,4.77.795,7.088.265,2.252.464,4.5.729,6.691s.53,4.372.729,6.492c.265,2.12.53,4.24.861,6.359a4.075,4.075,0,0,0-.861-.066h-1.855a3.777,3.777,0,0,0-.861.066c-.265-3.378-.53-6.624-.861-9.8-.265-3.18-.6-6.426-1.06-9.672h-.066l-5.167,9.539c-1.656,3.18-3.312,6.492-4.836,9.937h-.331c-1.524-3.18-3.113-6.426-4.77-9.605s-3.312-6.492-5.035-9.8H116.3c-.2,1.59-.4,3.312-.6,5.1-.2,1.855-.4,3.643-.6,5.432s-.331,3.445-.464,5.035-.2,2.915-.2,3.908a13.282,13.282,0,0,0-2.65,0c.331-2.054.662-4.24,1.06-6.492.331-2.252.729-4.571,1.06-6.889s.662-4.571.927-6.823.53-4.372.729-6.426h.662C117.961,19.177,119.749,22.688,121.538,26.067Z" transform="translate(-37.739 -5.28)" fill="#fff"/>
                    <path id="Path_7006" data-name="Path 7006" d="M81.988,32.394c-3.312-3.511-6.624-7.088-9.87-10.533,0,.729-.066,1.656-.066,2.849s-.066,2.848-.066,4.9V31.6c0,.795,0,1.59.066,2.451,0,.861,0,1.722.066,2.65,0,.861.066,1.722.066,2.451s.066,1.457.066,1.987c0,.6.066.927.066,1.192a8.446,8.446,0,0,0-1.325-.066,8.892,8.892,0,0,0-1.391.066,11.207,11.207,0,0,0,.2-1.391c.066-.662.132-1.524.2-2.451s.132-1.987.2-3.113.132-2.252.132-3.378c.066-1.126.066-2.186.066-3.246s.066-1.987.066-2.848V19.608c0-1.722-.066-3.047-.066-3.908h.6q2.285,2.484,4.968,5.366c1.789,1.921,3.511,3.776,5.233,5.7,1.722,1.855,3.378,3.643,4.968,5.3s2.915,3.047,4.107,4.24V31.135c0-2.65,0-5.233-.066-7.751s-.132-4.9-.265-7.154a5.355,5.355,0,0,0,1.325.2,4.454,4.454,0,0,0,1.192-.2c-.066.53-.132,1.391-.2,2.517s-.132,2.319-.2,3.577-.132,2.517-.2,3.71-.066,2.186-.066,2.915v4.968c0,.927,0,1.855.066,2.849,0,.927,0,1.855.066,2.65v3.378h-.53C88.413,39.349,85.3,35.9,81.988,32.394Z" transform="translate(-23.494 -5.314)" fill="#fff"/>
                    <path id="Path_7007" data-name="Path 7007" d="M203.965,26.2c0-1.656,0-3.312-.066-4.9s-.133-3.246-.2-4.9c.729.066,1.457.066,2.186.132s1.457.066,2.186.066,1.457,0,2.186-.066,1.457-.066,2.186-.066a15.334,15.334,0,0,1,3.047.331,7.154,7.154,0,0,1,2.517.994,4.65,4.65,0,0,1,1.722,1.855,6.052,6.052,0,0,1,.6,2.849,5.881,5.881,0,0,1-.795,3.113,7.843,7.843,0,0,1-1.987,2.186,10.212,10.212,0,0,1-2.517,1.325,10.058,10.058,0,0,1-2.451.6c.331.4.729.927,1.259,1.59s1.126,1.457,1.789,2.252,1.325,1.656,2.054,2.584,1.391,1.789,2.054,2.584,1.259,1.59,1.855,2.252,1.06,1.192,1.391,1.59a6.427,6.427,0,0,0-1.126-.066H219.6a6.427,6.427,0,0,0-1.126.066c-.4-.53-.927-1.325-1.722-2.385s-1.656-2.186-2.584-3.445c-.927-1.192-1.921-2.451-2.849-3.643-.994-1.192-1.855-2.252-2.65-3.047h-1.457v2.782c0,1.656,0,3.312.066,4.968s.132,3.246.2,4.77a26,26,0,0,0-3.71,0l.2-4.77c.066-1.656.066-3.246.066-4.968V26.2Zm3.18,2.186a5.942,5.942,0,0,0,1.192.132c.4,0,.861.066,1.391.066a10.665,10.665,0,0,0,2.451-.265A6.555,6.555,0,0,0,214.5,27.4a5.761,5.761,0,0,0,1.789-1.789,5.34,5.34,0,0,0,.729-2.849,5.367,5.367,0,0,0-.53-2.451,4.042,4.042,0,0,0-1.457-1.524,4.931,4.931,0,0,0-1.921-.795,10.11,10.11,0,0,0-1.987-.2,20.661,20.661,0,0,0-2.186.066c-.6.066-1.06.066-1.457.132-.066,1.391-.133,2.782-.133,4.173-.066,1.391-.066,2.716-.066,4.107v2.12Z" transform="translate(-68.761 -5.55)" fill="#fff"/>
                    <path id="Path_7008" data-name="Path 7008" d="M200.8,36.707s43.324-43.39-83.4-33.255c0,0,82.275-12.851,94.531,11.2C216.833,24.253,200.8,36.707,200.8,36.707Z" transform="translate(-39.629 -0.022)" fill="#b2a89f"/>
                    <g id="Group_15920" data-name="Group 15920" transform="translate(74.326 43.839)">
                        <path id="Path_7009" data-name="Path 7009" d="M112.2,66.4h.729v5.233h2.716V72.3h-3.378V66.4Z" transform="translate(-112.2 -66.268)" fill="#fff"/>
                        <path id="Path_7010" data-name="Path 7010" d="M119.6,66.4h.729v5.9H119.6Z" transform="translate(-114.698 -66.268)" fill="#fff"/>
                        <path id="Path_7011" data-name="Path 7011" d="M122.6,66.4h.795l1.789,4.836h0l1.855-4.836h.729l-2.319,5.9h-.662Z" transform="translate(-115.711 -66.268)" fill="#fff"/>
                        <path id="Path_7012" data-name="Path 7012" d="M132.6,66.4h.729v5.9H132.6Z" transform="translate(-119.086 -66.268)" fill="#fff"/>
                        <path id="Path_7013" data-name="Path 7013" d="M136.9,66.4h.861l3.378,4.9h0V66.4h.729v5.9h-.861l-3.378-4.9h0v4.9H136.9Z" transform="translate(-120.538 -66.268)" fill="#fff"/>
                        <path id="Path_7014" data-name="Path 7014" d="M152.466,71.831a2.927,2.927,0,0,1-1.06.4,6.526,6.526,0,0,1-1.126.132,3.857,3.857,0,0,1-1.259-.2,2.035,2.035,0,0,1-.994-.662,2.621,2.621,0,0,1-.662-.994,3.873,3.873,0,0,1-.265-1.259,2.688,2.688,0,0,1,.265-1.259,2.621,2.621,0,0,1,.662-.994,2.5,2.5,0,0,1,.994-.6,3.857,3.857,0,0,1,1.259-.2,3.364,3.364,0,0,1,1.126.2,3.953,3.953,0,0,1,.994.6l-.53.53a2.942,2.942,0,0,0-.729-.53,2.04,2.04,0,0,0-.861-.2,2.116,2.116,0,0,0-.927.2,1.879,1.879,0,0,0-.729.53,2.382,2.382,0,0,0-.53.795,2.42,2.42,0,0,0-.2.994,2.658,2.658,0,0,0,.2.994,2.1,2.1,0,0,0,.53.795,2.382,2.382,0,0,0,.795.53,2.42,2.42,0,0,0,.994.2,2.717,2.717,0,0,0,.729-.066,2.373,2.373,0,0,0,.729-.265V69.711h-1.325v-.662H152.6v2.782Z" transform="translate(-123.981 -66.2)" fill="#fff"/>
                        <path id="Path_7015" data-name="Path 7015" d="M163,66.4h.729v5.9H163Z" transform="translate(-129.348 -66.268)" fill="#fff"/>
                        <path id="Path_7016" data-name="Path 7016" d="M167.3,66.4h.861l3.378,4.9h0V66.4h.729v5.9h-.861l-3.378-4.9h0v4.9H167.3Z" transform="translate(-130.799 -66.268)" fill="#fff"/>
                        <path id="Path_7017" data-name="Path 7017" d="M182.7,66.4h.729v5.233h2.716V72.3h-3.378V66.4Z" transform="translate(-135.998 -66.268)" fill="#fff"/>
                        <path id="Path_7018" data-name="Path 7018" d="M194.4,70.043a4.474,4.474,0,0,1-.132.927,1.309,1.309,0,0,1-.4.729,2.594,2.594,0,0,1-.662.53,2.323,2.323,0,0,1-1.06.2,2.746,2.746,0,0,1-1.06-.2,1.384,1.384,0,0,1-.662-.53,1.579,1.579,0,0,1-.4-.729,3.909,3.909,0,0,1-.132-.927V66.4h.729v3.511a2.042,2.042,0,0,0,.066.662,2.353,2.353,0,0,0,.265.6.891.891,0,0,0,.464.4,1.31,1.31,0,0,0,.729.132,2.382,2.382,0,0,0,.729-.132,2.881,2.881,0,0,0,.464-.4,2.353,2.353,0,0,0,.265-.6,2.253,2.253,0,0,0,.066-.662V66.4h.729Z" transform="translate(-138.428 -66.268)" fill="#fff"/>
                        <path id="Path_7019" data-name="Path 7019" d="M200.954,69.182,198.966,66.4h.927l1.59,2.319,1.59-2.319h.861l-1.987,2.782,2.186,3.113h-.927l-1.722-2.584L199.761,72.3H198.9Z" transform="translate(-141.466 -66.268)" fill="#fff"/>
                        <path id="Path_7020" data-name="Path 7020" d="M213.2,70.043a4.473,4.473,0,0,1-.132.927,1.308,1.308,0,0,1-.4.729,2.594,2.594,0,0,1-.662.53,2.323,2.323,0,0,1-1.06.2,2.746,2.746,0,0,1-1.06-.2,1.384,1.384,0,0,1-.662-.53,1.579,1.579,0,0,1-.4-.729,3.909,3.909,0,0,1-.132-.927V66.4h.729v3.511a2.042,2.042,0,0,0,.066.662,2.354,2.354,0,0,0,.265.6.891.891,0,0,0,.464.4,1.31,1.31,0,0,0,.729.132,2.382,2.382,0,0,0,.729-.132,2.882,2.882,0,0,0,.464-.4,2.352,2.352,0,0,0,.265-.6,2.253,2.253,0,0,0,.066-.662V66.4h.729Z" transform="translate(-144.774 -66.268)" fill="#fff"/>
                        <path id="Path_7021" data-name="Path 7021" d="M218.666,66.4h1.524a3.777,3.777,0,0,1,.861.066c.265.066.53.132.729.2a1.786,1.786,0,0,1,.53.464,1.657,1.657,0,0,1,.2.861,1.4,1.4,0,0,1-.4,1.06,1.8,1.8,0,0,1-1.06.53l1.656,2.716h-.861l-1.59-2.65h-.927V72.3H218.6V66.4Zm.729,2.584h1.192a1.934,1.934,0,0,0,.6-.132,1.068,1.068,0,0,0,.464-.265.752.752,0,0,0,.2-.53,1.248,1.248,0,0,0-.132-.53.661.661,0,0,0-.331-.265.957.957,0,0,0-.464-.132h-1.524Z" transform="translate(-148.116 -66.268)" fill="#fff"/>
                        <path id="Path_7022" data-name="Path 7022" d="M227.986,69.778,225.8,66.4h.861l1.656,2.65,1.722-2.65h.861l-2.186,3.378V72.3h-.729Z" transform="translate(-150.547 -66.268)" fill="#fff"/>
                    </g>
                </svg>
            </div>

            <div className="App"  ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Switch location={location} key={'location.pathname'}>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/contact" component={Contacts}/>
                        <Route exact path="/csr" component={Sustainability}/>
                        <Route exact path="/joint-venture" component={JointVenture}/>
                        <Route exact path="/investor-relations" component={InvestorRelations}/>
                        <Route exact path="/career" component={Career}/>
                        <Route exact path="/media-center" component={MediaCenter}/>
                        <Route exact path="/media-center/:slug" component={MediaCenterDetail}/>
                        <Route exact path={`/projects`} component={Project}/>
                        <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                        <Route exact path={`/for-rent`} component={Rental}/>
                        <Route exact path={`/for-rent/:slug`} component={RentalDetail}/>
                        <Route exact path={`/search`} component={Search}/>
                        <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                        <Route exact path="/campaign" component={Campaign}/>
                        <Route exact path="/new-launching" component={Launch}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer getPost={getPost}/>
                </div>
            </div>
        </>

    );
}

export default App;
