import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import ProjectArea from "./ProjectArea";
import FeatureProjectV2 from "./FeatureProjectV2";
import { ColorBg } from "../../styles/globalStyleVars";
import FeatureProjectV4 from "./FeatureProjectV4";





const MyComponent = ({ data,title,list }) => {
    return (
        <StyledComponent>
            <div className={'shadow'}>
                <img src={'/images/static/project_line.svg'}/>
            </div>
            <div >
                <ProjectArea data={data} />
                <FeatureProjectV4 title={title} data={list}/>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;

  img{
    width: -webkit-fill-available;
  }

  .shadow {
    position: absolute;
    top: 0px;
    right: 0px;
    height: fit-content;
    box-shadow: none !important;
    width: 100%;
  }  //overflow: hidden;
  // background: ${ColorBg};
  //
  // .shadows {
  //   width: 130%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   object-fit: cover;
  //   box-shadow: none !important;
  //   z-index: 1;
  //   @media (max-width: 767px) {
  //     display: none;
  //   }
  // }

`;

export default MyComponent;
