import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import {Img} from "../Img";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import ReactHtmlParser from "react-html-parser";
import {ImgP} from "../ImgP";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";
// import {ParallaxImg} from "../animations/parallax";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Vision = ({reverse,lowImg,upImg,firstImage,secondImage,title1,title2,text}) => {
    gsap.registerPlugin(ScrollTrigger, CSSPlugin);

    useEffect(() => {
        if(window.innerWidth>767){
            gsap.utils.toArray(".image_right").forEach((item, i) => {
                let getImg = item.querySelector('img')
                let parallaxSpeed = item.getAttribute('data-speed');
                gsap.to(getImg, {
                    yPercent: parallaxSpeed ? parallaxSpeed : 30,
                    ease: "none",
                    scrollTrigger: {
                        trigger: item,
                        // markers: true,
                        scrub: true
                    }
                });
            });
        }


    }, [firstImage,secondImage])
    // ParallaxImg();
    return (
        <StyledTextWithImage reverse={reverse} className={`about_image_with_text pb-150`}>
            <Container className={'version_one'}>
                <Row className={` ${reverse ? 'about_image_with_text_wrapper pt-200 pb-150' : 'about_image_with_text_wrapper pt-200 pb-150'}`}>
                    <Col md={8}>
                        <div className={'top__title'}>
                            <Title text={title1} fontSize={'100'} lineHeight={'100'} margin={'0 0 0 0'}/>
                        </div>
                        <div className={'bottom__title'}>
                            <Title text={title2} fontSize={'60'} lineHeight={'100'} margin={'0 0 0 0'}/>
                        </div>
                        {
                            text &&
                            <div className="split-up">
                                <p>{ReactHtmlParser(text)}</p>
                            </div>
                        }

                    </Col>
                    <Col md={4}>
                        <div className="image_wrapper_whole">
                            <div className="image_left">
                                <Img src={upImg} alt={'upper image of parallex'}/>
                            </div>
                            <div className="image_right ">
                                <ImgP src={lowImg} alt={'lower image of parallex'}/>
                            </div>
                        </div>

                    </Col>
                </Row>

            </Container>
        </StyledTextWithImage>

    );


};


const StyledTextWithImage = styled.div`
  background-color: ${ColorBg};
  margin-top: -5px;
  
  
  &.title{
    @media(max-width: 767px){
      margin-bottom: -40px !important;
    }
  }

  .reverse .image_wrapper_whole{
    .image_right{
      right: -180px;
      bottom: -60px;
      left: unset !important;
    }
  }


  .version_one {
    .small_title {
      margin-bottom: 0 !important;
      @media (max-width: 767px) {
        margin-bottom: 60px !important;

      }
    }


    .image_wrapper_whole {
      position: relative;

      .image_left {
        padding-top: calc(520 / 400 * 100%);
        position: relative;
        z-index: 1;
        bottom: 0;
        width: calc(100% + ${props => props.offset + "px" || '0'});
          // height:calc(100% + ${props => props.offset + "px" || '0'} ) ;
        right: -15px;
        @media (max-width: 767px) {
          //bottom: -50px;
          right: 0px;
          top: -50px;

        }
      }

      .image_right {
        padding-top: calc(320 / 400 * 100%);
        position: absolute;
        left: -120px;
        bottom: -60px;
        //right: 0;
        width: calc(100% + ${props => props.offset + "px" || '0'});
        z-index: 0;
        width: 100%;
        @media (max-width: 767px) {
          left: -15px;
          bottom: 0px;
          width: calc(100% - 80px);
        }
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    .reverse{
      .image_wrapper_whole{
        .image_right{
          @media (max-width: 767px) {
            right: -15px;
            bottom: 0px;
            width: calc(100% - 80px);
          }
        }
      }
    }

  }

  .about_image_with_text_wrapper {
    .bottom__title{
      margin-left: 160px;
      @media(max-width: 767px){
        margin-left: 0px;
      }
    }
    .top__title{
      @media(max-width: 767px){
        margin-bottom: -40px;
      }
    }
    p {
      //margin-top: 60px;
      color: ${MidNightBlue} !important;
      &:not(:nth-last-child(1)) {
        margin-bottom: 20px;
      }

    }
    @media (max-width: 767px) {
      //flex-direction: column-reverse;
      padding-bottom: 0;
    }
  }




  @media (max-width: 767px) {
    .col-md-5{
      margin-top: 60px;
    }
    .pr-0 {
      padding-right: 15px !important;
    }

    .pl-0 {
      padding-left: 15px !important;
    }
  }

`;


export default Vision;
