import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import ButtonRound from "../ButtonRound";
import Button from "../Button";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import PopupV1 from "../forms/PopupV1";
import ReactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";




const MyComponent = ({data}) => {
    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedFileName, setSelectedFileName] = useState('');

        const handleFileChange = (event) => {
            setCv(event.target.files[0])

            const file = event.target.files[0];
            setSelectedFileName(file.name);
        };

        //Form submission

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });
    const [cv, setCv] = useState(null)

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CV_DROP_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('number-142', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file-971', cv);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedFileName('');
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])


        return (
        <StyledComponent className={'service-overview pt-150'}>
            <Container>
                <Row>
                    <Col md={9} className="service-overview__header">
                        <div className="service-overview__header__bigText">
                            <Title text={data?.title} color={'#897059'} fontWeight={500}
                                   lineHeight={40} fontSize={28}/>
                        </div>
                    </Col>
                </Row>
                <Row className={'service-overview__body'}>
                    <Col md={6} className="service-overview__body__left">
                        {
                            data?.description_left &&
                            <p className={'para split-up'}>
                                {ReactHtmlParser(data?.description_left)}
                            </p>
                        }
                    </Col>
                    <Col md={6} className="service-overview__body__right">
                        {
                            data?.description_right &&
                            <p className={'para split-up'}>
                                {ReactHtmlParser(data?.description_right)}

                            </p>
                        }
                    </Col>
                </Row>
                <div className="button" onClick={handleShow}>
                    <Button text={'Drop Your CV'} fontWeight={'700'} background={'#897059'} hoverBackground={'#181D24'} color={'#FFFDFB'}/>
                </div>
                {/*<ButtonRound text={'Read More'}/>*/}
            </Container>
            <Modal show={show} className='cv-modal' onHide={handleClose}>
                <Modal.Body>
                    <div className="modal-data">
                        <Col md={{span:10,offset:1}} className='modal-data__content'>
                            <div onClick={handleClose} className="modal-close dc-btn">
                                <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                                    <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(70 70) rotate(180)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="1">
                                        <circle id={'circle-border'} cx="35" cy="35" r="35" stroke="#181D24" stroke-width="2"/>
                                        <circle id={'hover-color'} cx="35" cy="35" r="34.5" fill="none" stroke-width="0"/>
                                    </g>
                                    <circle id="Ellipse_470" data-name="Ellipse 470" cx="2" cy="2" r="2" transform="translate(37 37) rotate(180)" fill="#181D24" opacity="0"/>
                                    <g id="Group_1433" data-name="Group 1433" transform="translate(-107 -18)">
                                        <line id="Line_4" data-name="Line 4" x2="24.021" transform="translate(133.515 44.515) rotate(45)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3" data-name="Line 3" x2="24.021" transform="translate(150.485 44.5) rotate(135)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>

                            </div>
                            <h3>Drop your CV for future job openings</h3>

                            <Form>

                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name",{
                                        required: 'Username is required',
                                        // pattern: {
                                        //     value: /^[A-Za-z]+$/,
                                        //     message: 'Username must contain only letters',
                                        // },

                                    })}
                                    type="text" placeholder="Full Name"/>
                                <div className="form__phoneEmail">
                                    <Form.Control
                                        className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("phone",{
                                            required:{
                                                value:true,
                                                message:'please enter your phone first'
                                            },
                                            pattern:{
                                                value:/^01[0-9]{9}$/,
                                                message:'please enter a valid 11 digit phone number'
                                            }
                                        })}
                                        type="number" placeholder="Phone Number*"/>
                                    <Form.Control
                                        className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("email",{
                                            required:{
                                                value:true,
                                                message:'please enter your email'
                                            },
                                            pattern:{
                                                value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:'please enter a valid email address'
                                            }
                                        })}
                                        type="email" placeholder="Email*"/>
                                </div>
                                <Form.Control
                                    className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("message",{
                                        required:{
                                            // value:true,
                                            message:'please enter your message'
                                        },

                                    })}
                                    type="textarea" placeholder="Message"/>

                                <div className="button-group">
                                    <div className="attachCvName">
                                        <div className="attach-cv">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.268" height="24" viewBox="0 0 26.268 24" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    <g id="Group_15866" data-name="Group 15866" transform="translate(-12252 -863)">
                                                        <path id="Path_6994" data-name="Path 6994" d="M129.092,23.561a4.6,4.6,0,0,0-3.3-1.463h-.057a4.7,4.7,0,0,0-3.2,1.384l-.009.008L110.8,35.42a2.822,2.822,0,0,0,0,4.1,2.878,2.878,0,0,0,2.044.892h0a2.9,2.9,0,0,0,2.061-.9l8.11-8.285a1.026,1.026,0,0,0-1.466-1.435l-8.106,8.281a.862.862,0,0,1-.6.283.828.828,0,0,1-.586-.283.791.791,0,0,1,0-1.212l11.718-11.914a2.444,2.444,0,0,1,3.658.058,2.571,2.571,0,0,1,.019,3.809,1.026,1.026,0,1,0,1.458,1.443,4.6,4.6,0,0,0-.019-6.695Z" transform="translate(12147.724 840.902)" fill="#181D24"/>
                                                        <path id="Path_6995" data-name="Path 6995" d="M21.594,71.964a1.026,1.026,0,0,0-1.45.023L10.019,82.444a4.609,4.609,0,0,1-3.266,1.435h0A4.56,4.56,0,0,1,3.49,82.418a4.374,4.374,0,0,1,0-6.476L13.6,65.836a1.026,1.026,0,0,0-1.45-1.451L2.037,74.493l0,0A6.745,6.745,0,0,0,0,79.046a6.537,6.537,0,0,0,2.029,4.814,6.653,6.653,0,0,0,4.721,2.07h0a6.644,6.644,0,0,0,4.728-2.048L21.617,73.414A1.026,1.026,0,0,0,21.594,71.964Z" transform="translate(12252 801.07)" fill="#181D24"/>
                                                    </g>
                                            </svg>
                                            <Form.Control
                                                type="file"
                                                accept=".pdf"
                                                id="resume-upload"
                                                style={{ display: "none" }}
                                                {...register("file", {
                                                    required: 'Please attach a file',
                                                })}
                                                onChange={handleFileChange}
                                            />
                                            <Form.Label htmlFor="resume-upload" className="resume-upload-label" style={{ display: selectedFileName ? "none" : "block" }}>
                                                Attach Resume
                                            </Form.Label>
                                            {selectedFileName && (
                                                <div className="file-name">
                                                    {selectedFileName}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div onClick={handleSubmit(onSubmit,onError)}>
                                        <Button text={'Submit'} fontWeight={'700'} background={'#897059'} hoverBackground={'#181D24'} color={'#FFFDFB'}/>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </div>
                </Modal.Body>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  //background-color: #ffffff;
  position: relative;

  .service-overview__header{

    &__bigText{
      .title{
        
      }
      @media (max-width: 767px){
        h2{
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 0;
        }

      }
    }
  }

  .service-overview__body{
    margin-top: 60px;
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
      p{
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: ${MidNightBlue};

    }
  }




`;

export default MyComponent;
