import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import ReactHtmlParser from "react-html-parser";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className=" pt-200" >
            <Container>
                <Row className="children ">
                    <Col md={5} >
                        <Title text={data?.title} fontSize={'60'} lineHeight={'72'} color={'#897059'}/>
                        {
                            data?.description &&
                            <div className="children__desc split-up">
                                <p>{ReactHtmlParser(data?.description)}</p>
                            </div>
                        }

                    </Col>
                    <Col md={{span:6, offset:1}}>
                        {
                            data?.image &&
                            <div className="children__img reveal">
                                <Img src={data?.image}/>
                            </div>
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  .children{
      &__desc{
        margin-top: 60px;
        p{
          font-size: 16px;
          line-height: 24px;
          color: ${MidNightBlue};
          margin-bottom: 60px;
        }
      }
      &__img{
        position: relative;
        padding-top: calc(520 / 570 * 100%);
        overflow: hidden;
      }
      @media(max-width: 767px){
        &__img{
          margin-bottom: 40px;
        }
        &__desc{
          margin-top: 30px;
          p{
            margin-bottom: 30px;
          }
        }
      }
  }
`;

export default MyComponent;
