import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import ButtonRound from "../ButtonRound";
import Button from "../Button";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import PopupV1 from "../forms/PopupV1";
import reactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/project";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";




const MyComponent = ({data,title}) => {
    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    //Brochure Form
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const [dataSubmtted, setDataSubmtted] = useState(true);
    const onSubmit = (e) => {

        let api_services = apiEndPoints.BROCHURE_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('number-142', e?.phone);
        formData.append('project-name', title);

        if (e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();

            // Trigger the PDF download here
            // You can replace this with your PDF file URL
            const pdfUrl = `${data?.brochure}`;
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.target = '_blank';
            link.click();
        }

    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
            setDataSubmtted(false)
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])


    return (
        <StyledComponent className={'service-overview pb-150 pt-150'}>
            <Container>
                <Row>
                    <Col md={9} className="service-overview__header">
                        <div className="service-overview__header__bigText">
                            <Title text={data?.title} color={'#897059'} fontWeight={500}
                                      lineHeight={40} fontSize={28}/>
                        </div>
                    </Col>
                </Row>
                <Row className={'service-overview__body'}>
                    <Col md={6} className="service-overview__body__left">
                        {
                            data?.description_left &&
                            <p className={'para split-up'}>
                                {reactHtmlParser(data?.description_left)}
                            </p>
                        }

                    </Col>
                    <Col md={6} className="service-overview__body__right">
                        {
                            data?.description_right &&
                            <p className={'para split-up'}>
                                {reactHtmlParser(data?.description_right)}
                            </p>
                        }

                    </Col>
                </Row>
                {
                    data?.brochure &&
                    <div className="button" onClick={handleShow}>
                        <Button text={'Project Brochure'}  background={'#897059'} hoverBackground={'#181D24'} color={'white'} svg/>
                    </div>
                }

                {/*<ButtonRound text={'Read More'}/>*/}
            </Container>
            <Modal show={show} className='project-modal' onHide={handleClose}>
                <Modal.Body>


                    <div className="modal-data">

                        <Col md={{span:10,offset:1}} className='modal-data__content'>
                            <div onClick={handleClose} className="modal-close dc-btn">
                                <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                                    <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(70 70) rotate(180)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                        <circle id={'circle-border'} cx="35" cy="35" r="35" stroke="#181D24" stroke-width="2"/>
                                        <circle id={'hover-color'} cx="35" cy="35" r="34.5" fill="none" stroke-width="0"/>
                                    </g>
                                    <circle id="Ellipse_470" data-name="Ellipse 470" cx="2" cy="2" r="2" transform="translate(37 37) rotate(180)" fill="#fffdfb" opacity="0"/>
                                    <g id="Group_1433" data-name="Group 1433" transform="translate(-107 -18)">
                                        <line id="Line_4" data-name="Line 4" x2="24.021" transform="translate(133.515 44.515) rotate(45)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3" data-name="Line 3" x2="24.021" transform="translate(150.485 44.5) rotate(135)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>

                            </div>
                            <h3>Access the brochure now</h3>

                            <Form>
                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name",{
                                        required: 'Username is required',

                                        // pattern: {
                                        //     value: /^[A-Za-z]+$/,
                                        //     message: 'Username must contain only letters',
                                        // },
                                        // validate: {
                                        //     noSpaces: (value) => !value.includes(' ') || 'Username cannot contain spaces',
                                        // },

                                    })}
                                    // autoComplete="off"
                                    type="text" placeholder="Full Name"/>
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone",{
                                        required:{
                                            value:true,
                                            message:'please enter your phone first'
                                        },
                                        pattern:{
                                            value:/^01[0-9]{9}$/,
                                            message:'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number" placeholder="Phone Number*"/>
                                <p>We use the information you provide to contact you about our relevant content, products, and services.</p>
                                <div className="button-group" onClick={handleSubmit(onSubmit,onError)}>

                                    <Button text={'Submit'}  background={'#897059'} hoverBackground={'#181D24'} color={'white'} />
                                </div>
                            </Form>

                        </Col>


                    </div>
                </Modal.Body>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  //background-color: #ffffff;
  position: relative;

  .service-overview__header{
    
    &__bigText{
      .title{
        span{
          color: #ED5333;
        }
      }
      @media (max-width: 767px){
        h2{
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 0;
        }

      }
    }
  }
  
  .service-overview__body{
    margin-top: 60px;
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
      margin-top: 0;
      p{
        margin-bottom: 30px;      
      }
  }

    p {
      font-size: 16px;
      line-height: 24px;
      color: ${MidNightBlue};
      
    }
  }
  
  
  
      
`;

export default MyComponent;
