import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import gsap from "gsap"
import {MidNightBlue} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const TruncateText = ({longText}) => {

    const [showFullText, setShowFullText] = useState(false);
    const [readMoreAnimation, setReadMoreAnimation] = useState(null);
    const [readLessAnimation, setReadLessAnimation] = useState(null);

    const textRef = useRef(null);
    const btnRef = useRef(null);

    const toggleTextDisplay = () => {
        setShowFullText(!showFullText);
    };

    const toggleReadMoreAnimation = () => {
        if (readMoreAnimation) {
            readMoreAnimation.kill();
        }

        const timeline = gsap.timeline();

        timeline.to(btnRef.current, {
            duration: 0.3,
            rotate: 180,
        }).add("rotate");

        const textHeight = textRef.current.clientHeight;
        const duration = showFullText ? 0.5 : 0.7;

        timeline.from(textRef.current, {
            height: showFullText ? 'auto' : textHeight,
            duration: duration,
            ease: 'power2.out',
        }, "rotate").to(textRef.current, {
            height: showFullText ? textHeight : 'auto',
            duration: duration,
            ease: 'power2.out',
        }, "rotate");

        setReadMoreAnimation(timeline);
    };



    // const toggleReadLessAnimation = () => {
    //     if (readLessAnimation) {
    //         readLessAnimation.kill();
    //     }
    //
    //     const timeline = gsap.timeline();
    //
    //     timeline.to(btnRef.current, {
    //         duration: 0.3,
    //         rotate: 0,
    //     }).add("rotate");
    //
    //     const textHeight = textRef.current.clientHeight;
    //     const duration = !showFullText ? 0.3 : 0.5;
    //
    //     timeline.from(textRef.current, {
    //         height: showFullText ? textHeight : 'auto',
    //         duration: duration,
    //         ease: 'power2.out',
    //     }, "rotate").to(textRef.current, {
    //         height: showFullText ? 'auto' : textHeight,
    //         duration: duration,
    //         ease: 'power2.out',
    //     }, "rotate");
    //
    //     setReadLessAnimation(timeline);
    // };
    const toggleReadLessAnimation = () => {
        if (readLessAnimation) {
            readLessAnimation.kill();
        }

        const timeline = gsap.timeline();



        const textHeight = textRef.current.clientHeight;
        const duration = showFullText ? 0.5 : 0.7;

        timeline.from(textRef.current, {
            height: showFullText ? textHeight : 'auto',
            duration: duration,
            ease: 'power2.out',
        }, "rotate").to(textRef.current, {
            height: showFullText ? 'auto' : textHeight,
            duration: duration,
            ease: 'power2.out',
        }, "rotate");

        timeline.from(btnRef.current, {
            duration: 0.7,
            rotate: 0,
        }).add("rotate");


        setReadLessAnimation(timeline);
    };



    const handleToggle = () => {
        toggleTextDisplay();

        if (showFullText) {
            toggleReadLessAnimation();
        } else {
            toggleReadMoreAnimation();
        }
    };


    // const toggleButtonAnimation = () => {
    //     gsap.to(btnRef.current, {
    //         duration: 0.3,
    //         rotate: showFullText ? 0 : 180,
    //         onComplete: toggleTextAnimation
    //     });
    // };
    //
    // const toggleTextAnimation = () => {
    //     const textHeight = textRef.current.clientHeight;
    //     const duration = showFullText ? 0.3 : 0.5;
    //
    //     gsap.from(textRef.current, {
    //         height: showFullText ? 'auto' : textHeight,
    //         duration: duration,
    //         ease: 'power2.out',
    //     });
    //
    // };
    // const handleToggle = () => {
    //     toggleTextDisplay();
    //     toggleTextAnimation(); // call text animation first
    //     setTimeout(() => toggleButtonAnimation(), 500); // wait for text animation to complete before calling button animation
    // };
    // const truncatedText = <span style={{ opacity: showFullText ? '1' : '0' }}>{longText.slice(0, 200) + '...'}</span>;
    const truncatedText = longText.slice(0, 202)+'...';
    const displayText = showFullText ? longText : truncatedText;



    return (
        <StyledComponent>
            <div className='fade-up'>
                <p
                    className={` fade-up ${showFullText ? 'show-full-text' : 'truncate-text'}`}
                    onClick={toggleTextDisplay}
                    ref={textRef}
                >
                    {ReactHtmlParser(displayText)}
                </p>
                {longText.length > 200 && (
                    <span className={'display'} onClick={handleToggle}>
          {showFullText ?
              (
                  // <div className="svg">
                  //     <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  //         <g id="Group_19016" data-name="Group 19016" transform="translate(-645.5 -6884.5)">
                  //             <line id="Line_12372" data-name="Line 12372" y2="10" transform="translate(651.5 6885.5)" fill="none" stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                  //             <line id="Line_12373" data-name="Line 12373" y2="10" transform="translate(656.5 6890.5) rotate(90)" fill="none" stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                  //         </g>
                  //     </svg>
                  // </div>
                  <div className="video-box">
                      <div className="hover-btn hover-black">
                          <a href="javascript:void(0)">
                              <svg id="Button_-_Expander" data-name="Button - Expander" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                  <circle id="Ellipse_452" data-name="Ellipse 452" cx="20" cy="20" r="20" fill="#736640"/>
                                  <circle id="Ellipse_467" data-name="Ellipse 467" cx="20" cy="20" r="20" fill="#897059"/>
                                  <g id="Group_15053" data-name="Group 15053" transform="translate(15 15)">
                                      {/*<line id="Line_3815" data-name="Line 3815" y1="10" transform="translate(5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1"/>*/}
                                      <line id="Line_3816" data-name="Line 3816" y1="10" transform="translate(10 5) rotate(90)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1"/>
                                  </g>
                              </svg>
                          </a>



                      </div>
                  </div>


              )
              :
              (<div className="video-box">
                  <div className="hover-btn hover-black">
                      <a href="javascript:void(0)">
                          <svg id="Button_-_Expander" data-name="Button - Expander" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                              <circle id="Ellipse_452" data-name="Ellipse 452" cx="20" cy="20" r="20" fill="#736640"/>
                              <circle id="Ellipse_467" data-name="Ellipse 467" cx="20" cy="20" r="20" fill="#897059"/>
                              <g id="Group_15053" data-name="Group 15053" transform="translate(15 15)">
                                  <line id="Line_3815" data-name="Line 3815" y1="10" transform="translate(5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1"/>
                                  <line id="Line_3816" data-name="Line 3816" y1="10" transform="translate(10 5) rotate(90)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1"/>
                              </g>
                          </svg>

                      </a>

                  </div>
              </div>)
          }
        </span>
                )}
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  
  p{
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${MidNightBlue};
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .video-box {
    position: relative;
    cursor: pointer;
    top: 30px;
    width: fit-content;
  

    .hover-btn {
      height: 40px;
      width: 40px;
      background-color: #897059;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      
    
      //&.hover:after {
      //  border-radius: 50%;
      //}

      svg {
        overflow: visible;
        position: relative;
        z-index: 2;
        #Ellipse_467 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

        }
        #Ellipse_452 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        }
        g {
          line {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &:hover {
          #Ellipse_467 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;

          }
          #Ellipse_452 {
          }

          g {
            line {
              stroke: #FFFDFB;
            }
          }
        }
        //&:not(:hover){
        //  #Ellipse_4377 {
        //    r: 26;
        //  }
        //}
      }

      path {
        transition: all .7s ease;
      }

    }
    
    @media(max-width: 768px){
      top: 0 !important;
      padding-bottom: 60px;
    }
  }
  
  
`;

export default TruncateText;
