import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Link} from "react-router-dom";
import {hoverNd} from "../../styles/globalStyleVars";
const MyComponent = ({img,location,type,name,link,rent,banner}) => {

    return (
        <StyledComponent className={'project-single'} rent={rent} >
            <Link to={rent? `/for-rent/${link}` : banner? `/project/${link}`: '/projects' }>
                <div className={'project-single__img'}>
                    <Img src={img}/>
                </div>
                <h3>{name}</h3>
                <div className={'d-flex align-items-center'}>
                    <p>{type}</p>
                    <div className={'dot'}></div>
                    <p>{location}</p>
                </div>
            </Link>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    .project-single{
      &__img{
        position: relative;
        padding-top: ${props => props.rent ? `calc(300/370 *100%)` : `calc(460/370 *100%)`};
        //padding-top: calc(460/370 *100%);
        .global-image{
          overflow: hidden;
          img{
            //transform: scale(1.01);
            transition: 0.4s ease;
          }
        }
        &:hover {
          .global-image img{
            transform: scale(1.06) !important;
          }
        }
      }
    }
  .dot{
    display: flex;
    justify-content: end;
    align-items: end;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #B2A89F;
    margin-right: 10px;
    margin-left: 10px;
  }
  margin-bottom: 60px !important;

  h3{
    font-size: 24px ;
    line-height: 32px;
    font-weight: 400;
    margin-top: 30px;
    color: ${hoverNd};
  }
  p{
    font-size: 16px ;
    line-height: 24px ;
    font-weight: 400;
    color: #B2A89F;
  }
  @media(max-width: 767px){
    margin-bottom: 30px !important;
    h3{
      margin-top: 20px !important;
    }
    
  }

`
export default MyComponent;