import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import {Img} from "./Img";
import Title from "./Title";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Modal from "react-bootstrap/Modal";
import {ColorBg} from "../styles/globalStyleVars";


const MyComponent = ({vidTitle,vidThumb,vidUrl,detail}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };
    return (
        <StyledComponent className={'video pt-200 '} detail={detail} vidUrl={vidUrl}>
            <Container>
                <Row className={'video__title'}>
                    <Col lg={7} md={9} className={''}>
                        <Title
                            text={vidTitle ? vidTitle : ''}
                            fontSize="60"
                            lineHeight="72"
                            color="#897059"
                        />
                    </Col>
                </Row>
                <Row className={'video__video'}>

                    <Col md={{span:10,offset:1}} >
                        {
                            vidUrl ?
                                <div className="video__video__image " onClick={() => handelOpen(true, vidUrl ? vidUrl : '')}>
                                    <Img src={vidThumb ? vidThumb : ''}/>
                                </div> :
                                <div className="video__video__image">
                                    <Img src={vidThumb ? vidThumb : ''}/>
                                </div>
                        }


                        {
                            vidUrl ?
                                <div className="video__video__button" onClick={() => handelOpen(true, vidUrl ? vidUrl : '')}>
                                    <div className="svg-container">
                                        <svg id="Group_20003" data-name="Group 20003" xmlns="http://www.w3.org/2000/svg"
                                             width="140" height="140" viewBox="0 0 140 140">
                                            <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(140 140) rotate(180)"
                                               fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                                <circle id="circle-border" cx="70" cy="70" r="70" stroke="#FFFDFB" stroke-width="1" fill="#FFFDFB"/>
                                                <circle id="hover-color" cx="70" cy="70" r="69.5" stroke="none" fill=""/>
                                            </g>
                                            <path id="Polygon_1" data-name="Polygon 1" d="M15,0,30,20H0Z"
                                                  transform="translate(83 55) rotate(90)" fill="#736640"/>
                                        </svg>
                                    </div>
                                </div> : ''

                        }


                    </Col>
                </Row>
            </Container>
            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoId}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  padding-bottom: 250px;
  background-color: #FFFDFB;
  position: relative;
  margin-top: -2px;

  &:before {
    content: '';
    position: absolute;
    //height: 50%;
    height: ${props => props?.detail ? '100%' : '50%'};

    background-color: ${ColorBg};
    bottom: 0;
    width: 100%;
    left: 0;
  }
  @media(max-width: 767px){
    padding-bottom: 120px;
  }
  .video {
    &__title {
      position: relative;
      z-index: 5;
      //h2 {
      //  font-size: 60px;
      //  line-height: 72px;
      //  color: white;
      //}
    }

    &__video {
      position: relative;
      margin-top: -83px;
      @media(min-width: 1499px){
        margin-top:-75px;
      }
      .modal-video{
        position: absolute;
        z-index: 9999999;
        .modal-video-inner{
          //width: 100% !important;
          //padding: 0 !important;
        }
      }
      &__image{
        cursor: ${props => props.vidUrl ? 'pointer' : ''};
        position: relative;
        padding-top: calc(840 / 1455 * 100%);
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__button {
        position: absolute;
        top: 100.6%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: ${props => props.vidUrl ? 'pointer' : ''};
      }
    }
  }
  .svg-container {
    display: inline-block;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    svg{
      width: 100%;
      height: 100%;
    }
  }

  #hover-color {
    fill: transparent;
    transition: fill 0.8s;
  }
  #circle-border{
    transition: all 1s;
  }


  .svg-container:hover {
    
    svg{
      path{
        fill: #FFFDFB;
      }
    }

    #hover-color {
      fill: #736640;
      animation: fillAnimation 0.8s forwards;
    }
    #circle-border{
      stroke-width: 0;
    }
  }


  .svg-container:not(:hover) #hover-color {
    fill: transparent;
    animation: reverseFillAnimation 0.8s backwards;
  }

  @keyframes fillAnimation {
    0% {
      r: 0px;
    }
    100% {
      r: 70px;
    }
  }
  @keyframes reverseFillAnimation {
    0% {
      r: 69.5px;
    }
    100% {
      r: 0px;
    }
  }

  @media(max-width: 767px){
    .video{
      &__title{
        .title{
          margin-bottom: 0 !important;
          h2{
            font-size: 36px;
            line-height: 48px;
          }
        }

      }
      &__video{
        .col-md-10{
          padding-left: 0;
          padding-right: 0;
        }
        margin-top: 40px;
        &__button{
          top:50%;
          left:50%;
          .svg-container{
            width: 60px;
            height: 60px;
            svg{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    margin: 0;
  }
  

`;

export default MyComponent;
