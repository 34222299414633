import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "../Title";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";


const Feature = ({data}) => {

    const features = data?.feature?.list;

    const settings = {
        dots: true,
        rows: 2,
        slidesPerRow: 1,
        infinite: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: false,
        prevArrow: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <StyledComponent id={'features'} className='feature pt-150 pb-150'>
            <Container>
                <Row className={'feature__row'} >
                    <Col md={7} >
                        <div className='feature__title'>
                            <Title text={data?.feature?.title}  color={'#897059'} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>

                        <div className="slider" style={{width: '100%'}}>

                            {
                                features && features.length>0 ?
                                    <Slider {...settings}>
                                        {
                                            features && features.length>0 &&
                                            features.map((element,index)=>{
                                                return(
                                                    <div className='feature__single' key={index}>
                                                        <div className='feature__single__img'>
                                                            <img src={element?.icon} alt=""/>
                                                        </div>
                                                        <div className={'feature__single__name'}>
                                                            <h6>{element?.title}</h6>
                                                        </div>
                                                        <p>{element?.desc}</p>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider> : ''
                            }

                        </div>
                    </Col>
                </Row>


            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  margin-top: -2px;
  position: relative;
  overflow: hidden;
  background-color: ${ColorBg};

  //padding-bottom: 65px;

  .shadow-right {
    position: absolute;
    top: 0;
    right: -145px;
    height: fit-content;
  }


  .col {
    padding-left: 0px;
    padding-right: 0px;
  }

  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .feature {
    &__row{
      margin-bottom: 60px;
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }

    &__title {
      //margin-bottom: 42px;
      @media(max-width: 767px){
        margin-bottom: 20px;
      }

    }
    &__disclaimer{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
      gap: 6px;
      p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #B2A89F;
        height: 22px;
        
      }
    }

    &__single {
      //padding: 40px 72px 0px 72px;
      padding-top: calc(234 / 234 * 100%);
      position: relative;
      cursor: pointer;
      margin-bottom: -8px;
      overflow: hidden;
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all .6s ease;
        background-color: #897059;
      }
      
      &__name {
        position: absolute;
        top: 135px;
        left: 20px;
        right: 20px;
        @media (min-width: 1500px) {
          top: 62%;
        }


        h6 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          text-align: center;
          //height: 44px;
          color: #181D24;

        }
      }

      &__img {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        height: 90px;
        width: 90px;
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        margin: auto;
        @media(min-width: 1500px){
          top: 26%;
        }
        
      }
      p{
        position: absolute;
        left: 10%;
        top: 10%;
        height: 100%;
        right: 10%;
        opacity: 0;
        transition: all 0.6s ease;
        transform: translateY(100%);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #FFFDFB;
      }
      //&:hover{
      //  &:after{
      //    opacity: 1;
      //    z-index: 2;
      //  }
      //  p{
      //    z-index: 3;
      //    opacity: 1;
      //    transform: translateY(0);
      //  }
      //  &__img{
      //    opacity: 0;
      //  }
      //  &__name{
      //    opacity: 0;
      //  }
      //
      //}

    }
  }


  .slick-dots li button:before {
    color: transparent;
    border: 1px solid #897059;
    opacity: 1;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .slick-dots li.slick-active button:before {
    background-color: #897059;
    border-color: #C19A5B;
    height: 20px;
    width: 20px;
  }

  @media (max-width: 991px) {
    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 50%;
      }
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 65px !important;
    .feature__single__img {
      top: 10px;
    }

    .feature__single h2 {
      top: 110px;
    }
  }

  .slick-track {
    display: flex !important;
    height: 100%;
  }

  .slick-slide {
    //height: auto;

    .slick-slide > div {
      //height: 100%;

      .myItemClass {
        height: 100%;
      }
    }
  }

  .slick-slide {
    //border-right: 1px solid rgba(178,168,159,0.3);
    //border-top: 1px solid rgba(178,168,159,0.3);
    //border-bottom: 1px solid rgba(178,168,159,0.3);
    //
    //> div:nth-of-type(1) {
    //  border-bottom: 1px solid rgba(178,168,159,0.3);
    //}
    //
    //&:nth-of-type(1) {
    //  border-left: 1px solid rgba(178,168,159,0.3);
    //}
    

    @media (max-width: 767px) {
      //&:nth-of-type(1) {
      //  border-left: 0;
      //}
    }
  }
  
  .slick-current{
    //border-left: 1px solid rgba(178,168,159,0.3);
    //width: 223px !important;
  }

  @media (max-width: 767px) {
    margin-left: -15px;
    margin-right: -15px;
    .title {
      margin-bottom: 0;
    }
  }
  
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
  
  .slick-list{
    border: 1px solid rgba(178,168,159,0.5);
  }
  .slick-slide{
    border-right: 1px solid rgba(178,168,159,0.5);
    > div:nth-of-type(1) {
      border-bottom: 1px solid rgba(178,168,159,0.5);
    }
  }

  .slick-dots{
    bottom: -60px;
  }


`;
export default Feature;