import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";




const AtGlance = ({data}) => {

    const title = data?.title;
    const image = data?.image;
    const description = data?.description;

    // offset
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])

    return (
        <StyledOverviewWithImage id={'glance'} className='atglance pb-200'>
            <Container className=''>
                <Row className={'atglance-row'}>

                    <Col md={6} className='atglance__content'>
                        <div className='atglance__content__title'>
                            <Title text={data?.title} color={'#897059'} margin={'0 0 60px 0'}/>
                            <div className="split-up">
                                {reactHtmlParser(data?.description)}
                            </div>
                            <div className="disclaimer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="Group_20037" data-name="Group 20037" transform="translate(-98 -2231)">
                                        <g id="Ellipse_479" data-name="Ellipse 479" transform="translate(98 2231)" fill="none" stroke="#897059" stroke-width="1">
                                            <circle cx="10" cy="10" r="10" stroke="none"/>
                                            <circle cx="10" cy="10" r="9.5" fill="none"/>
                                        </g>
                                        <text id="I" transform="translate(106.5 2246)" fill="#b2a89f" font-size="16" font-family="OregonLDOMedium, Oregon LDO"><tspan x="0" y="0">i</tspan></text>
                                    </g>
                                </svg>
                                <p>Disclaimer: It’s may vary depending on requirements.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={{span: 5, offset: 1}} className='px-0 pr-0'>
                        {
                            data?.image &&
                            <div className="atglance__img ">
                                <Img src={data?.image}/>
                                {
                                    data?.logo?
                                    <div className={'lower-logo d-flex justify-content-center align-items-center'}>
                                        <img src={data?.logo}/>
                                    </div> : ''
                                }

                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledOverviewWithImage>
    );
};
const StyledOverviewWithImage = styled.section`
  background-color: ${ColorBg};
  margin-top: -2px;
  position: relative;

  .atglance {
    &__img {
      position: relative;
      padding-top: calc(680 / 470 * 100%);
      @media (max-width: 767px) {
        position: relative;
        padding-top: calc(500 / 345 * 100%);
      }

      .lower-logo {
        position: absolute;
        bottom: 0;
        height: 120px;
        background: rgba(137, 112, 89, 0.8);
        width: 100%;
        
        img{
          height: 80px;
        }
      }
    }

    &__content {
      color: ${MidNightBlue};
      display: flex;
      align-items: center;
      padding-right: 70px;

      @media (max-width: 991px) {
        padding-top: 50px;
      }
      @media (max-width: 767px) {
        padding-right: 15px !important;
        padding-left: 30px;
      }

      &__title {
        width: 100%;

        h1 {
          font-size: 80px;
          line-height: 80px;
          font-weight: 400;
          text-transform: uppercase;
        }

        table {
          margin-top: 20px;

          tr {
            display: flex;
            width: 100%;
            border-bottom: 1px solid rgba(115, 102, 64, 0.2);
            padding-top: 20px;
            padding-bottom: 20px;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;

              td {
                padding-bottom: 0;
              }
            }

          }

          &:last-child {
            margin-bottom: 0;

          }

          td {
            font-weight: 400;
            flex: 0 0 65%;
            font-size: 16px;
            line-height: 24px;

            &:first-child {
              font-weight: 600;
              flex: 0 0 35%;
              padding-left: 0px;
              color: #897059;
            }

          }
        }

        .disclaimer {
          margin-top: 120px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          height: 100%;
          gap: 6px;

          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #B2A89F;
            height: 22px;

          }

          @media (max-width: 767px) {
            margin-top: 60px;
          }
        }

      }
    }
  }

  @media (max-width: 767px) {
    .atglance-row {
      flex-direction: column-reverse;
    }

    .title {
      margin-bottom: 0 !important;
    }
  }


`;
export default AtGlance;