import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "./Title";
import {ColorBg, MidNightBlue} from "../styles/globalStyleVars";





const MyComponent = ({headerText,leftText,rightText}) => {

    return (
        <StyledComponent className={'service-overview pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col md={9} className="service-overview__header">
                        <div className="service-overview__header__bigText">
                            <Title text={headerText} color={'#897059'} fontWeight={500}
                                   lineHeight={40} fontSize={28}/>
                        </div>
                    </Col>
                </Row>
                <Row className={'service-overview__body'}>
                    <Col md={6} className="service-overview__body__left">
                        <p className={'para split-up'}>{leftText}
                        </p>
                    </Col>
                    <Col md={6} className="service-overview__body__right">
                        <p className={'para split-up'}>{rightText}
                        </p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  //background-color: #ffffff;
  position: relative;

  .service-overview__header{

    &__bigText{
      .title{
        span{
          color: #ED5333;
        }
      }
      @media (max-width: 767px){
        h2{
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 0;
        }

      }
    }
  }

  .service-overview__body{
    margin-top: 60px;
    //margin-bottom: 60px;
    @media(max-width: 767px){
      margin-top: 0px;
      //margin-bottom: 40px;
      p{
        margin-bottom: 30px;
      }
      &__right{
        p{
          margin-bottom: 0 !important;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: ${MidNightBlue};

    }
  }




`;

export default MyComponent;
