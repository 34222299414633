import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        },
        loaded: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFooter/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAbout/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareer/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContact/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchInvestor/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchVenture/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDatapROJECT/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMedias/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['search/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchSustainability/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchRentData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchRentDataDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaDataDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
