import React, {useState} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";
import {ColorBg, NaturalSand} from "../styles/globalStyleVars";
const InnerBanner = ({img, text, title,page_name,detail,project_type,project_location,details_address,logo,mimg}) => {

    return (
        <StyledInnerBanner className='InnerBanner parallax' detail={detail}>
            <div className={'banner__img '} >
                {
                    window.innerWidth>767 ?
                        <Img parallax banner={true} src={img}/> :
                        <Img parallax banner={true} src={mimg ? mimg : img}/>
                }

            </div>
            <Container>
                <Col lg={8} md={12}>
                    <div className={'d-flex align-items-center'} style={{marginBottom:'20px'}}>
                        {
                            page_name && <div className={'border-style'}></div>
                        }
                        <p>{page_name}</p>
                    </div>
                    {
                        title &&
                        <div >
                            <h2>{reactHtmlParser(title)}</h2>
                        </div>
                    }

                </Col>
                {
                    detail?
                        <Col md={`${detail}`? 5 : 4} className={'address'}>
                            {
                                logo? <img src={logo? logo :''}/> : ''
                            }

                            <div>
                                <div className={'d-flex align-items-center'}>
                                    <p>{project_type}</p>
                                    <div className={'dot'}></div>
                                    <p>{project_location}</p>
                                </div>
                                <h6> {reactHtmlParser(details_address)}  </h6>
                            </div>
                        </Col>
                        :''
                }
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: 120px;
  position: relative;
  background-color: ${ColorBg};
  
  
  @media(max-width: 767px){
    padding-top: 180px;
    
    .address{
      img{
        width: 120px;
        height: 80px;
      }
    }
  }

  .address{
    position: absolute;
    bottom: 30px;
    img{
      margin-bottom: 37px;
      
    }
    p{
      font-size: 20px;
      line-height: 24px;
      color: #FFFDFB;
      font-weight: 500;
    }
    .dot{
      display: flex;
      justify-content: end;
      align-items: end;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #FFFDFB;
      margin-right: 10px;
      margin-left: 10px;
    }
    h6{
      font-size: 40px;
      line-height: 40px;
      font-weight: 500;
      color: #FFFDFB;
      margin-top: 10px;
    }
  }
  .banner__img{
    padding-top: calc(524 / 1366 * 100%);
    position: relative;
    @media(max-width: 767px){
      padding-top: calc(458 / 375 * 100%);
    }
    
  }
  .border-style{
    width: 30px;
    height: 0px;
    border: 1px solid #B2A89F;
    margin-right: 12px;
  }
  

  &:after {
    content: '';
    position: absolute;
    // background-color: ${ColorBg};
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

  }

  .container {
    height: 80%;
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    position: ${props => props.position || 'absolute'};

    top: ${props => props.detail ? '140px' : '120px'};
    z-index: 2;
  }
  p{
    color:#B2A89F;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;

  }

  h2 {
    //position: absolute;
    left: 15px;
    //bottom: 94px;
    color: ${NaturalSand};
    font-size: 80px;
    font-weight: 300;
    line-height: 80px;
    text-transform: uppercase;
    z-index: 2;

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }
 
  @media (min-width: 767px) {
    
    .title {
      width: 50%;
    }
    padding-top: 200px;

  }
  .col-lg-8{
    padding-left: 0px;
  }
  @media (max-width: 767px) {
    position: relative;
    .container {
      top: 90px;
    }
    .col-lg-6{
      padding-left: 0px;
    }

    .title {
      margin-bottom: 40px !important;
    }

    h2 {
      font-size: 50px;
      line-height: 56px;
    }
  }
`;

export default InnerBanner;
