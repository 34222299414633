import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InvestorOverview from "../../components/joint-venture/JointOverview";
import Video from "../../components/Video";
import Benifits from "../../components/joint-venture/Benefits";
import Benefits from "../../components/joint-venture/Benefits";
import Overview from "../../components/Overview";
import InvestorForm from "../../components/investor-relations/InvestorForm";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import {fetchInvestor} from "../../api/redux/Investor";
import VideoPopUp from "../../components/VideoPopUp";

const MyComponent = () => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.INVESTOR
        dispath(fetchInvestor([api_url]))
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])


    let getPost = useSelector(state => state.investor)
    const investorBanner = getPost?.posts?.page_data?.[0]?.banner;
    const investorOverview = getPost?.posts?.page_data?.[0]?.overview;
    const investorVideo = getPost?.posts?.page_data?.[0]?.video;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Investor Relations | Sanmar Properties Ltd</title>
                <meta name="description" content="Sanmar is one of the Top Real Estate Company In Bangladesh. Since 1999 Sanmar successfully handed over many projects in Dhaka and Chattogram"/>

            </Helmet>

            <StyledComponent>
                <InnerBanner img={investorBanner?.image} title={investorBanner?.title} page_name={'Investor Relations'}/>
                <Overview headerText={investorOverview?.title} leftText={investorOverview?.description_left}
                          rightText={investorOverview?.description_right}/>
                <VideoPopUp vidTitle={investorVideo?.title}
                       vidThumb={investorVideo?.image}
                       vidUrl={investorVideo?.url} detail/>
                <InvestorForm/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
