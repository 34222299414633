import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {BsChevronDown} from "react-icons/bs";
import {hover} from "../styles/globalStyleVars";
import Title from "./Title";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data,open}) => {

    const visitDetails = data?.office_locations;
    return (
        <StyledComponent className={'pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col>
                        <Title text={'Give Us a Visit'} color={'#FFFDFB'}/>
                    </Col>
                </Row>
            </Container>
            {
                visitDetails && visitDetails.length>0 ?
                <Accordion defaultActiveKey={open ? "0" : ''}>
                    {
                        visitDetails && visitDetails.length>0 &&
                        visitDetails.map((element,index)=>{
                            return(
                                <Accordion.Item eventKey={`${index}`}>
                                    <Container>
                                        <Accordion.Header>
                                            <Row>
                                                <Col md={3}>
                                                    <h6 className={''}>{element?.office_name}</h6>
                                                </Col>
                                                <Col md={4}>
                                                    <a href={element?.office_map_url} target="_blank">
                                                        <p className={''}>
                                                            {ReactHtmlParser(element?.office_address)}
                                                        </p>
                                                    </a>

                                                </Col>
                                                <Col md={3}>
                                                    <a  href={'tel:'+ element?.office_number}>
                                                        <p className={''}>{element?.office_number}</p>
                                                    </a>
                                                    <a  href={'tel:'+ element?.office_number_two}>
                                                        <p className={''}>{element?.office_number_two}</p>
                                                    </a>
                                                    <a  href={'tel:'+ element?.office_number_three}>
                                                        <p className={''}>{element?.office_number_three}</p>
                                                    </a>
                                                </Col>
                                                <Col md={2} className={'mapButton'}>
                                                    <p className={''}>Map</p>
                                                    <div className="mapButton__wrap">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                                                            <g id="Animation_-_Nav" data-name="Animation - Nav" transform="translate(52) rotate(90)">
                                                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                        fill="#897059"></circle>
                                                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                        fill="#181D24"></circle>
                                                                <g id="Group_15984" data-name="Group 15984" transform="translate(-97 -5894.5)">
                                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5" transform="translate(120.5 5915.5)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5" transform="translate(120.5 5920.5)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Accordion.Header>
                                    </Container>

                                    <Accordion.Body>

                                        <div className="mapImage">
                                            <a href={element?.office_map_url} target="_blank">
                                                <Img src={element?.office_map_image}/>
                                            </a>
                                        </div>
                                        <Container>
                                            <Row>
                                                <Col md={12}>
                                                    <div className={'header-border'}></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }


                </Accordion> : ''

            }

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #181D24;
  will-change: transform !important;

  .title {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .accordion-item {
    //margin-top: 60px;
    //margin-bottom: 60px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    p {
      color: #FFFDFB;
      font-size: 16px;
      line-height: 24px;
      font-family: "Oregon LDO";
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      &:hover {
        color: #897059;
      }
    }

    h6 {
      color: #FFFDFB;
      font-size: 24px;
      line-height: 32px;
      font-family: "Oregon LDO";
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      &:hover {
        color: #897059;
      }
    }
    &:last-child{
      .accordion-body{
        .header-border {
          padding-top: 40px;
          border-bottom: 1px solid transparent;
          border-left: none;
          border-right: none;
        }
      }
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    .mapButton {
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      &__wrap {
        margin-top: -11px;

        svg {
          transform: rotate(180deg);

          #Ellipse_4378 {
            r: 0;
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }

          #Line_3865 {
            stroke: #FFFDFB;
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }

          #Line_3866 {
            stroke: #FFFDFB;
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }

          &:hover {
            #Ellipse_4378 {
              r: 26;
            }

            #Line_3865 {
              stroke: #FFFFFF;
            }

            #Line_3866 {
              stroke: #FFFFFF;
            }
          }
        }
      }

      @media (max-width: 767px) {
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: ${hover};
      font-size: 26px;
      font-weight: bold;
      line-height: 32px;
      text-transform: capitalize;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(255, 253, 251, 0.5);
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      &.collapsed {
        //color: #1A1818;
        //border-color: #1A1818;
        padding-bottom: 40px;

        svg {
        }

        .mapButton__wrap {
          margin-top: -11px;

          svg {
            transform: rotate(0deg);

            #Line_3865 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              stroke: #FFFFFF;

            }

            #Line_3866 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              stroke: #FFFFFF;
            }

            #Ellipse_4378 {
              r: 26;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

            }

            #Ellipse_4377 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }

            &:hover {
              #Ellipse_4378 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 0;
              }

              #Line_3865 {
                stroke: #FFFDFB;
              }

              #Line_3866 {
                stroke: #FFFDFB;
              }

              #Ellipse_4377 {
              }
            }
          }
        }

      }


    }

    .accordion-item:last-child .button {
      border-bottom: none;
    }


    &:hover {

      button {
        color: ${hover};
        border-color: ${hover};
      }
    }
  }

  .accordion-body {
    .mapImage {
      position: relative;
      padding-top: calc(520 / 1366 * 100%);
      
      @media(max-width: 767px){
        padding-top: calc(280 / 375 * 100%);
      }
    }

    .header-border {
      padding-top: 40px;
      border-bottom: 1px solid rgba(255, 253, 251, 0.5);
      border-left: none;
      border-right: none;
    }
  }

  .accordion-item:last-child .accordion-header .accordion-button {
    border-bottom: none !important;
  }


`;

export default MyComponent;
