import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import JointOverview from "../../components/joint-venture/JointOverview";
import Video from "../../components/Video";
import Benefits from "../../components/joint-venture/Benefits";
import Testimonial from "../../components/joint-venture/Testimonial";
import JointForm from "../../components/joint-venture/JointForm";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import {fetchVenture} from "../../api/redux/joint-venture";
import VideoPopUp from "../../components/VideoPopUp";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.VENTURE
        dispath(fetchVenture([api_url]))
    }, [])



    let getPost = useSelector(state => state.venture)
    const jointBanner = getPost?.posts?.page_data?.[0]?.banner;
    const jointOverview = getPost?.posts?.page_data?.[0]?.overview;
    const jointVideo = getPost?.posts?.page_data?.[0]?.video;
    const jointBenefit = getPost?.posts?.page_data?.[0]?.benefits;
    const jointTestimonial = getPost?.posts?.page_data?.[0]?.testimonial;
    const meta = getPost?.posts?.page_data?.[0]?.meta;



    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>

            <StyledComponent>
                <InnerBannerV2 page_name={jointBanner?.page}
                               img={jointBanner?.image}
                               mimg={jointBanner?.mimage} title={jointBanner?.title}
                />
                <JointOverview data={jointOverview}/>

                {
                    jointVideo && jointVideo.title && jointVideo.image && jointVideo.url ?
                        <VideoPopUp vidTitle={jointVideo?.title} detail
                               vidThumb={jointVideo?.image}
                               vidUrl={jointVideo?.url}/> : ''
                }

                <Benefits data={jointBenefit}/>
                <Testimonial data={jointTestimonial}/>
                <JointForm/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
