import styled from "styled-components";
import {Img} from "./Img";
import {ColorBg, ColorWhite, hover, hoverNd} from "../styles/globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";

const BlogSingle = ({title, text, link, img, day, month, cat,external_link}) => {
    return (
        <StyledBlogSingle className='blog-single fade-up'>
            <div className="blog-single__inner">
                {
                    external_link ?
                        <a href={external_link} target={'_blank'}/> :
                        <Link to={link || '/media-center/1'}/>
                }

                <Img src={img} objectFit={'cover'} layout={'fill'}/>
                <div className="blog-single__inner__content">

                    <div className="blog-single__inner__content__top">
                        {
                            text &&
                            <p>{text}</p>

                        }
                        {
                            title &&
                            <h2>{title}</h2>

                        }
                    </div>
                            <div className="blog-single__inner__content__bottom">
                                <div className={'blog-single__inner__content__bottom-top'}>
                                    {
                                        day &&
                                        <h4>{day}</h4>

                                    }
                                    {
                                        month &&
                                        <p>{month}</p>

                                    }
                                </div>
                                {
                                    cat &&
                                    <div className='blog-single__inner__content__bottom__cat'>
                                        <p><span>{cat}</span></p>
                                    </div>
                                }

                            </div>


                </div>
            </div>
        </StyledBlogSingle>
    );
};

const StyledBlogSingle = styled.div`
  //width: 100%;

  margin-bottom: 60px;
  .blog-single__inner {
    padding-top: calc(460 / 370 * 100%);
    position: relative;
    .global-image{
      overflow: hidden;
      img{
        transform: scale(1.01);
        transition: 1.4s ease;
      }
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {

      &__top {
        p {
          font-weight: 400;
          color: #ffffff;
          position: absolute;
          left: 40px;
          top: 40px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        h3 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #ffffff;
          position: absolute;
          left: 40px;
          top: 180px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          width: 50%;
          text-decoration: underline;
          text-decoration-color: white;
        }
        .h3-container {
          border-bottom: 1px solid white;
          width: fit-content;
        }

        h2 {
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          color: #FFFFFF;
          margin: 0;
          transition: all .3s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 40px;
        right: 40px;
        bottom: 40px;
        display: flex;
        justify-content: space-between;
        //border-top: 1px solid #221F1F;
        padding-top: 20px;
        z-index: 2;
        color: ${ColorWhite};
        display: flex;
        align-items: end;
        p {
          color: #ffffff;

          span {
            color: #ffffff;
          }
        }
        
        .blog-single__inner__content__bottom-top{
          h4 {
            font-size: 100px;
            font-weight: 400;
            color: ${ColorWhite};
            line-height: 100px;
            transition: color .3s ease;
            margin-bottom: -15px;
          }
          p{
            text-align: start;
            margin-top: 0px;
          }
          
        }
        &__cat{
          height: 27px;
          width: 38%;
          background: ${ColorBg};
          border-radius: 15px;
          display: flex;
          justify-content: center;
          p {
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            text-align: center;
            font-weight: 400;
            transition: color .3s ease;
            color: ${hoverNd};
            span {
              display: block;
              color: ${hoverNd};
            }
            margin-top: auto;
          }
        }
      }
    }

    &:hover {
      .global-image img{
        transform: scale(1.06) !important;
      }
      .blog-single__inner__content__top {
        h2 {
          opacity: 0;
          transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }
        h3{
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: #FFF;

        h4 {
          color: #ffffff;
        }

        //p {
        //  color: #ffffff;
        //
        //  span {
        //    color: #ffffff;
        //  }
        //}
      }
    }

  }

  &:hover {
    .blog-single__inner__content:after {
      height: 0;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
    
    .blog-single__inner__content__bottom__cat{
      width: 38%;
    }

    .blog-single__inner__content__top {
      p, h2 {
        left: 30px;
        right: 30px;
        top: 30px;
      }
    }

    .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
      left: 30px;
      right: 30px;
      top: 30px;
    }

  }
  @media(min-width: 768px) and (max-width: 991px){
  .blog-single__inner__content__bottom__cat{
    width: 50%;
  }
`;
export default BlogSingle;
