import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import {SwiperSlide} from "swiper/react";
import MediaSingle from "../MediaSingle";
import ReactHtmlParser from "react-html-parser";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className="milestone pt-200 ">
            {
                data && data.length>0 ?

            <Container>
                {
                    data && data.length>0 &&
                    data.map((element,index)=>{
                        return(
                            <Row className="milestone__plant pb-200">
                                <Col md={{span:5, offset: index % 2 === 1 ? 1 : 0 }}>
                                    <Title text={element?.title} fontSize={'60'} lineHeight={'72'} color={'#897059'}/>
                                    <div className="milestone__plant__desc">
                                        <p className={'faded'}>
                                            {ReactHtmlParser(element?.description_plain)}
                                        </p>
                                        <div className="milestone__plant__desc__list">
                                                {ReactHtmlParser(element?.description_list)}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={{span:6, offset: index % 2 === 0 ? 1 : 0 }}>
                                    <div className="milestone__plant__img reveal">
                                        {/*<div className="reveal">*/}
                                        {/*    {*/}
                                        {/*        element?.image &&*/}
                                                <Img src={element?.image}/>

                                            {/*}*/}
                                        {/*</div>*/}
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Container> : ''
            }
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: ${ColorBg};
  margin-top: -5px;
    @media(max-width: 767px){
      padding-top: 60px !important;
      padding-bottom: 60px;
    }
  .milestone{
    &__plant{
      &:nth-child(even){
        flex-direction: row-reverse;
      }
      &__desc{
        margin-top: 60px;
        p{
          font-size: 16px;
          line-height: 24px;
          color: ${MidNightBlue};
          margin-bottom: 60px;
        }
        &__list{
          ul{
            li{
              font-size: 16px;
              line-height: 24px;
              color: ${MidNightBlue};
              padding-bottom: 10px;
              margin-bottom: 20px;
              border-bottom: 1px solid #B2A89F;
              &:last-child{
                border-bottom: unset;
                padding-bottom: 0px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
      &__img{
        position: relative;
        padding-top: calc(520 / 570 * 100%);
      }
      @media(max-width: 767px){
        padding-bottom: 60px !important;
        &:nth-child(odd){
          flex-direction: column-reverse;
        }
        &:nth-child(even){
          flex-direction: column-reverse;
        }
        &__img{
          margin-bottom: 40px;
        }
        &__desc{
          margin-top: 30px;
          p{
            margin-bottom: 30px;
          }
        }
      }
    }
  }
`;

export default MyComponent;
