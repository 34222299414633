import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import sustainabilityReducer from '../redux/sustainability'
import contactReducer from '../redux/contact'
import investorReducer from '../redux/Investor'
import careerReducer from '../redux/career'
import ventureReducer from '../redux/joint-venture'
import projectReducer from '../redux/project'
import rentReducer from '../redux/for-rent'
import mediaReducer from '../redux/media'
import policyReducer from '../redux/privacy'
import campaignReducer from '../redux/campaign'
import searchReducer from '../redux/search'
import launchReducer from '../redux/new-launching'
import footerReducer from '../redux/footer'
import globalReducer from '../redux/global/index'

const store = configureStore({
    reducer: {
        globalReducer,
        posts: postReducer,
        home: homeReducer,
        about: aboutReducer,
        sustainability: sustainabilityReducer,
        contact: contactReducer,
        investor: investorReducer,
        career: careerReducer,
        venture: ventureReducer,
        media: mediaReducer,
        project: projectReducer,
        rent: rentReducer,
        policy: policyReducer,
        campaign: campaignReducer,
        search: searchReducer,
        launch: launchReducer,
        footer: footerReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

