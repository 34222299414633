import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import {ColorBg, ColorWhite, MidNightBlue} from "../../styles/globalStyleVars";
import Button from "../Button";
import ButtonV3 from "../ButtonV3";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pb-200'}>
            <Container>
                <Row className={`mobile`}>
                    <Col md={5} className='left_col'>
                        <Title color={'#897059'} margin={'0 0 60px 0'} text={data?.title}/>
                        {
                            data?.description &&
                            <div>
                                {ReactHtmlParser(data?.description)}
                            </div>
                        }
                        <ButtonV3
                            src={data?.kit}
                            text={'Download Press Kit'} background={'#897059'} hoverBackground={'#181D24'} color={'#ffffff'} svg
                        />
                        {/*<Button*/}
                        {/*    src={data?.kit}*/}
                        {/*    text={'Download Press Kit'} background={'#897059'} hoverBackground={'#181D24'} color={'#ffffff'} svg />*/}

                    </Col>
                    <Col md={{span:6,offset:1}} className='right_col'>
                        {
                            data?.image &&
                            <div className='right_col_image_wrapper reveal'>
                                <Img src={data?.image} alt=""/>
                            </div>
                        }

                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: ${ColorBg};
  margin-top: -2px;
  .left_col{    
    p{
      color: ${MidNightBlue};      
    }
    @media(max-width: 767px){
      margin-top: 40px;
    }
  }
  .right_col_image_wrapper{
    position: relative;
    padding-top: calc(520/570 *100%);
  }
  @media(max-width: 767px){
    .mobile{
      flex-direction: column-reverse;      
    }
  }
  
`
export default MyComponent;