import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import MediaDetails from "../../components/media-details/MediaDetails";
import {fetchMediaDetail} from "../../api/redux/media";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.media)
    let {slug} = useParams();

    console.log(slug);
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA_DETAIL
        dispatch(fetchMediaDetail([api_url + `/${slug}`]))
    }, [])

    const mediaDetails = getData?.detail?.[0];
    const meta = getData?.detail?.[0]?.meta;


    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>

            <StyledComponent>
                <MediaDetails data={mediaDetails}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
