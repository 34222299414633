import React from 'react';
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { Img } from "../Img";
import { ColorWhite, MidNightBlue } from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <Row className={'contact'}>
                <Col md={6} className={'d-flex justify-content-center align-items-center'}>
                    <div className={'contact__address'}>
                        <h2>{ReactHtmlParser(data?.title)}</h2>
                        <p><a href={`tel:${data?.phone}`}>{data?.phone}</a></p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={'contact__image'}>
                        <Img src={data?.image} alt={'contact-image'} />
                    </div>
                </Col>
            </Row>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .contact {
    background-color: ${MidNightBlue};
    //width: 100%;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      justify-content: start;
      width: auto;
      margin-top: 100px;
      margin-bottom: 100px;
      &__address {
        margin: 0px 15px;
        padding: 40px 0px;
        br{
          display: none;
        }
        h2{
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 10px;
        }
      }
    }

    &__address {
      h2 {
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 48px;
        color: ${ColorWhite};
        @media(max-width: 991px){
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 10px;
        }
      }
      p {
        font-size: 60px;
        line-height: 100px;
        @media(max-width: 991px){
          font-size: 48px;
          line-height: 56px;
         
        }
        a {
          color: ${ColorWhite};
          border-bottom: 1px solid ${ColorWhite};
          cursor: pointer;
          &:hover {
            color: ${ColorWhite};
          }
        }
      }
    }
    &__image {
      position: relative;
      padding-top: calc(679 / 683 * 100%);
    }
  }
`;

export default MyComponent;
