import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {BsPlus} from 'react-icons/bs';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import {hover, text} from "../../styles/globalStyleVars";
import MediaSingle from "../MediaSingle";
import Button from "../Button";
import ButtonRound from "../ButtonRound";
// import {Img} from "../Img";

const Strength = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {
    const csrMediaSlider = data?.media_list;
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(0)


    useEffect(() => {
        // window.addEventListener('load', function () {
        setOffset(document.querySelector(' .container').offsetLeft)

        // })
        // window.addEventListener('resize', function () {
        //     setOffset(document.querySelector(' .container').offsetLeft)
        //
        // })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const NewsPrev13 = () => {
        document.querySelector('.swiper-button-prev').click();
    };

    const NewsNext13 = () => {
        document.querySelector('.swiper-button-next').click();
    };

    return (
        <StyledStrength getAfterHeight={getAfter} remove={remove}
                        className={`pionneering parallax-inner-box pt-200 pb-200`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={data?.title} color={'#181D24'}/>
                            </div>
                            <ul className="navigation">
                                <li className={'prev_swipper '}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                         viewBox="0 0 52 52">
                                        <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                           transform="translate(52 52) rotate(180)">
                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                    fill="#736640" stroke=""></circle>
                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                    fill="#897059"></circle>
                                            <g id="Group_15984" data-name="Group 15984"
                                               transform="translate(-97 -5894.5)">
                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                      transform="translate(120.5 5915.5)" fill="none"
                                                      stroke="#FFFDFB"
                                                      stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                      transform="translate(120.5 5920.5)" fill="none"
                                                      stroke="#FFFDFB"
                                                      stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </g>
                                    </svg>
                                </li>
                                <li className={'next_swipper'}>
                                    <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                         xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                         viewBox="0 0 52 52">
                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                fill="#736640" stroke=""></circle>
                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                fill="#897059"></circle>
                                        <g id="Group_15984" data-name="Group 15984"
                                           transform="translate(-97 -5894.5)">
                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                  transform="translate(120.5 5915.5)" fill="none" stroke="#FFFDFB"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                  transform="translate(120.5 5920.5)" fill="none" stroke="#FFFDFB"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className='about-section__bottom'>
                <Row>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        slideNextClass={'.next'}
                        allowSlideNext={true}
                        slidePrevClass={'.prev'}
                        allowSlidePrev={true}
                        allowTouchMove={true}
                        longSwipesMs={900}
                        speed={900}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        navigation={{
                            prevEl: '.prev_swipper',
                            nextEl: '.next_swipper  ',
                        }}
                        modules={[Navigation]}
                        // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {
                            csrMediaSlider && csrMediaSlider.length>0 &&
                            csrMediaSlider.map((element,index)=>{
                                return(
                                    <SwiperSlide key={index}>
                                        <div className='about-section__bottom__single '>
                                            <MediaSingle
                                                text={element?.media_hover_text}
                                                title={element?.post_title}
                                                img={element?.media_thumb}
                                                // link={`media-center/${element?.post_name}`}
                                                day={element?.media_details_date}
                                                month={element?.media_details_month}
                                                cat={element?.news_type}
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }


                    </Swiper>
                </Row>
            </Container>
            <Container className={'left-btn'}>
                <ButtonRound  text={'View All'} src={'/media-center'}/>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

  background: #FFFDFB;
  position: relative;
  //overflow: hidden;
  .title{
    
      span{
        color: #897059 !important;
      }
  }

  .view_btn {
    margin-top: 80px;
  }

  .title_wrapper {
    display: flex;
  }
  .dc-btn{
    position: absolute;
    top: 90%;
    z-index: 99999;
    @media(max-width: 767px){
      top: 95%;
    }
  }

  .swiper-initialized {
    padding-right: ${p => p.offset + 15}px;
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    //visibility: hidden;
  }
 


  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;

    //.dc-btn {
    //  display: none;
    //}

    @media (max-width: 767px) {
      margin-bottom: 40px;
      //.dc-btn {
      //  display: block;
      //  position: absolute;
      //  right: 15px;
      //  //margin-left: 113px;
      //}

    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: #897059;

        // &.hover:after {
          //   background-color: ${text};
        //  
        // }

        &:first-child {
          margin-right: 20px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;
        //&:hover {
        //  svg{
        //    z-index: 999;
        //  }
        //}


        svg {
          overflow: visible;
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

          }
          #Ellipse_4377 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
          g {
            line {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 0;

            }
            #Ellipse_4377 {
            }

            g {
              line {
                //stroke: #181D24;
              }
            }
          }
          //&:not(:hover){
          //  #Ellipse_4377 {
          //    r: 26;
          //  }
          //}
        }
      }
    }
  }
  

  .swiper-wrapper {
    height: auto !important;
    margin-left: 15px;
    margin-right: 15px;
    @media(max-width: 767px){
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .about-section__bottom {
    //margin-top: -50px;
    // padding-left: ${props => props.offset ? '0px' : '0px'};
    position: relative;
    //
    // &:after {
    //   height: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: #F6F6F6;
    //   content: "";
      //   display: ${props => props.remove ? 'none' : 'block'};
      //   opacity: ${props => props.remove ? '0' : '1'};
    //   //z-index: -1;
    // }

    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
      @media (max-width: 767px) {
        min-height: 370px;
      }

      &__inner {
        position: relative;
        padding-top: calc(460 / 370 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;
            margin-bottom: 20px;
          }

          ul {
            li {
              position: relative;
              padding-left: 20px;

              &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                background-color: #C1C1C1;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            color: #061524;
          }

        }

      }
    }
  }

  @media (max-width: 767px) {
    .view_btn {
      display: none;
    }

    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .navigation {
      min-width: 100%;
      //margin-top: 40px;
      margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }
  }
  .swiper-slide{
    width: 370px;
  }


  .left-btn{
    .dc-btn{
      top:93%;
    }
  }
  
  
`;

export default Strength;