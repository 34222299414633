import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import {Img} from "./Img";
import Title from "./Title";
import ReactHtmlParser from "react-html-parser";
import {hoverNd} from "../styles/globalStyleVars";

const MyComponent = ({title, des, opacity, icon}) => {

    return (
        <StyledComponent opacity={opacity} className={'video pt-200'} >
            <div className='feature__single__img'>
                <img src={icon} alt=""/>
            </div>
            <div className={'feature__single__name'}>
                <h6>{title}</h6>
            </div>
                <p className={'hover-text'}>{des}</p>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  position: absolute;
  inset:0;

  .hover-text{
    position: absolute;
    left: 10%;
    top: 10%;
    height: 100%;
    right: 10%;
    opacity: 0;
    transition: all 0.6s ease;
    transform: translateY(100%);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFDFB;
    text-align: center;
  }
  //
  // &:hover{
  //   background: ${probs => probs.opacity ?  'transparent': `${hoverNd}`};
  //   z-index: 1000000;
  //   h6{
  //     z-index: -1;
  //   }
  //
  // }
  //
    .feature__single{
      &__name {
        position: absolute;
        top: 135px;
        left: 20px;
        right: 20px;
        @media (min-width: 1500px) {
          top: 62%;
        }


        h6 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          text-align: center;
          //height: 44px;
          color: #181D24;

        }
      }

      &__img {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        height: 90px;
        width: 90px;
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        margin: auto;
        @media(min-width: 1500px){
          top: 26%;
        }

      }
  
      &:hover{
        &:after{
          opacity: 0;
          z-index: 2;
        }
       
        &__img{
          opacity: 0;
        }
        &__name{
          opacity: 0;
        }

      }
    }
 
`;

export default MyComponent;
