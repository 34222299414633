import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Link, useHistory, useLocation, withRouter} from 'react-router-dom';
import Accordion from "react-bootstrap/Accordion";
import SimpleBar from 'simplebar-react';
import htmlParser from "react-html-parser";
import {BurntSienna, ColorWhite, hover, hoverNd, NaturalSand} from "../styles/globalStyleVars";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {Container, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints as ApiServices, apiEndPoints} from "../api/network/apiEndPoints";
import {fetchProjects} from "../api/redux/project";
import {BsChevronDown} from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ApiParam} from "../api/network/apiParams";


gsap.registerPlugin(CSSPlugin);

const MobileMenu = (props) => {
    const location = useLocation();
    const MenuWrapRef = useRef();
    const HamburgerRef = useRef();
    const projectMenuWrapRef = useRef();
    const projectMenuRefMain = useRef([]);
    const closeProjectMenuRef = useRef();
    const backProjectMenuRef = useRef();
    const mainMenuClose = useRef();
    const history=useHistory();


    //Api call for menu

    const dispatch = useDispatch();
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const locations = useLocation();


    //get the query
    const project_status = new URLSearchParams(locations?.search).get("status");
    const project_type = new URLSearchParams(locations?.search).get("type");

    const [projectType, setProjectType] = useState()

    // api call
    useEffect(() => {
        let param = {
            [ApiParam.project_type]: projectType,
        }
        let api_url = apiEndPoints.PROJECTS
        dispath(fetchProjects([api_url,param]))

    }, [location?.pathname,projectType]);

    let getPost = useSelector(state => state.project)
    let projectTypeOption=getPost?.posts?.[0]?.filter?.project_statuses_by_type;


    // useEffect(() => {
    //     let api_services = ApiServices.PROJECTS
    //     let param2 = {
    //         [ApiParam.project_type]:type ,
    //     }
    //     dispatch(fetchProjects([api_services,param2]))
    // }, [type,project_type,project_status])
    //
    //
    //
    // let getPostList = useSelector(state => state.project)

    let [newClass, setNewClass] = useState(false);

    // const options = getPostList?.posts?.[0]?.list?.map(status => ({value: status?.project_status, label: status?.project_status}));
    // const key = 'label';
    //
    // const uniqueResidentialStatus = [...new Map(options?.map(item =>
    //     [item[key], item])).values()];


    const projectMenuRef = (el) => {
        if(el && !projectMenuRefMain.current.includes(el)){
            projectMenuRefMain.current.push(el)
        }
    }

    //Refactor Data
    const dispath = useDispatch()

    //
    const [category,setCategory] = useState('');
    if(category==='on'){
        setCategory('For Rent')
    }
    //



    //Refactor data

    let projectList=getPost?.posts?.[0]?.list;
    let collectionFilter=getPost?.posts?.[0]?.filter?.project_collection;
    let categories=projectList?.filter(element=>element?.project_type===category)
    let rent=projectList?.filter(element=>element?.isForRent==='on')


    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass(true)
            } else {
                setNewClass(false)
            }
        });

        HamburgerRef.current.addEventListener('click', () => {
            MenuWrapRef.current.classList.add('menu-open')
        });

        mainMenuClose.current.addEventListener('click', () => {
            MenuWrapRef.current.classList.remove('menu-open')
        });

        projectMenuRefMain.current.forEach((e)=>{
            e.addEventListener('click',()=>{
                projectMenuWrapRef.current.classList.add('menu-open')
            })
        });

        closeProjectMenuRef.current.addEventListener('click',()=>{
            projectMenuWrapRef.current.classList.remove('menu-open')
        });
        backProjectMenuRef.current.addEventListener('click',()=>{
            projectMenuWrapRef.current.classList.remove('menu-open')
            MenuWrapRef.current.classList.add('menu-open')
        });


    }, [getPost])

    useEffect(() => {

        // if (!projectMain) {
        //     props.getProjectsMain();
        // }
        let getA = document.querySelectorAll('.mobile-menu a');
        let getInnerA = document.querySelector('.product-menu');
        getA.forEach(e=>{
            e.addEventListener('click',()=>{
                MenuWrapRef.current.classList.remove('menu-open')
                projectMenuWrapRef.current.classList.remove('menu-open')
            })
        });

        getInnerA.addEventListener('click', () => {
            MenuWrapRef.current.classList.remove('menu-open')
            projectMenuWrapRef.current.classList.remove('menu-open')
        })


    }, [props])


    // let {projectMain} = props;
    // let products =  projectMain && projectMain.filter(f => f.category_data ? f.category_data.slug === category : [])

    const handleSubmitSearch = (e) => {
        e.preventDefault()
        history.push({
            pathname: '/search',

        });
    }

    let tl = new TimelineLite();
    let tl2 = new TimelineLite();

    const searchClickRef = useRef();
    // search api action
    const [searchKewWord, setSearchKeyword] = useState('')

    // on click search input toggle action --  desktop menu
    useEffect(() => {
        let searchInput = document.querySelector('.search-form');
        let searchFormTimeline = gsap.timeline(); // Create a timeline specifically for search form animations
        searchClickRef.current.addEventListener('click', (e) => {
            setTimeout(() => {
                searchInput.querySelector('input').focus();
            }, 200);

            if (searchClickRef.current.classList.contains('search-open')) {
                searchFormTimeline
                    .to('.search-form', {
                        duration: 0.4,
                        width: 0,
                        opacity: 1,
                        onComplete: () => {
                            searchFormTimeline.set('.search-form', { display: 'none' });
                        }
                    })
                    .to('.search-form input', {
                        padding: 0,
                    }, '-=.4');

                searchClickRef.current.classList.remove('search-open');
            } else {
                searchFormTimeline
                    .set('.search-form', { display: 'block' })
                    .to('.search-form', {
                        duration: 0.4,
                        width: 300,
                        opacity: 1,
                        position: 'absolute',
                        right: 0,
                        top: 30
                    }, '-=.2')
                    .to('.search-form input', {
                        padding: '0 20px',
                    }, '-=.4');

                searchClickRef.current.classList.add('search-open');
            }
        });

        window.addEventListener("click", (e) => {
            if (searchClickRef.current?.classList.contains('search-open')) {
                if (!e.target.matches('.search-form, .search-form input,.search-bar,.search-bar svg,.circle,.search-bar path,.search-bar g, .search-bar defs ')) {
                    tl.to('.search-form', {
                        duration: .4,
                        width: 0,
                        opacity: 1
                    }).to('.search-form input', {
                        padding: 0,
                    }, '-=.4').to('.search-form', {
                        display: 'none'
                    }, '-=.3')
                    searchClickRef.current.classList.remove('search-open')
                }
            }
        });
        searchInput.addEventListener('click', (e) => {
            e.stopPropagation()
        })

    }, []);


    const handleSubmenuClick=()=>{
        projectMenuWrapRef.current.classList.remove('menu-open');
        MenuWrapRef.current.classList.add('menu-open');
    }

    let router= history?.location?.pathname;
    let routerMark=router==='/' ? true :false;


    return (
        <>
            {/*menu bar*/}
            <StyledMenubar className={`${newClass ? 'menu-fixed' : ''}  hamburger`} routerMark={routerMark}>
                <div className="hamburger__inner">
                    <div className="logo">
                        <Link to={'/'}>
                            {
                                routerMark?
                                    <img src={'/images/static/mobile-logo.svg'} alt=""/> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="97.011" height="28" viewBox="0 0 97.011 28">
                                        <g id="Group_20088" data-name="Group 20088" transform="translate(-11.384 -25)">
                                            <g id="Group_20007" data-name="Group 20007" transform="translate(11.384 30.779)">
                                                <path id="Path_7002" data-name="Path 7002" d="M41.192,30.513a2.808,2.808,0,0,0-.556-.037H39.523a2.808,2.808,0,0,0-.556.037c-.111-.334-.223-.7-.408-1.187q-.278-.668-.556-1.447c-.223-.519-.408-1-.631-1.521s-.408-1-.594-1.484c-.445,0-.927-.037-1.373-.037H32.7c-.408,0-.853,0-1.3.037-.445,1.113-.853,2.152-1.224,3.153s-.631,1.818-.816,2.485a5.479,5.479,0,0,0-.779-.037,4.98,4.98,0,0,0-.779.037c1.15-2.485,2.263-4.971,3.376-7.419,1.076-2.448,2.189-4.971,3.3-7.494h.408q.5,1.224,1.113,2.671l1.224,2.894c.408.965.816,1.966,1.224,2.931s.816,1.855,1.15,2.708c.371.816.7,1.558.965,2.226A12.687,12.687,0,0,0,41.192,30.513Zm-9.423-6.64c.371,0,.742,0,1.15.037.408,0,.779.037,1.15.037h1.187c.408,0,.779,0,1.187-.037l-2.374-5.416Z" transform="translate(-17.487 -15.6)" fill="#897059"/>
                                                <path id="Path_7003" data-name="Path 7003" d="M175.692,30.513a2.808,2.808,0,0,0-.556-.037h-1.113a2.808,2.808,0,0,0-.556.037c-.111-.334-.223-.7-.408-1.187q-.278-.668-.556-1.447c-.223-.519-.408-1-.631-1.521s-.408-1-.594-1.484c-.445,0-.927-.037-1.373-.037H167.2c-.408,0-.853,0-1.3.037-.445,1.113-.853,2.152-1.224,3.153s-.631,1.818-.816,2.485a5.478,5.478,0,0,0-.779-.037,4.979,4.979,0,0,0-.779.037c1.15-2.485,2.263-4.971,3.376-7.419,1.076-2.448,2.189-4.971,3.3-7.494h.408q.5,1.224,1.113,2.671l1.224,2.894c.408.965.816,1.966,1.224,2.931s.816,1.855,1.15,2.708c.371.816.7,1.558.965,2.226A12.684,12.684,0,0,0,175.692,30.513Zm-9.423-6.64c.371,0,.742,0,1.15.037.408,0,.779.037,1.15.037h1.187c.408,0,.779,0,1.187-.037l-2.374-5.416Z" transform="translate(-102.092 -15.6)" fill="#897059"/>
                                                <path id="Path_7004" data-name="Path 7004" d="M1.113,28.51a2.549,2.549,0,0,0,.7.7,3.4,3.4,0,0,0,.965.519,3.264,3.264,0,0,0,1.224.185,2.742,2.742,0,0,0,1.261-.26,2.51,2.51,0,0,0,.965-.668,2.349,2.349,0,0,0,.594-.965,3.213,3.213,0,0,0,.223-1.187,2.254,2.254,0,0,0-.519-1.558,4.211,4.211,0,0,0-1.261-.965,15.865,15.865,0,0,0-1.6-.7,7.171,7.171,0,0,1-1.6-.816A4.947,4.947,0,0,1,.816,21.573a3.588,3.588,0,0,1-.445-1.966A3.891,3.891,0,0,1,.7,17.974a3.487,3.487,0,0,1,.927-1.261A3.966,3.966,0,0,1,3.079,15.9a6.193,6.193,0,0,1,1.929-.3,5.292,5.292,0,0,1,1.781.26,4.126,4.126,0,0,1,1.373.742c-.111.26-.223.482-.334.779a8.368,8.368,0,0,0-.26,1.039h-.26c-.074-.185-.185-.408-.3-.668a3.765,3.765,0,0,0-.482-.668,3.758,3.758,0,0,0-.742-.519,2.264,2.264,0,0,0-1.039-.185,3.5,3.5,0,0,0-1.113.185,2.009,2.009,0,0,0-.816.556,2.212,2.212,0,0,0-.594.816,2.528,2.528,0,0,0-.185,1.076,2.24,2.24,0,0,0,.519,1.6,4.211,4.211,0,0,0,1.261.965,15.864,15.864,0,0,0,1.6.7,9.047,9.047,0,0,1,1.6.742,4.141,4.141,0,0,1,1.261,1.15,3.18,3.18,0,0,1,.519,1.892,5.141,5.141,0,0,1-.334,1.855,4.277,4.277,0,0,1-1.039,1.521,4.316,4.316,0,0,1-1.632,1,6.618,6.618,0,0,1-2.189.334,5.82,5.82,0,0,1-2.152-.334A5.732,5.732,0,0,1,0,29.623a5.661,5.661,0,0,0,.26-.89c.037-.26.111-.594.148-1h.26A4.174,4.174,0,0,0,1.113,28.51Z" transform="translate(0 -15.6)" fill="#897059"/>
                                                <path id="Path_7005" data-name="Path 7005" d="M117.253,21.461c1,1.892,2.04,3.858,3.153,5.861.148-.26.334-.668.631-1.15.26-.519.594-1.076.927-1.744.371-.668.742-1.373,1.15-2.115s.816-1.521,1.224-2.3.816-1.558,1.261-2.3c.408-.742.779-1.447,1.15-2.115h.26c.148,1.373.3,2.671.445,3.969.148,1.261.26,2.523.408,3.747s.3,2.448.408,3.635c.148,1.187.3,2.374.482,3.561a2.282,2.282,0,0,0-.482-.037h-1.039a2.115,2.115,0,0,0-.482.037c-.148-1.892-.3-3.71-.482-5.49-.148-1.781-.334-3.6-.594-5.416h-.037l-2.894,5.342c-.927,1.781-1.855,3.635-2.708,5.565h-.185c-.853-1.781-1.744-3.6-2.671-5.379s-1.855-3.635-2.819-5.49h-.037c-.111.89-.223,1.855-.334,2.856-.111,1.039-.223,2.04-.334,3.042s-.185,1.929-.26,2.819-.111,1.632-.111,2.189a7.438,7.438,0,0,0-1.484,0c.185-1.15.371-2.374.594-3.635.185-1.261.408-2.56.594-3.858s.371-2.56.519-3.821.3-2.448.408-3.6h.371C115.25,17.6,116.252,19.569,117.253,21.461Z" transform="translate(-70.326 -15.6)" fill="#897059"/>
                                                <path id="Path_7006" data-name="Path 7006" d="M76.537,25.048c-1.855-1.966-3.71-3.969-5.527-5.9,0,.408-.037.927-.037,1.6s-.037,1.6-.037,2.745V24.6c0,.445,0,.89.037,1.373,0,.482,0,.965.037,1.484,0,.482.037.965.037,1.373s.037.816.037,1.113c0,.334.037.519.037.668a4.73,4.73,0,0,0-.742-.037,4.979,4.979,0,0,0-.779.037,6.272,6.272,0,0,0,.111-.779c.037-.371.074-.853.111-1.373s.074-1.113.111-1.744.074-1.261.074-1.892c.037-.631.037-1.224.037-1.818s.037-1.113.037-1.6V17.889c0-.965-.037-1.706-.037-2.189h.334q1.28,1.391,2.782,3c1,1.076,1.966,2.115,2.931,3.19.965,1.039,1.892,2.04,2.782,2.968s1.632,1.706,2.3,2.374V24.344c0-1.484,0-2.931-.037-4.34s-.074-2.745-.148-4.006a3,3,0,0,0,.742.111A2.494,2.494,0,0,0,82.4,16c-.037.3-.074.779-.111,1.41s-.074,1.3-.111,2-.074,1.41-.111,2.077-.037,1.224-.037,1.632V25.9c0,.519,0,1.039.037,1.6,0,.519,0,1.039.037,1.484v1.892h-.3C80.135,28.944,78.392,27.015,76.537,25.048Z" transform="translate(-43.781 -15.663)" fill="#897059"/>
                                                <path id="Path_7007" data-name="Path 7007" d="M203.848,21.89c0-.927,0-1.855-.037-2.745s-.074-1.818-.111-2.745c.408.037.816.037,1.224.074s.816.037,1.224.037.816,0,1.224-.037.816-.037,1.224-.037a8.587,8.587,0,0,1,1.706.185,4.006,4.006,0,0,1,1.41.556,2.6,2.6,0,0,1,.965,1.039,3.389,3.389,0,0,1,.334,1.6,3.293,3.293,0,0,1-.445,1.744,4.392,4.392,0,0,1-1.113,1.224,5.719,5.719,0,0,1-1.41.742,5.633,5.633,0,0,1-1.373.334c.185.223.408.519.7.89s.631.816,1,1.261.742.927,1.15,1.447.779,1,1.15,1.447.7.89,1.039,1.261.594.668.779.89a3.6,3.6,0,0,0-.631-.037H212.6a3.6,3.6,0,0,0-.631.037c-.223-.3-.519-.742-.965-1.335s-.927-1.224-1.447-1.929c-.519-.668-1.076-1.373-1.6-2.04-.556-.668-1.039-1.261-1.484-1.706h-.816V25.6c0,.927,0,1.855.037,2.782s.074,1.818.111,2.671a14.56,14.56,0,0,0-2.077,0l.111-2.671c.037-.927.037-1.818.037-2.782V21.89Zm1.781,1.224a3.328,3.328,0,0,0,.668.074c.223,0,.482.037.779.037a5.972,5.972,0,0,0,1.373-.148,3.671,3.671,0,0,0,1.3-.519,3.226,3.226,0,0,0,1-1,3.322,3.322,0,0,0,.111-2.968,2.264,2.264,0,0,0-.816-.853,2.761,2.761,0,0,0-1.076-.445,5.662,5.662,0,0,0-1.113-.111,11.569,11.569,0,0,0-1.224.037c-.334.037-.594.037-.816.074-.037.779-.074,1.558-.074,2.337-.037.779-.037,1.521-.037,2.3v1.187Z" transform="translate(-128.134 -16.103)" fill="#897059"/>
                                            </g>
                                            <path id="Path_7008" data-name="Path 7008" d="M164.1,20.565s24.261-24.3-46.7-18.623c0,0,46.074-7.2,52.937,6.269C173.082,13.591,164.1,20.565,164.1,20.565Z" transform="translate(-62.465 24.978)" fill="#b2a89f"/>
                                            <g id="Group_15920" data-name="Group 15920" transform="translate(53.006 49.55)">
                                                <path id="Path_7009" data-name="Path 7009" d="M112.2,66.4h.408v2.931h1.521V69.7h-1.892V66.4Z" transform="translate(-112.2 -66.326)" fill="#897059"/>
                                                <path id="Path_7010" data-name="Path 7010" d="M119.6,66.4h.408v3.3H119.6Z" transform="translate(-116.855 -66.326)" fill="#897059"/>
                                                <path id="Path_7011" data-name="Path 7011" d="M122.6,66.4h.445l1,2.708h0l1.039-2.708h.408l-1.3,3.3h-.371Z" transform="translate(-118.742 -66.326)" fill="#897059"/>
                                                <path id="Path_7012" data-name="Path 7012" d="M132.6,66.4h.408v3.3H132.6Z" transform="translate(-125.032 -66.326)" fill="#897059"/>
                                                <path id="Path_7013" data-name="Path 7013" d="M136.9,66.4h.482l1.892,2.745h0V66.4h.408v3.3H139.2l-1.892-2.745h0V69.7H136.9Z" transform="translate(-127.737 -66.326)" fill="#897059"/>
                                                <path id="Path_7014" data-name="Path 7014" d="M150.1,69.353a1.639,1.639,0,0,1-.594.223,3.655,3.655,0,0,1-.631.074,2.16,2.16,0,0,1-.7-.111,1.14,1.14,0,0,1-.556-.371,1.468,1.468,0,0,1-.371-.556,2.169,2.169,0,0,1-.148-.7,1.506,1.506,0,0,1,.148-.7,1.468,1.468,0,0,1,.371-.556,1.4,1.4,0,0,1,.556-.334,2.16,2.16,0,0,1,.7-.111,1.884,1.884,0,0,1,.631.111,2.214,2.214,0,0,1,.556.334l-.3.3a1.647,1.647,0,0,0-.408-.3,1.142,1.142,0,0,0-.482-.111,1.185,1.185,0,0,0-.519.111,1.052,1.052,0,0,0-.408.3,1.334,1.334,0,0,0-.3.445,1.355,1.355,0,0,0-.111.556,1.488,1.488,0,0,0,.111.556,1.175,1.175,0,0,0,.3.445,1.334,1.334,0,0,0,.445.3,1.355,1.355,0,0,0,.556.111,1.522,1.522,0,0,0,.408-.037,1.33,1.33,0,0,0,.408-.148v-1h-.742V67.8h1.15v1.558Z" transform="translate(-134.153 -66.2)" fill="#897059"/>
                                                <path id="Path_7015" data-name="Path 7015" d="M163,66.4h.408v3.3H163Z" transform="translate(-144.155 -66.326)" fill="#897059"/>
                                                <path id="Path_7016" data-name="Path 7016" d="M167.3,66.4h.482l1.892,2.745h0V66.4h.408v3.3H169.6l-1.892-2.745h0V69.7H167.3Z" transform="translate(-146.86 -66.326)" fill="#897059"/>
                                                <path id="Path_7017" data-name="Path 7017" d="M182.7,66.4h.408v2.931h1.521V69.7h-1.892V66.4Z" transform="translate(-156.547 -66.326)" fill="#897059"/>
                                                <path id="Path_7018" data-name="Path 7018" d="M192.423,68.44a2.506,2.506,0,0,1-.074.519.733.733,0,0,1-.223.408,1.453,1.453,0,0,1-.371.3,1.3,1.3,0,0,1-.594.111,1.538,1.538,0,0,1-.594-.111.775.775,0,0,1-.371-.3.884.884,0,0,1-.223-.408,2.189,2.189,0,0,1-.074-.519V66.4h.408v1.966a1.143,1.143,0,0,0,.037.371,1.318,1.318,0,0,0,.148.334.5.5,0,0,0,.26.223.734.734,0,0,0,.408.074,1.334,1.334,0,0,0,.408-.074,1.613,1.613,0,0,0,.26-.223,1.317,1.317,0,0,0,.148-.334,1.262,1.262,0,0,0,.037-.371V66.4h.408Z" transform="translate(-161.076 -66.326)" fill="#897059"/>
                                                <path id="Path_7019" data-name="Path 7019" d="M200.05,67.958,198.937,66.4h.519l.89,1.3.89-1.3h.482l-1.113,1.558,1.224,1.744h-.519l-.965-1.447-.965,1.447H198.9Z" transform="translate(-166.737 -66.326)" fill="#897059"/>
                                                <path id="Path_7020" data-name="Path 7020" d="M211.223,68.44a2.505,2.505,0,0,1-.074.519.733.733,0,0,1-.223.408,1.453,1.453,0,0,1-.371.3,1.3,1.3,0,0,1-.594.111,1.538,1.538,0,0,1-.594-.111.775.775,0,0,1-.371-.3.884.884,0,0,1-.223-.408,2.189,2.189,0,0,1-.074-.519V66.4h.408v1.966a1.144,1.144,0,0,0,.037.371,1.318,1.318,0,0,0,.148.334.5.5,0,0,0,.26.223.734.734,0,0,0,.408.074,1.334,1.334,0,0,0,.408-.074,1.614,1.614,0,0,0,.26-.223,1.318,1.318,0,0,0,.148-.334,1.261,1.261,0,0,0,.037-.371V66.4h.408Z" transform="translate(-172.902 -66.326)" fill="#897059"/>
                                                <path id="Path_7021" data-name="Path 7021" d="M218.637,66.4h.853a2.115,2.115,0,0,1,.482.037c.148.037.3.074.408.111a1,1,0,0,1,.3.26.928.928,0,0,1,.111.482.783.783,0,0,1-.223.594,1.008,1.008,0,0,1-.594.3L220.9,69.7h-.482l-.89-1.484h-.519V69.7H218.6V66.4Zm.408,1.447h.668a1.084,1.084,0,0,0,.334-.074.6.6,0,0,0,.26-.148.421.421,0,0,0,.111-.3.7.7,0,0,0-.074-.3.37.37,0,0,0-.185-.148.536.536,0,0,0-.26-.074h-.853Z" transform="translate(-179.129 -66.326)" fill="#897059"/>
                                                <path id="Path_7022" data-name="Path 7022" d="M227.024,68.292,225.8,66.4h.482l.927,1.484.965-1.484h.482l-1.224,1.892V69.7h-.408Z" transform="translate(-183.658 -66.326)" fill="#897059"/>
                                            </g>
                                        </g>
                                    </svg>

                            }

                        </Link>
                    </div>

                    <div className={'d-flex hamburger-right'} >
                        <div ref={searchClickRef} className={'wsearch-bar'}>
                            <Link to={'/search'}>
                                <svg id="Component_701" data-name="Component 70 – 1"
                                     width="40" height="40" viewBox="0 0 40 40">
                                    <defs>
                                        <linearGradient id="linear-gradient" x2="1" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#ad0000"/>
                                            <stop offset="1" stop-color="red"/>
                                        </linearGradient>
                                    </defs>
                                    <g id="Button-_Search" data-name="Button - Search"
                                       transform="translate(-1490 50)">
                                        <g id="Ellipse_450" data-name="Ellipse 450" transform="translate(1490 -50)"
                                           fill="none" stroke="#fff" stroke-width="1">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="19.5" fill="none"/>
                                        </g>
                                        <circle className='circle' id="Ellipse_485" data-name="Ellipse 485" cx="1"
                                                cy="1" r="1"
                                                transform="translate(1509 -31)" opacity="0"
                                               fill="url(#linear-gradient)"/>
                                        <path id="Path_8306" data-name="Path 8306"
                                              d="M-40.214,508.284a.5.5,0,0,1-.354-.146l-4.323-4.324a7.042,7.042,0,0,1-4.684,1.745h0a7.1,7.1,0,0,1-5.046-2.09,7.094,7.094,0,0,1-2.092-5.049,7.149,7.149,0,0,1,7.141-7.142h0a7.1,7.1,0,0,1,5.05,2.091,7.1,7.1,0,0,1,2.092,5.05,7.06,7.06,0,0,1-1.748,4.685l4.319,4.327a.5.5,0,0,1,0,.707A.5.5,0,0,1-40.214,508.284Zm-9.36-3.725h.013a6.164,6.164,0,0,0,4.327-1.79l.006-.006h0a6.076,6.076,0,0,0,1.8-4.34,6.1,6.1,0,0,0-1.8-4.344,6.1,6.1,0,0,0-4.343-1.8h0a6.148,6.148,0,0,0-6.141,6.142,6.1,6.1,0,0,0,1.8,4.341,6.1,6.1,0,0,0,4.34,1.8Z"
                                              transform="translate(1558.215 -529.781)" fill="#fff"/>
                                    </g>
                                </svg>
                            </Link>

                        </div>
                        <div ref={HamburgerRef} className="hamburger">
                            {
                                routerMark?
                                    <img src={'/images/static/Hamburger.svg'} alt=""/> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="14" viewBox="0 0 27 14">
                                        <g id="Hamburger" transform="translate(-1188 -33)">
                                            <g id="Group_15921" data-name="Group 15921" transform="translate(36 -50)">
                                                <path id="Path_1892" data-name="Path 1892" d="M-1459.918,26.994h25" transform="translate(2612.918 69.006)" fill="none" stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_1891" data-name="Path 1891" d="M-1459.918,26.994h25" transform="translate(2612.918 63.006)" fill="none" stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_1890" data-name="Path 1890" d="M-1459.918,26.994h25" transform="translate(2612.918 57.006)" fill="none" stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </g>
                                    </svg>

                            }


                        </div>
                    </div>
                    <Form className='search-form' onSubmit={handleSubmitSearch}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control value={searchKewWord}
                                          onChange={(e) => setSearchKeyword(e.target.value)}
                                          type="text" placeholder="Search"/>
                        </Form.Group>
                    </Form>


                </div>
            </StyledMenubar>

            {/*main menu*/}
            <StyledMenu ref={MenuWrapRef} >
                <div className={'mobile-menu__top'}>
                    <img className='menu-logo' ref={mainMenuClose} src={'/images/static/mobile-logo.svg'} alt=""/>
                    <img className='menu-close' ref={mainMenuClose} src={'/images/static/mobile-menu-close.svg'} alt=""/>
                </div>
                <div className='mobile-menu'>
                    <ul className='pages'>
                        <li className={location.pathname === '/'?'active':''}><Link to={'/'}>Home</Link></li>
                        <li className={location.pathname === '/about'?'active':''}><Link to={'/about'}>About Us</Link></li>
                        <li className={location.pathname === '/projects'?'active':''}><Link to={'/projects'}>Projects</Link></li>
                        {/*<li className={location.pathname === '/investor-relations'?'active':''}><Link to={'/investor-relations'}>Investor Relation</Link></li>*/}
                        <li className={location.pathname === '/joint-venture'?'active':''}><Link to={'/joint-venture'}>Joint Venture</Link></li>
                        <li className={location.pathname === '/sustainability'?'active':''}><Link to={'/csr'}>CSR</Link></li>
                        <li className={location.pathname === '/media-center'?'active':''}><Link to={'/media-center'}>Media Center</Link></li>
                        <li className={location.pathname === '/career'?'active':''}><Link to={'/career'}>Career</Link></li>
                        <li className={location.pathname === '/contact'?'active':''}><Link to={'/contact'}>Contact</Link></li>
                    </ul>

                    <ul className="projects job-lists__content">
                        <Accordion>
                            <Accordion.Item eventKey={'1'} >
                                <>
                                    <Accordion.Header >
                                        Residential
                                        <span><BsChevronDown/></span>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <li>
                                            <Link to={`/projects?type=residential&status=ongoing`}>
                                                Ongoing Projects
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/projects?type=residential&status=completed`}>
                                                Completed Projects
                                            </Link>
                                        </li>
                                        {/*{*/}
                                        {/*    projectTypeOption?.map((element)=>{*/}
                                        {/*        return(*/}
                                        {/*            <li>*/}
                                        {/*                <Link to={`/projects?type=residential&status=${element?.value}`}>*/}
                                        {/*                    {element?.label}*/}
                                        {/*                </Link>*/}
                                        {/*            </li>*/}
                                        {/*        )*/}
                                        {/*    })*/}
                                        {/*}*/}

                                    </Accordion.Body>
                                </>

                            </Accordion.Item>
                            <Accordion.Item eventKey={'2'} onClick={()=>setStatus('Collections')}>

                                <Accordion.Header>
                                    Collections
                                    <span><BsChevronDown/></span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Link to={`/projects?collection=penthouse-collections`}>
                                        <li>Penthouse Collection</li>
                                    </Link>
                                    <Link to={`/projects?collection=signature-collection`}>
                                        <li>Signature Collection</li>
                                    </Link>
                                    {/*{*/}
                                    {/*    collectionFilter?.map((element)=>{*/}
                                    {/*        return(*/}
                                    {/*            <Link to={`/projects?collection=${element?.label}`}>*/}
                                    {/*                <li>{element?.label}</li>*/}
                                    {/*            </Link>*/}
                                    {/*        )*/}
                                    {/*    })*/}
                                    {/*}*/}

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={'3'}>
                                {
                                    <>
                                        <Accordion.Header >
                                            Commercial
                                            <span><BsChevronDown/></span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <li>
                                                <Link to={`/projects?type=commercial&status=retail-shopping`}>
                                                       Retail (Shopping Mall)
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/projects?type=commercial&status=office-space`}>
                                                    Office Space
                                                </Link>
                                            </li>
                                            {/*{*/}
                                            {/*    projectTypeOption?.map((element)=>{*/}
                                            {/*        return(*/}
                                            {/*            <li>*/}
                                            {/*                <Link to={`/projects?type=commercial&status=${element?.value}`}>*/}
                                            {/*                    {element?.label}*/}
                                            {/*                </Link>*/}
                                            {/*            </li>*/}
                                            {/*        )*/}
                                            {/*    })*/}
                                            {/*}*/}
                                        </Accordion.Body>
                                    </>


                                }
                            </Accordion.Item>
                        </Accordion>
                        <Link to={'/for-rent'}>
                            <li>For Rent<img src={'/images/static/mobile-caret-right.svg'} alt=""/></li>
                        </Link>


                    </ul>

                    <a href="/images/static/sample.pdf" target={'_blank'}  className={'press-kit'}>
                        <h3>Press Kit</h3>
                        <div className={'d-flex justify-content-end align-items-center'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="19" viewBox="0 0 16.82 19">
                                <g id="Group_13283" data-name="Group 13283" transform="translate(-1655.59 -7448.5)">
                                    <line id="Line_10" data-name="Line 10" x2="14" transform="translate(1657 7466.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                    <g id="Group_39" data-name="Group 39" transform="translate(0 0.5)">
                                        <g id="Group_7" data-name="Group 7" transform="translate(427.5 7406.5)">
                                            <line id="Line_1" data-name="Line 1" x2="7" y2="6" transform="translate(1229.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_2" data-name="Line 2" x1="7" y2="6" transform="translate(1236.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                        <line id="Line_11" data-name="Line 11" y2="12" transform="translate(1664 7449)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>

                        </div>
                    </a>
                    <p className='copyright'><span>© 2023 </span>Sanmar Real Estate</p>
                </div>


            </StyledMenu>

            {/*slide menu*/}
            <StyledMenuSlide className='product-menu' ref={projectMenuWrapRef}>
                <div onClick={handleSubmenuClick} >
                    <img src={'/images/static/back-nav.svg'} ref={backProjectMenuRef} className='slide-menu-back' alt=""/>
                </div>

                <img src={'/images/static/cross-btn.svg'} ref={closeProjectMenuRef} className='slide-menu-close' alt=""/>
                <h4>{category? category : ''}</h4>

                <SimpleBar autoHide={false} style={{maxHeight: 'calc(100vh - 220px)'}}>
                    <ol>
                        {category === 'For Rent' ? (
                            rent && rent.length > 0 && rent.map((element, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={`/projects/${element?.post_name}`}>
                                            <h5>{element?.post_title}</h5>
                                            <p>{element?.project_details_location}</p>
                                        </Link>
                                    </li>
                                );
                            })
                        ) : category === 'Collections' ? (
                            collectionFilter && collectionFilter.length > 0 && collectionFilter.map((element, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={`/projects?collection=${element?.value}`}>
                                            <h5>{element?.label}</h5>
                                            {/*<p>{element?.project_details_location}</p>*/}
                                        </Link>
                                    </li>
                                );
                            })
                        ) : (
                            categories && categories.length > 0 && categories.map((element, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={`/projects/${element?.post_name}`}>
                                            <h5>{element?.post_title}</h5>
                                            <p>{element?.project_details_location}</p>
                                        </Link>
                                    </li>
                                );
                            })
                        )}
                        {
                            categories && categories.length>0 &&
                            categories.map((element,index)=>{
                                return(
                                    <li key={index}>
                                        <Link
                                            to={`/projects/${element?.post_name}`}>
                                            <h5>{element?.post_title}</h5>
                                            <p>{element?.project_details_location}</p>
                                        </Link>
                                    </li>
                                )
                            })
                        }


                    </ol>
                </SimpleBar>
            </StyledMenuSlide>
        </>
    );
};

const StyledMenubar = styled.section`
  height: 80px;
  padding: 0 15px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 992;
  display: flex;
  align-items: center;
  //background: linear-gradient(180deg, rgb(0 0 0 / 68%) 0%, rgb(0 0 0 / 36%) 47%, rgba(255, 255, 255, 0) 100%);
  transition: all .4s ease;
  

  

  .hamburger__inner {
    //border-bottom: 1px solid #B2A89F;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;
    .hamburger-right{
      display: flex;
      .wsearch-bar{
        svg{
          width: 28px;
          height: 28px;
          margin-right: 10px;

        }
      }
      svg{

        g{
          stroke: ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};
          line{
            stroke: ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};

          }
        }
      }
    }
    .search-form {
      display: none;
    }
    .hamburger{
      padding: 8px 20px;
      border:1px solid ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};
      width: 65px;
      height: 28px;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.menu-fixed {
    background-color: ${BurntSienna};

    .hamburger__inner {
      border: none;
    }
  }


  .logo{
    img{
      height: 27px;
    }
  }

`;

const StyledMenu = styled.section`
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${BurntSienna};
  z-index: 995;
  width: 100%;
  padding: 100px 20px 60px 20px;
  transform: translateX(120%);
  transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);



  .press-kit {
    display: flex;
    cursor: pointer;
    margin-top: 60px;

    svg {
      width: 14px;
      height: 17px;
    }

    h3 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      color: #FFFDFB;
      margin-right: 10px;
    }
  }

  .copyright {
    //position: absolute;
    //bottom: 34px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    color: #B2A89F;

    span {
      font-weight: 400;

    }
  }

  .copyright-dc {
    //position: absolute;
    //bottom: 20px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    color: #B2A89F;

    span {
      font-weight: 400;

    }
  }

  &.menu-open {
    transform: none;

    .mobile-menu{
      padding-top: 60px;
      &__top{
        border-bottom: 1px solid #B2A89F !important;
        margin-top: -20px;
      }
    }
  }

  .menu-close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .menu-logo {
    position: absolute;
    top: 24px;
    left: 24px;
  }


  .pages {
    padding-bottom: 50px;

    li {
      a {
        font-size: 20px;
        line-height: 28px;
        color: #FFFDFB;
        //padding-bottom: 15px;
        //border-bottom: 1px solid rgba(255, 255, 255, 0.75);
        margin-bottom: 20px;
        display: flex;
        text-transform: capitalize;
        color: #FFFDFB;
      }
      &.active{
        a{
          color: ${NaturalSand};
        }
      }
    }
    
    .accordion-item{
      .accordion-header{
        &.active{
          button{
            color: ${hoverNd};
          }
        }
      }
    }

    .accordion-button {
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: #ffffff;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.75);
      margin-bottom: 17px;
      display: flex;
      text-transform: capitalize;
      width: 100%;
      text-align: left;
      padding-left: 0;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 30px;
        width: 30px;
        right: -10px;
        bottom: 10px;
        background-image: url('/images/static/mobile-minus.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      &.collapsed:after {
        background-image: url('/images/static/mobile-plus.svg');
      }

    }

    .accordion-body {
      padding-top: 5px;
      padding-bottom: 28px;

      a {
        border: none;
        margin-bottom: 12px;
        padding-bottom: 0;
      }
    }


  }

  .projects {
    //margin-top: 50px;

    .accordion-item{
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 20px;
      h2{
        font-size: 20px;
        line-height: 28px;
      }
    }
    .accordion-header {
      position: relative;
      margin: 0 20px;
      
      

      button {
        border: none;
        background-color: transparent;
        padding-left: 0;
        color: #FFFDFB;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-transform: capitalize;
        //padding-bottom: 18px;
        border-bottom: none;
        width: 100%;
        text-align: left;
        //margin-bottom: 50px;
        transition: all .4s ease;

        &.collapsed {
          color: #FFFDFB;
          border-color: #2B3382;

          span {
            background-color: #FFFDFB;

            svg {
              transform: rotate(0deg);
            }

            &:after {
              background-color: transparent !important;
            }
          }
        }
      }

      span {
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: #000;
        background-color: #FFFDFB;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 10px;

        &:after {
          height: 0;
          width: 0;
          background-color: #FFFDFB;
          border-radius: 50%;
          opacity: 0;
          transition: all .4s ease;
          content: '';
          position: absolute;
        }

        svg {
          font-size: 10px;
          z-index: 2;
          transform: rotate(180deg);
        }

      }

      &:hover {
        span {
          &:after {
            height: 100%;
            width: 100%;
            opacity: 1;
          }
        }

        button {

        }
      }
    }

    .accordion-body {
      background: #F5EFE9;
      padding-top: 20px;
      padding-bottom: 20px;
      
      h4 {
        font-size: 15px;
        font-weight: bold;
        line-height: 21px;
        color: #313033;
        text-transform: uppercase;
        margin: 50px 0 30px 0;
      }
      li{
        color: #897059;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        margin-left: 20px;
        margin-right:20px;

        margin-bottom: 20px;
        position: relative;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
        
      }
      p {
        font-size: 16px;
        color: #717076;
      }

      .dc-btn {
        margin-top: 80px;
        margin-bottom: 80px;
      }

    }
    
    
    
    
    
    
    
    
    
    
    
    
    li {
      font-size: 20px;
      font-weight: 500;
      text-transform: capitalize;
      color: #ffffff;
      margin-bottom: 24px;
      position: relative;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }

    }
  }

  p {
    font-size: 10px;
    margin: 26px 0 0 0;
    color: #ffffff;

    span {
      font-weight: bold;

    }
  }


`;

const StyledMenuSlide = styled.section`
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  z-index: 999;
  width: 100%;
  padding: 132px 20px 60px 20px;
  transform: translateX(120%);
  transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
  &.menu-open{
    transform: none;
  }
  .slide-menu-close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .slide-menu-back {
    position: absolute;
    top: 24px;
    left: 15px;
  }


  h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #181D24;
    margin: 0 0 40px 0;
    text-transform: capitalize;
  }

  ol {
    padding-left: 0px;

    li {
      list-style: none;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 30px;
      h5 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: #897059;
      }

      p {
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
        color: #B2A89F;
      }

      span {
        display: flex;
        flex: 0 0 100%;
        color: #939299;
        font-size: 10px;
        text-transform: capitalize;
      }
    }
  }

  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 1px;
    background: #2B3382;
    margin-right: 2px;
  }

  .simplebar-scrollbar {
    left: -2px;
    background-color: #181D24;
    width: 5px;
  }

  .simplebar-track {
    overflow: visible !important;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
    background: #181D24;
  }

  .simplebar-offset {
    z-index: 9;
  }

  .simplebar-mask {
    z-index: 9;
  }


`;
export default MobileMenu;


