import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import ButtonRound from "../ButtonRound";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent  className={'about-counter pb-200 pt-200 parallax'}>
            <Img src={data?.bg ? data?.bg : 'images/static/about-counter.jpg'} parallax/>
            {/*<img src={'images/static/about-counter.jpg'}/>*/}
            <Container>
                <Row>
                    <Col className="about-counter__title">
                        {
                            data?.title &&
                            <h2 className={"split-up"}>
                                {ReactHtmlParser(data?.title)}
                            </h2>

                        }

                    </Col>
                </Row>
                <Row>
                    <Col md={{span:8,offset:3}} className={'about-counter__desc'}>
                        {
                            data?.description &&
                            <p className={"split-up"}>{data?.description}</p>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:8,offset:3}} className='counter_top'>
                        <div className="counter_first">
                            <div className="counter_first__number">
                                <CountUp start={0} end={data?.land_number} duration={0.5}
                                         separator=','
                                         // decimals={}
                                         decimal=",">

                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start} partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>

                                            <p ref={countUpRef}></p>


                                        </VisibilitySensor>

                                    )}

                                </CountUp>
                            </div>
                            <h6>{data?.land_number_subtitle}</h6>
                        </div>
                        <div className="counter_first">
                            <div className="counter_first__number">
                                <CountUp start={0} end={data?.customer_number} duration={0.5}
                                         separator=','
                                         // decimals={3}
                                         decimal=",">

                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start} partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>

                                            <p ref={countUpRef}></p>


                                        </VisibilitySensor>

                                    )}

                                </CountUp>
                            </div>
                            <h6>{data?.customer_number_subtitle}</h6>
                        </div>
                        <div className="counter_first">
                            <div className="counter_first__number">
                                <CountUp start={0} end={data?.days} duration={0.5}
                                         separator=','
                                         decimals={0}
                                         decimal=",">

                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start} partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>

                                            <p ref={countUpRef}></p>


                                        </VisibilitySensor>

                                    )}

                                </CountUp>
                            </div>
                            <h6>{data?.days_subtitle}</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  padding-bottom: 140px !important;
  img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .about-counter {
    &__title {
      h2 {
        color: #FFFDFB;
        font-size: 100px;
        line-height: 120px;
        margin-bottom: 60px;

        span {
          padding-left: 5%;
        }

        @media (max-width: 767px) {
          font-size: 60px;
          line-height: 72px;
          margin-bottom: 40px;
          span {
            padding-left: unset;
          }
        }
      }
    }

    &__desc {
      margin-bottom: 60px;
      p {
        color: #FFFDFB;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.8;
      }
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }
  }
  .counter_top{
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 767px){
      flex-direction: column;
    }
  }
  .counter_first {
    display: inline-block;
    padding-bottom: 15px;
    width: 50%;

    &__number{
      display: flex;

      p {
        font-size: 120px;
        font-weight: 500;
        line-height: 120px;
        color: #B2A89F;
        @media (max-width: 991px){
          font-size: 100px;
          line-height: 100px;
        }

        @media (max-width: 767px){
          font-size: 100px;
          line-height: 100px;
        }
      }
    }


    h6 {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      line-height: 24px;
      color: #FFFDFB;
      margin-top: 20px;
      margin-bottom: 60px;
      @media (max-width: 767px){
        margin-bottom: 30px;
      }
    }
  }
  
  
`;

export default MyComponent;
