import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import MediaSingle from "../MediaSingle";
import Button from "../Button";
import Select, {components} from "react-select";
import {ColorBg} from "../../styles/globalStyleVars";

const MyComponent = ({data,category}) => {

    const [selectStatus, setSelectStatus] = useState('')
    const handleStatus = (e) => {
        setSelectStatus(e?.value);
        // Router.push(`/projects?status=${e?.value}`);
        // selectTypeInputRef?.current?.select?.clearValue();
        // selectLocationInputRef?.current?.select?.clearValue();
    }

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#897059` : '#897059',
            backgroundColor: '#FFFDFB',
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            // paddingLeft: 10,
            // paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 500,
            "&:hover": {
                backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#181D24',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `#181D24` : '#FFFDFB',
            margin: 0,
            fontSize: 16,
            zIndex:10,
            borderRadius: 0,



        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 16,
            // paddingBottom:10,
        }),

    };
    const [selected, setSelected] = useState('');
    const handleSelect = (value) => {
        setSelected(value?.label)
    }

    let filteredData = selected === '' ? data : data?.filter(element => element?.media_cat === selected);

    const option=category?.filter(element => element.label !== 'Uncategorized');
    // const location = category?.map(element => ({value: element?.slug,label: location?.name}));

    //Load more
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(6)
    const initialData = filteredData?.slice( 0, index);


    const handleLoadMore = () => {
        setIndex(index + 6)
        if (index >= data?.length) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };

    return (
        <StyledComponent className={'pt-150 pb-200 media-list'}>
            <Container>
                <Row className={'filters'}>
                    <Col xs={12} md={3}>
                        <Select
                            classNamePrefix="filter"
                            onChange={handleSelect}
                            isSearchable={false}
                            styles={customStyles}
                            components={{DropdownIndicator}}
                            options={option}
                            placeholder={'All'}
                            defaultValue={selectStatus}
                        />
                    </Col>
                </Row>
                <Row className={'media-list__all'}>
                    {
                        initialData && initialData.length>0 &&
                        initialData.map((element,index)=>{
                            return(
                                <Col lg={4} md={6} key={element?.ID}>
                                    <MediaSingle
                                        external_link={element?.media_link}
                                        text={element?.media_title}
                                        title={element?.post_title}
                                        img={element?.media_thumb}
                                        link={`media-center/${element?.post_name}`}
                                        day={element?.media_date}
                                        month={element?.media_month}
                                        cat={element?.media_cat}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
                {/*{*/}
                {/*    index >= data?.length || initialData===undefined ? "" :*/}
                {/*        <Row className={'justify-content-center align-items-center'}>*/}
                {/*            <div onClick={handleLoadMore}>*/}
                {/*                <Button text={'Load More'} background={'#897059'} hoverBackground={'#181D24'} color={'#ffffff'}/>*/}
                {/*            </div>*/}
                {/*        </Row>*/}
                {/*}*/}

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: ${ColorBg};
  .media-list{
    &__all{
      padding-bottom: 40px;
    }
  }
  .filters {
    padding-bottom: 60px;
    background:${ColorBg};
  }

  .filter {
    &__control {
      background-color: ${ColorBg};
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #B2A89F;
      box-shadow: none;
      border-radius: 0;

      &--is-focused {
        background-color: transparent !important;
        border: none;
        outline: none;
        border-bottom: 1px solid #B2A89F;
      }

      &:hover {
        border-bottom: 1px solid #B2A89F;
      }
    }

    &__placeholder {
      color: #B2A89F;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    &__value-container {
      padding: 10px 0px;
    }

    &__indicator {
      padding: 0;
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__single-value {
      color: #B2A89F;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    &__menu {
      padding: 30px 20px;
    }

    &__option {
      border-bottom: 1px solid rgba(178, 168, 159, 0.2);
      :last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
      }
      :first-child{
        padding-top: 0px;
      }

    }
  }
`
export default MyComponent;