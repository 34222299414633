import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {Link, useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import ReactHtmlParser from "react-html-parser";

SwiperCore.use([Autoplay, Pagination, Navigation]);
const SliderShowRoom = ({padding,data,title}) => {

    // <width control

    const sourceDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 30;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
            document.documentElement.style.setProperty('--target-height', `${adjustedWidth + 300}px`);
            document.documentElement.style.setProperty('--target-height-bottom', `${adjustedWidth + 255}px`);
            document.documentElement.style.setProperty('--target-height-left', `${adjustedWidth + 190}px`);

        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data]);



    // offset

    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [data])

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);


    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [data])


    return (
        <>
            <StyledBox data-scroll-container offset={offset}
                       className={`showroom slider_component ${padding ? padding : ' pb-200'}`}>
                <Container className={'version_one'}>
                    <Row>
                        <Col>
                            <div className="testimonial__head__text">
                                <h2 className={''}>{ReactHtmlParser(title)}</h2>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        {
                            data?.length>3 ?
                                <Col md={6} className={'slider-position'}>
                                    <div className="testimonial__head__second">
                                        <div className="testimonial__head__navigation">
                                            <ul>
                                                <li id={'gallery-prev'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                                         viewBox="0 0 52 52">
                                                        <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                           transform="translate(52 52) rotate(180)">
                                                            <circle id="Ellipse_450" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                    fill="#181D24"></circle>
                                                            <circle id="Ellipse_451" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                    fill="#897059"></circle>
                                                            <g id="Group_15984" data-name="Group 15984"
                                                               transform="translate(-97 -5894.5)">
                                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                      transform="translate(120.5 5915.5)" fill="none"
                                                                      stroke="#FFFDFB"
                                                                      stroke-linecap="round" stroke-width="2"/>
                                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                      transform="translate(120.5 5920.5)" fill="none"
                                                                      stroke="#FFFDFB"
                                                                      stroke-linecap="round" stroke-width="2"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </li>
                                                <li id={'gallery-next'}>
                                                    <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                         xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                                         viewBox="0 0 52 52">
                                                        <circle id="Ellipse_450" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                fill="#181D24"></circle>
                                                        <circle id="Ellipse_451" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                fill="#897059"></circle>
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(-97 -5894.5)">
                                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                  transform="translate(120.5 5915.5)" fill="none" stroke="#FFFDFB"
                                                                  stroke-linecap="round" stroke-width="2"/>
                                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                  transform="translate(120.5 5920.5)" fill="none" stroke="#FFFDFB"
                                                                  stroke-linecap="round" stroke-width="2"/>
                                                        </g>
                                                    </svg>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col> : ''
                        }


                    </Row>
                </Container>
                <Container  className={''} md={12}>

                    {
                        data && data?.length>0 ?
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={15}
                                speed='500'
                                navigation={{
                                    prevEl: '#gallery-prev',
                                    nextEl: '#gallery-next',
                                }}
                                // initialSlide={1}

                                loop={true}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,

                                    },
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,

                                    },
                                    1025: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                {
                                    data && data?.length>0 &&
                                    data.map((element)=>{
                                        return(
                                            <SwiperSlide key={element?.id}>
                                                <Link to={`/project/${element?.post_name}`}>
                                                    <div className="testimonial__single">
                                                        <div className={'testimonial__single__height'}>
                                                            <div sm={5} className={'testimonial__single__position'}>
                                                                <div className="testimonial__single__image video">
                                                                    <Img src={element?.project_thumb}/>
                                                                </div>
                                                                <div className={'gallery-text'}>
                                                                    <div className="gallery-text__title">
                                                                        <h5>{element?.post_title}</h5>
                                                                        <div className={'d-flex align-items-center'}>
                                                                            <p>{element?.type}</p>
                                                                            <div className={'dot'}></div>
                                                                            <p>{element?.location}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper> : ''
                    }
                </Container>
                <Container>
                    <Row>
                        <Col md={5} ref={sourceDivRef}>
                            {
                                data?.length>3 ?
                                    <div className="testimonial__head">

                                        <div className={"arrow__mobile"}>
                                            <div className="testimonial__head__second">
                                                <div className="testimonial__head__navigation">
                                                    <ul>
                                                        <li id={'gallery-prev'}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                                 viewBox="0 0 50 50">
                                                                <circle id="Ellipse_604" data-name="Ellipse 604" cx="25" cy="25" r="25"
                                                                        fill="#323232"/>
                                                                <circle id="Ellipse_605" data-name="Ellipse 604" cx="75" cy="25" r="25"
                                                                        fill="#E50019"/>
                                                                <g id="Group_16558" data-name="Group 16558"
                                                                   transform="translate(22.5 20)">
                                                                    <line id="Line_3874" data-name="Line 3874" x1="5" y2="5" fill="none"
                                                                          stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                                    <line id="Line_3875" data-name="Line 3875" x1="5" y1="5"
                                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                                          stroke-linecap="round" stroke-width="2"/>
                                                                </g>
                                                            </svg>
                                                        </li>
                                                        <li id={'gallery-next'}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                                 viewBox="0 0 50 50">
                                                                <g id="Banner_Nav_-_Right" data-name="Banner Nav - Right"
                                                                   transform="translate(-28 -349)">
                                                                    <circle id="Ellipse_606" data-name="Ellipse 604" cx="25" cy="25"
                                                                            r="25" transform="translate(28 349)" fill="#323232"/>
                                                                    <circle id="Ellipse_607" data-name="Ellipse 604" cx="-25" cy="25"
                                                                            r="25" transform="translate(28 349)" fill="#E50019"/>

                                                                    <g id="Group_16558" data-name="Group 16558"
                                                                       transform="translate(10 12.5)">
                                                                        <line id="Line_3874" data-name="Line 3874" x2="5" y2="5"
                                                                              transform="translate(40.5 356.5)" fill="none"
                                                                              stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                                        <line id="Line_3875" data-name="Line 3875" y1="5" x2="5"
                                                                              transform="translate(40.5 361.5)" fill="none"
                                                                              stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                            }

                        </Col>
                    </Row>
                </Container>
            </StyledBox>
        </>
    )

};


const StyledBox = styled.section`
  background-color: ${props => props.background ? props.background : '#F5EFE9'};
  min-height: 100vh;
  .dot{
    display: flex;
    justify-content: end;
    align-items: end;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #B2A89F;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  
  @media(max-width: 767px){
    padding-top: 0px!important;
  }

  h2 {
    color: #897059;
    font-size: 100px;
    line-height: 120px;
    margin-bottom: -40px;
    span {
      padding-left: 9%;
    }

    @media (max-width: 767px) {
      font-size: 60px;
      line-height: 72px;
      margin-bottom: 0px;
      span {
        padding-left: unset;
      }
    }
  }


  .slider-position{

    position: absolute;
    bottom:var(--target-height-bottom)!important;
    left: 42%;
    z-index: 100;
    .testimonial__head__second{
      .testimonial__head__navigation{
        ul{
          display: flex !important;
          li {
            cursor: pointer;

            svg {
              border-radius: 50px;

              #Ellipse_451 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }

              #Ellipse_450 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }


              &:first-child {
                margin-right: 20px;
              }

              &:hover {
                #Ellipse_451 {
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  r: 0;
                }
              }
            }
          }
        }
      }
    }
    @media(max-width: 767px){
      left: 0;
      bottom: 110px;
      padding-left: 15px;
    }
    @media(min-width: 1400px) and (max-width: 1500px){
      margin-left: 10px;
    }
    @media(min-width: 1200px) and (max-width: 1320px){
      margin-left: 15px;
    }
  }
  .gallery-text {
    &__title {
      min-height: 65px;
    }

    h5 {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin-top: 25px;
      transition: 0.5s all ease;

    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #897059;
      //margin-top: 5px;
    }
  }

  .mySwiper {
    padding-right: ${props => props.offset ? props.offset + 45 + 'px' : '100px'} !important;
    height: var(--target-height) !important;

    .swiper-button-prev, .swiper-button-next {
      display: none;
    }


    .swiper-slide {
      margin-top: auto;
      transition: 0.7s all ease;
      
      @media(max-width: 767px){
        transition: none;
        margin-top: 0;

      }
      
    }
    

    @media (min-width: 1380px) and (max-width: 1499px){
      padding-right: ${props => props.offset ? props.offset - 15 + 'px' : '90px'} !important;
    }


    @media (min-width: 1025px) {
      .arrow__mobile {
        display: none;
      }
    }
    
    @media(max-width: 767px){
      .swiper-slide-prev{
        height: 100%;
      }
    }


    .gallery-text__second {
      display: none;
    }


    .swiper-slide-active {
      height: auto;
      width: 45% !important;


      .testimonial__single__image {
        padding-top: calc(600 / 470 * 100%);
        .global-image{
          overflow: hidden;
          img{
            //transform: scale(1.01);
            transition: 0.4s ease;
          }
        }
        &:hover {
          .global-image img{
            transform: scale(1.06) !important;
          }
        }
      }
      @media(max-width: 767px) and (min-width: 500px){
        width: 125% !important;
        margin-left: -15px;
      }
      @media(max-width: 499px) and (min-width: 300px){
        width: 150% !important;
        margin-left: -15px;

      }
      @media(max-width: 767px){
        
        
        //width: auto !important;
        .testimonial__single__image {
          padding-top: calc(428 / 345 * 100%) !important;
        }
      }


      .gallery-text {
        h5 {

          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          transition: all 0.8s ease;
          
          @media(max-width: 767px){
            font-size: 24px;
            line-height: 32px;
          }
        }

        p {
          font-size: 18px;
          line-height: 24px;
          opacity: 1;
          transition: opacity 0.8s ease;
          transition-delay: 0.7s;
          @media(max-width: 767px){
            font-size: 16px;
          }
        }
      }
    }

    .testimonial__head {
      display: flex;
      justify-content: space-between;

      &__text {
        h5 {
          span {
            color: #E50019;
          }
        }
      }

      &__second {
        display: flex;
        justify-content: flex-start;
      }

      &__navigation {
        ul {
          display: inline-flex;

          li {
            cursor: pointer;

            svg {
              border-radius: 50px;

              #Ellipse_605 {
                transition: 0.7s all ease;
              }

              #Ellipse_607 {
                transition: 0.7s all ease;
              }
            }

            &:first-child {
              margin-right: 20px;
            }

            &:hover {
              svg {
                #Ellipse_605, #Ellipse_607 {
                  r: 25px;
                  cx: 25px;
                  fill: #E50019;
                }
              }
            }


          }
        }
      }
    }
  }


  .testimonial__single {
    &__image {
      position: relative;
      padding-top: calc(400 / 320 * 100%) !important;

      .global-image{
        overflow: hidden;
        img{
          //transform: scale(1.01);
          transition: 0.4s ease;
        }
      }
      &:hover {
        .global-image img{
          transform: scale(1.06) !important;
        }
      }


      &__icon {
        position: absolute;
        top: -20px;
        right: -25px;
      }

    }

    &__position {
      position: relative;

    }

    &__content {
      .text {
        color: #32355D;
        line-height: 36px;
        font-weight: 700;
      }

      .description {
        margin: 40px 0 40px 0;
        color: #32355D;
      }

      .name {
        color: #18AEAE;
        line-height: 24px;
        font-weight: 500;

      }

      .designation {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }

  .global-image {
    img {
      cursor: pointer;;
    }
  }

  @media (max-width: 1023px) {

    min-height: auto;
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .gallery-text {
      //display: none!important;
    }

    .slider-position {
      display: none;
    }

    .arrow__mobile {
      display: block;
      padding-bottom: 40px;
    }

    .testimonial__head {
      flex-direction: column;
      padding-bottom: unset;

      &__text {
        padding-bottom: 40px;
      }
    }

    .testimonial__single__image__icon {
      position: absolute;
      top: -20px;
      left: 0;
    }

    .testimonial__single {
      &__content {
        margin-top: 20px;

        .description {
          margin: 20px 0 20px 0;
        }
      }
    }

    .swiper-initialized {
      padding-left: 15px;
      padding-right: 15px;
      height: auto !important;
    }


    .swiper-slide-active {
      height: 100%;
      width: inherit !important;

      .testimonial__single__image {
        //padding-top: calc(480 / 570 * 100%);
        width: 100%;
        height: 100%;

      }
    }

    .gallery-text__second {
      display: block;
      padding-top: 20px;
      display: flex;
      flex-direction: column-reverse;
    }

    .swiper-slide {
      width: inherit !important;
      height: 100%;
    }

    .testimonial__head__second {
      justify-content: left;
    }

    .slider-position {
      top: 20%;
    }
    .testimonial__single {
      height: unset;
      &__height {
        align-content: flex-end;
        height: 100%;
      }
    }
  }
  .arrow__mobile {
    display: none;
    @media(max-width: 767px){
      display: block;
      ul{
        display: flex;
        margin-top: 40px;
        li:not(:last-child){
          margin-right: 20px;
        }
      }
    }
  }
`;


export default SliderShowRoom;
