import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from 'react-bootstrap';
import Title from '../Title';
import Button from '../Button';
import { gsap } from 'gsap';
import ReactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";

const MyComponent = ({data,title}) => {


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.rent);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.RENT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('project-name', title);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };

    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])






    const [showContent, setShowContent] = useState(false);
    const detailsBtnTextRef = useRef(null);

    const toggleContent = () => {
        const detailsBtnText = detailsBtnTextRef.current;
        const currentHeight = showContent ? 0 : detailsBtnText.scrollHeight;

        gsap.to(detailsBtnText, {
            height: showContent ? 0 : currentHeight,
            opacity: showContent ? 0 : 1,
            duration: 0.6, // 1-second duration
            ease: 'linear', // You can change the easing function if needed
            onComplete: () => {
                setShowContent(!showContent);
                if (!showContent) {
                    detailsBtnText.style.height = 'auto'; // Set height to auto after expanding
                }
            },
        });
    };

    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col md={7}>
                        <Title text={data?.title} fontSize={'28'} fontWeight={'500'} lineHeight={'40'} color={'#897059'} />
                        <div className="details">
                            {ReactHtmlParser(data?.initial_description)}

                            <div
                                ref={detailsBtnTextRef}
                                className={`details_btn__text ${showContent ? 'show' : ''}`}
                            >
                                {ReactHtmlParser(data?.load_description)}
                            </div>
                            <Button
                                text={showContent ? 'Load Less' : 'Load More'}
                                background={'#897059'}
                                color={'#FFFDFB'}
                                hoverBackground={'#181D24'}
                                onClick={toggleContent}
                                margin={'60px 0 0 0 '}
                            />
                        </div>
                    </Col>
                    <Col md={5} className={'rent-form'}>
                        <div className="rent-form__content">

                            <h4>{data?.form_title}</h4>

                            <Form>
                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name",{
                                        required: 'Username is required',
                                        pattern: {
                                            value: /^[A-Za-z]+$/,
                                            message: 'Username must contain only letters',
                                        },

                                    })}
                                    type="text" placeholder="Full Name"/>
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone",{
                                        required:{
                                            value:true,
                                            message:'please enter your phone first'
                                        },
                                        pattern:{
                                            value:/^01[0-9]{9}$/,
                                            message:'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number" placeholder="Phone Number*"/>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email",{
                                        required:{
                                            value:true,
                                            message:'please enter your email'
                                        },
                                        pattern:{
                                            value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Email*"/>
                                <Form.Control
                                    className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("message",{
                                        required:{
                                            // value:true,
                                            message:'please enter your message'
                                        },

                                    })}
                                    type="textarea" placeholder="Message"/>
                                <Button onClick={handleSubmit(onSubmit,onError)} text={'Submit Message'} fontWeight={'700'} background={'#FFFDFB'} color={'#736640'} hoverBackground={'#736640'} hoverColor={'#FFFDFB'}/>
                                <Button call text={'Call Now'} fontWeight={'700'} src={'tel:+8801911111111'} background={'#FFFDFB'} color={'#897059'} hoverBackground={'#736640'} hoverColor={'#FFFDFB'}/>
                            </Form>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #f5efe9;

  .title {
    margin-bottom: 60px;
  }

  .details {
    p {
      color: #897059;
      font-weight: 500;
      margin-bottom: 40px;
    }

    &__tag {
      color: #897059;
      margin-bottom: 30px !important;
    }

    table {
      margin-bottom: 40px;

      tr {
        border-bottom: 1px solid rgba(178, 168, 159, 0.5);

        td {
          padding-bottom: 20px;
          padding-top: 20px;

          &:first-child {
            color: #897059;
          }
        }
      }
    }
  }

  /* Define transition properties */
  .details_btn__text {
    height: 0;
    overflow: hidden;
    opacity: 0;
    //transition: all 0.6s ease;
  }

  .details_btn__text.show {
    height: auto; /* Set height to auto after expanding */
    opacity: 1;
  }
  
  
  .rent-form__content{
    background-color: #897059;
    padding: 70px;
    @media(max-width: 767px){
      padding: 40px 15px;
    }
    h4{
      margin-bottom: 60px;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      color: #FFFDFB;
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }
    .form-control{
      background-color: transparent;
      border:none;
      border-bottom: 1px solid #B2A89F;
      margin-bottom: 40px;
      color: #FFFDFB;
      padding-left: 0;
      &::placeholder{
        color: #FFFDFB;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
      }
    }
    
    input[type="textarea"]{
      padding-bottom: 80px;
    }

    .dc-btn{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
        a{
          width: 195px;
        }
      }
    }
  }
  @media(max-width: 767px){
    .rent-form{
      padding: 0;
      margin-top: 60px;
    }
  }

  
`;

export default MyComponent;
