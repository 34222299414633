import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BurntSienna, ColorBg, NaturalSand} from "../styles/globalStyleVars";



const InnerBanner = ({img, text, title,page_name,detail,project_type,project_location,details_address,logo,mimg}) => {


    return (
        <StyledInnerBanner className='InnerBanner parallax' detail={detail} page_name={page_name}>
            <div className={'banner__img'} >
                {
                    window.innerWidth>767 ?
                        <Img parallax banner={true} src={img}/> :
                        <Img banner={true} src={mimg ? mimg : img}/>
                }
            </div>
            <Container>
                <Row>
                    <Col lg={12} md={12}>

                        {
                            title &&
                            <div>
                                <h2>{reactHtmlParser(title)}</h2>
                                <h6> {reactHtmlParser(details_address)}  </h6>
                            </div>
                        }
                    </Col>
                </Row>

                {
                    detail?
                        <Col md={`${detail}`? 5 : 4} className={'address'}>
                            {/*{*/}
                            {/*    logo? <img src={logo? logo :''}/> : ''*/}
                            {/*}*/}

                            <div>
                                <div className={'d-flex align-items-center'}>
                                    <p>{project_type}</p>
                                    <div className={'dot'}></div>
                                    <p>{project_location}</p>
                                </div>
                            </div>
                        </Col>
                        :''
                }
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: 60px;
  position: relative;
  background-color: ${ColorBg};
  
  


  .address{
    position: absolute;
    bottom: 50px;
    img{
      margin-bottom: 50px;      
    }
    p{
      font-size: 20px;
      line-height: 24px;
      color: #FFFDFB;
      font-weight: 500;
    }
    .dot{
      display: flex;
      justify-content: end;
      align-items: end;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #FFFDFB;
      margin-right: 10px;
      margin-left: 10px;
    }
    h6{
      font-size: 40px;
      line-height: 40px;
      font-weight: 500;
      color: #FFFDFB;
      margin-top: 10px;
    }
  }
  .banner__img{
    padding-top: calc(524 / 1366 * 100%);
    position: relative;
    @media(max-width: 767px){
      padding-top: calc(458 / 375 * 100%);
    }
    
  }
  .border-style{
    width: 30px;
    height: 0px;
    border: 1px solid #B2A89F;
    margin-right: 12px;
  }
  

  &:after {
    content: '';
    position: absolute;
    // background-color: ${ColorBg};
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

  }

  .container {
    height: 80%;
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    position: ${props => props.position || 'absolute'};

    top: ${props => props.detail ? '120px' : '120px'};
    z-index: 2;
  }
  p{
    color:#B2A89F;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
  }

  h2 {
    //position: absolute;
    left: 15px;
    //bottom: 94px;
    color: ${BurntSienna};
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    text-transform: uppercase;
    z-index: 2;

    span {
      font-weight: 500;
      color: #736640;
    }
  }
 
  h6{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #181D24;
  }
  @media(max-width: 767px){
    padding-top: ${props => props.detail ? '185' : ' 245' }px;
    .address{
      bottom: 30px;
      img{
        width: 120px;
        height: 80px;
      }
    }
  }
  @media (min-width: 767px) {
    
    .title {
      width: 50%;
    }
    padding-top: ${props => props.page_name ? '230' : '200' }px;

  }
  .col-lg-8{
    padding-left: 0px;
  }
  @media (max-width: 767px) {
    position: relative;
    .container {
      top: 110px;
    }
    .col-lg-6{
      padding-left: 0px;
    }

    .title {
      margin-bottom: 40px !important;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
    h6{
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export default InnerBanner;
