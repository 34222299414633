import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import AboutCounter from "../../components/about/AboutCounter";
import Quote from "../../components/about/Quote";
import Vision from "../../components/about/Vision";
import Mission from "../../components/about/Mission";
import CoreValue from "../../components/about/CoreValue";
import Ceo from "../../components/about/Ceo";
import Leaders from "../../components/about/Leaders";
import HomeAbout from "../../components/home/HomeAbout";
import Video from "../../components/Video";
import Overview from "../../components/project-details/Overview";
import InnerBanner from "../../components/InnerBanner";
import AwardSlider from "../../components/about/AwardSlider";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchHome} from "../../api/redux/home";
import {fetchAbout} from "../../api/redux/about";
import {gsap} from "gsap";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {

    const dispath = useDispatch()
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))
    }, [])

    let getPost = useSelector(state => state.about)

    const aboutCounter = getPost?.posts?.page_data?.[0]?.about_overview;
    const aboutQuotes = getPost?.posts?.page_data?.[0]?.about_quote;
    const aboutVision = getPost?.posts?.page_data?.[0]?.about_vision;
    const aboutMission = getPost?.posts?.page_data?.[0]?.about_mission;
    const aboutValue = getPost?.posts?.page_data?.[0]?.about_value;
    const aboutAward = getPost?.posts?.page_data?.[0]?.about_award;
    const aboutCeo = getPost?.posts?.page_data?.[0]?.about_ceo;
    const aboutLeader = getPost?.posts?.page_data?.[0]?.about_leader;
    const aboutBanner = getPost?.posts?.page_data?.[0]?.banner;
    const meta = getPost?.posts?.page_data?.[0]?.meta;


    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])


    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}

            </Helmet>

            <StyledComponent>
                {
                    aboutBanner?.image && aboutBanner?.title &&
                    <InnerBannerV2 page_name={aboutBanner?.page}
                                   img={aboutBanner?.image}
                                   mimg={aboutBanner?.mimage} title={aboutBanner?.title}
                    />
                }
                {/*{*/}
                {/*    aboutBanner?.image && aboutBanner?.title &&*/}
                {/*    <InnerBanner img={aboutBanner?.image} mimg={aboutBanner?.mimage} title={aboutBanner?.title} />*/}
                {/*}*/}
                {
                    aboutCounter?.title && aboutCounter?.description &&
                    <AboutCounter data={aboutCounter} />
                }


                <Quote data={aboutQuotes}/>

                <Vision
                    firstImage={aboutVision?.upper_image}
                    secondImage={aboutVision?.lower_image}
                    title={aboutVision?.title}
                    text={aboutVision?.description}
                />
                <Vision
                    reverse={'reverse'}
                    firstImage={aboutMission?.upper_image}
                    secondImage={aboutMission?.lower_image}
                    title={aboutMission?.title}
                    text={aboutMission?.description}
                />
                <CoreValue data={aboutValue}/>
                <AwardSlider data={aboutAward}/>
                <Ceo data={aboutCeo}/>
                <Leaders data={aboutLeader}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
