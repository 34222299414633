import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom"
import {Img} from "../Img";
import Title from "../Title";
import {ColorBg, hover, MidNightBlue} from "../../styles/globalStyleVars";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";



const MyComponent = ({data}) => {
    // const videoUrlId = data?.url?.replaceAll('https://youtu.be/', '');
    // offset


    return (

            <StyledComponent className={'ceo pb-200 pt-200'}>
                <Container>
                    <Row>
                        <Col md={5} >
                            {
                                data?.image &&
                                <div className="ceo__img ">
                                    <Img src={data?.image} alt=""/>
                                </div>
                            }

                        </Col>

                        <Col md={{span: 6, offset: 1}} className={' ceo__content'}>

                            <Title text={data?.title} fontSize={'60'} lineHeight={'72'} color={'#181D24'}/>
                            <div className="ceo__content__details">
                                {
                                    data?.message &&
                                    <div className={'ceo__content__details__message '}>
                                        {ReactHtmlParser(data?.message)}

                                    </div>

                                }
                                {
                                    data?.name &&
                                    <h6 className={'ceo__content__details__name split-up'}>{ReactHtmlParser(data?.name)}</h6>

                                }
                                {
                                    data?.designation &&
                                    <p className={'ceo__content__details__position split-up'}>{reactHtmlParser(data?.designation)}</p>

                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  

  .ceo__img {
    padding-top: calc(918 / 750 * 100%);
    position: relative;
    min-height: 100%;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .ceo__content{
    
    &__details{
      margin-top: 60px;
      &__message{
        color: ${MidNightBlue};
        p{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
          &:last-child{
            margin-bottom: 0px;
          }
        }
        
        margin-bottom: 60px;
      }
      &__name{
        font-size: 24px;
        line-height: 32px;
        color: ${MidNightBlue};
      }
      &__position{
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: ${MidNightBlue};
        margin-top: 10px;
      }
      
    }
  }
  
  @media(max-width: 767px){
    padding-top: 0px !important;
    .col-md-5{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .ceo__content{
      .title{
        margin-top: 40px;
        h2{
          font-size: 40px;
          line-height: 48px;
        }
      }
      &__details{
        margin-top: 40px;
        &__message{
          margin-bottom: 30px;
        }

      }
    }
    
  }
  

  

  

`;

export default MyComponent;
