import React from 'react';
import styled from 'styled-components';
import { hover } from '../styles/globalStyleVars';
import { Link } from 'react-router-dom';

const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    hoverColor,
                    onClick,svg,call,download

                }) => {
    return (
        <StyledBtn
            className={`dc-btn fade-up`}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            background={background}
            lineHeight={lineHeight}
            letterSpacing={letterSpacing}
            margin={margin}
            border={border}
            img={img}
            borderRadius={borderRadius}
            width={width}
            hoverImg={hoverImg}
            hoverBackground={hoverBackground}
            height={height}
            borderColor={borderColor}
            target={target}
            hoverColor={hoverColor}
            onClick={onClick}
        >
            {
                call ?
                    <a href={src}><span>{text}</span></a> :
                    src ? (
                        <Link to={src || '/'}>
                            {
                                svg ?
                                    <div className={'download'}>
                                        <span> {text} </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="19" viewBox="0 0 16.82 19">
                                            <g id="Group_20091" data-name="Group 20091" transform="translate(-1655.59 -7448.5)">
                                                <line id="Line_10" data-name="Line 10" x2="14" transform="translate(1657 7466.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                <g id="Group_39" data-name="Group 39" transform="translate(0 0.5)">
                                                    <g id="Group_7" data-name="Group 7" transform="translate(427.5 7406.5)">
                                                        <line id="Line_1" data-name="Line 1" x2="7" y2="6" transform="translate(1229.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_2" data-name="Line 2" x1="7" y2="6" transform="translate(1236.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" y2="12" transform="translate(1664 7449)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>:
                                    <span> {text} </span>
                            }

                        </Link>
                    ) : (
                        <a target={target || '_self'}>
                            {
                                svg ?
                                    <div className={'download'}>
                                        <span> {text} </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="19" viewBox="0 0 16.82 19">
                                            <g id="Group_20091" data-name="Group 20091" transform="translate(-1655.59 -7448.5)">
                                                <line id="Line_10" data-name="Line 10" x2="14" transform="translate(1657 7466.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                <g id="Group_39" data-name="Group 39" transform="translate(0 0.5)">
                                                    <g id="Group_7" data-name="Group 7" transform="translate(427.5 7406.5)">
                                                        <line id="Line_1" data-name="Line 1" x2="7" y2="6" transform="translate(1229.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_2" data-name="Line 2" x1="7" y2="6" transform="translate(1236.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" y2="12" transform="translate(1664 7449)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>:
                                    <span> {text} </span>
                            }

                        </a>
                    )
            }
        </StyledBtn>
    );
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || 'fit-content'};
    height: ${props => props.height || '48'}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '20'}px;
      font-weight: ${props => props.fontWeight || 600};
      margin: 0;
      line-height: ${props => props.lineHeight || '22'}px;
      background-color: ${props => props.background || `#736640`};
      position: relative;
      border-radius: ${props => props.borderRadius || '28'}px;
      z-index: 0;
      padding: 16px 36px;
      box-sizing: border-box;

      span {
        transition: color 0.3s ease;
        color: ${props => props.color || hover};
        position: relative;
        z-index: 3;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: ${props => props.hoverBackground || '#ffffff'};
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        transform-origin: center center;
        transform: scale(0);
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1) 0s, transform 0.3s ease;
        border-radius: 50px;
        z-index: -1;
      }

      &:hover {
        border-color: transparent;

        &:before {
          transform: scale(1);
        }

        span {
          color: ${props => props.hoverColor || '#FFF'};
        }
      }

      &:focus {
        color: #736640;
      }
    }
  }
  .download{
    display: flex;
    span{
      margin-right: 13px;
    }
    
  }
`;

export default Button;
