import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ProjectSingle from "./ProjectSingle";
import Select, {components} from "react-select";
import Button from "../Button";
import {useHistory, useLocation} from "react-router-dom";
import {ApiParam} from "../../api/network/apiParams";
import {useDispatch} from "react-redux";
import {apiEndPoints as ApiServices} from "../../api/network/apiEndPoints";
import {fetchProjects} from "../../api/redux/project";
import {ColorBg, ColorWhite, hoverNd} from "../../styles/globalStyleVars";
import {Loading} from "../Loading";
import PageTransition from "../../animation/PageTransition";

const MyComponent = ({data,filters,setFakeLoader}) => {

    const dispatch = useDispatch();
    let history=useHistory();

    const [selectStatus, setSelectStatus] = useState('')
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')
    const [selectFeature, setSelectFeature] = useState('')

    const selectStatusInputRef = useRef();

    //get the query
    const locations = useLocation();
    const project_status = new URLSearchParams(locations?.search).get("status");
    const project_type = new URLSearchParams(locations?.search).get("type");
    const location_param = new URLSearchParams(locations?.search).get("location");
    const collection_param = new URLSearchParams(locations?.search).get("collection");

    //Load more
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(6)
    const initialData = data?.slice( 0, index);


    const handleLoadMore = () => {
        setIndex(index + 6)
        if (index >= data?.length) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }
    }

    // api call on filtering

    useEffect(() => {

        let api_services = ApiServices.PROJECTS

        if(collection_param){
            let param = {
                [ApiParam.collection_id]: collection_param,
            }
            dispatch(fetchProjects([api_services,param]))
        }

        if(project_status && project_type){
            let param2 = {
                [ApiParam.project_status]: project_status,
                [ApiParam.project_type]: project_type,
            }
            dispatch(fetchProjects([api_services,param2]))
        }
        if(location_param){
            let param={
                [ApiParam.project_location]: location_param
            }
            dispatch(fetchProjects([api_services,param]))
        }
        else{
            let param = {
                [ApiParam.project_status]: selectStatus? selectStatus : project_status ,
                [ApiParam.project_type]: selectType? selectType : project_type,
                [ApiParam.project_location]: location_param ? location_param :selectLocation,
                [ApiParam.feature_id]: selectFeature,
                [ApiParam.collection_id]: collection_param,
            }
            dispatch(fetchProjects([api_services,param]))
        }
    }, [selectStatus, selectType,selectLocation,selectFeature,location_param,collection_param,project_status,project_type])

    const handleStatus = (e) => {
        setSelectStatus(e.value);
    }
    const handleType = (e) => {
        setSelectType(e?.value);
        // selectStatusInputRef?.current?.clearValue();
        // setState('Project Status')

    }
    const handleLocation = (e) => {
        setSelectLocation(e?.value);
        if(collection_param===''){
            history.push({
                pathname: '/projects',
                search: `?location=${e?.value}`
            });
        }
    }

    const handleFeature = (e) => {
        setSelectFeature(e?.value);
    }

    let inputOption=filters?.project_statuses_by_type?.residential;
    let inputCommercialOption=filters?.project_statuses_by_type?.commercial;

    // Create a temporary Set to keep track of unique objects
    const uniqueSet = new Set();
    const [statusOption, setStatusOption] = useState('');
    const [comStatusOption, setComStatusOption] = useState('');

    const inputArray = [
        {
            "value": "retail-shopping-mall",
            "label": "Retail (Shopping Mall)"
        },
        {
            "value": "office-space",
            "label": "Office Space"
        },
    ];


    useEffect(() => {

        if(inputCommercialOption?.length>0){
            const uniqueStatusObjects = inputCommercialOption.filter(obj => {
                const key = JSON.stringify(obj);
                // If the object is not in the Set, add it and return true to keep it
                if (!uniqueSet.has(key)) {
                    uniqueSet.add(key);
                    return true;
                }
                // If the object is in the Set, return false to filter it out
                return false;
            });
            setComStatusOption(uniqueStatusObjects)
        }
    }, []);

    useEffect(() => {

        // Use the filter method to filter out unique objects

        if(inputOption?.length>0){
            const uniqueStatusObjects = inputOption.filter(obj => {
                const key = JSON.stringify(obj);
                // If the object is not in the Set, add it and return true to keep it
                if (!uniqueSet.has(key)) {
                    uniqueSet.add(key);
                    return true;
                }
                // If the object is in the Set, return false to filter it out
                return false;
            });
            setStatusOption(uniqueStatusObjects)
        }
    }, []);

    const status = filters?.project_statuses_by_type;
    const type =filters?.project_type;
    const location = filters?.project_location;
    const features = filters?.project_feature;

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"

        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#897059` : '#897059',
            backgroundColor: '#FFFDFB',
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            fontWeight: state.isSelected ? 700 : 500,
            "&:hover": {
                backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#181D24',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `#181D24` : '#FFFDFB',
            margin: 0,
            fontSize: 16,
            zIndex:10,
            borderRadius: 0,



        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 16,
            // paddingBottom:10,
        }),
        control:(provided,state)=>({
            ...provided,
            "&:hover": {
                // transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                // backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#FFFDFB',
            },
        })

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };


    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#897059" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#897059" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };

    const statusOptions=[
        {label:'Ongoing',value:'Ongoing'},
        {label:'Completed',value:'Completed'},
    ]


    //
    // useEffect(() => {
    //     setTimeout(() => {
    //         setFakeLoader(false)
    //     }, 600)
    // }, [])

    return (
        <StyledComponent className={'pb-200'}>
            <div className={'filter-section'}>
                <Container>
                    <Row className={'filters'}>

                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={handleType}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={type}
                                placeholder={project_type?project_type:'Project Type'}
                                defaultValue={selectStatus}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={e => handleStatus(e)}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={selectType=='residential' ? statusOptions: inputArray}
                                ref={selectStatusInputRef}
                                placeholder={project_status ? project_status:'Project Status'}
                                defaultValue={selectStatus}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={handleLocation}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={location}
                                placeholder={location_param ? location_param : 'Project Location' }
                                // defaultValue={selectStatus}
                                // placeholder={'Project Location'}
                                defaultValue={selectLocation}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={handleFeature}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={features}
                                placeholder={'Features'}
                                defaultValue={selectStatus}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'projects'}>
                <Container>
                    {
                        initialData && initialData?.length ?
                            <Row>
                                {
                                    initialData && initialData?.length>0 &&
                                    initialData?.map((element,index)=>{
                                        return(
                                            <Col md={4} key={index}>
                                                <ProjectSingle img={element?.project_thumb}
                                                               name={element?.post_title}
                                                               location={element?.project_location}
                                                               type={element?.project_type}
                                                               link={element?.post_name}
                                                               banner={element?.banner_image}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row> : <p className={'not-found'}>No data found</p>

                    }


                    {
                        index >= data?.length || initialData===undefined ? "" :
                            <Row className={'project-btn justify-content-center'}>
                                <div onClick={handleLoadMore}>
                                    <Button text={'Load More'} background={'#897059'} hoverBackground={'#181D24'} color={'#ffffff'}/>
                                </div>
                            </Row>
                    }

                </Container>
            </div>


        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: ${ColorBg};
  
  .filter-section{
    background: ${ColorWhite};
  }
  .filters {
    padding-top: 60px;
    padding-bottom: 60px;
    background: ${ColorWhite};
  }
  .projects{
    padding-top: 60px;
  }

  .project-btn {
    margin-top: 40px;
  }

  .filter {
    &__control {
      background-color: ${ColorWhite};
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid ${hoverNd};
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 14px;
      
      

      &--is-focused {
        background-color: transparent !important;
        border: none;
        outline: none;
        border-bottom: 1px solid #B2A89F;
      }

      &:hover {
        border-bottom: 1px solid #B2A89F;
      }
    }

    &__placeholder {
      color: ${hoverNd};
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }

    &__value-container {
      padding: 10px 0px;
    }

    &__indicator {
      padding: 0;
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__single-value {
      color: #B2A89F;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    &__menu {
      padding: 30px 20px;
    }

    &__option {
      border-bottom: 1px solid rgba(178, 168, 159, 0.2);
      :last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
      }
      :first-child{
        padding-top: 0px;
      }

    }
  }
  .not-found{
    color: ${hoverNd};
  }

  @media (max-width: 767px) {
    .col-md-4 {
      :last-child {
        margin-bottom: -30px;

      }
    }
  }

`
export default MyComponent;