import React, {useEffect} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Button from "../Button";
const Banner = ({data,social}) => {

    const interleaveOffset = 0.5;

    const pagination = document.querySelector('.swiper-pagination-horizontal');
    if (pagination) {
        pagination.classList.add('container');
    } else {
        // console.log('Element with class "swiper-pagination" not found.');
    }

    // handle slider progress
    const handleProgress = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
        }
    };



    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".slide-inner")
                .style.transition = `${speed}ms`;
        }
    };


    // remove extra height from mobile
    useEffect(() => {

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);


        window.addEventListener('load',function () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

        });
        window.addEventListener('resize',function () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

        });

    }, []);
    return (
        <StyledBanner className={'home-banner parallax'}>

            <Container >

                <Row className={'social-icons'}>
                    <Col >
                        <div className={'d-flex align-items-end icons'}>
                            <div className={'icon'}>
                                <a href={social?.facebook} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.57" height="16" viewBox="0 0 8.57 16">
                                        <path id="Path_2112" data-name="Path 2112" d="M1206.8,105.1l.444-2.9h-2.778v-1.879a1.448,1.448,0,0,1,1.632-1.564h1.264V96.295a15.4,15.4,0,0,0-2.242-.2,3.535,3.535,0,0,0-3.784,3.9V102.2h-2.544v2.9h2.544v7h3.13v-7Z" transform="translate(-1198.787 -96.099)" fill="#fffdfb"/>
                                    </svg>
                                </a>
                            </div>
                            <div className={'icon'}>
                                <a href={social?.linkedin} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.738" height="16" viewBox="0 0 16.738 16">
                                        <g id="Group_15872" data-name="Group 15872" transform="translate(14538 -1638)">
                                            <path id="Path_6996" data-name="Path 6996" d="M2.03-.668a1.87,1.87,0,1,0-.047,3.73h.023A1.871,1.871,0,1,0,2.03-.668Zm0,0" transform="translate(-14538 1638.668)" fill="#fffdfb"/>
                                            <path id="Path_6997" data-name="Path 6997" d="M8.109,198.313H11.7v10.8H8.109Zm0,0" transform="translate(-14545.897 1444.891)" fill="#fffdfb"/>
                                            <path id="Path_6998" data-name="Path 6998" d="M228.037,188.625a4.5,4.5,0,0,0-3.234,1.819v-1.566h-3.588v10.8H224.8v-6.029a2.459,2.459,0,0,1,.118-.876,1.964,1.964,0,0,1,1.841-1.312c1.3,0,1.818.99,1.818,2.441v5.775h3.588v-6.19c0-3.316-1.77-4.859-4.131-4.859Zm0,0" transform="translate(-14753.429 1454.326)" fill="#fffdfb"/>
                                        </g>
                                    </svg>
                                </a>

                            </div>
                            <div className={'icon'}>
                                <a href={social?.insta} target={'_blank'}>
                                    <svg id="Group_1419" data-name="Group 1419" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                        <path id="Path_2109" data-name="Path 2109" d="M1095.913,105.945a1,1,0,1,0,1,1A1,1,0,0,0,1095.913,105.945Z" transform="translate(-1082.912 -102.91)" fill="#fffdfb"/>
                                        <path id="Path_2110" data-name="Path 2110" d="M1083.241,108.605a4.187,4.187,0,1,0,4.187,4.187A4.191,4.191,0,0,0,1083.241,108.605Zm0,6.868a2.682,2.682,0,1,1,2.682-2.681A2.684,2.684,0,0,1,1083.241,115.473Z" transform="translate(-1074.672 -104.292)" fill="#fffdfb"/>
                                        <path id="Path_2111" data-name="Path 2111" d="M1081.826,116.628h-6.787a5.113,5.113,0,0,1-5.107-5.106v-6.788a5.112,5.112,0,0,1,5.107-5.106h6.787a5.112,5.112,0,0,1,5.106,5.106v6.788A5.112,5.112,0,0,1,1081.826,116.628Zm-6.787-15.4a3.511,3.511,0,0,0-3.507,3.506v6.788a3.511,3.511,0,0,0,3.507,3.507h6.787a3.511,3.511,0,0,0,3.507-3.507v-6.788a3.511,3.511,0,0,0-3.507-3.506Z" transform="translate(-1069.932 -99.628)" fill="#fffdfb"/>
                                    </svg>
                                </a>

                            </div>
                            <div className={'icon'}>
                                <a href={social?.youtube} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                                        <path id="Path_2114" data-name="Path 2114" d="M1154.075,114.706a4.389,4.389,0,0,0-4.389-4.389h-11.223a4.389,4.389,0,0,0-4.389,4.389v5.223a4.389,4.389,0,0,0,4.389,4.389h11.223a4.389,4.389,0,0,0,4.389-4.389Zm-6.6,3-5.033,2.489c-.2.107-.867-.036-.867-.26v-5.111c0-.227.676-.37.873-.257l4.818,2.621C1147.468,117.306,1147.68,117.6,1147.475,117.709Z" transform="translate(-1134.074 -110.317)" fill="#fffdfb"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'swiper-pagination'}></div>
                    </Col>
                </Row>
            </Container>
            {

                    <Swiper
                        modules={[Autoplay, Pagination, Navigation]}
                        spaceBetween={0}
                        allowSlideNext={true}
                        allowSlidePrev={true}
                        allowTouchMove={false}
                        autoplay
                        speed='1750'
                        onProgress={handleProgress}
                        onSetTransition={handleSetTransition}
                        grabCursor={false}
                        watchSlidesProgress={true}
                        mousewheelControl={true}
                        keyboardControl={true}
                        navigation={true}
                        pagination={{ clickable: true }}
                        className="mySwiper"
                    >
                        {
                            data && data.length>0 &&
                            data.map((element,index)=>{
                                return(
                                    <SwiperSlide key={index}>
                                        <div className="slide-inner">
                                            {
                                                window?.innerWidth>767 ?
                                                    <Img parallax src={element?.Image}/> :
                                                    <Img src={element?.MImage ? element?.MImage : element?.Image}/>
                                            }
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <div className="slide-inner__info d-flex justify-content-center align-items-center">

                                                            {
                                                                element?.Title &&
                                                                <h2>{ReactHtmlParser(element?.Title)}</h2>

                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Container>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
            }

        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  //height: calc(100vh - 100px);
  //height: calc(var(--vh, 1vh) * 100 - 100px);
  
  position: relative;
  overflow: hidden;

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: .5;
    height: 6px;
    padding: 0;
    border-radius: 50%;
    width: 6px;

    &.swiper-pagination-bullet-active {
      width: 8px;
      height: 8px;
      opacity: 1;
    }
  }
  
  //margin-top: 100px;
  .home-banner{
    .container{
      position: relative;
    }
    
  }
  .social-icons{
    position: absolute;
    bottom: 40px;
    .icons{
      margin-left: -15px;
    }
    @media(max-width: 767px){
      .icons{
        margin-left: 0px;
      }
    }
    
    //top: 50%;
    //transform: translateY(-50%);
    z-index: 5;
  }

  .icon{
         padding:  12px 16px;
         margin-right: 20px;
       }

  @keyframes bounce {
    from {
      transition: var(--transition);
      transform: translate3d(0,0,0);
    }

    to {
      transition: var(--transition);
      transform: translate3d(0,10px,0);
    }
  }
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;

  }

  .slide-inner {
    position: relative;
    height: 100vh;
    @media(max-width: 767px){
      height: 95vh;

    }
     
    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: 60%;
      transform: translateY(-50%);
      z-index: 2;

    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;

      h2 {
        font-size: 100px;
        font-weight: 300;
        line-height: 120px;
        color: #FFFFFF;
        position: relative;
        transform: translateY(400px);
        transition: 3s all cubic-bezier(0.4, 0, 0, 1);
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Oregon LDO';
      }

      .dc-btn {
        position: relative;
        transform: translateY(400px);
        transition-delay: 0.5s;
        transition: 3s all cubic-bezier(0.4, 0, 0, 1);

      }
    }
  }

  .swiper-slide-active {
    .slide-inner {
      &__info {
        h2 {
          transform: translateY(0px);
        }

        .dc-btn {
          transform: translateY(0px);
        }
      }

    }
  }
  .swiper-pagination-bullet-active{
    font-size: 30px;
    color: transparent;
    background: transparent !important;
    -moz-appearance: none; /* For Firefox */
   
  }

  .swiper-pagination-bullet{
    display: block !important;
    background-color: white;
    opacity: 1 !important;
  }
  .swiper-pagination-lock {
    display: block !important;
  }
  .swiper{
    position: relative;
  }
  .swiper-pagination {
    display: flex;
    justify-content: end;
    position: absolute;
    left: 0;
    right: 0px;
    bottom: 50px;
    z-index: 1;
  }
  .swiper-pagination-bullet-active{
    outline: auto;
    background-color: transparent;
    opacity: 0;
  }

    //navigation
  .navigation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    display: inline-block;
    z-index: 1;

    ul {
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          path {
            transition: all 0.5s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &:hover {
          svg {
            path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }


  //scroll to next section
  .scrollTo {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 10;
    animation: bounce 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    circle {
      transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
    }

    #Ellipse_400 {
      transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
      fill: ${hover};
      stroke: ${hover}
    }

    &:hover {
      circle {
        stroke: ${hover}
      }

      path {
        stroke: rgba(255, 255, 255, 1);
      }

      #Ellipse_400 {
        r: 19.5px;

      }
    }
  }


  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 80px;
          line-height: 100px;
        }

        .dc-btn {
          a {
            font-size: 22px;
            line-height: 33px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    //border-bottom-right-radius: 30px;
    .icon{
      margin-right: 10px;
    }
    .slide-inner {
      .container {
        top: unset;
        transform: unset;
        bottom: 258px;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 40px;
          line-height: 52px;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      bottom: 30px;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
  }
`;

export default Banner;
