export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',
    HOME: 'page/home',
    ABOUT: 'page/about',
    PROJECTS: 'page/projects',
    RENTS: 'page/rent',
    RENT_DETAIL: 'rent',
    PROJECT_DETAIL: 'project',
    CONTACT: 'page/contact',
    LAUNCH: 'page/new-launching',
    SUSTAINABILITY: 'page/sustainability',
    VENTURE: 'page/joint-venture',
    INVESTOR: 'page/investor-relations',
    CAREER: 'page/career',
    FOOTER: 'page/footer',
    MEDIA: 'page/media-center',
    MEDIA_DETAIL: 'media',
    POLICY: 'page/privacy-policy',
    CAMPAGN: 'page/lead-campaign',
    BROCHURE_FORM: 'contact-form-7/v1/contact-forms/162/feedback',
    CAMPAIGN_FORM: 'contact-form-7/v1/contact-forms/203/feedback',
    CV_DROP_FORM: 'contact-form-7/v1/contact-forms/163/feedback',
    APPLY_FORM: 'contact-form-7/v1/contact-forms/164/feedback',
    CONTACT_FORM: 'contact-form-7/v1/contact-forms/158/feedback',
    RENT_FORM: 'contact-form-7/v1/contact-forms/253/feedback',
    VENTURE_FORM: 'contact-form-7/v1/contact-forms/161/feedback',
    LAUNCH_FORM: 'contact-form-7/v1/contact-forms/1000/feedback',
    PROJECT_CONTACT_FORM: 'contact-form-7/v1/contact-forms/160/feedback',
    SEARCH:'search/all'

};
