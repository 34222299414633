import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Button from "../Button";
import Title from "../Title";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";

const MyComponent = ({title}) => {


    const [state, setState] = useState(title);

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.PROJECT_CONTACT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('number-142', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('project-name', title);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])

    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container >
                <Row>
                    <Col md={{span:5,offset:1}}>
                        <Title text={'Interested? <br/> Get in Touch Today!'} color={'#FFFDFB'}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:10,offset:1}}>
                        <Form>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name",{
                                    required: 'Username is required',


                                })}
                                type="text" placeholder="Full Name"/>
                            <div className="form__phoneEmail">
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone",{
                                        required:{
                                            value:true,
                                            message:'please enter your phone first'
                                        },
                                        pattern:{
                                            value:/^01[0-9]{9}$/,
                                            message:'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number" placeholder="Phone Number*"/>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email",{
                                        required:{
                                            value:true,
                                            message:'please enter your email'
                                        },
                                        pattern:{
                                            value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Email*"/>
                            </div>
                            <Form.Control
                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("message",{
                                    required:{
                                        // value:true,
                                        message:'please enter your email'
                                    },

                                })}
                                type="textarea" placeholder="Message"/>
                            <div onClick={handleSubmit(onSubmit,onError)}>
                                <Button text={'Submit Message'} fontWeight={'700'} background={'#FFFDFB'} hoverBackground={'#181D24'}/>
                            </div>

                        </Form>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #897059;
  .title{
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
  }
  .form-control{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid #B2A89F;
    margin-bottom: 40px;
    color: #FFFDFB;
    padding-left: 0;
    &::placeholder{
      color: #FFFDFB;
    }
  }
  .form__phoneEmail{
    display: flex;
    gap: 60px;
    @media(max-width: 767px){
      flex-direction: column;
      gap: 0;
      input{
        width: 100% !important;
      }
    }
    input{
      width: 49%;
    }
  }
  input[type="textarea"]{
    padding-bottom: 80px;
  }

  .dc-btn{

  }
  input:-webkit-autofill {
    -webkit-text-fill-color: white;
    &:focus{
      border-bottom: 1px solid #B2A89F !important;
    }
    &:hover{
      border-bottom: 1px solid #B2A89F !important;
    }
  }

`;

export default MyComponent;
