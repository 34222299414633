export const ApiParam = {
    project_status:'project_status',
    project_type: 'project_type',
    project_location: "project_location",
    feature_id: "project_feature",
    collection_id: "project_collection",
    keyword : 'query',


    rent_city:'rent_city',
    rent_type: 'rent_type',
    rent_area: "rent_area",
    rent_range: "rent_range",
};
