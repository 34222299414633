import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {Link, useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import {ColorBg, coloredText, hoverNd} from "../../styles/globalStyleVars";
import Button from "../Button";
SwiperCore.use([Autoplay, Pagination, Navigation]);
const SliderShowRoom = ({padding,data,title,textOne,textTwo}) => {

    let containerRef = useRef();
    const ref = useRef(null);
    const location = useLocation();



    // <width control

    const sourceDivRef = useRef(null);
    const targetDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 30;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth }px`);
            document.documentElement.style.setProperty('--target-height', `${adjustedWidth + 280}px`);
            // console.log();
            document.documentElement.style.setProperty('--target-height-right', `${adjustedWidth + 30}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    // offset

    // const [offset, setOffset] = useState(100)
    //
    // useEffect(() => {
    //     setOffset(document.querySelector('.container').offsetLeft)
    //
    //     window.addEventListener('load', function () {
    //         setOffset(document.querySelector('.container').offsetLeft)
    //
    //     })
    //     window.addEventListener('resize', function () {
    //         setOffset(document.querySelector('.container').offsetLeft)
    //
    //     })
    // }, [])

    const [offset, setOffset] = useState(100);

    useEffect(() => {
        function updateOffset() {
            const container = document.querySelector('.fcontainer');
            if (container) {
                const containerOffset = container.offsetLeft;
                if (window.innerWidth > 767) {
                    setOffset(containerOffset);
                } else {
                    setOffset(100); // Reset to the initial offset when window size is <= 767
                }
            }
        }

        window.addEventListener('resize', updateOffset);

        updateOffset(); // Initial offset update

        return () => {
            window.removeEventListener('resize', updateOffset);
        };
    }, []);




    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupId, setPopupId] = useState()
    const handleClose = () => setShow(false);

    const [selectedbodTitle, setSelectedbodTitle] = useState('');
    const [selectedboddesination, setselectedboddesination] = useState('');
    const [selectedbodimg, setselectedbodimg] = useState('');
    const [selectedbodtext, setselectedbodtext] = useState('');
    const handleShow = () => {
        setShow(true)
        setPopupId()
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    return (
        <>


            <StyledBox data-scroll-container offset={offset}
                       className={`showroom slider_component parallax-img horizontal-scroll  ${padding ? padding : 'pb-200'}`} data-speed-right={30} data-speed-left={-30}>

                <Container className={'version_one fcontainer'}>
                    <Row>
                        <Col md={7}>
                            <div className={'title'}>
                                <h2>Our Touch <br/><span> of Excellence </span></h2>
                            </div>
                            {/*<Title text={`Our Touch <span> of Excellence </span>`} fontSize={'100'} lineHeight={'100'} fontWeight={'regular'}/>*/}
                        </Col>
                    </Row>
                    <Row>
                        {/*<Col md={6} ref={sourceDivRef}>*/}
                        {/*    <div className="testimonial__head">*/}
                        {/*          <div className={"arrow__mobile"}>*/}
                        {/*            <div className="testimonial__head__second">*/}
                        {/*                <div className="testimonial__head__navigation">*/}
                        {/*                    <ul>*/}
                        {/*                        <li id={'gallery-prev'}>*/}
                        {/*                            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"*/}
                        {/*                                 viewBox="0 0 52 52">*/}
                        {/*                                <g id="Button_-_Nav_Button" data-name="Button - Nav Button"*/}
                        {/*                                   transform="translate(52 52) rotate(180)">*/}
                        {/*                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"*/}
                        {/*                                            fill="#181D24" stroke=""></circle>*/}
                        {/*                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"*/}
                        {/*                                            fill="#897059"></circle>*/}
                        {/*                                    <g id="Group_15984" data-name="Group 15984"*/}
                        {/*                                       transform="translate(-97 -5894.5)">*/}
                        {/*                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"*/}
                        {/*                                              transform="translate(120.5 5915.5)" fill="none"*/}
                        {/*                                              stroke="#FFFDFB"*/}
                        {/*                                              stroke-linecap="round" stroke-width="2"/>*/}
                        {/*                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"*/}
                        {/*                                              transform="translate(120.5 5920.5)" fill="none"*/}
                        {/*                                              stroke="#FFFDFB"*/}
                        {/*                                              stroke-linecap="round" stroke-width="2"/>*/}
                        {/*                                    </g>*/}
                        {/*                                </g>*/}
                        {/*                            </svg>*/}
                        {/*                        </li>*/}
                        {/*                        <li id={'gallery-next'} >*/}
                        {/*                            <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"*/}
                        {/*                                 xmlns="http://www.w3.org/2000/svg" width="52" height="52"*/}
                        {/*                                 viewBox="0 0 52 52">*/}
                        {/*                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"*/}
                        {/*                                        fill="#181D24" stroke=""></circle>*/}
                        {/*                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"*/}
                        {/*                                        fill="#897059"></circle>*/}
                        {/*                                <g id="Group_15984" data-name="Group 15984"*/}
                        {/*                                   transform="translate(-97 -5894.5)">*/}
                        {/*                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"*/}
                        {/*                                          transform="translate(120.5 5915.5)" fill="none" stroke="#FFFDFB"*/}
                        {/*                                          stroke-linecap="round" stroke-width="2"/>*/}
                        {/*                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"*/}
                        {/*                                          transform="translate(120.5 5920.5)" fill="none" stroke="#FFFDFB"*/}
                        {/*                                          stroke-linecap="round" stroke-width="2"/>*/}
                        {/*                                </g>*/}
                        {/*                            </svg>*/}
                        {/*                        </li>*/}
                        {/*                    </ul>*/}
                        {/*                </div>*/}
                        {/*                <Button text={'Explore All'} src={'/projects'}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                        <Col md={6} className={'slider-position'}>
                            <div className="testimonial__head__second">
                                <div className="testimonial__head__navigation">
                                    <ul>
                                        <li id={'gallery-prev'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                                 viewBox="0 0 52 52">
                                                <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                   transform="translate(52 52) rotate(180)">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                            fill="#181D24" stroke=""></circle>
                                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                            fill="#897059"></circle>
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(-97 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                              transform="translate(120.5 5915.5)" fill="none"
                                                              stroke="#FFFDFB"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                              transform="translate(120.5 5920.5)" fill="none"
                                                              stroke="#FFFDFB"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                        <li id={'gallery-next'} >
                                            <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                 xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                                 viewBox="0 0 52 52">
                                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                        fill="#181D24" stroke=""></circle>
                                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                        fill="#897059"></circle>
                                                <g id="Group_15984" data-name="Group 15984"
                                                   transform="translate(-97 -5894.5)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                          transform="translate(120.5 5915.5)" fill="none" stroke="#FFFDFB"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                          transform="translate(120.5 5920.5)" fill="none" stroke="#FFFDFB"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                                {
                                    // <Button text={'Explore All'} src={'/projects'}/>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className={'p-0'} md={12}>

                    <Swiper
                        slidesPerView={4}
                        spaceBetween={15}
                        speed='500'
                        navigation={{
                            prevEl: '#gallery-prev',
                            nextEl: '#gallery-next',
                        }}
                        // initialSlide={1}
                        loop={false}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 30,

                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 30,

                            },
                            1025: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Autoplay, Pagination, Navigation]}

                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #1</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #2</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #3</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #4</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #5</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #6</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center #7</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}>
                                            <div className="testimonial__single__image video">
                                                <Link to={`/project/1`}>
                                                    <Img src={'/images/dynamic/home/project1.jpg'}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <h3>Sanmar Rahim Center</h3>
                                    <p>Retail Collection</p>
                                </div>
                            </>

                        </SwiperSlide>
                    </Swiper>
                </Container>
                <Container>
                    <Row>
                        <Col md={6} ref={sourceDivRef}>
                            <div className="testimonial__head">
                                <div className={"arrow__mobile"}>
                                    <div className="testimonial__head__second">
                                        <div className="testimonial__head__navigation">
                                            <ul>
                                                <li id={'gallery-prev'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                                         viewBox="0 0 52 52">
                                                        <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                           transform="translate(52 52) rotate(180)">
                                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                    fill="#181D24" stroke=""></circle>
                                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                    fill="#897059"></circle>
                                                            <g id="Group_15984" data-name="Group 15984"
                                                               transform="translate(-97 -5894.5)">
                                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                      transform="translate(120.5 5915.5)" fill="none"
                                                                      stroke="#FFFDFB"
                                                                      stroke-linecap="round" stroke-width="2"/>
                                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                      transform="translate(120.5 5920.5)" fill="none"
                                                                      stroke="#FFFDFB"
                                                                      stroke-linecap="round" stroke-width="2"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </li>
                                                <li id={'gallery-next'} >
                                                    <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                         xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                                         viewBox="0 0 52 52">
                                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                fill="#181D24" stroke=""></circle>
                                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                                fill="#897059"></circle>
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(-97 -5894.5)">
                                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                  transform="translate(120.5 5915.5)" fill="none" stroke="#FFFDFB"
                                                                  stroke-linecap="round" stroke-width="2"/>
                                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                  transform="translate(120.5 5920.5)" fill="none" stroke="#FFFDFB"
                                                                  stroke-linecap="round" stroke-width="2"/>
                                                        </g>
                                                    </svg>
                                                </li>
                                            </ul>
                                        </div>
                                        {/*<Button text={'Explore All'} src={'/projects'}/>*/}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </StyledBox>
        </>
    )

};


const StyledBox = styled.section`
  margin-top: -2px;
  // background-color: ${props => props.background ? props.background : `${ColorBg}`};
  position: relative;
  min-height: 100vh;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 10%;
  //  left: 0;
  //  bottom: 0;
  //  background-color: #3C4348;
  //  @media(max-width: 767px){
  //    height: 35%;
  //  }
  //}
  .title {
    h2 {
      color: ${hoverNd};
      font-size: 100px;
      line-height: 100px;
      margin-bottom: 60px;

      span {
        padding-left: 9%;
      }

      @media (max-width: 991px) {
        font-size: 60px !important;
        line-height: 72px !important;
        margin-bottom: 40px;
        font-weight: 400;
        span {
          padding-left: unset;
        }
      }
    }
  }
  
  
  //.title{
  //  margin-bottom: 60px;
  //  h3{
  //    font-family: Century;
  //  }
  //}
  .bg-text-one, .bg-text-two {
    font-size: 200px;
    line-height: 200px;
    font-weight: 700;
    margin: 0;
    color: #252525;
    opacity: 0.05;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .bg-text-one {
    left: -10%;
    top: 0;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }

  .bg-text-two {
    right: -10%;
    bottom: 55%;

    @media (max-width: 767px) {
      bottom: 72%;
    }
  }
  }



  .slider-position{
    position: absolute;
    bottom: 56%;
    left: 50%;
    z-index: 100;
    
    @media(min-width: 1550px){
      bottom: 59%;

    }
  }
  .mySwiper{
    padding-left: 15px;
    // padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
    height: var(--target-height)!important;;

    @media (max-width: 1024px){
      padding-right: unset;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
  .gallery-text{
    h3,p,svg{
      opacity: 0;
      @media(max-width: 1024px){
        opacity: 1;
      }
    }
  }

  .swiper-slide {
    //width: var(--target-width)!important;
    //width: 320px!important;

    margin-top: auto;
    transition: 0.7s all ease;
    h3{
      font-size: 32px;
      line-height: 40px;
      margin-top: 25px;
    }
    p{
      font-size: 18px;
      line-height: 24px;
    }
    @media(max-width: 1024px){
      margin-top: unset;
    }
  }

  
@media (min-width: 1025px){
  .arrow__mobile{
    display: none;
  }
}
  
 
  .gallery-text__second{
    display: none;
  }
  
 
  

  .swiper-slide-active {
    height: auto;
    //width: 470px !important;
    width: var(--target-width)!important;



    .testimonial__single__image {
      //padding-top: calc(600 / 470 * 100%);
      position: relative;
      //padding-top: calc(100%);
      

      .global-image{
        overflow: hidden;
        img{
          transform: scale(1.06);
        }
      }
      &:hover{
        .global-image img{
          //transform: scale(1.06) !important;
        }
      }
    }
    //.gallery-text{
    //  position: absolute;
    //  z-index: 100;
    //  display: flex;
    //  flex-direction: column;
    //  width: 100%;
    //  //left: var(--target-height-right);
    //  @media(max-width: 1024px){
    //    position: unset;
    //    margin-bottom: 20px;
    //  }
    //}
    .gallery-text{
      
      h3{
        opacity: 1;
        transition: opacity 0.8s ease;
        transition-delay: 0.7s;
        font-family: Ubuntu;
        color: #D05110;
      }
      &__desc{
        display: flex;
        align-items: center;
        gap: 10px;
        svg{
          opacity: 1;
          transition: opacity 0.8s ease;
          transition-delay: 0.7s;
        }
        p{
          opacity: 1;
          transition: opacity 0.8s ease;
          transition-delay: 0.7s;
          font-family: Ubuntu;
          text-transform: capitalize;

        }
      }
      
    }
  }

  .testimonial__head {
    display: flex;
    justify-content: space-between;

    &__text {
        h3{
          span{
            color: #FC671A;
          }
        }
    }
    
    &__second{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dc-btn{
        margin-right: 45px;
      }
    }

    &__navigation {
      ul {
        display: inline-flex;

        li {
          cursor: pointer;

          svg {
            overflow: visible;
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

            }
            #Ellipse_4377 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
            g {
              line {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }

            &:hover {
              #Ellipse_4378 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 0;

              }
              #Ellipse_4377 {
              }

              g {
                line {
                  //stroke: #181D24;
                }
              }
            }
            //&:not(:hover){
            //  #Ellipse_4377 {
            //    r: 26;
            //  }
            //}
          }


          &:first-child {
            margin-right: 20px;
          }




        }
      }
    }
  }

  .testimonial__single {
    &__image {
      position: relative;
      padding-top: calc(600 / 470 * 100%);

      //padding-top: calc(100%) !important;
      a {
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
      }
      
      .react_lightgallery_item {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
      }

      &__icon {
        position: absolute;
        top: -20px;
        right: -25px;
      }
      
    }
    &__position{
      position: relative;

      &__text{
        h3{
          font-size: 24px;
          line-height: 32px;
        }
        p{
          font-size: 18px;
          line-height: 24px;
        }
      }

      
    }
    &__content {
      .text {
        color: #32355D;
        line-height: 36px;
        font-weight: 700;
      }

      .description {
        margin: 40px 0 40px 0;
        color: #32355D;
      }

      .name {
        color: #18AEAE;
        line-height: 24px;
        font-weight: 500;

      }

      .designation {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
  
  .global-image{
    img{
      cursor: pointer;
      transform: none;
    }
  }


  


  
  @media (max-width: 1023px) {

    //padding-bottom: 150px!important;
    //padding-top: 150px!important;
    //min-height: auto;

   

    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    
    .gallery-text{
      //display: none!important;
    }
    .slider-position{
      display: none;
    }
    .arrow__mobile{
      display: block;
      padding-bottom: 40px;
      margin-top: 30px;
    }
    //.swiper-slide-active{
    //  width: unset;
    //}
    .testimonial__head {
      flex-direction: column;
      padding-bottom: unset;
      &__text{
        padding-bottom: 40px;
      }
    }

    .testimonial__single__image__icon {
      position: absolute;
      top: -20px;
      left: 0;
    }

    .testimonial__single {
      &__content {
        margin-top: 20px;

        .description {
          margin: 20px 0 20px 0;
        }
      }
    }

    .swiper-initialized {
      padding-left: 15px;
      padding-right: 15px;
      height: auto !important;
    }


    .swiper-slide-active {
      height: 100%;
      width: inherit !important;

      .testimonial__single__image {
        padding-top: calc(600 / 470 * 100%);
        width: 100%;
        height: 100%;

      }
    }
    .gallery-text__second{
      display: block;
      padding-top: 20px;
      display: flex;
      flex-direction: column-reverse;
    }
    
    .swiper-slide{
      width: inherit!important;
      height:100%;
    }
    .testimonial__head__second{
      justify-content: space-between;
      .dc-btn{
        margin-right: 0 !important;
      }
    }
    .slider-position{
      top:20%;
    }
    .testimonial__single {
      height: unset;
      &__height {
        align-content: flex-end;
        height: 100%;
      }
    }
    
  }

.swiper-button-lock {
  display: block;
}



`;


export default SliderShowRoom;
