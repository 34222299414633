import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";





const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'service-overview pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col md={9} className="service-overview__header">
                        <div className="service-overview__header__bigText">
                            <Title text={data?.title} color={'#897059'} fontWeight={400}
                                   lineHeight={'72'} fontSize={'60'}/>
                        </div>
                    </Col>
                </Row>
                <Row className={'service-overview__body'}>
                    <Col md={6} className="service-overview__body__left">
                        {
                            data?.description_left &&
                            <p className={'para split-up'}>
                                {ReactHtmlParser(data?.description_left)}
                            </p>
                        }

                    </Col>
                    <Col md={6} className="service-overview__body__right">
                        {
                            data?.description_right &&
                            <p className={'para split-up'}>
                                {ReactHtmlParser(data?.description_right)}
                            </p>
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  //background-color: #ffffff;
  position: relative;
  margin-top: -2px;

  .service-overview__header{

    &__bigText{
      .title{
        span{
          color: #ED5333;
        }
      }
      @media (max-width: 767px){
        //h2{
        //  font-size: 24px;
        //  line-height: 32px;
        //  margin-bottom: 40px;
        //  padding-bottom: 0;
        //}

      }
    }
  }

  .service-overview__body{
    margin-top: 60px;
    //margin-bottom: 60px;
    @media(max-width: 767px){
      margin-top: 0px;
      //margin-bottom: 40px;
      p{
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: ${MidNightBlue};

    }
  }




`;

export default MyComponent;
