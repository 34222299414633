import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import TextWithImages from "../../components/new-launching/TextWithImages";
import ImageAndPhone from "../../components/new-launching/ImageAndPhone";
import Gallery from "../../components/new-launching/Gallery";
import ProjectMap from "../../components/project-details/ProjectMap";
import LaunchingForm from "../../components/new-launching/LaunchingForm";
import DescriptionWithImage from "../../components/about/DescriptionWithImage";
import VideoBanner from "../../components/VideoBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchLaunch} from "../../api/redux/new-launching";
import FeatureSlider from "../../components/new-launching/FeatureSlider";
const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.LAUNCH
        dispath(fetchLaunch([api_url]))
    }, [])

    let getPost = useSelector(state => state.launch)

    let banner = getPost?.posts?.page_data?.[0]?.banner_video;
    let location = getPost?.posts?.page_data?.[0]?.location;
    let description = getPost?.posts?.page_data?.[0]?.description;
    let gallery = getPost?.posts?.page_data?.[0]?.gallery;
    let form = getPost?.posts?.page_data?.[0]?.form;
    let register = getPost?.posts?.page_data?.[0]?.register;
    let map = getPost?.posts?.page_data?.[0]?.map;
    let feature = getPost?.posts?.page_data?.[0];


    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    return (
        <StyledComponent>
            {/*<InnerBanner/>*/}
            <VideoBanner img={banner?.image} title={banner?.title} page_name={'New Launch'} vidUrl={banner?.video_id}/>
            <TextWithImages lowImg={location?.lower_image} upImg={location?.upper_image} title1={location?.title1} title2={location?.title2}/>
            <DescriptionWithImage data={description}/>
            <FeatureSlider data={feature}/>
            <ImageAndPhone data={register}/>
            <Gallery data={gallery}/>
            <ProjectMap data={map} />
            <LaunchingForm title={form?.title}/>


        </StyledComponent>
    );
};
const StyledComponent = styled.section`
`
export default MyComponent;