import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import ProjectLists from "../../components/project/ProjectLists";
import InnerBanner from "../../components/InnerBanner";
import {fetchProjects} from "../../api/redux/project";
import {useHistory, useLocation} from "react-router-dom";
import {ApiParam} from "../../api/network/apiParams";
import InnerBannerV2 from "../../components/InnerBannerV2";
import {Loading} from "../../components/Loading";

const MyComponent = () => {

    const dispath = useDispatch()
    const history=useHistory();
    const locations = useLocation();
    const project_status = new URLSearchParams(locations?.search).get("status");
    const project_type = new URLSearchParams(locations?.search).get("type");
    const project_collection = new URLSearchParams(locations?.search).get("collection");
    const location_param = new URLSearchParams(locations?.search).get("location");


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECTS
        if(project_status && project_type){
            let param = {
                [ApiParam.project_status]: project_status,
                [ApiParam.project_type]: project_type,
            }
            dispath(fetchProjects([api_url,param]))
        }
        if(project_collection){
            let param = {
                [ApiParam.collection_id]: project_collection,
            }
            dispath(fetchProjects([api_url,param]))
        }
        if(location_param){
            let param = {
                [ApiParam.project_location]: location_param ,
            }
            dispath(fetchProjects([api_url,param]))
        }
        else{
            dispath(fetchProjects([api_url]))
        }
    }, [project_collection,project_status,project_type])

    //Refactor data
    let getPost = useSelector(state => state.project)
    let banner=getPost?.posts?.[0]?.banner;
    let projectList=getPost?.posts?.[0]?.list;
    let projectFilter=getPost?.posts?.[0]?.filter;
    let meta=getPost?.posts?.[0]?.meta;

    //
    // useEffect(() => {
    //     window.scroll(0, 0)
    // }, [getPost])
    const[fakeLoader,setFakeLoader] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFakeLoader(false)
        }, 600)
    }, [])




    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>



            <StyledComponent>
                <InnerBannerV2 page_name={banner?.page}
                               img={banner?.image}
                               mimg={banner?.mimage} title={banner?.title}
                />
                {/*{*/}
                {/*    banner?.image && banner?.title &&*/}
                {/*    <InnerBanner img={banner?.image} title={banner?.title} page_name={banner?.page}/>*/}
                {/*}*/}
                <ProjectLists data={projectList} filters={projectFilter} fakeLoader={fakeLoader} setFakeLoader={setFakeLoader}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
background: #181D24;
  
`;

export default MyComponent;
