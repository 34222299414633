import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <Container fluid>
                <Row>
                    <Col md={6} className="umbrella__left">
                        <div className="umbrella__left__img">
                            <Img src={data?.image}/>
                        </div>
                    </Col>
                    <Col md={{span:5, offset: 1}} className={'umbrella__right'}>
                        <div className="umbrella__right__desc">
                            <Title text={data?.title} fontSize={'60'} lineHeight={'72'} color={'#FFFDFB'}/>
                            <img src={'images/dynamic/csr/umbrella-logo-1.png'} alt=""/>
                            <div className="umbrella__right__desc__text split-up">
                                <p>{ReactHtmlParser(data?.description)}
                                </p>
                            </div>
                            <Button text={'Visit Website'} color={'#004D81'} fontWeight={'600'} background={'#FFFDFB'} hoverBackground={'#181D24'} hoverColor={'#FFFDFB'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #004D81;
  .container-fluid{
    padding-left: 0;
    padding-right: 0;
  }
  .umbrella__left{
    &__img{
      padding-top: calc(871 / 668 * 100%);
      position: relative;
      height: 100%;
    }
  }
  .umbrella__right{
    padding: 120px 100px 120px 0;
    &__desc{
      .title{
        margin-bottom: 30px;
      }
      &__text{
        margin-top: 60px;
        margin-bottom: 60px;
        p{
          font-size: 16px;
          line-height: 24px;
          color: #FFFDFB;
        }
      }
    }
  }
  
  @media(max-width: 767px){
    .container-fluid{
      padding-left: 15px;
      padding-right: 15px;
    }
    .umbrella__left{
      padding-left: 0;
      padding-right: 0;
    }
    .umbrella__right{
      padding: 60px 15px 60px 15px;
      &__desc{
        &__text{
          margin-bottom: 40px;
        }
      }
    }
  }
`;

export default MyComponent;
