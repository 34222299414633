import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, hoverNd, MidNightBlue, text} from "../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchFooter} from "../api/redux/footer";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({getPost}) => {

    let address=getPost?.posts?.page_data?.[0]?.addresses;
    let files=getPost?.posts?.page_data?.[0]?.files;
    let phones=getPost?.posts?.page_data?.[0]?.phones;
    let social=getPost?.posts?.page_data?.[0]?.social;


    return (
        <StyledComponent className={'footer pt-120 pb-40'}>
            <div className={'shadow'}>
                <img src={'/images/static/footer.svg'}/>
            </div>

            <Container>
                <Row className={'pb-60'}>
                    <Col lg={2} className={'logo'}>
                        <a href={'/'}>
                            <svg id="Sarnmar" xmlns="http://www.w3.org/2000/svg" width="207.88" height="60" viewBox="0 0 207.88 60">
                                <path id="Path_7002" data-name="Path 7002" d="M56.5,47.556a6.017,6.017,0,0,0-1.192-.079H52.92a6.017,6.017,0,0,0-1.192.079c-.238-.715-.477-1.51-.874-2.544q-.6-1.431-1.192-3.1c-.477-1.113-.874-2.146-1.351-3.259s-.874-2.146-1.272-3.18c-.954,0-1.987-.079-2.941-.079h-5.8c-.874,0-1.828,0-2.782.079-.954,2.385-1.828,4.611-2.623,6.757s-1.351,3.9-1.749,5.326a11.74,11.74,0,0,0-1.669-.079,10.671,10.671,0,0,0-1.669.079c2.464-5.326,4.849-10.652,7.234-15.9,2.305-5.247,4.69-10.652,7.075-16.058h.874q1.073,2.623,2.385,5.723l2.623,6.2c.874,2.067,1.749,4.213,2.623,6.28s1.749,3.975,2.464,5.8c.795,1.749,1.51,3.339,2.067,4.77A27.183,27.183,0,0,0,56.5,47.556ZM36.306,33.327c.795,0,1.59,0,2.464.079.874,0,1.669.079,2.464.079h2.544c.874,0,1.669,0,2.544-.079L41.234,21.8Z" transform="translate(-5.701 -3.216)" fill="#897059"/>
                                <path id="Path_7003" data-name="Path 7003" d="M191,47.556a6.017,6.017,0,0,0-1.192-.079H187.42a6.017,6.017,0,0,0-1.192.079c-.238-.715-.477-1.51-.874-2.544q-.6-1.431-1.192-3.1c-.477-1.113-.874-2.146-1.351-3.259s-.874-2.146-1.272-3.18c-.954,0-1.987-.079-2.941-.079h-5.8c-.874,0-1.828,0-2.782.079-.954,2.385-1.828,4.611-2.623,6.757s-1.351,3.9-1.749,5.326a11.74,11.74,0,0,0-1.669-.079,10.671,10.671,0,0,0-1.669.079c2.464-5.326,4.849-10.652,7.234-15.9,2.305-5.247,4.69-10.652,7.075-16.058h.874q1.073,2.623,2.385,5.723l2.623,6.2c.874,2.067,1.749,4.213,2.623,6.28s1.749,3.975,2.464,5.8c.795,1.749,1.51,3.339,2.067,4.77A27.183,27.183,0,0,0,191,47.556ZM170.806,33.327c.795,0,1.59,0,2.464.079.874,0,1.669.079,2.464.079h2.544c.874,0,1.669,0,2.544-.079L175.734,21.8Z" transform="translate(-33.283 -3.216)" fill="#897059"/>
                                <path id="Path_7004" data-name="Path 7004" d="M2.385,43.264a5.462,5.462,0,0,0,1.51,1.51,7.293,7.293,0,0,0,2.067,1.113,7,7,0,0,0,2.623.4,5.875,5.875,0,0,0,2.7-.556A5.378,5.378,0,0,0,13.355,44.3a5.034,5.034,0,0,0,1.272-2.067,6.886,6.886,0,0,0,.477-2.544,4.829,4.829,0,0,0-1.113-3.339,9.024,9.024,0,0,0-2.7-2.067,34,34,0,0,0-3.418-1.51,15.366,15.366,0,0,1-3.418-1.749,10.6,10.6,0,0,1-2.7-2.623,7.688,7.688,0,0,1-.954-4.213,8.338,8.338,0,0,1,.715-3.5,7.472,7.472,0,0,1,1.987-2.7,8.5,8.5,0,0,1,3.1-1.749,13.271,13.271,0,0,1,4.134-.636,11.34,11.34,0,0,1,3.816.556,8.841,8.841,0,0,1,2.941,1.59c-.238.556-.477,1.033-.715,1.669a17.931,17.931,0,0,0-.556,2.226H15.66c-.159-.4-.4-.874-.636-1.431a8.069,8.069,0,0,0-1.033-1.431,8.053,8.053,0,0,0-1.59-1.113,4.851,4.851,0,0,0-2.226-.4,7.494,7.494,0,0,0-2.385.4,4.3,4.3,0,0,0-1.749,1.192A4.74,4.74,0,0,0,4.77,20.608a5.418,5.418,0,0,0-.4,2.305,4.8,4.8,0,0,0,1.113,3.418,9.024,9.024,0,0,0,2.7,2.067,33.995,33.995,0,0,0,3.418,1.51,19.386,19.386,0,0,1,3.418,1.59,8.874,8.874,0,0,1,2.7,2.464,6.814,6.814,0,0,1,1.113,4.054,11.017,11.017,0,0,1-.715,3.975A9.166,9.166,0,0,1,15.9,45.251,9.249,9.249,0,0,1,12.4,47.4a14.182,14.182,0,0,1-4.69.715A12.471,12.471,0,0,1,3.1,47.4,12.283,12.283,0,0,1,0,45.648a12.13,12.13,0,0,0,.556-1.908c.079-.556.238-1.272.318-2.146h.556A8.944,8.944,0,0,0,2.385,43.264Z" transform="translate(0 -3.216)" fill="#897059"/>
                                <path id="Path_7005" data-name="Path 7005" d="M123.485,28.16c2.146,4.054,4.372,8.267,6.757,12.56.318-.556.715-1.431,1.351-2.464.556-1.113,1.272-2.305,1.987-3.736.795-1.431,1.59-2.941,2.464-4.531s1.749-3.259,2.623-4.929,1.749-3.339,2.7-4.929c.874-1.59,1.669-3.1,2.464-4.531h.556c.318,2.941.636,5.723.954,8.506.318,2.7.556,5.406.874,8.029s.636,5.247.874,7.79c.318,2.544.636,5.088,1.033,7.631a4.89,4.89,0,0,0-1.033-.079h-2.226a4.533,4.533,0,0,0-1.033.079c-.318-4.054-.636-7.949-1.033-11.765-.318-3.816-.715-7.711-1.272-11.606h-.079l-6.2,11.447c-1.987,3.816-3.975,7.79-5.8,11.924h-.4c-1.828-3.816-3.736-7.711-5.723-11.526s-3.975-7.79-6.041-11.765h-.079c-.238,1.908-.477,3.975-.715,6.121-.238,2.226-.477,4.372-.715,6.518s-.4,4.134-.556,6.041-.238,3.5-.238,4.69a15.939,15.939,0,0,0-3.18,0c.4-2.464.795-5.088,1.272-7.79.4-2.7.874-5.485,1.272-8.267s.795-5.485,1.113-8.188.636-5.247.874-7.711h.795C119.193,19.893,121.339,24.106,123.485,28.16Z" transform="translate(-22.927 -3.216)" fill="#897059"/>
                                <path id="Path_7006" data-name="Path 7006" d="M84.465,35.732c-3.975-4.213-7.949-8.506-11.844-12.639,0,.874-.079,1.987-.079,3.418s-.079,3.418-.079,5.882v2.385c0,.954,0,1.908.079,2.941,0,1.033,0,2.067.079,3.18,0,1.033.079,2.067.079,2.941s.079,1.749.079,2.385c0,.715.079,1.113.079,1.431a10.135,10.135,0,0,0-1.59-.079,10.671,10.671,0,0,0-1.669.079,13.448,13.448,0,0,0,.238-1.669c.079-.795.159-1.828.238-2.941s.159-2.385.238-3.736.159-2.7.159-4.054c.079-1.351.079-2.623.079-3.9s.079-2.385.079-3.418V20.39c0-2.067-.079-3.657-.079-4.69h.715q2.743,2.981,5.962,6.439c2.146,2.305,4.213,4.531,6.28,6.836,2.067,2.226,4.054,4.372,5.962,6.359s3.5,3.657,4.929,5.088v-6.2c0-3.18,0-6.28-.079-9.3s-.159-5.882-.318-8.585a6.426,6.426,0,0,0,1.59.238,5.344,5.344,0,0,0,1.431-.238c-.079.636-.159,1.669-.238,3.021s-.159,2.782-.238,4.293-.159,3.021-.238,4.452-.079,2.623-.079,3.5v5.962c0,1.113,0,2.226.079,3.418,0,1.113,0,2.226.079,3.18v4.054h-.636C92.176,44.079,88.44,39.945,84.465,35.732Z" transform="translate(-14.273 -3.237)" fill="#897059"/>
                                <path id="Path_7007" data-name="Path 7007" d="M204.018,28.165c0-1.987,0-3.975-.079-5.882s-.159-3.9-.238-5.882c.874.079,1.749.079,2.623.159s1.749.079,2.623.079,1.749,0,2.623-.079,1.749-.079,2.623-.079a18.4,18.4,0,0,1,3.657.4,8.585,8.585,0,0,1,3.021,1.192,5.58,5.58,0,0,1,2.067,2.226,7.263,7.263,0,0,1,.715,3.418,7.057,7.057,0,0,1-.954,3.736,9.412,9.412,0,0,1-2.385,2.623,12.255,12.255,0,0,1-3.021,1.59,12.07,12.07,0,0,1-2.941.715c.4.477.874,1.113,1.51,1.908s1.351,1.749,2.146,2.7,1.59,1.987,2.464,3.1,1.669,2.146,2.464,3.1,1.51,1.908,2.226,2.7,1.272,1.431,1.669,1.908a7.713,7.713,0,0,0-1.351-.079h-2.7a7.713,7.713,0,0,0-1.351.079c-.477-.636-1.113-1.59-2.067-2.862s-1.987-2.623-3.1-4.134c-1.113-1.431-2.305-2.941-3.418-4.372-1.192-1.431-2.226-2.7-3.18-3.657h-1.749v3.339c0,1.987,0,3.975.079,5.962s.159,3.9.238,5.724a31.2,31.2,0,0,0-4.452,0l.238-5.724c.079-1.987.079-3.9.079-5.962V28.165Zm3.816,2.623a7.13,7.13,0,0,0,1.431.159c.477,0,1.033.079,1.669.079a12.8,12.8,0,0,0,2.941-.318,7.865,7.865,0,0,0,2.782-1.113A6.914,6.914,0,0,0,218.8,27.45a6.408,6.408,0,0,0,.874-3.418,6.441,6.441,0,0,0-.636-2.941,4.851,4.851,0,0,0-1.749-1.828,5.917,5.917,0,0,0-2.305-.954,12.132,12.132,0,0,0-2.385-.238,24.792,24.792,0,0,0-2.623.079c-.715.079-1.272.079-1.749.159-.079,1.669-.159,3.339-.159,5.008-.079,1.669-.079,3.259-.079,4.929v2.544Z" transform="translate(-41.773 -3.38)" fill="#897059"/>
                                <path id="Path_7008" data-name="Path 7008" d="M217.482,44.044S269.47-8.024,117.4,4.138c0,0,98.73-15.422,113.437,13.434C236.719,29.1,217.482,44.044,217.482,44.044Z" transform="translate(-24.075 -0.022)" fill="#b2a89f"/>
                                <g id="Group_15920" data-name="Group 15920" transform="translate(89.191 52.607)">
                                    <path id="Path_7009" data-name="Path 7009" d="M112.2,66.4h.874v6.28h3.259v.795h-4.054V66.4Z" transform="translate(-112.2 -66.241)" fill="#897059"/>
                                    <path id="Path_7010" data-name="Path 7010" d="M119.6,66.4h.874v7.075H119.6Z" transform="translate(-113.718 -66.241)" fill="#897059"/>
                                    <path id="Path_7011" data-name="Path 7011" d="M122.6,66.4h.954l2.146,5.8h0l2.226-5.8h.874l-2.782,7.075h-.795Z" transform="translate(-114.333 -66.241)" fill="#897059"/>
                                    <path id="Path_7012" data-name="Path 7012" d="M132.6,66.4h.874v7.075H132.6Z" transform="translate(-116.383 -66.241)" fill="#897059"/>
                                    <path id="Path_7013" data-name="Path 7013" d="M136.9,66.4h1.033l4.054,5.882h0V66.4h.874v7.075h-1.033l-4.054-5.882h0v5.882H136.9Z" transform="translate(-117.265 -66.241)" fill="#897059"/>
                                    <path id="Path_7014" data-name="Path 7014" d="M153.539,72.957a3.512,3.512,0,0,1-1.272.477,7.832,7.832,0,0,1-1.351.159,4.628,4.628,0,0,1-1.51-.238,2.442,2.442,0,0,1-1.192-.795,3.145,3.145,0,0,1-.795-1.192,4.648,4.648,0,0,1-.318-1.51,3.226,3.226,0,0,1,.318-1.51,3.145,3.145,0,0,1,.795-1.192,2.994,2.994,0,0,1,1.192-.715,4.628,4.628,0,0,1,1.51-.238,4.037,4.037,0,0,1,1.351.238,4.744,4.744,0,0,1,1.192.715l-.636.636a3.53,3.53,0,0,0-.874-.636,2.448,2.448,0,0,0-1.033-.238,2.539,2.539,0,0,0-1.113.238,2.255,2.255,0,0,0-.874.636,2.859,2.859,0,0,0-.636.954,2.9,2.9,0,0,0-.238,1.192,3.19,3.19,0,0,0,.238,1.192,2.517,2.517,0,0,0,.636.954,2.859,2.859,0,0,0,.954.636,2.9,2.9,0,0,0,1.192.238,3.261,3.261,0,0,0,.874-.079,2.848,2.848,0,0,0,.874-.318V70.413h-1.59v-.795H153.7v3.339Z" transform="translate(-119.357 -66.2)" fill="#897059"/>
                                    <path id="Path_7015" data-name="Path 7015" d="M163,66.4h.874v7.075H163Z" transform="translate(-122.618 -66.241)" fill="#897059"/>
                                    <path id="Path_7016" data-name="Path 7016" d="M167.3,66.4h1.033l4.054,5.882h0V66.4h.874v7.075h-1.033l-4.054-5.882h0v5.882H167.3Z" transform="translate(-123.499 -66.241)" fill="#897059"/>
                                    <path id="Path_7017" data-name="Path 7017" d="M182.7,66.4h.874v6.28h3.259v.795h-4.054V66.4Z" transform="translate(-126.657 -66.241)" fill="#897059"/>
                                    <path id="Path_7018" data-name="Path 7018" d="M195.306,70.772a5.369,5.369,0,0,1-.159,1.113,1.57,1.57,0,0,1-.477.874,3.112,3.112,0,0,1-.795.636,2.787,2.787,0,0,1-1.272.238,3.3,3.3,0,0,1-1.272-.238,1.661,1.661,0,0,1-.795-.636,1.9,1.9,0,0,1-.477-.874,4.691,4.691,0,0,1-.159-1.113V66.4h.874v4.213a2.45,2.45,0,0,0,.079.795,2.824,2.824,0,0,0,.318.715,1.069,1.069,0,0,0,.556.477,1.572,1.572,0,0,0,.874.159,2.858,2.858,0,0,0,.874-.159,3.457,3.457,0,0,0,.556-.477,2.823,2.823,0,0,0,.318-.715,2.7,2.7,0,0,0,.079-.795V66.4h.874Z" transform="translate(-128.134 -66.241)" fill="#897059"/>
                                    <path id="Path_7019" data-name="Path 7019" d="M201.364,69.739,198.979,66.4h1.113L202,69.182l1.908-2.782h1.033l-2.385,3.339,2.623,3.736h-1.113L202,70.375l-2.067,3.1H198.9Z" transform="translate(-129.98 -66.241)" fill="#897059"/>
                                    <path id="Path_7020" data-name="Path 7020" d="M214.106,70.772a5.368,5.368,0,0,1-.159,1.113,1.57,1.57,0,0,1-.477.874,3.113,3.113,0,0,1-.795.636,2.787,2.787,0,0,1-1.272.238,3.3,3.3,0,0,1-1.272-.238,1.661,1.661,0,0,1-.795-.636,1.9,1.9,0,0,1-.477-.874,4.691,4.691,0,0,1-.159-1.113V66.4h.874v4.213a2.45,2.45,0,0,0,.079.795,2.825,2.825,0,0,0,.318.715,1.069,1.069,0,0,0,.556.477,1.572,1.572,0,0,0,.874.159,2.858,2.858,0,0,0,.874-.159,3.457,3.457,0,0,0,.556-.477,2.822,2.822,0,0,0,.318-.715,2.7,2.7,0,0,0,.079-.795V66.4h.874Z" transform="translate(-131.989 -66.241)" fill="#897059"/>
                                    <path id="Path_7021" data-name="Path 7021" d="M218.679,66.4h1.828a4.533,4.533,0,0,1,1.033.079c.318.079.636.159.874.238a2.143,2.143,0,0,1,.636.556,1.989,1.989,0,0,1,.238,1.033,1.678,1.678,0,0,1-.477,1.272,2.16,2.16,0,0,1-1.272.636l1.987,3.259H222.5l-1.908-3.18h-1.113v3.18H218.6V66.4Zm.874,3.1h1.431a2.321,2.321,0,0,0,.715-.159,1.281,1.281,0,0,0,.556-.318.9.9,0,0,0,.238-.636,1.5,1.5,0,0,0-.159-.636.794.794,0,0,0-.4-.318,1.149,1.149,0,0,0-.556-.159h-1.828Z" transform="translate(-134.019 -66.241)" fill="#897059"/>
                                    <path id="Path_7022" data-name="Path 7022" d="M228.423,70.454,225.8,66.4h1.033l1.987,3.18,2.067-3.18h1.033L229.3,70.454v3.021h-.874Z" transform="translate(-135.496 -66.241)" fill="#897059"/>
                                </g>
                            </svg>
                        </a>
                    </Col>
                    <Col lg={{span:4,offset:2}} className={'address'}>
                        <p>{address?.address_title1}</p>

  <a href={address?.address1_link} target={'_blank'}>
                            <h3>{ReactHtmlParser(address?.address1)}</h3></a>

                    </Col>
                    <Col lg={{span:3,offset:1}} className={'address'}>
                        <p>{address?.address_title2}</p>
                        <a href={address?.address2_link} target={'_blank'}>
                            <h3>{ReactHtmlParser(address?.address2)}</h3></a>

                    </Col>
                </Row>
                <Row className={'pb-60 footer__buttons'}>
                    <Col lg={3} className={'phone'}>
                        {
                            phones?.number && phones?.number?.length>0 &&
                            phones?.number?.map((element)=>{
                                return(
                                    <h4><a href={`tel:${element?.phone_numb}`}>{element?.phone_numb}</a></h4>
                                )
                            })
                        }

                    </Col>
                    {
                        files?.corporate ?
                            <Col lg={{span:2,offset:1}}>
                                <div className={'footer__button hover '}>
                                    <a href={files?.corporate} target={'_blank'} download>Corporate Brochure</a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="19" viewBox="0 0 16.82 19">
                                        <g id="Group_13283" data-name="Group 13283" transform="translate(-1655.59 -7448.5)">
                                            <line id="Line_10" data-name="Line 10" x2="14" transform="translate(1657 7466.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                            <g id="Group_39" data-name="Group 39" transform="translate(0 0.5)">
                                                <g id="Group_7" data-name="Group 7" transform="translate(427.5 7406.5)">
                                                    <line id="Line_1" data-name="Line 1" x2="7" y2="6" transform="translate(1229.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_2" data-name="Line 2" x1="7" y2="6" transform="translate(1236.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" y2="12" transform="translate(1664 7449)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </g>
                                    </svg>

                                </div>

                            </Col> : ''
                    }

                    {
                        files?.presskit ?
                            <Col lg={{span:3,offset:1}} >
                                <a href={files?.presskit} target={'_blank'} download>

                                    <div className={'footer__button second-down hover'}>
                                        <a>Press Kit</a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="19" viewBox="0 0 16.82 19">
                                            <g id="Group_13283" data-name="Group 13283" transform="translate(-1655.59 -7448.5)">
                                                <line id="Line_10" data-name="Line 10" x2="14" transform="translate(1657 7466.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                <g id="Group_39" data-name="Group 39" transform="translate(0 0.5)">
                                                    <g id="Group_7" data-name="Group 7" transform="translate(427.5 7406.5)">
                                                        <line id="Line_1" data-name="Line 1" x2="7" y2="6" transform="translate(1229.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_2" data-name="Line 2" x1="7" y2="6" transform="translate(1236.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" y2="12" transform="translate(1664 7449)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                </a>


                            </Col> : ''
                    }


                </Row>
                <Row className={'pb-60'}>
                    <Col lg={{span:8,offset:4}}>
                        <div className={'footer__bottom'}>
                            <ul>
                                <li>
                                    <Link to={'/'}>Home</Link>
                                </li>
                                <li>
                                    <Link to={'/about'}>About</Link>
                                </li>
                                <li>
                                    <Link to={'/projects'}>Projects</Link>
                                </li>
                                <li>
                                    <Link to={'/joint-venture'}>Joint Venture</Link>
                                </li>
                                <li>
                                    <Link to={'/csr'}>CSR</Link>
                                </li>
                                <li>
                                    <Link to={'/media-center'}>Media Center</Link>
                                </li>
                                <li>
                                    <Link to={'/career'}>Career</Link>
                                </li>
                                <li>
                                    <Link to={'/contact'}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className={'desktop'} lg={3}>
                        <p>© 2023 Sanmar Properties Ltd.</p>
                    </Col>
                    {/*<Col lg={{span:3,offset:1}}>*/}
                    {/*    <p>Designed and Developed by Dcastalia</p>*/}
                    {/*</Col>*/}
                    <Col lg={{span:3,offset:6}}>
                        <div className={'footer__social'}>
                            <ul>
                                <li>
                                    <a href={social?.facebook} target={'_blank'} className={'hover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.942" height="14.827" viewBox="0 0 7.942 14.827">
                                            <path id="Path_2112" data-name="Path 2112"
                                                  d="M1206.208,104.44l.411-2.684h-2.575v-1.741a1.341,1.341,0,0,1,1.513-1.45h1.171V96.28a14.274,14.274,0,0,0-2.078-.181,3.276,3.276,0,0,0-3.506,3.612v2.045h-2.357v2.684h2.357v6.487h2.9V104.44Z"
                                                  transform="translate(-1198.787 -96.099)" fill="#f8f8f8"/>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href={social?.linkedin} target={'_blank'} className={'hover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.738" height="16" viewBox="0 0 16.738 16">
                                            <g id="Group_15872" data-name="Group 15872" transform="translate(14538 -1638)">
                                                <path id="Path_6996" data-name="Path 6996" d="M2.03-.668a1.87,1.87,0,1,0-.047,3.73h.023A1.871,1.871,0,1,0,2.03-.668Zm0,0" transform="translate(-14538 1638.668)" fill="#fffdfb"/>
                                                <path id="Path_6997" data-name="Path 6997" d="M8.109,198.313H11.7v10.8H8.109Zm0,0" transform="translate(-14545.897 1444.891)" fill="#fffdfb"/>
                                                <path id="Path_6998" data-name="Path 6998" d="M228.037,188.625a4.5,4.5,0,0,0-3.234,1.819v-1.566h-3.588v10.8H224.8v-6.029a2.459,2.459,0,0,1,.118-.876,1.964,1.964,0,0,1,1.841-1.312c1.3,0,1.818.99,1.818,2.441v5.775h3.588v-6.19c0-3.316-1.77-4.859-4.131-4.859Zm0,0" transform="translate(-14753.429 1454.326)" fill="#fffdfb"/>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <Link >
                                        <a href={social?.instagram} target={'_blank'} className={'hover'}>
                                            <svg id="Group_1419" data-name="Group 1419" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                                <path id="Path_2109" data-name="Path 2109" d="M1095.913,105.945a1,1,0,1,0,1,1A1,1,0,0,0,1095.913,105.945Z" transform="translate(-1082.912 -102.91)" fill="#fffdfb"/>
                                                <path id="Path_2110" data-name="Path 2110" d="M1083.241,108.605a4.187,4.187,0,1,0,4.187,4.187A4.191,4.191,0,0,0,1083.241,108.605Zm0,6.868a2.682,2.682,0,1,1,2.682-2.681A2.684,2.684,0,0,1,1083.241,115.473Z" transform="translate(-1074.672 -104.292)" fill="#fffdfb"/>
                                                <path id="Path_2111" data-name="Path 2111" d="M1081.826,116.628h-6.787a5.113,5.113,0,0,1-5.107-5.106v-6.788a5.112,5.112,0,0,1,5.107-5.106h6.787a5.112,5.112,0,0,1,5.106,5.106v6.788A5.112,5.112,0,0,1,1081.826,116.628Zm-6.787-15.4a3.511,3.511,0,0,0-3.507,3.506v6.788a3.511,3.511,0,0,0,3.507,3.507h6.787a3.511,3.511,0,0,0,3.507-3.507v-6.788a3.511,3.511,0,0,0-3.507-3.506Z" transform="translate(-1069.932 -99.628)" fill="#fffdfb"/>
                                            </svg>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <a href={social?.youtube} target={'_blank'} className={'hover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                                            <path id="Path_2114" data-name="Path 2114" d="M1154.075,114.706a4.389,4.389,0,0,0-4.389-4.389h-11.223a4.389,4.389,0,0,0-4.389,4.389v5.223a4.389,4.389,0,0,0,4.389,4.389h11.223a4.389,4.389,0,0,0,4.389-4.389Zm-6.6,3-5.033,2.489c-.2.107-.867-.036-.867-.26v-5.111c0-.227.676-.37.873-.257l4.818,2.621C1147.468,117.306,1147.68,117.6,1147.475,117.709Z" transform="translate(-1134.075 -110.317)" fill="#fffdfb"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={3} className={'s-phone'}>
                        <p >© 2023 Sanmar Properties Ltd.</p>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: #FFFDFB;
  position: relative;
  overflow: hidden;

 
  .shadow {
    position: absolute;
    top: 0px;
    right: 0px;
    height: fit-content;
    box-shadow: none !important;
    width: 100%;
  }
  .s-phone{
    display: none;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    color: #181D24;
    &:hover{
      color: #897059;
    }
  }

  p {
    color: #B2A89F;
    &:hover{
      color: #897059;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 32px;

    a {
      color: #897059;
      &:hover{
        color: ${MidNightBlue};
      }
    }
  }
  .footer {
    &__bottom {
      padding: 30px 20px;
      border-top: 1px solid rgba(137, 112, 89, 0.5);
      border-bottom: 1px solid rgba(137, 112, 89, 0.5);
      ul {
        display: flex;
        justify-content: space-between;
        //gap: 30px;
        li{
          //margin-right:30px;

          &:last-child{
            margin-right:0 !important;
          }
          a{
            color: #897059;
          }
        }
      }
    }
    &__social {
      ul {
        width: 100%;
        display: flex;

        li {
          display: flex;
          margin-right: 20px;

          a {
            display: flex;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background-color: ${hoverNd};
            align-items: center;
            justify-content: center;
            color: #ffffff;

            svg {
              position: relative;
              z-index: 2;
            }

            &:hover {
              path {
                fill: #FFFDFB;
              }
            }
          }

          .hover:after {
            background-color: ${hover};
          }

          &:not(:nth-last-of-type(1)) {
            margin-bottom: 20px;
          }
        }
      }
    }   


    &__button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 48px;
      background-color: ${hoverNd};
      border-radius: 28px;
      &.hover:after {
        background-color: ${MidNightBlue};
      }
      
      a{
        margin-right: 10px;
        font-size: 20px;
        line-height: 28px;
        color: #FFFDFB !important;

      }
      &:hover {
        a{
          position: relative;
          color: #FFFDFB !important;
          z-index: 30;
        }
        svg {
          position: relative;
          z-index: 20;
        }
        
      }

    }
  }
  @media(max-width: 1200px){
    .footer{
      &__bottom{
        padding: 30px 10px;
        ul{
          li{
            //margin-right: 20px !important;
          }
        }
      }
    }
  }
  .footer__buttons{
    .second-down{
      //margin-left: 60px;
    }
  }
  @media(min-width: 992px) and (max-width: 1100px){
    .footer__buttons{
      .second-down {
        margin-left: 19% !important;
      }
    }
  }
  @media(min-width: 1101px) and (max-width: 1200px){
    .footer__buttons{
      .second-down {
        margin-left: 10% !important;
      }
    }
  }
  @media(min-width: 768px) and (max-width: 991px){
    .s-phone{
      display: block;
    }
    .offset-md-1{
      margin-left: 0px !important;
    }
    .logo{
      margin-bottom: 30px;
    }
    .phone{
      margin-bottom: 30px;
    }
    h3{
      margin-bottom: 30px;
    }
    .footer{
      &__bottom{
        padding-left: 0px;
        ul {
          li{
            &:not(last-child){
              margin-right:20px;
            }
          }
        }
      }
      &__social{
        margin-top: 30px;
      }
    }
    .footer__button{
      margin-bottom: 20px;
      &.second-down{
        margin-left: 0px;
      }
    }
    .desktop{
      display: none;
    }

  }
  @media(max-width: 767px){
 
    .desktop{
      display: none;
    }
    .s-phone{
      display: block !important;
    }
    .logo{
      margin-bottom: 60px;
    }
    .phone{
      margin-bottom: 30px;
    }
    .address{
      margin-bottom: 30px;
      :last-child{
        margin-bottom: 0px;
      }
    }
    h3{
      margin-bottom: 30px;
      :last-child{
        margin-bottom: 0px;
      }
    }
    h4{
      margin-bottom: 10px;

      :last-child{
        margin-bottom: 0px;
      }
    }
    .footer{
      &__bottom{
        padding-left:0px ;
        ul{
          display: block;
          li{
            :not(last-child){
              margin-bottom: 20px;
            }
          }
        }
      }
      &__social{
        margin-top: 40px;
      }
    }
    .footer__buttons{
      .second-down{
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
  

`
export default MyComponent;