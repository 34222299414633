import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import {Link} from "react-router-dom";
import {ColorBg} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'project-area pt-150 pb-200'}>
            <Container>
                <Row>
                    {
                        data && data.length>0 &&
                        data.map((element,index)=>{
                            return(
                                <Col md={4} key={index}>
                                    <Link to={element?.Route ? `/projects?location=${element?.Route}` : '/projects'}>
                                        <div className={'project-area__img'}>
                                            <Img src={element?.Image}/>
                                            <h3>{element?.Title}</h3>
                                            <p>Explore</p>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }

                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  //background: #181D24;
  background: ${ColorBg};
  

  .project-area{
      &__img{
        position: relative;
        padding-top: calc(370/370 *100%);
        p{
          color: #FFFDFB;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          width: max-content;
          position: absolute;
          top: 75%;
          left: 50%;
          opacity: 0;
          transition: 1.0s ease;
          transform: translate(-50%,-50%);
        }
        .global-image{
          overflow: hidden;
          img{
            transform: scale(1.01);
            transition: 1.0s ease;
          }
        }
        h3{
          color: white;
          font-size: 24px;
          line-height: 32px;
          width: max-content;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        &:hover {
          .global-image img{
            transform: scale(1.10) !important;
          }
          p{
            opacity: 1;
            top: 60%;
          }
        }
      }
    }

  @media(max-width: 767px){
    .col-md-4{
      margin-bottom: 30px !important;
      :last-child {
        margin-bottom: 0px !important;
      }
    }
  }
`
export default MyComponent;