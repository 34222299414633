import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Select, {components} from "react-select";
import Button from "../Button";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ColorBg, ColorWhite, hoverNd} from "../../styles/globalStyleVars";
import ProjectSingle from "../project/ProjectSingle";
import {ApiParam} from "../../api/network/apiParams";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProjects} from "../../api/redux/for-rent";
const MyComponent = ({data,options}) => {


    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(6)
    const initialData = data?.slice( 0, index);

    //Load More
    const handleLoadMore = () => {
        setIndex(index + 6)
        if (index >= data?.length) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }
    }


    let area=options?.project_area;
    let city=options?.project_city;
    let range=options?.project_range;
    let ptype=options?.project_type;
;
    const dispatch = useDispatch();
    let history=useHistory();


    const [selectType, setSelectType] = useState('')
    const [selectCity, setSelectCity] = useState('')
    const [selectArea, setSelectArea] = useState('')
    const [selectRange, setSelectRange] = useState('')

    // api call on filtering

    const handleType = (e) => {
        setSelectType(e?.value);
    }

    const handleCity = (e) => {
        setSelectCity(e.value);
    }

    const handleArea = (e) => {
        setSelectArea(e?.value);
    }

    const handleRange = (e) => {
        setSelectRange(e?.value);
    }
    let api_services = apiEndPoints.RENTS

    // api call
    useEffect(() => {

        let param = {
            [ApiParam.rent_type]: selectType,
            [ApiParam.rent_city]: selectCity,
            [ApiParam.rent_area]: selectArea,
            [ApiParam.rent_range]: selectRange
        }

        dispatch(fetchProjects([api_services,param]))

    }, [selectCity,selectType,selectArea,selectRange,api_services])


    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"

        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#897059` : '#897059',
            backgroundColor: '#FFFDFB',
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            fontWeight: state.isSelected ? 700 : 500,
            "&:hover": {
                backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#181D24',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `#181D24` : '#FFFDFB',
            margin: 0,
            fontSize: 16,
            zIndex:10,
            borderRadius: 0,



        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 16,
            // paddingBottom:10,
        }),
        control:(provided,state)=>({
            ...provided,
            "&:hover": {
                // transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                // backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#FFFDFB',
            },
        })

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#897059" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#897059" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };

    return (
        <StyledComponent className={'pb-200'}>
            <div className={'filter-section'}>
                <Container>
                    <Row className={'filters'}>
                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={e => handleType(e)}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={ptype}
                                placeholder={selectType?selectType:'Type'}
                                defaultValue={selectType}
                            />
                        </Col>

                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={e=>handleCity(e)}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={city}
                                placeholder={selectCity?selectCity:'City'}
                                defaultValue={selectCity}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={e=>handleArea(e)}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={area}
                                placeholder={selectArea ? selectArea : 'Area' }
                                // defaultValue={selectStatus}
                                // placeholder={'Project Location'}
                                defaultValue={selectArea}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                classNamePrefix="filter"
                                onChange={e=>handleRange(e)}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={range}
                                placeholder={selectRange?selectRange : 'Range'}
                                defaultValue={selectRange}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'projects'}>
                <Container>
                    {
                        initialData && initialData?.length ?
                            <Row>
                                {
                                    initialData && initialData?.length>0 &&
                                    initialData?.map((element,index)=>{
                                        return(
                                            <Col md={4} key={index}>
                                                <ProjectSingle img={element?.project_thumb}
                                                               name={element?.post_title}
                                                               location={element?.rent_city}
                                                               type={element?.rent_type}
                                                               link={element?.post_name}
                                                               rent
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row> : <p className={'not-found'}>No data found</p>

                    }

                    {
                        index >= data?.length || initialData===undefined ? "" :
                            <Row className={'project-btn justify-content-center'}>
                                <div onClick={handleLoadMore}>
                                    <Button text={'Load More'} background={'#897059'} hoverBackground={'#181D24'} color={'#ffffff'}/>
                                </div>
                            </Row>

                    }
                </Container>
            </div>


        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: ${ColorBg};
  .filter-section{
    background: ${ColorWhite};
  }
  .filters {
    padding-top: 60px;
    padding-bottom: 60px;
    background: ${ColorWhite};
  }
  .projects{
    padding-top: 60px;
  }

  .project-btn {
    margin-top: 40px;
  }

  .filter {
    &__control {
      background-color: ${ColorWhite};
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid ${hoverNd};
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 14px;


      &--is-focused {
        background-color: transparent !important;
        border: none;
        outline: none;
        border-bottom: 1px solid #B2A89F;
      }

      &:hover {
        border-bottom: 1px solid #B2A89F;
      }
    }

    &__placeholder {
      color: ${hoverNd};
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }

    &__value-container {
      padding: 10px 0px;
    }

    &__indicator {
      padding: 0;
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__single-value {
      color: #B2A89F;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    &__menu {
      padding: 30px 20px;
    }

    &__option {
      border-bottom: 1px solid rgba(178, 168, 159, 0.2);
      :last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
      }
      :first-child{
        padding-top: 0px;
      }

    }
  }
  .not-found{
    color: ${hoverNd};
  }

  @media (max-width: 767px) {
    .col-md-4 {
      :last-child {
        margin-bottom: -30px;

      }
    }
  }

`
export default MyComponent;