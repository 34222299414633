import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {SwiperSlide} from "swiper/react";
import {ColorBg, MidNightBlue} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    const values= data?.value;
    return (
        <StyledComponent className={'core-vale pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="core-value__title">
                            <Title text={data?.title} color={'#897059'} fontSize={'60'} lineHeight={'72'}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} >
                        {
                            values && values.length>0 ?

                        <div className={'core-value__list'}>
                            {
                                values && values.length>0 &&
                                values.map((element,index)=>{
                                    return(
                                        <div className="core-value__list__single" key={index}>
                                            <div className="core-value__list__single__img">
                                                <img src={element?.icon} alt=""/>
                                            </div>

                                            <h5>{ReactHtmlParser(element?.title)}</h5>
                                            <p>{element?.hover_text}</p>
                                        </div>
                                    )

                                })
                            }
                        </div> : ''
                        }
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${ColorBg};
  position: relative;
  margin-top: -2px;

  .core-value {
    &__title {
      margin-bottom: 60px;
    }

    &__list {
      display: flex;
      @media (max-width: 991px) {
        flex-wrap: wrap;
        border: unset;
      }
      &__single{
        overflow: hidden;
        position: relative;
        padding: 60px 0px;
        width: 20%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(24,29,36,0.2);
        border-top: 1px solid rgba(24,29,36,0.2);
        border-bottom: 1px solid rgba(24,29,36,0.2);
        cursor: pointer;
        //border-bottom: none; /* Remove bottom border */
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          transition: all .6s ease;
          background-color: #897059;
        }
        &:hover{
          &:after{
            opacity: 1;
            z-index: 2;
          }
          p{
            top: 10%;
            opacity: 1;
            transform: translateY(0);
            z-index: 3;
            text-align: center;
          }
          &__img{
            opacity: 0;
          }
          h5{
            opacity: 0;
          }
        }
      &__img{
        position: relative;
        height: 60px;
        width: 60px;
        transition: all 0.6s ease;
        img{
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }
      }
        
        
        &:first-child{
          border-left: 1px solid rgba(24,29,36,0.2);
        }
        h5{
          color: #181D24;
          font-size: 28px;
          font-weight: 500;
          line-height: 32px;
          margin-top: 20px;
          transition: all 0.6s ease;
          text-align: center;

        }
        p{
          position: absolute;
          left: 10%;
          top: 10%;
          right: 10%;
          opacity: 0;
          transition: all 0.6s ease;
          //display: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #FFFDFB;
          transform: translateY(100%);
        }

        svg {
          width: 60px;
          height: 60px;
        }

        @media (max-width: 991px) {

        }

        @media (max-width: 767px) {
          width: 50%;
          &:nth-child(odd){
            border-left: 1px solid rgba(24,29,36,0.2);
            border-top: none;
          }
          &:first-child{
            border-top: 1px solid rgba(24,29,36,0.2);
          }
          &:nth-child(even){
            border-left: none;
          }
          &:nth-child(4){
            border-top: none;
          }
        }
        
      }
    }
  }
`;

export default MyComponent;
