import React, {useEffect, useState} from "react";
import Select, {components} from "react-select";
import {ColorBg, ColorWhite, hover, MidNightBlue} from "../../styles/globalStyleVars";
import {BsChevronDown, BsCircleFill, BsX} from "react-icons/bs";
import styled from "styled-components";
import {Accordion, Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import Title from "../Title";
import {Link, Router, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import {useLocation} from "react-router-dom";
import {SwiperSlide} from "swiper/react";
import router from "react-router-dom/es/Router";

const customStyles = {
    dropdownIndicator: (base, state) => ({
        ...base,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen && "rotate(180deg)"
    }),
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? `#897059` : '#897059',
        backgroundColor: '#FFFDFB',
        margin: 0,
        fontSize: 16,
        cursor: 'pointer',
        // paddingLeft: 10,
        // paddingRight: 10,
        fontWeight: state.isSelected ? 700 : 500,
        "&:hover": {
            backgroundColor: `rgba(178, 168, 159, 0.2)`,
            color: '#181D24',
            cursor: 'pointer'
        },

    }), menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? `#181D24` : '#FFFDFB',
        margin: 0,
        fontSize: 16,
        zIndex:10,
        borderRadius: 0,



    }), menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#191818' : '#FFF',
        borderRadius: 0,
        fontWeight: '400',
        color: '#FFF',
        fontSize: 16,
        // paddingBottom:10,
    }),

};

const IndustrialLube = ({queryParam,data,loc}) => {


    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [selectedJobTitle, setSelectedJobTitle] = useState('');

    const handlePopup = (jobTitle) => {
        setSelectedJobTitle(jobTitle);
        setShow(true);
        // Logic for handling the popup
    };
    const [selectedFileName, setSelectedFileName] = useState('');
    const [cv, setCv] = useState(null)

    const handleFileChange = (event) => {
        setCv(event.target.files[0])
        const file = event.target.files[0];
        setSelectedFileName(file.name);
    };

    useEffect(() => {
        let getLists = document.querySelector('.desktop-lists');
        let getLi = getLists.querySelectorAll('li');
        if (typeof (queryParam) === 'undefined') {
            getLi[0]?.classList.add('active')
        } else {
            getLi[0]?.classList.remove('active')
        }
    }, [queryParam])

    const optionsOne = [
        {value: 'Dhaka', label: 'Dhaka'},
        {value: 'Chattogram', label: 'Chattogram'},

    ]
    let handleMobileSelect = (location) => {
        setSelectedLocation(location);
    }

    const [selectedLocation, setSelectedLocation] = useState('');
    const handleLocationClick = (location) => {
        setSelectedLocation(location);
        // history.push(`/career?location=${location}`);
    };
    let firstLocation= loc?.[0]?.label;

    let filterPostBySlug = selectedLocation === '' ? data?.filter(element => element?.office_location === firstLocation) : data?.filter(element => element?.office_location === selectedLocation);




    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };


   //Form submission

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });
    const onSubmit = (e) => {
        let api_services = apiEndPoints.APPLY_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('number-142', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file-971', cv);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedFileName('');
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])

    // let filterPostBySlug = selectedLocation === '' ? data : data?.filter(element => element?.office_location === selectedLocation);




    return (
        <StyledFaq className="job-lists pt-200 pb-200">

            <Container>

                <Row>

                    <Col sm={4} className='job-lists__sidebar'>

                        <div className="job-lists__sidebar__mobile-dropdown">
                            <Select
                                classNamePrefix="filter"
                                onChange={op => handleMobileSelect(op.value)} styles={customStyles}
                                    components={{DropdownIndicator}}
                                    className='select-here files'
                                    placeholder={'Select'}
                                    options={optionsOne}/>
                        </div>


                        <ul className='desktop-lists'>
                            {
                                loc && loc.length>0 &&
                                loc.map((element,index)=>{
                                    return(
                                        <li className={selectedLocation === element?.label ? 'active' : ''} key={index}>
                                            <Link to={'#'} onClick={() => handleLocationClick(element?.label)}>
                                                {element?.label} {selectedLocation === element?.label && <BsCircleFill />}
                                            </Link>
                                        </li>
                                    )

                                })
                            }

                        </ul>
                    </Col>

                    <Col sm={8} className='job-lists__content'>

                        {
                            filterPostBySlug  && filterPostBySlug.length>0 &&
                            filterPostBySlug.map((element,index)=>{
                                return(
                                    <Accordion defaultActiveKey="0">

                                        <Accordion.Item eventKey={`${index}`}>
                                            <Accordion.Header>
                                                {
                                                    element?.job_title &&
                                                    <div className={''}>
                                                        {reactHtmlParser(element?.job_title)}
                                                    </div>
                                                }
                                                <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                                                <g id="Animation_-_Nav" data-name="Animation - Nav" transform="translate(52) rotate(90)">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                            fill="#181D24"></circle>
                                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                            fill="#181D24"></circle>
                                                    <g id="Group_15984" data-name="Group 15984" transform="translate(-97 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5" transform="translate(120.5 5915.5)" fill="none" stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5" transform="translate(120.5 5920.5)" fill="none" stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </g>
                                            </svg>
                                    </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {reactHtmlParser(element?.job_description)}

                                                <div className="button-group d-flex" onClick={() => handlePopup(element?.job_title)}>
                                                    <Button fontSize={'16'} letterSpacing={'0'} fontWeight={'600'}
                                                            text={'Apply'} background={'#897059'} hoverBackground={'#181D24'} color={'#FFFDFB'}/>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                )
                            })
                        }
                    </Col>

                </Row>


            </Container>

            <Modal show={show} className='apply-modal' onHide={handleClose}>
                <Modal.Body>


                    <div className="modal-data">

                        <Col md={{span:10,offset:1}} className='modal-data__content'>
                            <div onClick={handleClose} className="modal-close dc-btn">
                                <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                                    <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(70 70) rotate(180)" fill="transparent" stroke="#FFFDFB" stroke-linecap="round" stroke-width="1">
                                        <circle id={'circle-border'} cx="35" cy="35" r="35" stroke="#181D24" stroke-width="2"/>
                                        <circle id={'hover-color'} cx="35" cy="35" r="34.5" fill="none" stroke-width="0"/>
                                    </g>
                                    <circle id="Ellipse_470" data-name="Ellipse 470" cx="2" cy="2" r="2" transform="translate(37 37) rotate(180)" fill="#181D24" opacity="0"/>
                                    <g id="Group_1433" data-name="Group 1433" transform="translate(-107 -18)">
                                        <line id="Line_4" data-name="Line 4" x2="24.021" transform="translate(133.515 44.515) rotate(45)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3" data-name="Line 3" x2="24.021" transform="translate(150.485 44.5) rotate(135)" fill="none" stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>

                            </div>
                            {selectedJobTitle && <h3>{selectedJobTitle}</h3>}

                            <Form>

                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name",{
                                        required: 'Username is required',

                                    })}
                                    type="text" placeholder="Full Name"/>
                                <div className="form__phoneEmail">
                                    <Form.Control
                                        className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("phone",{
                                            required:{
                                                value:true,
                                                message:'please enter your phone first'
                                            },
                                            pattern:{
                                                value:/^01[0-9]{9}$/,
                                                message:'please enter a valid 11 digit phone number'
                                            }
                                        })}
                                        type="number" placeholder="Phone Number*"/>
                                    <Form.Control
                                        className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("email",{
                                            required:{
                                                value:true,
                                                message:'please enter your email'
                                            },
                                            pattern:{
                                                value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:'please enter a valid email address'
                                            }
                                        })}
                                        type="email" placeholder="Email*"/>
                                </div>
                                <Form.Control
                                    className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("message",{
                                        required:{
                                            // value:true,
                                            message:'please enter your message'
                                        },

                                    })}
                                    type="textarea" placeholder="Message"/>

                                <div className="button-group">
                                    <div className="attachCvName">
                                        <div className="attach-cv">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.268" height="24" viewBox="0 0 26.268 24" style={{ display: selectedFileName ? "none" : "block" }}>
                                                <g id="Group_15866" data-name="Group 15866" transform="translate(-12252 -863)">
                                                    <path id="Path_6994" data-name="Path 6994" d="M129.092,23.561a4.6,4.6,0,0,0-3.3-1.463h-.057a4.7,4.7,0,0,0-3.2,1.384l-.009.008L110.8,35.42a2.822,2.822,0,0,0,0,4.1,2.878,2.878,0,0,0,2.044.892h0a2.9,2.9,0,0,0,2.061-.9l8.11-8.285a1.026,1.026,0,0,0-1.466-1.435l-8.106,8.281a.862.862,0,0,1-.6.283.828.828,0,0,1-.586-.283.791.791,0,0,1,0-1.212l11.718-11.914a2.444,2.444,0,0,1,3.658.058,2.571,2.571,0,0,1,.019,3.809,1.026,1.026,0,1,0,1.458,1.443,4.6,4.6,0,0,0-.019-6.695Z" transform="translate(12147.724 840.902)" fill="#181D24"/>
                                                    <path id="Path_6995" data-name="Path 6995" d="M21.594,71.964a1.026,1.026,0,0,0-1.45.023L10.019,82.444a4.609,4.609,0,0,1-3.266,1.435h0A4.56,4.56,0,0,1,3.49,82.418a4.374,4.374,0,0,1,0-6.476L13.6,65.836a1.026,1.026,0,0,0-1.45-1.451L2.037,74.493l0,0A6.745,6.745,0,0,0,0,79.046a6.537,6.537,0,0,0,2.029,4.814,6.653,6.653,0,0,0,4.721,2.07h0a6.644,6.644,0,0,0,4.728-2.048L21.617,73.414A1.026,1.026,0,0,0,21.594,71.964Z" transform="translate(12252 801.07)" fill="#181D24"/>
                                                </g>
                                            </svg>
                                            <Form.Control
                                                {...register("file", {
                                                    required: 'Please attach a file',
                                                })}
                                                type="file"
                                                accept=".pdf"
                                                id="resume-upload"
                                                style={{ display: "none" }}
                                                onChange={handleFileChange}
                                            />
                                            <Form.Label htmlFor="resume-upload" className="resume-upload-label" style={{ display: selectedFileName ? "none" : "block" }}>
                                                Attach Resume
                                            </Form.Label>
                                            {selectedFileName && (
                                                <div className="file-name">
                                                    {selectedFileName}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div onClick={handleSubmit(onSubmit,onError)}>
                                        <Button text={'Submit'} fontWeight={'700'} background={'#897059'} hoverBackground={'#181D24'} color={'#FFFDFB'}/>
                                    </div>
                                </div>
                            </Form>

                        </Col>


                    </div>
                </Modal.Body>
            </Modal>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  min-height: 70vh;
  background-color: ${ColorBg};
  padding-bottom: 200px;
  font-family: "Oregon LDO";
  margin-top: -2px;
  
  @media(max-width: 767px){
    .modal-data__content{
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
  }
  .career-title {
    margin-bottom: 60px;
  }
  .apply-modal .modal-close svg:hover #hover-color{
    fill: red;
  }
  .desktop-lists{
    li{
      svg{
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }
  }
  .job-lists__sidebar {

    &__mobile-dropdown {
      @media (min-width: 551px) {
        display: none;
      }

      img {
        margin: auto;
      }
    }

    p {
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      width: 80%;
      color: #FFFDFB;
    }

    ul {
      @media (max-width: 550px) {
        display: none;
      }

      li {
        margin-bottom: 10px;

        a {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #B2A89F;

        }

        &.active {
          a {
            color: #897059;

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }
  .dc-btn {
    @media (max-width: 767px){
      //padding-left: 30px;
    }
    //a{
    //  border-radius: 22px;
    //  transition: 3s ease;
    //  background: #ED5333;
    //  &:hover{
    //    color: #F0EDE3;
    //  }
    //}
  }
  .accordion-item {
    margin-bottom: 60px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #897059;
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
      text-transform: capitalize;
      padding-bottom: 15px;
      border-bottom: 1px solid ${hover};
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;
      span{
        svg{
          transform: rotate(180deg);
          #Ellipse_4378{
            r: 0;
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
          #Line_3865{
            stroke: #FFFDFB;
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
          #Line_3866{
            stroke: #FFFDFB;
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
          &:hover{
            #Ellipse_4378{
              r: 26;
            }
            #Line_3865{
              stroke: #181d24;
            }
            #Line_3866{
              stroke: #181d24;
            }
          }
        }
      }

      &.collapsed {
        color: #897059;
        border-color: #897059;

        span {
          //background-color: #FFFDFB;

          svg {
            transform: rotate(0deg);

            #Line_3865{
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              stroke: ${ColorWhite};

            }
            #Line_3866{
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              stroke: ${ColorWhite};
            }
            #Ellipse_4378 {
              r: 26;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

            }

            #Ellipse_4377 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }

            &:hover {

              #Ellipse_4378 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 0;
              }
              #Line_3865{
                stroke: #FFFDFB;
              }
              #Line_3866{
                stroke: #FFFDFB;
              }

              #Ellipse_4377 {
              }
            }
          }

          &:after {
            background-color: ${hover} !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #1C1718;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: ${hover};
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
          // color: ${hover};
          // border-color: ${hover};
      }
    }
  }

  .accordion-body {
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      padding-top: 40px;
      color: ${MidNightBlue};
    }

    ul {
      margin-top: 40px;

      h4 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
        color: #897059;
      }

      li {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;
        color: ${MidNightBlue};

        &:after {
          position: absolute;
          content: '';
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: #B2A89F;
          left: 0px;
          top: 8px;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }

  .button-group {
    margin-top: 45px;
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: ${hover};
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
    margin-top: 2px;
  }


  @media (max-width: 767px) {
    overflow: visible;
    .container {
      overflow: visible;
    }

    .job-lists__sidebar {
      margin-bottom: 50px;

      p {
        width: 100%;
      }
    }
  }
  .filter {
    &__control {
      background-color: ${ColorBg};
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #B2A89F;
      box-shadow: none;
      border-radius: 0;

      &--is-focused {
        background-color: transparent !important;
        border: none;
        outline: none;
        border-bottom: 1px solid #B2A89F;
      }

      &:hover {
        border-bottom: 1px solid #B2A89F;
      }
    }

    &__placeholder {
      color: #B2A89F;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    &__value-container {
      padding: 10px 0px;
    }

    &__indicator {
      padding: 0;
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__single-value {
      color: #B2A89F;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }

    &__menu {
      padding: 30px 20px;
    }

    &__option {
      border-bottom: 1px solid rgba(178, 168, 159, 0.2);
      :last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
      }
      :first-child{
        padding-top: 0px;
      }

    }
  }

`;
export default IndustrialLube;

