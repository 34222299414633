import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {TimelineLite} from "gsap";
import disableScroll from 'disable-scroll';
import {useDispatch, useSelector} from "react-redux";



const Message = ({offset}) => {

    let tl = new TimelineLite();
    let tl2 = new TimelineLite();
    const searchClickRef = useRef()
    const dispatch = useDispatch()

    const Store = useSelector((state) => state);



    // on click search input toggle action --  desktop menu
    useEffect(() => {
        searchClickRef.current.addEventListener('click', (e) => {
            // disableScroll.off()
            if (searchClickRef.current.classList.contains('social-open')) {

                tl.to('.social_list', {
                    duration: .4,
                    height: 0,
                    opacity: 1
                }).to('.social_list', {
                    padding: 0,
                }, '-=.4').to('.social_list', {
                    display: 'none'
                }, '-=.3')
                searchClickRef.current.classList.remove('social-open')
                // document.body.style.overflow = "inherit"

            } else {
                // disableScroll.on();
                tl.to('.social_list', {
                    display: 'block'
                }).to('.social_list', {
                    duration: .4,
                    height: 'auto',
                    opacity: 1
                }, '-=.2').to('.social_list', {
                    padding: '0',
                }, '-=.4')
                searchClickRef.current.classList.add('social-open')
                // document.body.style.overflow = "hidden"
            }

        })

    }, []);


    // disableScroll.on()

    return (
        <StyledMessage data-scroll-section offset={offset} className={`title `}
        >
            <div className={'message_wrapper'}>
                <div id={'click_message'} ref={searchClickRef} className=" social_bar">
                    {/*<div className="overlay_message"></div>*/}
                    <a target={'_blank'} href={'https://wa.me/+8801745666888'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                            <g id="Sticky_Whatsapp" data-name="Sticky Whatsapp" transform="translate(-177 -575)">
                                <circle id="Ellipse_16" data-name="Ellipse 16" cx="26" cy="26" r="26" transform="translate(177 575)" fill="#897059"/>
                                <circle id="Ellipse_17" data-name="Ellipse 17" cx="26" cy="26" r="26" transform="translate(177 575)" fill="#736640"/>
                                <g id="Group_15656" data-name="Group 15656" transform="translate(-8949.978 -392.275)">
                                    <path id="Path_6990" data-name="Path 6990" d="M91.151,104.133a10.173,10.173,0,0,0,4.866,1.239h0a10.183,10.183,0,1,0-8.824-5.1l-1.445,5.277ZM96.024,86.72a8.466,8.466,0,0,1,0,16.933h0a8.45,8.45,0,0,1-4.308-1.18l-.309-.183-3.2.84.855-3.123-.2-.32A8.468,8.468,0,0,1,96.024,86.72Z" transform="translate(9057 898)" fill="#fffdfb"/>
                                    <path id="Path_6991" data-name="Path 6991" d="M169.229,177.914a14.609,14.609,0,0,0,1.45.536,3.487,3.487,0,0,0,1.6.1,2.62,2.62,0,0,0,1.717-1.21,2.128,2.128,0,0,0,.148-1.21c-.064-.106-.233-.17-.488-.3s-1.505-.743-1.738-.828-.4-.127-.572.127-.657.828-.806,1-.3.191-.551.064a6.948,6.948,0,0,1-2.046-1.263,7.667,7.667,0,0,1-1.415-1.763c-.148-.255-.016-.392.112-.519s.254-.3.382-.446a1.739,1.739,0,0,0,.254-.424.469.469,0,0,0-.021-.446c-.064-.127-.572-1.38-.784-1.889s-.416-.429-.572-.437-.318-.009-.488-.009a.935.935,0,0,0-.678.318,2.854,2.854,0,0,0-.89,2.123,4.949,4.949,0,0,0,1.039,2.632A11.341,11.341,0,0,0,169.229,177.914Z" transform="translate(8984.003 819.481)" fill="#fffdfb"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
        </StyledMessage>

    )
};


const StyledMessage = styled.div`
  position: fixed;
  right: 33px;
  bottom: 20px;
  z-index: 990;

  .overlay_message {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    display: none;
  }

  .message_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    li{
      a{
        position: relative;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
        span{
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          //color: #F9F9F9;
          margin-right: 15px;
        }
      }
    }
    .hover {
      height: 82px;
      width: 82px;
      overflow: hidden;
      border-radius: 50% !important;
      //background: #D80028;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      //box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      //border: 1px solid #ffffff;
      transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
      cursor: pointer;
      position: relative;

      svg {
        path, line, #Ellipse_16-3 {
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        }
      }

      a {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 3;
      }

      &:hover {
        //border: 1px solid #1E5C95;
        //background: #D80028;

        svg {
          position: relative;
          z-index: 1;

          path {
            //fill: white;

          }

          #Ellipse_16-3 {
            r: 26px;
          }

          line {
            stroke: white;
          }
        }

        &:after {
          border: none;

        }
      }

      &:after {
        display: none;
      }

      .hover_bar {
        visibility: visible;
        opacity: 1;
        transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        display: block;
        height: 52px;
        width: 52px;
        overflow: hidden;
      }

      .close {
        visibility: hidden;
        opacity: 0;
        transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        display: none;
        #Ellipse_602{
          transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        }
      }

      &.social-open {
        .overlay_message {
          display: block;
        }

        .close {
          visibility: visible;
          opacity: 1;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          display: block;
          &:hover{
            #Ellipse_602{
              r: 20;
            }
          }
        }

        .hover_bar {
          visibility: hidden;
          opacity: 0;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          display: none;

        }

      }
    }
    
    @media(max-width: 767px){
      margin-right: 20px;
    }
  }

  .social_list {
    position: relative;
    height: 0;
    z-index: 0;
    overflow: hidden;
    bottom: 0;
    li{
      a{
        svg{
          #Ellipse_602{
            transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
          }
        }
        &:hover{
          #Ellipse_602{
            r: 20;
          }
        }
      }
    }
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    right: -10px;
    left: unset;
    bottom: 20px;
  }
  
  .hover{
    &:after{
      display: block !important;
    }
  }
`;

// Message.getInitialProps = wrapper.getInitialPageProps(
//     (store) =>
//         async () => {
//
//             let api_settings = ApiServices.SETTINGS
//
//
//             await store.dispatch(fetchDataSettings([api_settings]))
//         }
// );
export default Message;














