import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import CampaignForm from "../../components/Campaign/CampaignForm";
import GiveUsVisit from "../../components/GiveUsVisit";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchCampaign} from "../../api/redux/campaign";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAMPAGN
        dispath(fetchCampaign([api_url]))
    }, [])

    let getPost = useSelector(state => state.campaign)

    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Lead Campaign | Sanmar Properties Ltd</title>
                <meta name="description" content="Sanmar is one of the Top Real Estate Company In Bangladesh. Since 1999 Sanmar successfully handed over many projects in Dhaka and Chattogram"/>

            </Helmet>

            <StyledComponent>
                <CampaignForm title={getPost?.posts?.page_data?.[0]?.form?.title}/>
                <GiveUsVisit data={getPost?.posts?.page_data?.[0]?.give_us_visit} />
            </StyledComponent>
        </HelmetProvider>
    );
};
const StyledComponent = styled.section`
`
export default MyComponent;