import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Button from "../Button";
import Title from "../Title";
import Select, {components} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {useForm,Controller} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";

const MyComponent = () => {

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset,control} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.VENTURE_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('number-142', e?.phone);
        formData.append('land-size', e?.land);
        formData.append('land-address', e?.address);
        formData.append('your-message', e?.message);
        formData.append('menu-938', e?.landType?.value);

        if (e.email !== '' && e.name !== '' && e.phone !== '' && e?.landType?.value!=='' && e?.address!=='' && e?.land!=='') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])







    const landOptions = [
        { value: 'Type 1', label: 'Type 1' },
        { value: 'Type 2', label: 'Type 2' },
        { value: 'Type 3', label: 'Type 3' }
    ];
    const { Control } = components;

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: '1px solid #B2A89F',
            marginBottom: '40px',
            color: '#FFFDFB',
            borderRadius: "0px",
            cursor: 'pointer',
            boxShadow: state.isFocused ? 0 : 0, // Remove the default box shadow
            '&:hover': {
                borderColor: '#B2A89F',
            }
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 'none',
            marginTop: '-40px',
            color: '#FFFDFB',
        }),
        option: (provided,state) => ({
            ...provided,
            color: '#B2A89F',
            backgroundColor: '#FFFDFB',
            // color: state.isSelected ? '#FFFDFB' : '#000000',
            borderBottom: '1px solid #B2A89F',
            cursor: 'pointer',
            fontWeight: state.isSelected ? 700 : 400,
            '&:hover': {
                backgroundColor: '#736640',
                color: '#FFFDFB',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#FFFDFB',
        }),
        indicatorSeparator: () => ({ display: 'none' }), // Hide the separator
    };

        const [selectedLand, setSelectedLand] = useState(null);

        const handleLandChange = (selectedOption) => {
            setSelectedLand(selectedOption);
        };
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container >
                <Row>
                    <Col md={{span: 5, offset:1}}>
                        <Title text={'Want to Form a Great Partnership?'} color={'#FFFDFB'}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 10, offset: 1}}>
                        <Form>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name",{
                                    required: true,
                                })}
                                type="text" placeholder="Full Name*"/>
                            <div className="form__phoneEmail">
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone",{
                                        required:{
                                            value:true,
                                            message:'please enter your phone first'
                                        },
                                        // pattern:{
                                        //     value:/^01[0-9]{9}$/,
                                        //     message:'please enter a valid 11 digit phone number'
                                        // }
                                    })}
                                    type="number" placeholder="Phone Number *"/>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email",{
                                        required:{
                                            value:true,
                                            message:'please enter your email'
                                        },
                                        pattern:{
                                            value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Email *"/>
                            </div>
                            <div className="form__phoneEmail">
                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("land",{
                                        required: true,

                                    })}
                                    type="text" placeholder="Land Measurement *"/>
                                <Controller
                                    control={control}
                                    name="landType"
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix="filter"
                                            options={landOptions}
                                            placeholder="Type of Land"
                                            styles={customStyles}
                                        />
                                    )}
                                />

                            </div>
                            <Form.Control
                                {...register("address",{
                                    required: 'Username is required',

                                })}
                                type="text" placeholder="Registered Land Address *"/>
                            <Form.Control
                                {...register("message",{
                                })}
                                type="textarea" placeholder="Message"/>
                            <div onClick={handleSubmit(onSubmit,onError)}>
                                <Button text={'Submit Message'} fontWeight={'700'} background={'#FFFDFB'} hoverBackground={'#181D24'}/>
                            </div>
                        </Form>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #897059;

  .title {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #B2A89F;
    margin-bottom: 40px;
    color: #FFFDFB;
    padding-left: 0;

    &::placeholder {
      color: #FFFDFB;
    }
  }

  .form__phoneEmail {
    display: flex;
    gap: 60px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0;
      input {
        width: 100% !important;
      }
    }

    input {
      width: 49%;
    }
  }

  input[type="textarea"] {
    padding-bottom: 80px;
  }

  .dc-btn {

  }

  .css-b62m3t-container {
    width: 50%;
    @media(max-width: 767px){
      width: 100%;
    }
  }

  

  .filter__single-value{
    color: #ffffff !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white;
    &:focus{
      border-bottom: 1px solid #B2A89F !important;
    }
    &:hover{
      border-bottom: 1px solid #B2A89F !important;
    }
  }
  .filter__control .css-1fdsijx-ValueContainer{
    padding: 7px 8px !important;
  }


`;

export default MyComponent;
