import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import Banner from "../../components/home/Banner";
import HomeAbout from "../../components/home/HomeAbout";
import GiveUsVisit from "../../components/GiveUsVisit";
import HomeTestimonial from "../../components/home/HomeTestimonial";
import JointVenture from "../../components/home/JointVenture";
import MediaSlider from "../../components/home/MediaSlider";
import {fetchHome} from "../../api/redux/home";
import VideoPopUp from "../../components/VideoPopUp";
import HomeCombinedProjects from "../../components/home/HomeCombinedProjects";
import {gsap} from "gsap";
import {useHistory, useLocation} from "react-router-dom";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";

const Home = () => {

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);

    const dispath = useDispatch()

    //Refactor Data

    let getPost = useSelector(state => state.home)

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHome([api_url]))
    }, [])

    const sliderBanner=getPost?.posts?.page_data?.[0]?.home_slider?.slider;
    const projectArea= getPost?.posts?.page_data?.[0]?.property_search?.slider;
    const homeAbout = getPost?.posts?.page_data?.[0]?.home_about;
    const homeVideo = getPost?.posts?.page_data?.[0]?.home_video;
    const homeTestimonial = getPost?.posts?.page_data?.[0]?.home_testimonial;
    const homeJoint = getPost?.posts?.page_data?.[0]?.home_joint_venture;
    const giveVisit = getPost?.posts?.page_data?.[0]?.visit;
    const social = getPost?.posts?.page_data?.[0]?.home_icons;
    const homeMedia = getPost?.posts?.page_data?.[0];
    const featureTitleDesk = getPost?.posts?.page_data?.[0]?.home_feature?.title_desk;
    const collectionProject = getPost?.posts?.page_data?.[0]?.project?.project_list;
    const filteredCollection=collectionProject?.filter(element=>element?.isFeature=='on')

    const meta = getPost?.posts?.page_data?.[0]?.meta;

    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Sanmer Properties Ltd.' }`}</title>
                <meta name="description" content={meta?.meta_desc ? meta?.meta_desc : 'Sanmar is one of the Top Real Estate Company In Bangladesh. Since 1999 Sanmar successfully handed over many projects in Dhaka and Chattogram.'}/>
                <meta property="og:title" content={meta?.og_title ?meta?.og_title : 'Sanmer Properties Ltd.'}/>
                <meta property="og:description" content={ meta?.og_desc ?meta?.og_desc : 'Sanmar is one of the Top Real Estate Company In Bangladesh. Since 1999 Sanmar successfully handed over many projects in Dhaka and Chattogram.'}/>
            </Helmet>

            <StyledSection>
                <Banner data={sliderBanner} social={social}/>
                <HomeCombinedProjects data={projectArea} title={featureTitleDesk} list={filteredCollection}/>
                <HomeAbout data={homeAbout}/>
                {/*<HomeGallerySlider data={homeGallery}/>*/}
                {
                    homeVideo && homeVideo.title && homeVideo.image && homeVideo.url ?
                        <VideoPopUp vidTitle={homeVideo?.title}
                               vidThumb={homeVideo?.image}
                               vidUrl={homeVideo?.url}/> : ''
                }
                <HomeTestimonial popUrl={homeVideo?.url} data={homeTestimonial}/>
                <JointVenture data={homeJoint}/>
                <MediaSlider data={homeMedia}/>
                <GiveUsVisit data={giveVisit} open/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
