import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Button from "../Button";
import Title from "../Title";
import SimpleBar from "simplebar-react";
import Modal from "react-bootstrap/Modal";
import ModalVideo from "react-modal-video";
import {ColorBg} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'virtual-tour '}>
            <Container>
                <Row>
                    <Col md={12}>
                        {
                            data?.title &&
                            <Title margin={'0 0 25px 0'} center text={data?.title} fontSize={60} lineHeight={72} color={'#897059'}/>
                        }
                    </Col>
                    <Col md={{span:10,offset:1}}>
                        {
                            data?.iframe &&
                            <div className=" virtual-tour__img " >
                                <iframe src={`${data?.iframe}`}
                                        frameBorder="0"
                                        width="100%"
                                        height="620px"
                                        scrolling="no"
                                        allow="vr,gyroscope,accelerometer"
                                        allowFullScreen="true"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        oallowfullscreen="true"
                                        msallowfullscreen="true"/>

                            </div>
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: ${ColorBg};
  margin-top: -2px;
  .virtual-tour__img {
    //padding-top: calc(620 / 970 * 100%);
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .dc-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      //margin: auto;
      //left: 0;
      //right: 0;
    }
  }

  @media (max-width: 767px) {
    .virtual-tour__img {
      iframe{
        height: 220px;
      }
      
    }
    .title {
      margin-bottom: 10px;
    }
  }
`;

export default MyComponent;
