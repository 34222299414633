import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Overview from "../../components/Overview";
import GiveUsVisit from "../../components/GiveUsVisit";
import InnerBanner from "../../components/InnerBanner";
import ContactForm from "../../components/contact/ContactForm";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import {fetchContact} from "../../api/redux/contact";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchContact([api_url]))
    }, [])



    let getPost = useSelector(state => state.contact)
    const contactBanner = getPost?.posts?.page_data?.[0]?.banner;
    const contactOverview = getPost?.posts?.page_data?.[0]?.contact_overview;
    const giveVisit = getPost?.posts?.page_data?.[0]?.visit;
    const meta = getPost?.posts?.page_data?.[0]?.meta;
    //
    //
    // useEffect(() => {
    //     window.scroll(0, 0)
    // }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>

            <StyledComponent>
                <InnerBannerV2 page_name={contactBanner?.page}
                               img={contactBanner?.image}
                               mimg={contactBanner?.mimage} title={contactBanner?.title}
                />
                {/*<InnerBanner img={contactBanner?.image} title={contactBanner?.title} page_name={contactBanner?.page}/>*/}
                <Overview headerText={contactOverview?.title}
                    leftText={contactOverview?.description_left} rightText={contactOverview?.description_right}/>
                <GiveUsVisit data={giveVisit} open/>
                <ContactForm/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
