import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import ButtonRound from "../ButtonRound";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'about parallax'}>
            {
                data && data.title && data.description ?
                    <>
                        {
                            window?.innerWidth>767  ?
                                <div className="">
                                    <Img parallax src={data?.bg ? data?.bg : 'images/static/home-about.jpg'}/>
                                </div> :
                                <div className="">
                                    <Img src={data?.bg ? data?.bg : 'images/static/home-about.jpg'}/>
                                </div>
                        }

                        <Container>
                            <Row>
                                <Col className="about__title">
                                    {
                                        data?.title &&
                                        <h2 className={'split-up'}>
                                            {ReactHtmlParser(data?.title)}
                                        </h2>
                                    }

                                </Col>
                            </Row>
                            <Row>
                                <Col md={{span:8,offset:3}} className={'about__desc '}>
                                    {
                                        data?.description &&
                                        <p className={'split-up'}>{data?.description}</p>
                                    }
                                </Col>
                            </Row>
                            <ButtonRound text={'Read More'} src={'/about'}/>
                        </Container>

                    </> : ''
            }
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //will-change: transform !important;

  position: relative;
  padding-top: 180px;
  
  
  .back-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .about {
    &__title {
      h2 {
        color: white;
        font-size: 100px;
        line-height: 120px;
        margin-bottom: 60px;

        span {
          padding-left: 9%;
        }

        @media (max-width: 767px) {
          font-size: 55px;
          line-height: 72px;
          margin-bottom: 40px;
          span {
            padding-left: unset;
          }
        }
      }
    }

    &__desc {
      margin-bottom: 224px;
      p {
        color: white;
        font-size: 16px;
        line-height: 24px;

      }
      @media(max-width: 767px){
        margin-bottom: 120px;
      }
    }
  }
  .dc-btn{
    position: absolute;
    top: 87%;
    z-index: 99999;
    @media(max-width: 767px){
      top: 90.5%;
    }
  }
  
  @media(max-width: 767px){
    padding-top: 130px;

  }
`;

export default MyComponent;
