import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";


import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";
import {blue, ColorBg, hover} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import Modal from "react-bootstrap/Modal";
import SimpleBar from "simplebar-react";

const MyComponent = ({background, data, id,popUrl}) => {

    const subtitle = data?.subtitle;
    const slide = data?.testimonial;


    // const slide_title = slide?.data?.title;
    // const slide_desc = slide?.data?.description;
    // const slide_img = slide?.images[0]?.full_path;


    const nextRef = useRef()
    const prevRef = useRef()
    const [current, setCurrent] = useState('1')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    // const handleSliderCurrent = () => {
    //     setTimeout(() => {
    //         if (document.querySelector('.sustain .swiper-pagination-current')) {
    //             setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
    //         }
    //     }, 200)
    // }

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handleOpenTest = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };
    return (
        <StyledComponent className={''} popUrl={popUrl}>

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="testimonial-title">
                            {
                                data && data.title ?
                                    <h2 className={'split-up'}>
                                        {ReactHtmlParser(data?.title)}
                                    </h2> : ''
                            }

                        </div>
                    </Col>
                </Row>

                {
                    slide && slide.length>0 ?

                <Swiper
                    Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                    allowTouchMove={true}
                    longSwipesMs={500}
                    slidesPerView={1}
                    pagination={{
                        // el: '.swiper-pagination',
                        type: "fraction",
                    }}
                    navigation={{
                        prevEl: '#gallery-prev1',
                        nextEl: '#gallery-next1',
                    }}
                    //effect={"fade"}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                    }}
                    shouldSwiperUpdate={(prev, next) => {
                        if (prev.activeIndex === next.slides.length - 1) {
                            setButtonDisabled(true);
                        } else {
                            setButtonDisabled(false);
                        }
                    }}
                    loop={true}
                    speed={900}
                    // onSlideChange={(s) => handleSliderCurrent()}
                >
                    {
                        slide && slide.length>0 &&
                        slide.map((element,index)=>{
                            return(
                                <SwiperSlide>

                                    <div className="row testimonials__slider d-flex">

                                        <Col md={{span:4, offset:1}} className="slider-img">
                                            <div className="testimonials__slider__img">
                                                <div className="testmonials__slider__img__wrapper" onClick={() => handleOpenTest(true, element?.video_url)}>
                                                    <Img src={element?.image}/>
                                                </div>
                                                <div className="svg-container" onClick={() => handleOpenTest(true, element?.video_url)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                                        <g id="Group_18204" data-name="Group 18204" transform="translate(17828 -6664.999)">
                                                            <circle id="Ellipse_450" data-name="Ellipse 451" cx="40" cy="40" r="40" transform="translate(-17748 6744.999) rotate(180)" fill="#736640"/>
                                                            <circle id="Ellipse_451" data-name="Ellipse 451" cx="40" cy="40" r="40" transform="translate(-17748 6744.999) rotate(180)" fill="#897059"/>
                                                            <path id="Polygon_1" data-name="Polygon 1" d="M15,0,30,20H0Z" transform="translate(-17775 6689.999) rotate(90)" fill="#fffdfb"/>
                                                        </g>
                                                    </svg>

                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                                    <g id="Group_18200" data-name="Group 18200" transform="translate(-206.342 -4804)">
                                                        <path id="Path_6993" data-name="Path 6993" d="M104.944,101a25.874,25.874,0,0,0,7.059-.948c11.307-3.2,12.525-19.838,2.28-25.909C103.11,67.524,97.273,57.157,97.273,45.517c0-12.581,6.786-30,26.258-44.517h0C91.079,22.29,75.738,44.226,75.738,66.162,75.738,87.774,90.49,101,104.944,101Z" transform="translate(182.811 4803)" fill="#b2a89f"/>
                                                        <path id="Path_6992" data-name="Path 6992" d="M104.944,101a25.874,25.874,0,0,0,7.059-.948c11.307-3.2,12.525-19.838,2.28-25.909C103.11,67.524,97.273,57.157,97.273,45.517c0-12.581,6.786-30,26.258-44.517h0C91.079,22.29,75.738,44.226,75.738,66.162,75.738,87.774,90.49,101,104.944,101Z" transform="translate(130.604 4803)" fill="#b2a89f"/>
                                                    </g>
                                                </svg>


                                            </div>
                                        </Col>

                                        <Col md={{span:6,offset:1}} className={'testimonials__slider__content'}>
                                            {
                                                element?.review &&
                                                <p className={'split-up'}>{element?.review}</p>

                                            }
                                            {
                                                element?.person_name &&
                                                <h6 className={'split-up'}>{element?.person_name}</h6>

                                            }
                                            {
                                                element?.person_designation &&
                                                <p className={'split-up desig'}>{element?.person_designation}</p>
                                            }
                                        </Col>
                                    </div>
                                </SwiperSlide>
                        )
                        })
                    }

                </Swiper> : ''
                }
                <Row className={'slider--nav fade-up'}>
                    <Col sm={{span: 6, offset: 6}}>
                        <ul>
                            <li id={'gallery-prev1'} ref={prevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                     viewBox="0 0 52 52">
                                    <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                       transform="translate(52 52) rotate(180)">
                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                fill="#181D24" stroke=""></circle>
                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                fill="#897059"></circle>
                                        <g id="Group_15984" data-name="Group 15984"
                                           transform="translate(-97 -5894.5)">
                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                  transform="translate(120.5 5915.5)" fill="none"
                                                  stroke="#FFFDFB"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                  transform="translate(120.5 5920.5)" fill="none"
                                                  stroke="#FFFDFB"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li id={'gallery-next1'} ref={nextRef} >
                                <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                     xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                     viewBox="0 0 52 52">
                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                            fill="#181D24" stroke=""></circle>
                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                            fill="#897059"></circle>
                                    <g id="Group_15984" data-name="Group 15984"
                                       transform="translate(-97 -5894.5)">
                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                              transform="translate(120.5 5915.5)" fill="none" stroke="#FFFDFB"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                              transform="translate(120.5 5920.5)" fill="none" stroke="#FFFDFB"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoId}
                                                onClose={handleCloseModal}/>
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section` {
  overflow: hidden;
  margin-top: -5px;
  background-color: ${ColorBg};
  padding-top: ${props => props.popUrl ? '' : '200'}px;
  padding-bottom: 300px;
  @media (max-width: 767px) {
    padding-bottom: 120px;
    padding-top: ${props => props.popUrl ? '' : '120'}px;

  }

  .container {
    position: relative;
  }

  .testimonial-title {
    h2 {
      color: #897059;
      font-size: 100px;
      line-height: 100px;
      margin-bottom: 60px;

      span {
        padding-left: 7%;
      }

      @media (max-width: 767px) {
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 40px;
        span {
          padding-left: unset;
        }
      }
    }
  }

  .testimonials__slider {
    .modal-video {
      //position: relative;
      //z-index: 99999;
    }

    height: 100%;

    .slider-img {
      padding: 0;

      .modal-video {
        position: absolute;

        .modal-video-inner {
          //width: 100% !important;
          //padding: 0 !important;
        }
      }
    }

    &__img {

      margin-right: 50px;
      min-height: 100%;
      padding-top: calc(400 / 400 * 100%);

      &__wrapper {
        position: relative;
        padding-top: calc(400 / 400 * 100%);
        min-height: 100%;
        cursor: pointer;
        //margin-right: 50px;
      }

      svg {
        position: absolute;
        right: -50px;
        top: -50px;
      }

      .svg-container {
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          #Ellipse_451 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

          }

          #Ellipse_450 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }

          &:hover {
            #Ellipse_451 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 0;

            }

            #Ellipse_450 {
            }

          }
        }
      }

    }
  }

  .testimonials__slider__content {
    //padding-bottom: 70px;
    margin-bottom: 146px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 20px;
      margin-right: 20px;
    }
    //padding-left: 50px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #181D24;

      &:first-child {
        margin-bottom: 20px;
      }
    }

    h6 {
      margin-top: 40px;
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #897059;
    }

    .desig {
      color: rgba(178, 168, 159, 0.8);
    }

  }

  ul {
    position: absolute;
    bottom: 0;
    left: 15px;
    z-index: 2;
    display: flex;
    @media (max-width: 767px) {
      //left: 0;
    }

    li {
      cursor: pointer;

      svg {
        overflow: visible;
        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

        }
        #Ellipse_4377 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        }
        g {
          line {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &:hover {
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;

          }
          #Ellipse_4377 {
          }

          g {
            line {
              //stroke: #181D24;
            }
          }
        }
        //&:not(:hover){
        //  #Ellipse_4377 {
        //    r: 26;
        //  }
        //}
      }

    }

    li:first-child {
      padding-right: 20px;
    }
  }
}

  .swiper {
    padding-top: 60px;
    @media(max-width: 767px){
      margin-left: -20px;
      margin-right: -20px;
    }
    //z-index: 0;
  }

  .swiper-wrapper {
    //z-index: 0;
  }

  .swiper-button-lock {
    display: block;
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-pagination {

    display: none;
    position: absolute;
    width: fit-content;
    right: 0;
    left: auto;
    color: #FFFDFB;
    //bottom: 50px;

    font-size: 16px;
    font-weight: 500;

    span {
      color: #FFFDFB;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .slider--nav {
    position: absolute;
    width: 100%;
    bottom: 0px;
    //bottom: 50px;
    //z-index: 99;

  }

  @media (max-width: 767px) {

    .d-flex {
      flex-wrap: wrap;
    }

    .testimonials__slider {
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;

      .col-sm-6 {
        //padding-left: 0;
        min-width: 100%;
        margin-left: 1px;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .testimonials__slider__img {
      margin-right: 0;
      padding-top: calc(343 / 345 * 100%);
      //img{
      //  height: 100% !important;
      //}
      svg {
        height: 78px;
        right: 0px;
        width: 100px;
        top: -40px;
      }
    }

    .testimonials__slider__content {
      margin-top: 40px !important;
    }

    .slider--nav {
      //position: relative;
      //bottom: 0;
      display: block;
      padding: 0 15px;
      margin-top: 16px;
      top: 206px;

      .col-sm-6 {
        min-width: 100%;
        margin: 0;
      }
    }

    .swiper-pagination {
      right: 15px;
      top: 0;
    }

    .swiper {
      //padding-top: 10px;
      padding-bottom: 10px;
    }

    .slider--nav {
      padding: 0;

    }

  }




`;

export default MyComponent;
