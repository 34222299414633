import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";




const MyComponent = ({data,src}) => {
    return (
        <StyledComponent>
            <Row>
                <Col md={12}>
                    <div className="map-image">
                        <a href={data?.url ? data?.url : data?.link} target="_blank">
                            <Img src={data?.image ? data?.image : src}/>
                        </a>
                    </div>
                </Col>
            </Row>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .map-image{
      position: relative;
      padding-top: calc(520 / 1366 * 100%);
    }
`;

export default MyComponent;
