import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import {Img} from "../Img";
import Title from "../Title";
import ButtonRound from "../ButtonRound";
import {CSSPlugin, gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ColorBg} from "../../styles/globalStyleVars";
import {useHistory} from "react-router-dom";

const AboutV17 = ({data}) => {

    const [offset, setOffset] = useState()
    let history=useHistory();

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])

    gsap.registerPlugin(ScrollTrigger, CSSPlugin);

    useEffect(() => {
        if(data?.description){
            gsap.utils.toArray(".joint-parallax-img").forEach((item, i) => {
                let getImg = item?.querySelector('img')
                let parallaxSpeed = item?.getAttribute('data-speed');
                gsap.to(getImg, {
                    yPercent: parallaxSpeed ? parallaxSpeed : 30,
                    ease: "none",
                    scrollTrigger: {
                        trigger: item,
                        // markers: true,
                        scrub: true
                    }
                });
            });
        }

    }, [history?.location?.pathname,data])
    return (
        <StyledParralaxHome offset={offset} background={"#F2F0F0"}
                            className={`about_image_with_text pt-200`}>

                <Container fluid className={'version_one p-0'}>

                    <Row className={'about_image_with_text_wrapper '}>

                        <Col lg={{span:5}} className={'pl-0 pr-0'}>
                            <div className="large-image joint-parallax-img">
                                <Img src={data?.upper_image}/>
                            </div>
                        </Col>
                        <Col lg={{span: 7}} className={'pr-0 pl-0 right_text_content'}>

                            <div className="right_text_content_wrp">
                                <Title margin={'0 0 60px'} text={data?.title} color={'#F5EFE9'}/>
                                {
                                    data?.description &&
                                    <div className={'split-up'}>
                                        <p>{data?.description}</p>
                                    </div>
                                }

                                <ButtonRound text={'Read More'} src={'/joint-venture'}/>
                            </div>

                        </Col>

                    </Row>

                </Container>

            <div className="imageFixed mission-parallax-img">
                <div className="wrapper">
                    <Img src={data?.lower_image}/>
                </div>

            </div>
        </StyledParralaxHome>

    );


};


const StyledParralaxHome = styled.div`
  background: ${ColorBg};
  position: relative;
  display: flex;
  height: 100%;
  margin-top: -5px;


  .imageFixed {
    position: absolute;
    left: 0;
    top: 200px;
    width: 50vh;
    //height: 620px;
    min-height: 100vh;
    z-index: 1;
    @media (min-width: 1500px) and (max-width: 1800px) {
      min-height: calc(100vh - 200px);
    }
    @media (max-width: 1380px) {
      img {
        height: 95%;
      }
    }
    @media (max-width: 1280px) {
      min-height: 50vh;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }

  .about_image_with_text_wrapper {
    //align-items: center;
    .pr-0 {
      padding-right: 0 !important;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      .col-lg-5 {
        padding-left: 0 !important;
      }
    }

  }

  .small_title {
    margin-bottom: 0 !important;
  }

  .version_one {
    padding-left: ${props => props.offset + 30 + 'px !important' || ''};
    position: relative;
    z-index: 2;
    top: -250px;

    .large-image {
      padding-top: calc(680 / 500 * 100%);
      position: relative;
      margin-top: -100px;
      @media (min-width: 1500px) {
        img {
          height: 88%;
        }
      }
    }

    @media (max-width: 991px) {
      top: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
      .large-image {
        margin-top: 0;
      }

      img {
        transform: unset !important;
      }
    }


    .right_text_content {

      position: relative;

      .right_text_content_wrp {
        padding-left: 100px;
        padding-right: ${props => props.offset + 30 + 'px !important' || ''};
        padding-top: 120px;
        padding-bottom: 120px;
        background: #181D24;
        position: relative;
        @media (max-width: 991px) {
          padding-top: 30px;
          padding-bottom: 120px;
          padding-left: 15px !important;
          padding-right: 15px !important;

        }

        p {
          color: rgba(255, 253, 251, 0.8);
        }
      }

      @media (max-width: 991px) {
        //padding-bottom: 120px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        background: #B2A89F;

      }
    }


  }

  @media (max-width: 991px) {
    padding-top: 0px !important;
    .pr-0 {
      padding-right: 15px !important;
    }

    .pl-0 {
      padding-left: 15px !important;
    }
  }

  &:after {
    height: 80vh;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFFDFB;
    //@media(min-width: 1500px){
    //  height: 100vh;
    //}
  }

  .dc-btn {
    position: absolute;
    top: 88%;
    @media (max-width: 991px) {
      top: 86.5%;
    }
    @media (max-width: 767px) {
      top: 89.5%;
    }
    @media (min-width: 1440px) {
      top: 86%;
    }
  }
`;


export default AboutV17;
