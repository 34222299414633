import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

export const SplitUp = () => {
    const location = useLocation();
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);

    let getPost = useSelector(state => state)

    useEffect(() => {
        // ScrollTrigger.refresh();
        // document.fonts.ready.then(() => {

        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent",
                type: "lines",
            })
            const childSplit = new SplitText(item, {
                type: "lines",
                linesClass: "split-child"
            })

            const tl = gsap.timeline()

            gsap.from(parentSplit.lines, {
                duration: 1,
                // delay: .02,
                yPercent: 150,
                ease: 'power4.out',
                stagger: .08,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: '-250',
                    // start: "top top",
                    // end: "+=300",
                    // markers: true
                }
            })
        });
    }, [location.pathname,getPost])


//     useEffect(()=>{
//         const animateText = () => {
//             const text = document.querySelectorAll('.faded');
//             text.forEach((text) => {
//                 const splitText = new SplitText(text, {type: 'lines', linesClass: "split-line"});
//
//
//                 // Initial position and opacity of the characters
//                 gsap.from(splitText.lines, {
//                     y: 80,
//                     opacity: 0,
//                     stagger: 0.03,
//                     duration: 1,
//                     ease: 'power2',
//                     scrollTrigger: {
//                         trigger: text,
//                         start: 'top 70%',
//                         // end: 'top bottom+=200',
//                         toggleActions: 'play none none reverse',
//                     },
//                 });
//             });
//         };
//
// // Call the animateText function to start the animation
//         animateText();
//
//     },[]);
}
// how to use
// 1. Add 'split-up' class on the tag which hold text(p,Title,h1-h6,etc...)