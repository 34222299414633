import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {ColorWhite} from "../../styles/globalStyleVars";
import Button from "../Button";
import {Img} from "../Img";
const AboutPriority = ({url, title,data}) => {

    return (
        <>
            <StyledDetail className='pt-100 pb-100 parallax'>
                <Img parallax src={data?.image} alt={'priority'}/>
                <Container className={'priority parallax-box'}>
                    <Row>
                        <Col lg={{span: 5, offset: 7}} >
                            <div className={'priority__details '}>

                                <ul>
                                    {
                                        data?.list && data?.list?.length>0 &&
                                        data?.list.map((element)=>{
                                            return(
                                                <li>{element?.title}</li>
                                            )
                                        })
                                    }
                                   <Button fontWeight={'700'} background={'#F5EFE9'} hoverBackground={'#181D24'} color={'#897059'} hoverColor={''} src={'/'}
                                            text={'Click for More Details'}/>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </StyledDetail>
        </>

    );
};

const StyledDetail = styled.section`
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .row {
    position: relative;
  }

  .content-wrapper-col {
    position: absolute;
    left: 0;
    z-index: 2;
    top: 20px;
    width: auto;

    .content-wrapper {
      h2 {
        margin: 0 !important;
        padding-bottom: 20px;

        &:after {
          bottom: 0px;
          position: absolute;
          left: 0px;
          width: 100%;
          height: 1px;
          content: '';
          top: unset;
          background: rgba(249, 249, 249, 0.4);
        }


      }

      //.dc-btn {
      //  margin-top: 80px;
      //}
    }

  }

  .priority {
    padding: 60px 30px;

    &__details {
      padding-top: 80px;
      padding-bottom: 180px;
      padding-left: 40px;
      padding-right: 40px;
      background-color: rgba(137, 112, 89, 0.8);
      min-height: 592px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      //justify-content: end;
      -webkit-display: flex;

      .text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: #F9F9F9;
        margin-top: 40px;
        margin-bottom: 80px;
      }

      ul {
        width: 100%;
        li {
          font-size: 24px;
          line-height: 24px;
          font-weight: 500;
          color: ${ColorWhite};
          display: flex;
          position: relative;
          border-bottom: 1px solid ${ColorWhite};
          padding-top: 25px;
          padding-bottom: 15px;
          padding-left: 25px;

          &:last-child {
            margin-bottom: 40px !important;
          }

          &:before {
            margin-right: 15px;
            margin-bottom: -1px;
          }

          &:after {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: ${ColorWhite};
            left: 0px;
            top: 4px;
            margin-top: 25px;
            margin-bottom: 15px;
          }
        }
      }

      .dc-btn {
        position: absolute;
        bottom: 80px;
        //margin-left: -625px;

      }

      p {
        color: white;

        span {
          font-weight: 500;
          font-size: 21px;
          line-height: 26px;
        }
      }

    }

  }
  //responsive
  @media (min-width: 1550px) {
    
    .priority {
      &__details {
        min-height: auto;
        padding-top: 100px;
      }
    }
  }
  @media (max-width: 992px) and (min-width: 768px) {
    .content-wrapper-col {
      .content-wrapper {
        h2 {
          font-size: 50px;
          line-height: 30px;

        }
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 0px !important;
    .content-wrapper-col {
      position: relative;
    }

    .mobile {
      display: block !important;
    }
    

    position: unset;
    img {
      display: none;
      width: 100%;
    }

    .priority {
      padding: 0;

      &__details {
        padding-top: 60px;
        padding-bottom: 150px;
        padding-left:  30px;
        padding-right:  30px;

        .subtitle {
          font-size: 40px;
          line-height: 40px;
          margin-left: 0;
        }

        .subtitle:after {
          position: absolute;
          left: 0;
          width: 100%;
          content: "";
          background: #4F616B;
          height: 1px;
          top: 20px;
          margin-top: 40px;
        }

        .dc-btn {
          position: absolute;
          bottom: 30px;
          margin-left: 0;
        }
      }
    }

    .row {
      .col {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .content-wrapper-col {
      background: #D80028;
      position: relative;
      padding: 40px 0 0 !important;
      top: 0;

      .content-wrapper {
        padding: 0 30px;
      }

      .subtitle {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 0 !important;

        p {
          margin-bottom: 0 !important;
          padding-bottom: 40px;
        }

        &:after {
          margin-top: 0 !important;
        }
      }

      .desktop {
        display: none;

      }

      .dc-btn {

        margin-top: 40px !important;
      }
    }

    .priority__details {
      min-height: auto;

    }
  }




`;

export default AboutPriority;
