import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
// import Swiper from 'react-id-swiper';
import {BsArrowLeftShort, BsArrowRightShort} from "react-icons/bs";
import Title from "../Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Img} from "../Img";

// css
// import "swiper/swiper.css";


const Achievements = ({data}) => {

    const awardSlider= data?.award;
    const nextRefbt = useRef()
    const prevRefbt = useRef()
    const [current, setCurrent] = useState('1')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [offset, setOffset] = useState(90)
    const [state, setState] = useState(false);

    useEffect(() => {
        if(awardSlider?.length>3){
            setState(true)
        }
        else setState(false)
        window.addEventListener('resize', function () {
            setOffset(document?.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document?.querySelector(' .container')?.offsetLeft)
    }, [])


    const containerRef = useRef()
    const sliderRef = useRef()



    return (

        <StyledAchievements className='achievements pt-150 pb-150' state={state}>

            <Container  offset={offset} className='achievements-top'>
                <Row>
                    <Col>
                        <Title margin={'0 0 60px 0'} text={data?.title}/>
                    </Col>

                </Row>
            </Container>

            <Container fluid className=' slider-wrap'>
                <Row>
                    <Col>
                        {
                            awardSlider && awardSlider?.length>0 ?
                                <Swiper ref={sliderRef} Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                                        allowTouchMove={true}
                                        longSwipesMs={500}
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        navigation={{
                                            prevEl: prevRefbt.current,
                                            nextEl: nextRefbt.current,
                                        }}
                                    //effect={"fade"}
                                        onBeforeInit={(swiper) => {
                                            swiper.params.navigation.prevEl = prevRefbt.current;
                                            swiper.params.navigation.nextEl = nextRefbt.current;
                                        }}
                                        shouldSwiperUpdate={(prev, next) => {
                                            if (prev.activeIndex === next.slides.length - 1) {
                                                setButtonDisabled(true);
                                            } else {
                                                setButtonDisabled(false);
                                            }
                                        }}
                                        loop={true}
                                        speed={900}
                                        breakpoints={{
                                            // Define breakpoints
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            992: {
                                                slidesPerView: 2,
                                            },
                                            1200: {
                                                slidesPerView: 3,
                                            },
                                        }} // Apply breakpoints
                                >
                                    {
                                        awardSlider && awardSlider?.length>0 &&
                                        awardSlider?.map((element,index)=>{
                                            return(
                                                <SwiperSlide key={index}>
                                                    <div  className="achievements__single">
                                                        {
                                                            element?.year &&
                                                            <h2 className={'split-up'}>{element?.year}</h2>

                                                        }
                                                        <div className="achievements__single__img">
                                                            <Img
                                                                src={element?.image}
                                                                alt={''}/>
                                                        </div>
                                                        <div className="achievements__single__content">
                                                            {
                                                                element?.title &&
                                                                <h5 className={'split-up'}>{element?.title}</h5>

                                                            }
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper> : ''
                        }
                    </Col>
                </Row>
            </Container>
        </StyledAchievements>
    );
};

const StyledAchievements = styled.section`
background-color: #FFFDFB;
  overflow: hidden;
  .achievements-top {
    position: relative;
    
    .title{
      h2{
        color: #181D24;
        span{
          color:#897059;
        }
      }
    }
  }
  .swiper{
    .swiper-button-prev, .swiper-button-next {
      display: none;
      opacity: 0 !important;
    }
  }

    ul {
      display: flex;
      justify-content: right;
      position: absolute;
      right: 15px;
      bottom: 64px;
      gap: 20px;

      .go-right {
        z-index: 2;
        
        cursor: pointer;
        svg {
          overflow: visible;
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

          }
          #Ellipse_4377 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
          g {
            line {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 0;

            }
            #Ellipse_4377 {
            }

            g {
              line {
                //stroke: #181D24;
              }
            }
          }
          //&:not(:hover){
          //  #Ellipse_4377 {
          //    r: 26;
          //  }
          //}
        }
        
      }

      .go-left {
        border-radius: 50%;
        
        cursor: pointer;

        svg {
          overflow: visible;
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

          }
          #Ellipse_4377 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }
          g {
            line {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 0;

            }
            #Ellipse_4377 {
            }

            g {
              line {
                //stroke: #181D24;
              }
            }
          }
          //&:not(:hover){
          //  #Ellipse_4377 {
          //    r: 26;
          //  }
          //}
        }
      }
    }
  }


  .achievements__single {
    position: relative;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 5;
      bottom: 0;
    }

    h2 {
      font-size: 100px;
      font-weight: 600;
      line-height: 95px;
      position: absolute;
      top: -60px;
      right: 30px;
      z-index: 2;
      -webkit-text-stroke: 1px #181D24;
      -webkit-text-fill-color: transparent;
      transition: color 0.8s ease, z-index 0.8s ease;
      font-family: "Oregon LDO";
    }

    &__img {
      position: relative;
      padding-top: calc(370 / 370 * 100%);
      overflow: hidden;

      img {
        //position: absolute;
        //height: 100%;
        //width: 100%;
        //top: 0;
        //object-fit: cover;
        ////filter: grayscale(100%);
        transition: all 1s ease;
        transform: scale(1.01);
        transform-origin: right top;
        z-index: 3;
      }


    }

    &__content {
      margin-top: 20px;

      h5 {
        font-size: 28px;
        font-weight: 500;
        margin: 0;
        line-height: 32px;
        color: #181D24;
      }


    }

    &:hover {
      .achievements__single__img {
        img {
          transform: scale(1.04);
          //filter: grayscale(0);
        }
      }
    }
  }
  .slider-wrap{
    padding-left: 120px;
    @media(max-width: 991px){
      padding-left: 60px;
      margin-top: 80px;
      
    }
    @media(max-width: 767px){
    padding-left: 15px;
      margin-top: -10px;
    }
  }

  .swiper-initialized {
    padding-top: 70px;
    position: relative;
    padding-right: 120px;
    @media (max-width: 767px) {
      padding-right: 0 ;
      width: 100%;
    }
    //padding-right: 0px;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 80%;
      background-color:${props => props.state ? '#897059' : 'transparent'}; 
      top: 0;
      bottom: 20px;
      margin: auto;
      //left: 0;
      right: 0;
    }

    .swiper-slide-active {
      h2 {
        color: #897059;
        -webkit-text-fill-color: #897059;
        -webkit-text-stroke: 1px #897059;
        z-index: 5;
      }
      h5{
        color: #897059;
      }

      .achievements__single__img {
        img {
          //filter: grayscale(0);
        }
      }


    }
  }

  @media (min-width: 1499px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  
  @media (max-width: 768px) {
    .achievements-top {
      ul {
        right: unset;
        bottom: unset;
      }

      .col {
        flex: 0 0 100%;
      }
    }


  

`;

export default Achievements;
