import {useEffect, useLayoutEffect, useRef} from "react";
import {gsap} from "gsap";
import {useLocation} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useSelector} from "react-redux";

export const Parallax = () => {
    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger);
    let getPost = useSelector(state => state)

    useEffect(() => {
        let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

        gsap.utils.toArray(".parallax").forEach((section, i) => {
            section.bg = section.querySelector(".parallax-bg");
            let parallaxSpeed = section.getAttribute('data-speed');


            gsap.fromTo(section.bg, {
                backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px",
            }, {
                backgroundPosition: () => `50% ${parallaxSpeed ? parallaxSpeed + 'px' : '150px'}`,
                ease: "none",
                scrollTrigger: {
                    trigger: section,
                    start: () => i ? "top bottom" : "top top",
                    end: "bottom top",
                    scrub: true,
                    invalidateOnRefresh: true // to make it responsive
                }
            });


        });
    }, [location.pathname, getPost])
}


// how to use
// 1. Add 'parallax' class on the section. Add 'data-speed={speed string/number}' for parallax speed (if needed)
// 2. Add 'parallax' props on Img component.


export const ParallaxImg = () => {
    const location = useLocation();
    let getPost = useSelector(state => state)

    useEffect(() => {
        gsap.utils.toArray(".parallax-img").forEach((item, i) => {
            let getImg = item.querySelector('img')
            let parallaxSpeed = item.getAttribute('data-speed');
            gsap.to(getImg, {
                yPercent: parallaxSpeed ? parallaxSpeed : 15,
                ease: "none",
                scrollTrigger: {
                    trigger: item,
                    // markers: true,
                    scrub: true
                }
            });
        });
    }, [location.pathname, getPost])

}

// parallax item
export const ParallaxItem = () => {
    const location = useLocation();
    let getPost = useSelector(state => state)

    useEffect(() => {
        gsap.utils.toArray(".parallax-item").forEach((item, i) => {
            let parallaxSpeed = item.getAttribute('data-speed');
            gsap.to(item, {
                y: parallaxSpeed ? parallaxSpeed : 150,
                ease: "none",
                scrollTrigger: {
                    trigger: item,
                    // markers: true,
                    scrub: true
                }
            });
        });
    }, [location.pathname, getPost])


}