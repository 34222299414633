import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProjectDetail} from "../../api/redux/for-rent";
import RentOverview from "../../components/rental/RentOverview";
import FeatureSlider from "../../components/project-details/FeatureSlider";
import ProjectGallery from "../../components/project-details/ProjectGallery";
import ProjectMap from "../../components/project-details/ProjectMap";
import ProjectForm from "../../components/project-details/ProjectForm";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBannerV2 from "../../components/InnerBannerV2";
import RentForm from "../../components/rental/RentForm";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.rent)
    let {slug} = useParams();



    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.RENT_DETAIL
        dispatch(fetchProjectDetail([api_url + `/${slug}`]))
    }, [])

    const banner = getData?.detail?.[0]?.banner;
    const overview = getData?.detail?.[0]?.overview;
    const feature = getData?.detail?.[0]?.feature;
    const gallery = getData?.detail?.[0]?.gallery;
    const map = getData?.detail?.[0]?.map;
    const meta = getData?.detail?.[0]?.meta;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>
            <StyledComponent>
                <InnerBannerV2 page_name={''}
                               img={banner?.image}
                               mimg={banner?.mimage}
                               title={banner?.title}
                               logo={banner?.logo}
                               project_type={banner?.project_type}
                               project_location={banner?.project_location}
                               details_address={banner?.address}
                               detail
                />
                <RentOverview title={banner?.title} data={overview}/>
                <FeatureSlider data={feature}/>
                <ProjectGallery data={gallery}/>
                <ProjectMap data={map}/>
                <RentForm title={banner?.title}/>

            </StyledComponent>
        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
`
export default MyComponent;