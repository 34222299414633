import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import PrivacyPolicyDetails from "../../components/privacy-policy/PrivacyPolicyDetails";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPolicy} from "../../api/redux/privacy";
import InnerBannerV2 from "../../components/InnerBannerV2";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.POLICY
        dispath(fetchPolicy([api_url]))
    }, [])

    let getPost = useSelector(state => state.policy)

    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Privacy Policy | Sanmar Properties Ltd</title>
                <meta name="description" content="Sanmar is one of the Top Real Estate Company In Bangladesh. Since 1999 Sanmar successfully handed over many projects in Dhaka and Chattogram"/>

            </Helmet>

            <StyledComponent>
                <InnerBannerV2 img={getPost?.posts?.page_data?.[0]?.banner?.image} title={getPost?.posts?.page_data?.[0]?.banner?.title} page_name={'Privacy Policy'}/>
                <PrivacyPolicyDetails data={getPost?.posts?.page_data?.[0]?.overview}/>
            </StyledComponent>
        </HelmetProvider>
    );
};
const StyledComponent = styled.section`
`
export default MyComponent;