import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import {Img} from "../Img";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import ReactHtmlParser from "react-html-parser";
import {ImgP} from "../ImgP";

const Vision = ({reverse,firstImage,secondImage,title,text}) => {


    return (
        <StyledTextWithImage reverse={reverse} className={`about_image_with_text`}>
            <Container className={'version_one'}>
                <Row className={` ${reverse ? 'reverse about_image_with_text_wrapper pt-200 pb-200' : 'about_image_with_text_wrapper pt-200 pb-150'}`}>
                    <Col md={reverse ? { span: 5, offset: 3 } : 5 }>
                        <Title text={title} fontSize={'60'} lineHeight={'72'} color={'#FFFDFB'}/>
                        <div className="fade-up">
                            <p>{ReactHtmlParser(text)}</p>
                        </div>
                    </Col>
                    <Col md={reverse ? 4 : { span: 4, offset: 3 }}>
                        <div className="image_wrapper_whole">

                                    <div className="image_left ">
                                        <ImgP src={firstImage}/>
                                    </div>
                                    <div className="image_right parallax-img">
                                        <Img src={secondImage}/>
                                    </div>
                        </div>

                    </Col>
                </Row>

            </Container>
        </StyledTextWithImage>

    );


};


const StyledTextWithImage = styled.div`
  background-color: #181D24;
  .reverse{
    flex-direction: row-reverse;
    
  }
  .reverse .image_wrapper_whole{
    .image_right{
      right: -180px;
      bottom: -90px;
      left: unset !important;
    }
  }


  .version_one {
    .small_title {
      margin-bottom: 0 !important;
      @media (max-width: 767px) {
        margin-bottom: 60px !important;

      }
    }
    

    .image_wrapper_whole {
      position: relative;

      .image_left {
        padding-top: calc(520 / 400 * 100%);
        position: relative;
        z-index: 1;
        bottom: 0;
        width: calc(100% + ${props => props.offset + "px" || '0'});
          // height:calc(100% + ${props => props.offset + "px" || '0'} ) ;
        right: -15px;
        @media (max-width: 767px) {
          //bottom: -50px;
          right: 0px;
          top: -50px;

        }
      }

      .image_right {
        padding-top: calc(320 / 400 * 100%);
        position: absolute;
        left: -120px;
        bottom: -90px;
        //right: 0;
        width: calc(100% + ${props => props.offset + "px" || '0'});
        z-index: 0;
        width: 100%;
        @media (max-width: 767px) {
          left: -15px;
          bottom: 50px;
          width: calc(100% - 80px);
        }
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    .reverse{
      .image_wrapper_whole{
        .image_right{
          @media (max-width: 767px) {
            right: -15px;
            bottom: 50px;
            width: calc(100% - 80px);
          }
        }
      }
    }

  }

  .about_image_with_text_wrapper {
    p {
      margin-top: 60px;
      color: #FFFDFB !important;
      &:not(:nth-last-child(1)) {
        margin-bottom: 20px;
      }

    }
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      padding-bottom: 0;
    }
  }

  


  @media (max-width: 767px) {
    .col-md-5{
      margin-top: 80px;
    }
    .pr-0 {
      padding-right: 15px !important;
    }

    .pl-0 {
      padding-left: 15px !important;
    }
  }

`;


export default Vision;
