import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import 'simplebar-react/dist/simplebar.min.css';
import {BsChevronDown, BsPlus, BsX} from "react-icons/bs";
import Accordion from 'react-bootstrap/Accordion';
import {ColorWhite, hover, hoverNd, MidNightBlue} from "../styles/globalStyleVars";
import {Link, useHistory, useLocation} from "react-router-dom";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchProjects} from "../api/redux/project";
import {ApiParam} from "../api/network/apiParams";
import disableScroll from 'disable-scroll';

gsap.registerPlugin(CSSPlugin);

const Menu = ({getPost}) => {

    const [offset, setOffset] = useState()
    const [projectType, setProjectType] = useState();

    const dispath = useDispatch()
    let location=useLocation();

    let menu=getPost?.posts?.page_data?.[0]?.menu;

    //
    // // api call
    // useEffect(() => {
    //     let param = {
    //         [ApiParam.project_type]: projectType,
    //     }
    //     let api_url = apiEndPoints.PROJECTS
    //     dispath(fetchProjects([api_url,param]))
    //
    // }, [location?.pathname,projectType])
    //
    //
    // let getPost = useSelector(state => state.project)
    // let presskit = getPost?.posts?.[0]?.presskit

    const key = 'label';

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isAccordionOpenResidential, setIsAccordionOpenResidential] = useState(false);
    const [isAccordionOpenCollection, setIsAccordionOpenCollection] = useState(false);
    const [isAccordionOpenCommercial, setIsAccordionOpenCommercial] = useState(false);


    const handleAccordionClickCollection = () => {
        // Toggle the isAccordionOpen state when the accordion is clicked
        setIsAccordionOpenCollection(!isAccordionOpenCollection);
        setIsAccordionOpenResidential(false);
        setIsAccordionOpenCommercial(false);

    };

    const handleAccordionClickResidential = () => {
        setTimeout(() => {
            setIsAccordionOpenResidential(!isAccordionOpenResidential);
        }, 500); // 1000 milliseconds (1 second) delay

        setIsAccordionOpenCollection(false);
        setIsAccordionOpenCommercial(false);
    };
    const handleAccordionClickCommercial = () => {
        // Toggle the isAccordionOpen state when the accordion is clicked
        setTimeout(() => {
            setIsAccordionOpenCommercial(!isAccordionOpenCommercial);
        }, 300); // 1000 milliseconds (1 second) delay
        setIsAccordionOpenCollection(false);
        setIsAccordionOpenResidential(false);
    };
    const handleAccordionClickRent = () => {
        // Toggle the isAccordionOpen state when the accordion is clicked
        setIsAccordionOpenCommercial(!isAccordionOpen);
        setIsAccordionOpenCollection(false);
        setIsAccordionOpenResidential(false);
        setIsAccordionOpenCommercial(false);
    };



    //Refactor data

    // let projectList=getPost?.posts?.[0]?.list;
    // let collectionFilter=getPost?.posts?.[0]?.filter?.project_collection;
    //
    // let residential=projectList?.filter(element=>element?.project_type==="Residential")
    // let commercial=projectList?.filter(element=>element?.project_type==="Commercial")
    // let rent=projectList?.filter(element=>element?.isForRent==="on")

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])
    let [newClass, setNewClass] = useState(false);
    const hamburgerRef = useRef(null);
    const menuItemRef = useRef(null);
    const menuCloseRef = useRef(null);
    const openMenuClickRefMain = useRef([]);
    const closeScrollMenuRefMain = useRef([]);
    const containerRef = useRef(null);

    useEffect(() => {

        disableScroll.off()

    }, [location?.pathname]);
    useEffect(() => {

        let getOffset = containerRef && containerRef.current.offsetLeft;

        setOffset(getOffset);


        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass(true)
            } else {
                setNewClass(false)
            }
        });


        hamburgerRef.current.addEventListener('click', (e) => {
            e.preventDefault()

            if (!menuItemRef.current.classList.contains('menu-open')) {
                menuItemRef.current.classList.add('menu-open')
                disableScroll.on()

            }
            else {
                menuItemRef.current.classList.remove('menu-open')
                disableScroll.off()

            }

            setTimeout(() => {
                menuItemRef.current.classList.add('hover-state')
            }, 1000)

            const accordionItems = document.querySelectorAll('.main-menu-desktop__items-wrap__items__single .accordion-item');

            accordionItems.forEach(item => {
                item.style.opacity = '1';
            });
        })

        menuCloseRef.current.addEventListener('click', () => {
            menuItemRef.current.classList.remove('menu-open', 'hover-state');

            disableScroll.off()

            const accordionItems = document.querySelectorAll('.main-menu-desktop__items-wrap__items__single .accordion-item');

            const delayBetweenItems = 50; // Adjust the delay in milliseconds

            accordionItems.forEach((item, index) => {
                setTimeout(() => {
                    item.style.opacity = '0';
                }, index * delayBetweenItems);
            });

            document.querySelector('body').style.cssText = `
                height: 10000px;
                // overflow:auto;
                overscroll-behavior: none;
                scroll-behavior: auto;
            `
        })

        openMenuClickRefMain.current.forEach((el, index) => {
            el.addEventListener('click', () => {
                el.offsetParent.classList.add('open-scroll-menu')
            })
        })

        closeScrollMenuRefMain.current.forEach((el, index) => {
            el.addEventListener('click', () => {
                el.offsetParent.classList.remove('open-scroll-menu')
            })
        });


    }, [])


    useEffect(() => {
        let getAllATag = document.querySelectorAll('.main-menu-desktop__items-wrap__items a');

        getAllATag.forEach(el => {
            el.addEventListener('click', () => {
                menuItemRef.current.classList.remove('menu-open', 'hover-state');
                document.querySelector('body').style.cssText = `
                height: auto;
                overflow:auto;
                            `
            })

        })

    })

    const searchItemRef = useRef();
    const searchClickRef = useRef();

    // search api action
    const history=useHistory();

    const handleSubmitSearch = (e) => {
        e.preventDefault()
        history.push({
            pathname: '/search',
            search: `?query=${searchInput}`
        });

    }

    useEffect(() => {

        // search desktop
        searchClickRef?.current?.addEventListener("click", () => {
            if (!searchItemRef?.current?.classList?.contains("search-open")) {
                searchItemRef?.current?.classList?.add("search-open");
                gsap.to(searchItemRef?.current, {
                    display: "flex",
                });
                gsap.to(searchItemRef?.current, {
                    duration: 0.5,
                    height: 195,
                    opacity: 1,
                    overflow: "visible",
                });
                gsap.to(searchItemRef?.current?.querySelector(".container"), {
                    opacity: 1,
                    delay: 0.2,
                });
            } else {
                searchItemRef?.current?.classList?.remove("search-open");
                gsap.to(searchItemRef?.current, {
                    duration: 0.4,
                    height: 0,
                    opacity: 0,
                    overflow: "hidden",
                });
                gsap.to(
                    searchItemRef?.current?.querySelector(".container"),
                    {
                        opacity: 0,
                    },
                    "-=.4"
                );
                gsap.to(searchItemRef?.current, {
                    display: "none",
                });
            }
        });

        // on click hide search . should be reduce code later
        document.querySelector('.search-desktop svg')?.addEventListener('click', () => {
            searchItemRef?.current?.classList?.remove('search-open')
            gsap.to(searchItemRef?.current, {
                duration: .4, height: 0, opacity: 0, overflow: 'hidden'
            })
            gsap.to(searchItemRef?.current?.querySelector('.container'), {
                opacity: 0,
            }, '-=.4')
            gsap.to(searchItemRef?.current, {
                display: 'none'
            })
            setSearchInput('')
        })
        document.querySelector('.search-desktop__menu')?.addEventListener('click', () => {
            searchItemRef?.current?.classList?.remove('search-open')
            gsap.to(searchItemRef?.current, {
                duration: .4, height: 0, opacity: 0, overflow: 'hidden'
            })
            gsap.to(searchItemRef?.current?.querySelector('.container'), {
                opacity: 0,
            }, '-=.4')
            gsap.to(searchItemRef?.current, {
                display: 'none'
            })
            setSearchInput('')
        })
    }, []);


    let tl = new TimelineLite();
    let tl2 = new TimelineLite();


    // search handle
    const [searchInput, setSearchInput] = useState('')
    const [scrollUp, setScrollUp] = useState(false)
    const [scrollDown, setScrollDown] = useState(false)


// menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }
    });
    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;
        let howMuchScroll = 150;
        if (window.screen.width < 991) {
            howMuchScroll = 80;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener("scroll", () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (
                currentScroll < lastScroll &&
                body.classList.contains(scrollDown)
            ) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);





    // on click search input toggle action --  desktop menu
    useEffect(() => {
        let searchInput = document.querySelector('.search-form');
        let searchFormTimeline = gsap.timeline(); // Create a timeline specifically for search form animations



    }, []);

    // // search api action
    // const [searchKewWord, setSearchKeyword] = useState('')
    // const handleSubmitSearch = (e) => {
    //     e.preventDefault()
    //     Router.push({
    //         pathname: '/search', query: {keyword: searchKewWord}
    //     })
    // }

    const searchSubmit = (e) => {
        e.preventDefault()
        history.push({
            pathname: '/search',
            search: `?query=${searchInput}`
        });

        setSearchInput('')

        document.querySelector('.search-desktop').classList.remove('search-open')
        gsap.to(searchItemRef.current, {
            duration: .4, height: 0, opacity: 0, overflow: 'hidden'
        })
        gsap.to(searchItemRef.current.querySelector('.container'), {
            opacity: 0,
        }, '-=.4')
        gsap.to(searchItemRef.current, {
            display: 'none'
        })
    }

    useEffect(() => {
        document.querySelector('.search-desktop').classList.remove('search-open')
        gsap.to(searchItemRef.current, {
            duration: .4, height: 0, opacity: 0, overflow: 'hidden'
        })
        gsap.to(searchItemRef.current.querySelector('.container'), {
            opacity: 0,
        }, '-=.4')
        gsap.to(searchItemRef.current, {
            display: 'none'
        })

    }, [location?.pathname]);


    const handleInput = (e) => {
        setSearchInput(e?.target?.value)
    }


    let router= history?.location?.pathname;

    let routerMark=router==='/' ? true :false;
    // useEffect(() => {
    //     let getLinkTag = document.querySelectorAll('.main-menu-desktop__items-wrap__items__single .accordion-body li a');
    //
    // }, [projectTypeOption]);

    //
    // let getLinkTag = document.querySelectorAll('.main-menu-desktop__items-wrap__items__single .accordion-header');
    // useEffect(() => {
    //     for (let i of getLinkTag) {
    //         i.addEventListener("click", () => {
    //             gsap.fromTo(
    //                 '.main-menu-desktop__items-wrap__items__single .accordion-body li a',
    //                 {
    //                     opacity: 0,
    //                     visibility: 'hidden',
    //                     top: '70px',
    //                 },
    //                 {
    //                     opacity: 1,
    //                     visibility: 'visible',
    //                     top: '0',
    //                     duration: 0.5, // Adjust the duration as needed
    //                     zIndex: 1, // You may set the desired zIndex here
    //                 }
    //             );
    //         });
    //     }
    // }, [projectTypeOption,isAccordionOpenResidential]);





    return (
        <StyledMenu className={`${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up ' : ''}  main-menu-desktop`} routerMark={routerMark} >
            <Container ref={containerRef}>
                <Row>
                    <Col md={3} className='nav-list'>
                        <ul>
                            <li>
                                <Link to={'/'}>
                                    Home
                                </Link>
                            </li>
                            <li className={location.pathname === '/about' ? 'active' : ''}>
                                <Link to={'/about'}>
                                    About Us
                                </Link>
                            </li>
                            <li className={location.pathname === '/projects' ? 'active' : ''}>
                                <Link to={'/projects'}>
                                    Projects
                                </Link>
                            </li>
                        </ul>

                    </Col>

                    <Col md={{span:2,offset:2}} className='logo justify-content-center align-items-center d-flex b-logo desktop-logo'>
                        <Link to={'/'}>
                            {
                                router==='/' ?
                                    <img src={'/images/static/logo.svg'} alt=""/>:
                                    <svg xmlns="http://www.w3.org/2000/svg" width="173.233" height="50" viewBox="0 0 173.233 50">
                                        <g id="Group_20032" data-name="Group 20032" transform="translate(7952 -1474)">
                                            <g id="Sarnmar_-_White" data-name="Sarnmar - White" transform="translate(-7952 1474)">
                                                <g id="Group_20007" data-name="Group 20007" transform="translate(0 0)">
                                                    <path id="Path_7002" data-name="Path 7002" d="M51.714,42.23a5.014,5.014,0,0,0-.994-.066H48.733a5.014,5.014,0,0,0-.994.066c-.2-.6-.4-1.259-.729-2.12q-.5-1.192-.994-2.584c-.4-.927-.729-1.789-1.126-2.716s-.729-1.789-1.06-2.65c-.795,0-1.656-.066-2.451-.066H36.544c-.729,0-1.524,0-2.319.066C33.431,34.148,32.7,36,32.04,37.792s-1.126,3.246-1.457,4.438a9.784,9.784,0,0,0-1.391-.066,8.892,8.892,0,0,0-1.391.066c2.054-4.438,4.041-8.877,6.028-13.249,1.921-4.372,3.908-8.877,5.9-13.381h.729q.894,2.186,1.987,4.77l2.186,5.167c.729,1.722,1.457,3.511,2.186,5.233s1.457,3.312,2.054,4.836c.662,1.457,1.259,2.782,1.722,3.975A22.654,22.654,0,0,0,51.714,42.23ZM34.888,30.372c.662,0,1.325,0,2.054.066.729,0,1.391.066,2.054.066h2.12c.729,0,1.391,0,2.12-.066L39,20.767Z" transform="translate(-9.384 -5.28)" fill="#897059"/>
                                                    <path id="Path_7003" data-name="Path 7003" d="M186.214,42.23a5.014,5.014,0,0,0-.994-.066h-1.987a5.014,5.014,0,0,0-.994.066c-.2-.6-.4-1.259-.729-2.12q-.5-1.192-.994-2.584c-.4-.927-.729-1.789-1.126-2.716s-.729-1.789-1.06-2.65c-.795,0-1.656-.066-2.451-.066h-4.836c-.729,0-1.524,0-2.319.066-.795,1.987-1.524,3.842-2.186,5.631s-1.126,3.246-1.457,4.438a9.784,9.784,0,0,0-1.391-.066,8.892,8.892,0,0,0-1.391.066c2.054-4.438,4.041-8.877,6.028-13.249,1.921-4.372,3.908-8.877,5.9-13.381h.729q.894,2.186,1.987,4.77l2.186,5.167c.729,1.722,1.457,3.511,2.186,5.233s1.457,3.312,2.054,4.836c.662,1.457,1.259,2.782,1.722,3.975A22.651,22.651,0,0,0,186.214,42.23ZM169.388,30.372c.662,0,1.325,0,2.054.066.729,0,1.391.066,2.054.066h2.12c.729,0,1.391,0,2.12-.066l-4.24-9.672Z" transform="translate(-54.786 -5.28)" fill="#897059"/>
                                                    <path id="Path_7004" data-name="Path 7004" d="M1.987,38.653a4.552,4.552,0,0,0,1.259,1.259,6.077,6.077,0,0,0,1.722.927,5.829,5.829,0,0,0,2.186.331,4.9,4.9,0,0,0,2.252-.464,4.481,4.481,0,0,0,1.722-1.192,4.2,4.2,0,0,0,1.06-1.722,5.738,5.738,0,0,0,.4-2.12,4.024,4.024,0,0,0-.927-2.782,7.52,7.52,0,0,0-2.252-1.722,28.33,28.33,0,0,0-2.849-1.259A12.805,12.805,0,0,1,3.71,28.451a8.835,8.835,0,0,1-2.252-2.186,6.406,6.406,0,0,1-.795-3.511,6.949,6.949,0,0,1,.6-2.915,6.226,6.226,0,0,1,1.656-2.252A7.082,7.082,0,0,1,5.5,16.13a11.059,11.059,0,0,1,3.445-.53,9.45,9.45,0,0,1,3.18.464,7.368,7.368,0,0,1,2.451,1.325c-.2.464-.4.861-.6,1.391a14.943,14.943,0,0,0-.464,1.855H13.05c-.132-.331-.331-.729-.53-1.192a6.724,6.724,0,0,0-.861-1.192,6.711,6.711,0,0,0-1.325-.927,4.043,4.043,0,0,0-1.855-.331,6.245,6.245,0,0,0-1.987.331,3.587,3.587,0,0,0-1.457.994,3.95,3.95,0,0,0-1.06,1.457,4.515,4.515,0,0,0-.331,1.921,4,4,0,0,0,.927,2.849,7.52,7.52,0,0,0,2.252,1.722,28.329,28.329,0,0,0,2.849,1.259,16.155,16.155,0,0,1,2.849,1.325A7.4,7.4,0,0,1,14.772,30.9a5.679,5.679,0,0,1,.927,3.378,9.181,9.181,0,0,1-.6,3.312,7.638,7.638,0,0,1-1.855,2.716A7.708,7.708,0,0,1,10.334,42.1a11.818,11.818,0,0,1-3.908.6,10.392,10.392,0,0,1-3.842-.6A10.236,10.236,0,0,1,0,40.64a10.109,10.109,0,0,0,.464-1.59c.066-.464.2-1.06.265-1.789h.464A7.454,7.454,0,0,0,1.987,38.653Z" transform="translate(0 -5.28)" fill="#897059"/>
                                                    <path id="Path_7005" data-name="Path 7005" d="M121.538,26.067c1.789,3.378,3.643,6.889,5.631,10.467.265-.464.6-1.192,1.126-2.054.464-.927,1.06-1.921,1.656-3.113.662-1.192,1.325-2.451,2.054-3.776s1.457-2.716,2.186-4.107,1.457-2.782,2.252-4.107c.729-1.325,1.391-2.584,2.054-3.776h.464c.265,2.451.53,4.77.795,7.088.265,2.252.464,4.5.729,6.691s.53,4.372.729,6.492c.265,2.12.53,4.24.861,6.359a4.075,4.075,0,0,0-.861-.066h-1.855a3.777,3.777,0,0,0-.861.066c-.265-3.378-.53-6.624-.861-9.8-.265-3.18-.6-6.426-1.06-9.672h-.066l-5.167,9.539c-1.656,3.18-3.312,6.492-4.836,9.937h-.331c-1.524-3.18-3.113-6.426-4.77-9.605s-3.312-6.492-5.035-9.8H116.3c-.2,1.59-.4,3.312-.6,5.1-.2,1.855-.4,3.643-.6,5.432s-.331,3.445-.464,5.035-.2,2.915-.2,3.908a13.282,13.282,0,0,0-2.65,0c.331-2.054.662-4.24,1.06-6.492.331-2.252.729-4.571,1.06-6.889s.662-4.571.927-6.823.53-4.372.729-6.426h.662C117.961,19.177,119.749,22.688,121.538,26.067Z" transform="translate(-37.739 -5.28)" fill="#897059"/>
                                                    <path id="Path_7006" data-name="Path 7006" d="M81.988,32.394c-3.312-3.511-6.624-7.088-9.87-10.533,0,.729-.066,1.656-.066,2.849s-.066,2.848-.066,4.9V31.6c0,.795,0,1.59.066,2.451,0,.861,0,1.722.066,2.65,0,.861.066,1.722.066,2.451s.066,1.457.066,1.987c0,.6.066.927.066,1.192a8.446,8.446,0,0,0-1.325-.066,8.892,8.892,0,0,0-1.391.066,11.207,11.207,0,0,0,.2-1.391c.066-.662.132-1.524.2-2.451s.132-1.987.2-3.113.132-2.252.132-3.378c.066-1.126.066-2.186.066-3.246s.066-1.987.066-2.848V19.608c0-1.722-.066-3.047-.066-3.908h.6q2.285,2.484,4.968,5.366c1.789,1.921,3.511,3.776,5.233,5.7,1.722,1.855,3.378,3.643,4.968,5.3s2.915,3.047,4.107,4.24V31.135c0-2.65,0-5.233-.066-7.751s-.132-4.9-.265-7.154a5.355,5.355,0,0,0,1.325.2,4.454,4.454,0,0,0,1.192-.2c-.066.53-.132,1.391-.2,2.517s-.132,2.319-.2,3.577-.132,2.517-.2,3.71-.066,2.186-.066,2.915v4.968c0,.927,0,1.855.066,2.849,0,.927,0,1.855.066,2.65v3.378h-.53C88.413,39.349,85.3,35.9,81.988,32.394Z" transform="translate(-23.494 -5.314)" fill="#897059"/>
                                                    <path id="Path_7007" data-name="Path 7007" d="M203.965,26.2c0-1.656,0-3.312-.066-4.9s-.133-3.246-.2-4.9c.729.066,1.457.066,2.186.132s1.457.066,2.186.066,1.457,0,2.186-.066,1.457-.066,2.186-.066a15.334,15.334,0,0,1,3.047.331,7.154,7.154,0,0,1,2.517.994,4.65,4.65,0,0,1,1.722,1.855,6.052,6.052,0,0,1,.6,2.849,5.881,5.881,0,0,1-.795,3.113,7.843,7.843,0,0,1-1.987,2.186,10.212,10.212,0,0,1-2.517,1.325,10.058,10.058,0,0,1-2.451.6c.331.4.729.927,1.259,1.59s1.126,1.457,1.789,2.252,1.325,1.656,2.054,2.584,1.391,1.789,2.054,2.584,1.259,1.59,1.855,2.252,1.06,1.192,1.391,1.59a6.427,6.427,0,0,0-1.126-.066H219.6a6.427,6.427,0,0,0-1.126.066c-.4-.53-.927-1.325-1.722-2.385s-1.656-2.186-2.584-3.445c-.927-1.192-1.921-2.451-2.849-3.643-.994-1.192-1.855-2.252-2.65-3.047h-1.457v2.782c0,1.656,0,3.312.066,4.968s.132,3.246.2,4.77a26,26,0,0,0-3.71,0l.2-4.77c.066-1.656.066-3.246.066-4.968V26.2Zm3.18,2.186a5.942,5.942,0,0,0,1.192.132c.4,0,.861.066,1.391.066a10.665,10.665,0,0,0,2.451-.265A6.555,6.555,0,0,0,214.5,27.4a5.761,5.761,0,0,0,1.789-1.789,5.34,5.34,0,0,0,.729-2.849,5.367,5.367,0,0,0-.53-2.451,4.042,4.042,0,0,0-1.457-1.524,4.931,4.931,0,0,0-1.921-.795,10.11,10.11,0,0,0-1.987-.2,20.661,20.661,0,0,0-2.186.066c-.6.066-1.06.066-1.457.132-.066,1.391-.133,2.782-.133,4.173-.066,1.391-.066,2.716-.066,4.107v2.12Z" transform="translate(-68.761 -5.55)" fill="#897059"/>
                                                </g>
                                                <path id="Path_7008" data-name="Path 7008" d="M200.8,36.707s43.324-43.39-83.4-33.255c0,0,82.275-12.851,94.531,11.2C216.833,24.253,200.8,36.707,200.8,36.707Z" transform="translate(-39.629 -0.022)" fill="#b2a89f"/>
                                                <g id="Group_15920" data-name="Group 15920" transform="translate(74.326 43.839)">
                                                    <path id="Path_7009" data-name="Path 7009" d="M112.2,66.4h.729v5.233h2.716V72.3h-3.378V66.4Z" transform="translate(-112.2 -66.268)" fill="#897059"/>
                                                    <path id="Path_7010" data-name="Path 7010" d="M119.6,66.4h.729v5.9H119.6Z" transform="translate(-114.698 -66.268)" fill="#897059"/>
                                                    <path id="Path_7011" data-name="Path 7011" d="M122.6,66.4h.795l1.789,4.836h0l1.855-4.836h.729l-2.319,5.9h-.662Z" transform="translate(-115.711 -66.268)" fill="#897059"/>
                                                    <path id="Path_7012" data-name="Path 7012" d="M132.6,66.4h.729v5.9H132.6Z" transform="translate(-119.086 -66.268)" fill="#897059"/>
                                                    <path id="Path_7013" data-name="Path 7013" d="M136.9,66.4h.861l3.378,4.9h0V66.4h.729v5.9h-.861l-3.378-4.9h0v4.9H136.9Z" transform="translate(-120.538 -66.268)" fill="#897059"/>
                                                    <path id="Path_7014" data-name="Path 7014" d="M152.466,71.831a2.927,2.927,0,0,1-1.06.4,6.526,6.526,0,0,1-1.126.132,3.857,3.857,0,0,1-1.259-.2,2.035,2.035,0,0,1-.994-.662,2.621,2.621,0,0,1-.662-.994,3.873,3.873,0,0,1-.265-1.259,2.688,2.688,0,0,1,.265-1.259,2.621,2.621,0,0,1,.662-.994,2.5,2.5,0,0,1,.994-.6,3.857,3.857,0,0,1,1.259-.2,3.364,3.364,0,0,1,1.126.2,3.953,3.953,0,0,1,.994.6l-.53.53a2.942,2.942,0,0,0-.729-.53,2.04,2.04,0,0,0-.861-.2,2.116,2.116,0,0,0-.927.2,1.879,1.879,0,0,0-.729.53,2.382,2.382,0,0,0-.53.795,2.42,2.42,0,0,0-.2.994,2.658,2.658,0,0,0,.2.994,2.1,2.1,0,0,0,.53.795,2.382,2.382,0,0,0,.795.53,2.42,2.42,0,0,0,.994.2,2.717,2.717,0,0,0,.729-.066,2.373,2.373,0,0,0,.729-.265V69.711h-1.325v-.662H152.6v2.782Z" transform="translate(-123.981 -66.2)" fill="#897059"/>
                                                    <path id="Path_7015" data-name="Path 7015" d="M163,66.4h.729v5.9H163Z" transform="translate(-129.348 -66.268)" fill="#897059"/>
                                                    <path id="Path_7016" data-name="Path 7016" d="M167.3,66.4h.861l3.378,4.9h0V66.4h.729v5.9h-.861l-3.378-4.9h0v4.9H167.3Z" transform="translate(-130.799 -66.268)" fill="#897059"/>
                                                    <path id="Path_7017" data-name="Path 7017" d="M182.7,66.4h.729v5.233h2.716V72.3h-3.378V66.4Z" transform="translate(-135.998 -66.268)" fill="#897059"/>
                                                    <path id="Path_7018" data-name="Path 7018" d="M194.4,70.043a4.474,4.474,0,0,1-.132.927,1.309,1.309,0,0,1-.4.729,2.594,2.594,0,0,1-.662.53,2.323,2.323,0,0,1-1.06.2,2.746,2.746,0,0,1-1.06-.2,1.384,1.384,0,0,1-.662-.53,1.579,1.579,0,0,1-.4-.729,3.909,3.909,0,0,1-.132-.927V66.4h.729v3.511a2.042,2.042,0,0,0,.066.662,2.353,2.353,0,0,0,.265.6.891.891,0,0,0,.464.4,1.31,1.31,0,0,0,.729.132,2.382,2.382,0,0,0,.729-.132,2.881,2.881,0,0,0,.464-.4,2.353,2.353,0,0,0,.265-.6,2.253,2.253,0,0,0,.066-.662V66.4h.729Z" transform="translate(-138.428 -66.268)" fill="#897059"/>
                                                    <path id="Path_7019" data-name="Path 7019" d="M200.954,69.182,198.966,66.4h.927l1.59,2.319,1.59-2.319h.861l-1.987,2.782,2.186,3.113h-.927l-1.722-2.584L199.761,72.3H198.9Z" transform="translate(-141.466 -66.268)" fill="#897059"/>
                                                    <path id="Path_7020" data-name="Path 7020" d="M213.2,70.043a4.473,4.473,0,0,1-.132.927,1.308,1.308,0,0,1-.4.729,2.594,2.594,0,0,1-.662.53,2.323,2.323,0,0,1-1.06.2,2.746,2.746,0,0,1-1.06-.2,1.384,1.384,0,0,1-.662-.53,1.579,1.579,0,0,1-.4-.729,3.909,3.909,0,0,1-.132-.927V66.4h.729v3.511a2.042,2.042,0,0,0,.066.662,2.354,2.354,0,0,0,.265.6.891.891,0,0,0,.464.4,1.31,1.31,0,0,0,.729.132,2.382,2.382,0,0,0,.729-.132,2.882,2.882,0,0,0,.464-.4,2.352,2.352,0,0,0,.265-.6,2.253,2.253,0,0,0,.066-.662V66.4h.729Z" transform="translate(-144.774 -66.268)" fill="#897059"/>
                                                    <path id="Path_7021" data-name="Path 7021" d="M218.666,66.4h1.524a3.777,3.777,0,0,1,.861.066c.265.066.53.132.729.2a1.786,1.786,0,0,1,.53.464,1.657,1.657,0,0,1,.2.861,1.4,1.4,0,0,1-.4,1.06,1.8,1.8,0,0,1-1.06.53l1.656,2.716h-.861l-1.59-2.65h-.927V72.3H218.6V66.4Zm.729,2.584h1.192a1.934,1.934,0,0,0,.6-.132,1.068,1.068,0,0,0,.464-.265.752.752,0,0,0,.2-.53,1.248,1.248,0,0,0-.132-.53.661.661,0,0,0-.331-.265.957.957,0,0,0-.464-.132h-1.524Z" transform="translate(-148.116 -66.268)" fill="#897059"/>
                                                    <path id="Path_7022" data-name="Path 7022" d="M227.986,69.778,225.8,66.4h.861l1.656,2.65,1.722-2.65h.861l-2.186,3.378V72.3h-.729Z" transform="translate(-150.547 -66.268)" fill="#897059"/>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                            }
                        </Link>
                    </Col>
                    <Col md={{span:3,offset:2}} className='menu-right-col'>
                        <ul>
                            <li className={location.pathname === '/contact' ? 'active' : ''}>
                                <Link to={'/contact'}>
                                    Contact
                                </Link>
                            </li>
                        </ul>
                        <div ref={hamburgerRef} className={'hamburg'}>
                            <div className={'d-flex menu-right '}>
                                <span>Menu</span>
                                <div className='hamburger'>
                                    <div className="line"/>
                                    <div className="line"/>
                                    <div className="line"/>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li className='hover-here search-bar ' ref={searchClickRef}>

                                <svg id="Component_701" data-name="Component 70 – 1"
                                     width="40" height="40" viewBox="0 0 40 40">
                                    <defs>
                                        <linearGradient id="linear-gradient" x2="1" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#ad0000"/>
                                            <stop offset="1" stop-color="red"/>
                                        </linearGradient>
                                    </defs>
                                    <g id="Button-_Search" data-name="Button - Search"
                                       transform="translate(-1490 50)">
                                        <g id="Ellipse_450" data-name="Ellipse 450" transform="translate(1490 -50)"
                                           fill="none" stroke="#fff" stroke-width="1">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="19.5" fill="none"/>
                                        </g>
                                        <circle className='circle' id="Ellipse_485" data-name="Ellipse 485" cx="1"
                                                cy="1" r="1"
                                                transform="translate(1509 -31)" opacity="0"
                                                fill="url(#linear-gradient)"/>
                                        <path id="Path_8306" data-name="Path 8306"
                                              d="M-40.214,508.284a.5.5,0,0,1-.354-.146l-4.323-4.324a7.042,7.042,0,0,1-4.684,1.745h0a7.1,7.1,0,0,1-5.046-2.09,7.094,7.094,0,0,1-2.092-5.049,7.149,7.149,0,0,1,7.141-7.142h0a7.1,7.1,0,0,1,5.05,2.091,7.1,7.1,0,0,1,2.092,5.05,7.06,7.06,0,0,1-1.748,4.685l4.319,4.327a.5.5,0,0,1,0,.707A.5.5,0,0,1-40.214,508.284Zm-9.36-3.725h.013a6.164,6.164,0,0,0,4.327-1.79l.006-.006h0a6.076,6.076,0,0,0,1.8-4.34,6.1,6.1,0,0,0-1.8-4.344,6.1,6.1,0,0,0-4.343-1.8h0a6.148,6.148,0,0,0-6.141,6.142,6.1,6.1,0,0,0,1.8,4.341,6.1,6.1,0,0,0,4.34,1.8Z"
                                              transform="translate(1558.215 -529.781)" fill="#fff"/>
                                    </g>
                                </svg>
                            </li>
                            <div className="search-desktop" ref={searchItemRef}>
                                <Container>
                                    <BsX/>
                                    <Form onSubmit={searchSubmit}>
                                        <Form.Group className="search-input">
                                            {/*<Img width={'20px'} height={'20px'} top={'auto'} bottom={'20px'} src={'/images/static/search-red.svg'}/>*/}
                                            <Form.Control value={searchInput} onChange={e => handleInput(e)} type="text"
                                                          placeholder="Search here"/>
                                            <button type='submit'>Search</button>
                                            {/*<p>Search</p>*/}
                                        </Form.Group>
                                    </Form>
                                </Container>
                            </div>
                        </ul>
                    </Col>
                </Row>
            </Container>
         <div className="main-menu-desktop__items-wrap" ref={menuItemRef}>
                {/*<img src={bannerImage} alt=""/>*/}
                <div className="menu-close" ref={menuCloseRef}>
                    <BsX/>
                </div>
                <div className="main-menu-desktop__items-wrap__items">
                    {/**/}
                    <div className="main-menu-desktop__items-wrap__items__single">
                        <ul className='menu-top'>
                            <li className={'active'}><Link to={'/'}>Home</Link></li>
                        </ul>
                        <ul>
                            <li className={''}><Link
                                to={'/about'}>About</Link></li>
                            <li className={''}><Link
                                to={'/projects'}>Projects</Link></li>
                            <li className={''}><Link
                                to={'/joint-venture'}>Joint Venture</Link></li>
                            <li className={''}><Link
                                to={'/csr'}>CSR</Link></li>
                            <li className={''}><Link to={'/media-center'}>Media Center</Link></li>
                            <li className={''}><Link
                                to={'/career'}>Career</Link></li>
                            <li className={''}><Link
                                to={'/contact'}>Contact</Link></li>
                        </ul>
                        <Accordion>
                        </Accordion>
                        <div >
                            <a href={menu?.file} target={'_blank'}  className={'press-kit'}>
                                <h3>Press Kit</h3>
                                <div className={'d-flex justify-content-end align-items-center'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="19" viewBox="0 0 16.82 19">
                                        <g id="Group_13283" data-name="Group 13283" transform="translate(-1655.59 -7448.5)">
                                            <line id="Line_10" data-name="Line 10" x2="14" transform="translate(1657 7466.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                            <g id="Group_39" data-name="Group 39" transform="translate(0 0.5)">
                                                <g id="Group_7" data-name="Group 7" transform="translate(427.5 7406.5)">
                                                    <line id="Line_1" data-name="Line 1" x2="7" y2="6" transform="translate(1229.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_2" data-name="Line 2" x1="7" y2="6" transform="translate(1236.5 48.5)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" y2="12" transform="translate(1664 7449)" fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </g>
                                    </svg>

                                </div>
                            </a>
                        </div>
                        <p className='copyright'><span>© 2023 </span>Sanmar Properties Ltd.
                        </p>
                    </div>
                    {/**/}
                    <div className="main-menu-desktop__items-wrap__items__single">
                        <Accordion activeKey={isAccordionOpen ? '4' : null}>
                            <Accordion.Item eventKey={'4'} onClick={handleAccordionClickRent}>
                                {
                                    <Link to={'/for-rent'}>
                                        <Accordion.Header >
                                            For Rental
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_18356" data-name="Group 18356" transform="translate(-373 536.627) rotate(-90)">
    <circle id="circle" cx="16" cy="16" r="16" transform="translate(504.627 373)" fill="#fffdfb"/>
    <g id="Group_20026" data-name="Group 20026" transform="translate(6441.127 266.5) rotate(90)">
      <line id="Line_3865" data-name="Line 3865" x2="5" y2="5" transform="translate(120.5 5915.5)" fill="none" stroke="#897059" stroke-linecap="round" stroke-width="2"/>
      <line id="Line_3866" data-name="Line 3866" y1="5" x2="5" transform="translate(120.5 5920.5)" fill="none" stroke="#897059" stroke-linecap="round" stroke-width="2"/>
    </g>
  </g>
</svg>

                                            </span>
                                        </Accordion.Header>
                                    </Link>
                                }
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className={`main-menu-desktop__items-wrap__items__single ${isAccordionOpenCommercial ? 'active' : ''}`}>
                        <Accordion activeKey={isAccordionOpenCommercial ? '3' : null}>
                            <Accordion.Item eventKey={'3'} onClick={handleAccordionClickCommercial}>
                                {
                                    <>
                                        <Accordion.Header onClick={()=>setProjectType('Commercial')}>
                                            Commercial
                                            <span><BsChevronDown/></span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <li>
                                                <Link to={`/projects?type=commercial&status=retail-shopping-mall`}>
                                                    Retail (Shopping Mall)
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/projects?type=commercial&status=office-space`}>
                                                    Office Space
                                                </Link>
                                            </li>
                                            {/*{*/}
                                            {/*    projectTypeOption?.map((element)=>{*/}
                                            {/*        return(*/}
                                            {/*            <li>*/}
                                            {/*                <Link to={`/projects?type=commercial&status=${element?.value}`}>*/}
                                            {/*                    {element?.label}*/}
                                            {/*                </Link>*/}
                                            {/*            </li>*/}
                                            {/*        )*/}
                                            {/*    })*/}
                                            {/*}*/}
                                        </Accordion.Body>
                                    </>
                                }
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    {/**/}
                    <div className={`main-menu-desktop__items-wrap__items__single ${isAccordionOpenCollection ? 'active' : ''}`}>

                        <Accordion activeKey={isAccordionOpenCollection ? '2' : null}>
                            <Accordion.Item eventKey={'2'} onClick={handleAccordionClickCollection}>
                                {
                                    <>
                                        <Accordion.Header >
                                            Collections
                                            <span><BsChevronDown/></span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <li>
                                                <Link to={`/projects?collection=signature-collection`}>
                                                    Signature Collections
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/projects?collection=penthouse-collections`}>
                                                    Penthouse Collections
                                                </Link>
                                            </li>
                                            {/*{*/}
                                            {/*    collectionFilter?.map((element)=>{*/}
                                            {/*        return(*/}
                                            {/*            <li>*/}
                                            {/*                <Link to={`/projects?collection=${element?.value}`}>*/}
                                            {/*                    {element?.label}*/}
                                            {/*                </Link>*/}
                                            {/*            </li>*/}
                                            {/*        )*/}
                                            {/*    })*/}
                                            {/*}*/}
                                        </Accordion.Body>
                                    </>
                                }
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className={`main-menu-desktop__items-wrap__items__single ${isAccordionOpenResidential ? 'active' : ''}`}>

                        <Accordion activeKey={isAccordionOpenResidential ? '1' : null}>
                            <Accordion.Item eventKey={'1'} onClick={handleAccordionClickResidential}>
                                {
                                    <>
                                        <Accordion.Header onClick={()=>setProjectType('Residential')}>
                                            Residential
                                            <span><BsChevronDown/></span>
                                        </Accordion.Header>

                                        <Accordion.Body>
                                            <li>
                                                <Link to={`/projects?type=residential&status=ongoing`}>
                                                    Ongoing
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/projects?type=residential&status=completed`}>
                                                    Completed
                                                </Link>
                                            </li>
                                            {/*{*/}
                                            {/*    projectTypeOption?.map((element)=>{*/}
                                            {/*        return(*/}
                                            {/*            <li>*/}
                                            {/*                <Link to={`/projects?type=residential&status=${element?.value}`}>*/}
                                            {/*                    {element?.label}*/}
                                            {/*                </Link>*/}
                                            {/*            </li>*/}
                                            {/*        )*/}
                                            {/*    })*/}
                                            {/*}*/}
                                        </Accordion.Body>
                                    </>
                                }
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </div>
            </div>
        </StyledMenu>

    )
};

const StyledMenu = styled.section`
  .hidden {
    display: none;
  }
  width: 100%;
  position: fixed;
  height: 100px;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 99999;
  //background: rgba(137, 112, 89, 0.8);
  display: flex;
  align-items: center;
  transition: all .4s ease;

  //.search-form {
  //  display: none;
  //}
  //search
  .search-desktop {
    //height: 195px;
    background-color: #fff;
    //display: flex;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 100px;
    height: 0;
    display: none;
    opacity: 0;
    overflow: hidden;
    z-index: 9;

    .container {
      position: relative;
      opacity: 0;
    }

    svg {
      position: absolute;
      top: -25px;
      right: -14px;
      font-size: 22px;
      cursor: pointer;

      &:hover {
        color: ${hover};
      }
    }

    form {
      min-width: 100%;

      .search-input {
        position: relative;

        button,
        p {
          box-shadow: none;
          border: none;
          background-color: transparent;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: fit-content;
          font-size: 12px;
          line-height: 18px;
          color: #221f1f;
          transition: color 0.3s ease;
          margin: auto;
          cursor: pointer;

          &:hover {
            color: ${hover};
          }
        }

        span {
          position: absolute !important;
          bottom: 18px;
        }

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #221f1f;
          padding-left: 14px;
          padding-bottom: 9px;
          padding-top: 0;
          height: 50px;
          font-size: 32px;
          line-height: 36px;
          font-weight: 600;
          color: rgba(34, 31, 31, 0.2);

          ::placeholder {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            color: rgba(34, 31, 31, 0.2);
          }
        }
      }
    }

    &__menu {
      margin-top: 23px;
      min-width: 100%;

      p {
        font-size: 12px;
        color: ${hover};
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 13px;
      }

      ul {
        display: inline-flex;

        li {
          a {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #221f1f;
            display: flex;
            margin-right: 30px;
          }

          &:nth-last-child(1) {
            a {
              margin-right: 0;
            }
          }
        }
      }
    }

  }
  &.menu-fixed {
    background-color: rgba(137, 112, 89, 0.7);

    .container:after {
      display: none;
    }
  }

  .menu-right {
    width: 110px;
    height: 36px;
    border: 1px solid ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};
    border-radius: 28px;
    cursor: pointer;

    span {
      color:  ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};
      margin-right: 8px;
    }

    padding: 6px 20px;

  }

  .menu-right-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    ul {
      li {
        font-size: 18px !important;
        line-height: 24px !important;
        a {
          color: ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};
          :hover {
            color: ${props => props.routerMark ? `${ColorWhite}` :'#AC835E'} !important;
          }          
        }
        &.active {
          a {
            color: #AC835E;
            border-bottom: 2px solid #AC835E;
            pointer-events: none;
            padding-bottom: 15px;
          }
        }
      }
    }
    .search-bar{
      cursor: pointer;
      svg{
        g{
          stroke: ${props => props.routerMark ? `${ColorWhite}` :'#181D24'};
        }
      }
    }
  }
  @media (max-width: 1250px) and (min-width: 992px) {
    .menu-right-col {
      ul{
        :first-child{
          margin-right: 20px;
        }
      }
      .hamburg{
        margin-right: 20px;
      }
    }
    
  }

  .nav-list {
    //opacity: 0;
    ul {
      display: flex;
      align-items: center;
      height: 90px;
      justify-content: space-between;
      position: relative;

      li {
        font-size: 18px !important;
        line-height: 24px !important;
        a {
          //color: #FFFDFB;
          color: ${props => props.routerMark ? '#FFFDFB' :'#181D24'};

          :hover {
            color: ${props => props.routerMark ? '#FFFDFB' :'#AC835E'} !important;
          }
          margin-bottom: 15px;

        }
        &.active {
          a {
            color: #AC835E;
            border-bottom: 2px solid #AC835E;
            pointer-events: none;
            padding-bottom: 15px;

          }
        }
      }
    }
  }


  &:before {
    content: '';
    position: absolute;
    //background: linear-gradient(180deg, rgb(0 0 0 / 68%) 0%, rgb(0 0 0 / 36%) 47%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .container {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 15px;
      right: 15px;
      bottom: -30px;
      //background-color: #ffffff;
    }

  }

  .menu-section {
    width: 110px;
    height: 36px;
  }

  .hamburger {
    text-align: revert;
    display: flex;
    flex: 0 0 30px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
    //background-color: #000;
    //right: 15px;
    height: 18px;
    top: 0;
    bottom: 0;
    margin: auto;


    .line {
      width: 25px;
      height: 2px;
      background-color:  ${props => props.routerMark ? `#fff` :'#181D24'};
      position: absolute;
      right: 0;
      transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &:nth-of-type(3) {
        bottom: 0;
      }
    }

    //&:hover {
    //  .line {
    //    &:nth-of-type(1) {
    //      top: -4px;
    //    }
    //
    //    &:nth-of-type(2) {
    //      top: 0;
    //      bottom: 0;
    //      margin: auto;
    //    }
    //
    //    &:nth-of-type(3) {
    //      bottom: -4px;
    //    }
    //  }
    //
    //}

  }


  //menu item
  .main-menu-desktop__items-wrap {
    //background-color: rgba(18, 17, 19, 0.70);
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    z-index: 999;
    //left: 0;
    right: 0;
    width: 0;
    transition: all 1.5s ease;
    transform-origin: right top;
    overflow: hidden;
    transform: translateX(100px);

    .menu-close {
      position: absolute;
      top: 40px;
      right: 40px;
      height: 32px;
      width: 32px;
      background: rgba(255, 253, 251, 0.2);
      z-index: 3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 20px;
        z-index: 2;
        color: white;
      }

      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        background-color: ${ColorWhite};
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 0;
        width: 0;
        margin: auto;
        opacity: 0;
        transition: all .3s ease;
      }

      &:hover {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }

        svg {
          color: ${hoverNd};
        }
      }
    }

    > img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &__items {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      flex-direction: row-reverse;

      &__single {
        flex: 0 0 21.25%;
        max-width: 21.25%;
        height: 100vh;
        display: flex;
        //align-items: center;
        border-left: 1px solid #FFFFFF;
        flex-wrap: wrap;
        padding: 30px;
        //opacity: 0;
        transition: transform .3s ease, background-color 0.1s ease;
        //transition: transform .6s ease;
        transform: translateX(50%);
        background-color: rgba(18, 17, 19, 0.70);
        position: relative;
        padding-top: 348px;
        //transition: background-color 0.6s ease;
        &.active{
          background-color: ${ColorWhite};
        }
        

        .watermark {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .accordion-item{
          transition: opacity 0.6s ease;
          .accordion-header{
            &.active{
              button{
                color: ${hoverNd};
              }
            }
          }
        }

        .accordion-button {
          background-color: white;
          border: none;
          font-size: 14px;
          color: ${MidNightBlue};
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.75);
          margin-bottom: 17px;
          display: flex;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          padding-left: 0;
          position: relative;
          

          &:after {
            content: '';
            position: absolute;
            height: 32px;
            width: 32px;
            right: -10px;
            bottom: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('/images/static/upArrow.svg');

            transform: rotate(360deg);
            //transform: rotate(180deg);

          }

          &.collapsed:after {
            background-image: url('/images/static/downArrow.svg');

            transform: rotate(0deg);


          }

        }

        .accordion-body {
          padding-top: 5px;
          padding-bottom: 28px;
          li{
            margin-bottom: 16px;
            a {
              border: none;
              margin-bottom: 16px;
              padding-bottom: 0;
              color: ${hoverNd};
              font-size: 20px;
              line-height: 28px;
              font-weight: 400 !important;
            }
          }
        }

        .accordion {
          z-index: 5;

          &-item {

            .accordion-header {
              .accordion-button{
                color:${MidNightBlue}  ;
                
                &.collapsed{
                  color:${ColorWhite}!important
                }
              }
              button {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;

                span{
                  display: none;
                  //display: flex;
                  //justify-content: center;
                  //align-items: center;
                  //svg{
                  //  background-color: white;
                  //  height: 32px;
                  //  width: 32px;
                  //  border-radius: 50%;
                  //  fill: #897059;
                  //  path{
                  //    width: 5px;
                  //    height: 10px;
                  //
                  //  }
                  //  &:hover{
                  //    transform: rotate(180deg);
                  //    background-color: black;
                  //    fill: white;
                  //
                  //  }
                  //}
                }
              }

              &.active {
                button {
                  color: ${hoverNd};
                }
              }

            }
          }

          &-body {
            //padding-left: 15px;
            margin-bottom: 30px;
            font-size: 20px;
            line-height: 28px;
            //color: #897059;
            color: white;

            li {
              &.active {
                a {
                  color: ${MidNightBlue};
                }
              }

              //a {
              //  font-weight: 300 !important;
              //}
            }
          }
        }

        &:after {
          content: '';
          position: absolute;
          //background-color: #FFFDFB;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 0;
          transition: height 0.8s cubic-bezier(0.33, 1, 0.68, 1);
          //display: none;
        }

        h4 {
          color: #181D24;
          text-transform: capitalize !important;
          span{
            svg{
              transition: transform 0.3s ease;

            }
          }

        }

        // &:hover {
        //   h4 {
        //     span {
        //       background: ${hover};
        //
        //       svg {
        //         transform: rotate(180deg);
        //
        //         g{
        //           line{
        //             stroke: ${ColorWhite};
        //           }
        //         }
        //         // color: ${ColorWhite};
        //       }
        //     }
        //   }
        //
        //   svg {
        //     color: #FFF;
        //   }
        // }

        .close-scroll-menu {
          position: absolute;
          top: 40px;
          right: 25px;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${MidNightBlue};
          z-index: 99;
          cursor: pointer;
          opacity: 0;

          svg {
            font-size: 20px;
            color: #FFFDFB;
            z-index: 2;
          }

          &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: ${hoverNd};
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 0;
            width: 0;
            margin: auto;
            opacity: 0;
            transition: all .3s ease;
          }

          &:hover {
            &:after {
              height: 100%;
              width: 100%;
              opacity: 1;
            }

            svg {
              color: #FFF;
            }
          }
        }

        h4 {
          color: #ffffff;
          font-size: 20px;
          //margin: 0;
          font-weight: 600;
          white-space: nowrap;
          //position: relative;
          z-index: 2;
          transition: all .4s ease;
          position: absolute;
          //top: 120px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          height: fit-content;
          cursor: pointer;
          left: 30px;
          right: 20px;
          text-transform: uppercase;
          //transition: all .6s ease;
          transition-delay: .1s;
          white-space: normal;

          span {
            height: 32px;
            width: 32px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: all .8s ease;
            opacity: 1;

            svg {
              font-size: 20px;
              color: #000;
            }

          }
        }

        .collections {
          ol {
            li {
              a {
                text-transform: none;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 20px !important;
              }
            }
          }
        }

        .scroll-menu-wrap {
          //margin-top: 160px;
          width: 100%;
          height: 0;
          overflow: hidden;
          transition: all .5s ease;
          top: 0;
          bottom: 0;
          margin: auto;
          //padding-top: 140px;
          h5 {
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            color: #897059;
            text-transform: capitalize;
          }

          p {
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            color: #B2A89F;
            text-transform: capitalize;

          }
        }

        ol {
          margin: 0px 0 0 0;
          padding: 0 20px 50px 0;
          //display: none;
          position: relative;
          z-index: 2;
          //height: 425px;
          overflow: hidden;


          li {
            //list-style: auto !important;
            list-style-type: none;
            color: #ffffff;

            a {
              color: #ffffff;
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 17px;
              text-transform: uppercase;
              display: flex;
              flex-wrap: wrap;

              span {
                font-size: 10px;
                font-weight: 400;
                color: #939299;
                text-transform: inherit;
                display: flex;
                flex: 0 0 100%;
              }
            }
          }
        }

        &:nth-of-type(1) {
          border: none;
          flex: 0 0 15%;
          max-width: 15%;
          background-color: #897059;
          flex-direction: column;
          justify-content: center;
          align-items: baseline;
          padding-top: 0;

          &:after {
            display: none;
          }

          ul {
            //display: none;
            //margin-top: 100px;

            li {
              a {
                font-size: 20px;
                line-height: 28px;
                font-weight: 400;
                color: #FFFDFB;
                text-transform: capitalize;
                margin-bottom: 30px;
                display: flex;
                white-space: nowrap;
              }
            }
          }


          .menu-top {
            //position: absolute;
            //top: 130px;
            margin-top: 0;
          }

          .menu-bottom {
            //margin-top: 60px;
          }

          .press-kit {
            display: flex;
            position: absolute;
            bottom: 65px;
            cursor: pointer;

            svg {
              width: 14px;
              height: 17px;
            }

            h3 {
              font-size: 20px;
              line-height: 28px;
              font-weight: 400;
              color: #FFFDFB;
              margin-right: 10px;
            }
          }

          .copyright {
            position: absolute;
            bottom: 34px;
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            margin: 0;
            white-space: nowrap;
            color: #B2A89F;

            span {
              font-weight: 400;

            }
          }

          .copyright-dc {
            position: absolute;
            bottom: 20px;
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            margin: 0;
            white-space: nowrap;
            color: #B2A89F;

            span {
              font-weight: 400;

            }
          }

          //&:hover {
          //  a, li {
          //    color: #FFF !important;
          //  }
          //}

        }

        &:nth-last-of-type(1) {
          border: none;

        }

        &.open-scroll-menu {
          &:after {
            height: 100%;
          }

          .close-scroll-menu {
            opacity: 1;
          }

          .scroll-menu-wrap {
            height: 100%;
            visibility: visible;
          }

          h4 {
            top: 120px;
            bottom: calc(100% - 130px);
            color: #181D24;
            text-transform: capitalize;
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;

            span {
              opacity: 0;
              transition: all .3s ease;
            }
          }
          a{
            display: block;
          }

          a, li {
            color: #000000;
          }
        }

        ul {
          li {
            &.active {
              a {
                color: ${ColorWhite};
              }
            }

            a {
              &:hover {
                color: rgb(201, 201, 201) !important;
              }
            }
          }
        }

        &:hover {
          > h4 {
            color: ${hoverNd};
          }
        }
      }
    }

    &.menu-open {
      width: 100%;
      transform: none;
      transition: all 1.5s ease;

      .main-menu-desktop__items-wrap__items__single {
        //opacity: 1;
        transform: none;

        &:nth-of-type(5) {
          //transition-delay: 0.2s;
        }

        &:nth-of-type(4) {
          //transition-delay: .2s;
        }

        &:nth-of-type(3) {
          //transition-delay: .2s;
        }

        &:nth-of-type(2) {
          transition-delay: .1s;
          .accordion-button{
            &:after{
              transform: rotate(270deg);
            }
          }
        }


      }
    }

    &.hover-state {
      .main-menu-desktop__items-wrap__items__single:hover {
        &:after {
          height: 100%;
        }

        //h4 {
        //  color: #ffffff;
        //}
        //
        //a, li {
        //
        //  color: #ffffff;
        //}
      }
    }

  }


  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 1px;
    background: #181D24;
    margin-right: 2px;
  }

  .simplebar-scrollbar {
    left: -2px;
    background-color: #181D24;
    width: 5px;
  }

  .simplebar-track {
    overflow: visible !important;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
    background: #181D24;
  }

  .simplebar-offset {
    z-index: 9;
  }

  .simplebar-mask {
    z-index: 9;
  }

  .accordion {
    width: 100%;
    position: relative;

    svg {
      position: absolute;
      right: 90px;
    }

    .accordion-header {
      margin: 0;
      font-size: 0;
    }

    .accordion-button {
      background-color: transparent;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      margin-bottom: 17px;
      white-space: nowrap;
      padding-left: 0;
      width: 100%;
      text-align: left;
      position: relative;
    }
  }

  @media (min-width: 991px) {
    display: flex;
  }

  @media (max-width: 991px) {
    display: none;
  }

  @media (max-width: 414px) {
    .logo {
      img {
        height: 20px;
      }
    }
  }

`;


export default Menu;
