import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Link from "react-router-dom"
import Title from "../Title";
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import ResponsiveMasonry from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry/lib/Masonry";
import {ColorBg} from "../../styles/globalStyleVars";
const GalleryV1 = ({background, topBg, titleColor, img, data}) => {

    const gallery = data?.image;

    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img alt='' src={image} style={{width: "100%"}}/>
        </LightgalleryItem>

    );

    return (
        <StyledComponent id={'gallery'} className={'pt-150 pb-200'}>
            <Container>
                <div className="gallery-title ">
                    <Title text={data?.title? data?.title : 'Gallery'} color={'#897059'}/>
                </div>
            </Container>

            <LightgalleryProvider
                lightgallerySettings={
                    {
                        download: false,
                        thumbnail: false,
                        fullScreen: true,
                        share: false
                    }
                }
            >
                <ResponsiveMasonry
                    columnsCountBreakPoints={{750: 2, 900: 4}}
                >
                    <Masonry>
                        {
                            data?.list && data?.list?.length>0 &&
                            data?.list?.map((element)=>{
                                return(
                                    <PhotoItem image={element?.image} group={'1'} />
                                )
                            })
                        }
                    </Masonry>
                </ResponsiveMasonry>
            </LightgalleryProvider>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${ColorBg};

  .title {
    padding-bottom: 40px;
    margin-bottom: 0;
  }

  .gallery-title {
    width: 100%;
  }

  .react_lightgallery_item {
    cursor: pointer;
    //border-top: 1px solid rgba(255,253,251,0.5);
    //border-right: 1px solid rgba(255,253,251,0.5);

  }

  .db-btn {
    background-color: #736640;
  }

  .shadow-bottom {
    position: absolute;
    left: 100px;
    bottom: 250px;
  }

  .shadow-right {
    position: absolute;
    right: 50px;
    bottom: -50px;
  }

  @media (max-width: 767px) {
    .shadow-right {
      bottom: 40px;

      svg {
        height: 40px;
      }
    }
  }

`;

export default GalleryV1;